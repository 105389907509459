import React from "react";
import wrong_way from "../../assets/images/wrong_way.jpeg";
import { StyleSheet, css } from "../../config/my-aphrodite";
import Header from "../../components/Header";
import { Typography } from "antd";

const { Title } = Typography;

const Lost404Page = props => {
    const styles = StyleSheet.create({
        body: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
            backgroundSize: "cover",
            backgroundImage: "url(" + wrong_way + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "100% 40%"
        },
        tint: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: "hsl(0,0%, 0%, .6)",
            width: "100%",
            height: "100% !important",
            overflowY: "auto", //used to be scroll
            overflowX: "hidden",
            color: "white"
        }
    });

    let styling: any = {
        position: "absolute",
        top: "10%",
        left: "15%",
        fontSize: "100px",
        fontWeight: 600
    };

    return (
        <div className={css(styles.body)}>
            <Header />
            <div className={css(styles.tint)}>
                <p style={styling}> 404 </p>
                <Title style={{ color: "white" }} level={2}>
                    You seem lost. Let's help you back{" "}
                    <a href="/" style={{ color: "yellow", textDecoration: "underline" }}>
                        "home"
                    </a>{" "}
                </Title>
            </div>
        </div>
    );
};

export default Lost404Page;
