const aphrodite = require('aphrodite')

function pseudochild (selector, baseSelector, generateSubtreeStyles) {
    const regex = /^#:\w+\s{1}\w+$/im
    if (!selector.match(regex)) {
      return null
    }
  
    const pseudo = selector.slice(1).split(' ')[0]
    const parentsel = '.' + selector.split(' ')[1]
    return generateSubtreeStyles(parentsel + pseudo + ' ' + baseSelector)
}
  
  const myExtension = {selectorHandler: pseudochild}

  
  //export default StyleSheets.extend([myExtension])
  module.exports = aphrodite.StyleSheet.extend([myExtension])
