import React, { useState, useContext } from "react";
import { SearchContext } from "../../../context/search-context/search-context";
import Grid from "@mui/material/Grid";
import Popover from "antd/es/popover";
import { PriceRange } from "./price-range";
import { PropertyTypes } from "./property-types";
import { Location } from "./location";
import Button from "@mui/material/Button";
import AntButton from "antd/es/button";
import Modal from "antd/es/modal";
import TextField from "@mui/material/TextField";
import { Divider } from "antd";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import { FormLabel } from "@mui/material";
import numeral from "numeral";
import Radio from "antd/es/radio";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import axios from "axios";

import "./search-bar.scss";
import { RulesFilter } from "./rules";
import { OtherFilters } from "./other";
import { AcquisitionFilter } from "./acquisition";
import { ShortStayDuration } from "./short-stay-duration";

interface ISearchBar {
    search: any;
}
export const SearchBar = ({ search }: ISearchBar) => {
    const [filter, setFilter] = useState<boolean>(false);
    const {
        propertyFilters,
        handleFilterChange,
        handleLocationChange,
        handleLocationClear,
        setPropertyFilters,
        handleBathroomChange,
        handleBedroomChange,
        handleReset
    } = useContext(SearchContext);
    const [autocomplete, setAutocomplete] = useState<any>(null);

    const [query, setQuery] = useState("");

    function toggleFilter() {
        setFilter(!filter);
    }

    const add_K_inPlaceOfZeros = (amount: number) => {
        let number = Math.round(amount);
        let length = number.toString().length;
        if (number.toString().length > 4) {
            if (length < 7) {
                return `${number / 1000}K`;
            } else {
                return `${number / 1000000}M`;
            }
        } else {
            return `${number.toFixed(0)}`;
        }
    };

    const onPriceChange = (e, price_field) => {
        const value = e.target.value.replaceAll(",", "");
        const re = /^[0-9\b]+$/;
        if (value === "" || re.test(value)) {
            const value_to_number = Number(value);
            if (price_field === "min")
                setPropertyFilters({
                    ...propertyFilters,
                    price: {
                        min: value_to_number,
                        max: value_to_number > propertyFilters.price.max ? value_to_number : propertyFilters.price.max
                    }
                });
            if (price_field === "max")
                setPropertyFilters({ ...propertyFilters, price: { ...propertyFilters.price, max: value_to_number } });
        }
    };

    function onLoad(auto: any) {
        console.log("autocomplete: ", auto);
        setAutocomplete(auto);
    }

    const handlePlaceChanged = () => {
        if (autocomplete) {
            const place = autocomplete.getPlace();
            console.log("Sorcery", place);
            setQuery(place.formatted_address);
            handleLocationChange({ longitude: place.geometry.location.lng(), latitude: place.geometry.location.lat() });
            console.log(place.geometry.location.lat(), place.geometry.location.lng());
        }
    };

    const clearLocation = () => {
        setQuery("");
        handleLocationClear();
    };

    return (
        <div id="search-bar">
            <Modal
                title="More Filters"
                open={filter}
                onCancel={toggleFilter}
                footer={[
                    <AntButton onClick={toggleFilter}>Close</AntButton>,
                    <AntButton key="back" onClick={handleReset}>
                        Reset
                    </AntButton>,
                    <AntButton
                        key="submit"
                        type="primary"
                        onClick={() => {
                            search();
                            toggleFilter();
                        }}>
                        Search
                    </AntButton>
                ]}>
                <div>
                    <Grid container className="px-3" spacing={1}>
                        <Grid item xs={12} sm={6} md={6} lg={4}></Grid>

                        <Grid item md={12} lg={12} sm={12}>
                            <AcquisitionFilter />
                        </Grid>
                        <Divider type="horizontal" />

                        {propertyFilters?.acquisition === "short_stay" && (
                            <>
                                <Grid item md={12} lg={12} sm={12}>
                                    <ShortStayDuration />
                                </Grid>
                                <Divider type="horizontal" />
                            </>
                        )}

                        <Grid item md={12} lg={12} sm={12}>
                            <PropertyTypes />
                        </Grid>
                        <Divider type="horizontal" />

                        {propertyFilters?.category === "residential" && (
                            <>
                                <Grid item md={6} sm={12} className="d-flex justify-content-center">
                                    <FormControl fullWidth>
                                        <FormLabel component="legend">
                                            <h6 className="text-center fw-bold">Bedrooms</h6>
                                        </FormLabel>
                                        <div className="d-flex justify-content-center">
                                            <button
                                                type="button"
                                                onClick={() => handleBathroomChange("bathroom-plus")}
                                                name="bathroom-plus"
                                                className="counter-button-l">
                                                <i className="fa-solid fa-plus"></i>
                                            </button>
                                            <div style={{ fontSize: "bold", padding: "0 10px" }}>
                                                <Chip color="default" label={propertyFilters.bathrooms} />
                                            </div>
                                            <button
                                                type="button"
                                                onClick={() => handleBathroomChange("bathroom-minus")}
                                                name="bathroom-minus"
                                                className="counter-button-l">
                                                <i className="fa-solid fa-minus"></i>
                                            </button>
                                        </div>
                                    </FormControl>
                                </Grid>

                                <Grid item md={6} sm={12} className="d-flex justify-content-center">
                                    <FormControl fullWidth className="d-flex justify-content-center">
                                        <FormLabel component="legend">
                                            <h6 className="text-center fw-bold">Bedrooms</h6>
                                        </FormLabel>
                                        <div className="d-flex justify-content-center">
                                            <button
                                                type="button"
                                                onClick={() => handleBedroomChange("bedroom-plus")}
                                                name="bedroom-plus"
                                                className="counter-button-l">
                                                <i className="fa-solid fa-plus"></i>
                                            </button>
                                            <div style={{ fontSize: "bold", padding: "0 10px" }}>
                                                <Chip
                                                    color="default"
                                                    style={{ padding: "0, 30px" }}
                                                    label={propertyFilters.bedrooms}
                                                />
                                            </div>
                                            <button
                                                type="button"
                                                onClick={() => handleBedroomChange("bedroom-minus")}
                                                name="bedroom-minus"
                                                className="counter-button-l">
                                                <i className="fa-solid fa-minus"></i>
                                            </button>
                                        </div>
                                    </FormControl>
                                </Grid>
                                <Divider type="horizontal" />
                            </>
                        )}

                        <Grid item md={12} lg={12} sm={12}>
                            <RulesFilter />
                        </Grid>
                        <Divider type="horizontal" />

                        <Grid item md={12} lg={12} sm={12}>
                            <OtherFilters />
                        </Grid>
                        <Divider type="horizontal" />

                        <Grid item xs={12} md={12} sm={12} className="d-flex justify-content-center">
                            <PriceRange hideReset={true} />
                        </Grid>
                    </Grid>
                </div>
            </Modal>

            <Grid container style={{ width: "100%" }} spacing={1}>
                <Grid item xs={6} sm={6} lg={4}>
                    {/* <Popover trigger="click" placement="bottom" content={<Location />}>
                        <input type="text" placeholder="Location" className="sp-location-input" />
                    </Popover> */}
                    <LoadScript libraries={["places"]} googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}>
                        <Autocomplete
                            onLoad={onLoad}
                            options={{
                                types: ["geocode"],
                                componentRestrictions: { country: "gh" }
                            }}
                            onPlaceChanged={handlePlaceChanged}>
                            {/* <input
                            type="text"
                            placeholder="Enter a location"
                            value={query}
                            onChange={event => setQuery(event.target.value)}
                        /> */}
                            <div style={{ position: "relative" }}>
                                <input
                                    type="text"
                                    onChange={event => setQuery(event.target.value)}
                                    value={query}
                                    className="sp-location-input"
                                    placeholder="Enter a location"
                                />
                                <i
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        right: "10px",
                                        transform: "translate(-50%, -50%)",
                                        cursor: "pointer"
                                    }}
                                    className="fa-solid fa-rectangle-xmark"
                                    onClick={clearLocation}></i>
                            </div>
                        </Autocomplete>
                    </LoadScript>
                </Grid>

                <Grid item xs={2} className="view-mobile">
                    <select name="acquisition" onChange={handleFilterChange} className="sp-location-input">
                        <option value={""}>Acquisition Type</option>
                        <option value={"buy"}>Buy</option>
                        <option value={"rent"}>Rent</option>
                        <option value={"short_stay"}>Short Stay</option>
                    </select>
                </Grid>
                <Grid item xs={2} className="view-mobile">
                    <Popover trigger="click" placement="bottom" content={<PriceRange />}>
                        <div className="sc-filter">
                            {"$" + add_K_inPlaceOfZeros(propertyFilters?.price?.min)} -{" "}
                            {"$" + add_K_inPlaceOfZeros(propertyFilters?.price?.max)}
                        </div>
                    </Popover>
                </Grid>
                <Grid item xs={1} className="view-mobile">
                    <Popover trigger="click" placement="bottom" content={<PropertyTypes />}>
                        <div className="sc-filter">TYPE(1)</div>
                    </Popover>
                </Grid>
                {/* <Grid item xs={1}>
                    <div className="sc-filter">2 BEDS</div>
                </Grid> */}
                <Grid item xs={3} md={2} lg={1} className="me-3">
                    <Button sx={{ height: "45px" }} onClick={toggleFilter} variant="outlined" color="primary">
                        <i className="fa-solid fa-filter me-2"></i>
                        Filter
                    </Button>
                </Grid>
                <Grid item xs={1} md={2} lg={1}>
                    <Button style={{ height: "45px" }} onClick={search} variant="contained" color="primary">
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};
