import React, { useState } from "react";
import { AuthenticationContext } from "./auth-context";
import "./auth-styling.scss";
import { Login } from "./login";
import { SignUp } from "./signup";
import Modal from "antd/es/modal";

export const AuthenticationProvider = ({ children }) => {
    const [openLogin, setOpenLogin] = useState(false);
    const [openSignUp, setOpenSignUp] = useState(false);

    const openLoginModal = () => {
        setOpenLogin(true);
        setOpenSignUp(false);
    };

    const closeLoginModal = () => {
        setOpenLogin(false);
    };

    const openSignupModal = () => {
        setOpenSignUp(true);
        setOpenLogin(false);
    };

    const closeSignupModal = () => {
        setOpenSignUp(false);
    };

    return (
        <AuthenticationContext.Provider value={{ openLoginModal, closeLoginModal, openSignupModal, closeSignupModal }}>
            <Modal visible={openLogin} destroyOnClose onCancel={/*handleLoginCancel*/ closeLoginModal} footer={null} width={450}>
                <Login openSignUp={openSignupModal} closeLogin={closeLoginModal} openForgotPassword={() => ""} />
            </Modal>

            <Modal visible={openSignUp} destroyOnClose onCancel={/*handleLoginCancel*/ closeSignupModal} footer={null} width={500}>
                <SignUp closeSignUp={closeSignupModal} openLogin={openLoginModal} />
            </Modal>

            {children}
        </AuthenticationContext.Provider>
    );
};
