import React, { useState, useEffect, useContext } from "react";
import Tabs from "antd/es/tabs";
import Header from "../../components/Header";
import { Properties } from "./screens/properties/properties";
import { Board } from "./screens/dashboard/dashboard";
import { Profile } from "./screens/profile/profile";
import { Documents } from "./screens/documents/documents";
import { Requests } from "./screens/requests/requests";
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import { PropertySearchPage } from "../property-search-page/property-search-page";
import { USER_KEY } from "../../common/keys/keys";
import { AdminProviderContext } from "../../common/admin-provider/admin-provider-context";
import { useQueryParams } from "../../utils/use-query-params";
import Drawer from "antd/es/drawer";
import "./aqivah-dashboard.scss";
import { XCalendar } from "./screens/calender/calender";

const AqivahDashboard = () => {
    const [tab, setTab] = useState("dashboard");
    const [open, setOpen] = useState(false);
    const { user, refreshAdmin } = useContext(AdminProviderContext);
    const qp = useQueryParams();

    const handleTabChange = (value: any) => {
        setTab(value);
    };

    const close = () => {
        setOpen(false);
    };

    // useEffect(() => {
    //     //check tab in query params
    //     const current_tab = qp.tab || null;
    //     if (current_tab) {
    //         setTab(current_tab);
    //     }

    //     const rawUser = localStorage.getItem(USER_KEY);
    //     if (rawUser) {
    //         const refinedUser = JSON.parse(rawUser);
    //         setUser(refinedUser);
    //         if (refinedUser.user_type == "owner" || refinedUser.user_type == "organization_member") {
    //             return;
    //         } else {
    //             window.location.href = "/";
    //         }
    //     }
    // }, []);

    const { TabPane } = Tabs;
    return (
        <div id="aqivah-dashboard">
            <Header />

            {/* <Modal
                open={open}
                width={950}
                bodyStyle={{ height: 500, overflowY: "scroll" }}
                onCancel={close}
                footer={null}
                title={"Property Search"}>
                <PropertySearchPage modal={true} />
            </Modal> */}
            <Drawer width={500} title="Search" placement="right" onClose={close} open={open}>
                <PropertySearchPage modal={true} />
            </Drawer>
            <div className="dashboard-container mb-5 py-2">
                <header className="d-flex mt-3 justify-content-between align-items-center">
                    <h3>Aqivah Dashboard</h3>
                    <div className="d-flex justify-content-between align-items-end">
                        <Button
                            type="primary"
                            onClick={() => setOpen(true)}
                            className="me-4 mb-2"
                            shape="circle"
                            icon={<i className="fa-solid fa-magnifying-glass"></i>}
                            size={"middle"}
                        />
                        <h5 className="fw-light">Welcome {user?.first_name}</h5>
                    </div>
                </header>
                <Tabs onChange={handleTabChange} activeKey={tab}>
                    <TabPane tab="Dashboard" key="dashboard">
                        <Board />
                    </TabPane>
                    {(user?.user_type === "owner" || user?.user_type === "organization_member") && (
                        <TabPane tab="Properties" key="properties">
                            <Properties />
                        </TabPane>
                    )}
                    <TabPane tab="Requests" key="requests">
                        <Requests />
                    </TabPane>
                    <TabPane tab="Documents" key="documents">
                        <Documents />
                    </TabPane>
                    {/* {(user?.user_type === "owner" || user?.user_type === "organization_member") && (
                        <TabPane tab="Advertisements" key="advertisements"></TabPane>
                    )} */}
                    {/* <TabPane tab="Messages" key="messages"></TabPane> */}
                    <TabPane tab="Profile" key="profile">
                        <Profile />
                    </TabPane>
                    <TabPane tab="Calender" key="canlender">
                        <XCalendar />
                    </TabPane>
                    {/* <TabPane tab="Calender" key="canlender"> 
                        <Calender />
                    </TabPane> */}
                </Tabs>
            </div>
        </div>
    );
};

export default AqivahDashboard;
