import React, { useState } from 'react';
import { Button, Tooltip, Modal, Descriptions } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Tag } from 'antd';
import { useSelector } from 'react-redux'

const Preferences = (props: any) => {

	// @ts-ignore: Unreachable code error
	const ownerProperties = useSelector(state => state.propertyFetch)
	const { properties } = ownerProperties

	const [visible, setVisible] = useState<boolean>(false)
	const [editOrDelete, setEditOrDelete] = useState<boolean>(false)

	const formatNumber = (num: any) => {
		return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}

	const removeUnderScoreAndCapitalize = (e: string) => {
		const new_string = e ? e.replace(/_/g, " ") : ""
		const split_string = new_string.split(" ")

		if (split_string[0] !== "") {

			const final_string = split_string.map((word: any) => {
				return word[0].toUpperCase() + word.substring(1)
			}).join(" ")

			//console.log(final_string)
			return final_string
		}
		else {
			return ""
		}
	}


	const columns = [
		{
			title: 'Name',
			dataIndex: 'type',
			key: 'type',
			render: (text) => <a href="javascript:;">{removeUnderScoreAndCapitalize(text)}</a>
		},
		{
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
			responsive: ['md']
		},
		{
			title: 'Location',
			dataIndex: 'location',
			key: 'location',
			responsive: ['md'],
			render: (Location) => {
				return <div>
					{Location.town}
				</div>
			}
		},
		{
			title: 'Price',
			key: 'prices',
			dataIndex: 'price',
			render: (Price) => {
				return <div>
					<Tag color={Price.length > 9 ? 'geekblue' : 'green'} key={Price}>
						GH&#162; {formatNumber(Price)}
					</Tag>
				</div>
			}
			// render: (Prices) => (
			// 	<div>
			// 		{Prices.map((price) => {
			// 			let color = price.length > 9 ? 'geekblue' : 'green';

			// 			return (
			// 				<Tag color={color} key={price}>
			// 					{price.toUpperCase()}
			// 				</Tag>
			// 			);
			// 		})}
			// 	</div>
			// )
		},
		{
			title: 'Action',
			key: 'action',

			render: (text, record) => (
				//<Space size="middle">
				<div style={{zIndex:10000}}>
					<div style={{ marginBottom: '5px', zIndex: 1 }}>
						<Button
							type="primary"
							size="small"
							icon={<EditOutlined />}
							onClick={() => {
							}}
						/>
					</div>
					<div style={{ zIndex: 1 }}>
						<Button
							danger={true}
							size="small"
							icon={<DeleteOutlined />}
							onClick={() => {
							}}
						/>
					</div>
				</div>
				//</Space>
			)
		}
	];

	const handleOk = (e) => {
		console.log(e);
		setVisible(false)
	};

	const handleCancel = (e) => {
		console.log(e);
		setVisible(false)
	};

	return (
		<div>
			<div className="preference-bar" style={{ backgroundColor: '#f3f2f7' }}>
				<Tooltip title="Add Preference">
					<Button
						className="add-preference-button"
						shape="circle"
						icon={<PlusOutlined style={{ color: '#fff' }} />}
						style={{
							textAlign: 'center',
							marginTop: '10px',
							marginBottom: '10px',
							backgroundColor: '#4d33dc'
						}}
					/>
				</Tooltip>
			</div>

			<Table
				className="preference-table"
				style={{ marginTop: '10px' }}
				// @ts-ignore: Unreachable code error
				columns={columns}
				dataSource={properties}
				onRow={(record, rowIndex) => {
					return {
						onClick: (event) => {
							if (editOrDelete === false) {
								setVisible(true);
							}
						} // click row
					};
				}}
			/>

			<Modal width={1000} title="Apartment" visible={visible} onOk={handleOk} onCancel={handleCancel}>
				<Descriptions bordered={true} column={1}>
					<Descriptions.Item span={3} style={{color: 'white !important'}} label="Category">Residential</Descriptions.Item>
					<Descriptions.Item span={3} label="Type">Apartment</Descriptions.Item>
					<Descriptions.Item span={3} label="Price">GHC 4500</Descriptions.Item>
					<Descriptions.Item span={3} label="Location">East Legon</Descriptions.Item>
					<Descriptions.Item span={3} label="Rooms">empty</Descriptions.Item>
					<Descriptions.Item span={3} label="Property Feature">empty</Descriptions.Item>
					<Descriptions.Item span={3} label="Local Amenities">empty</Descriptions.Item>
				</Descriptions>
			</Modal>
		</div>
	);
}

export default Preferences;
