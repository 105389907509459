import React from 'react'
import { Helmet } from "react-helmet"

const Meta = ({ title, description, image_url, url }) => {

    React.useEffect(()=> {
       console.log(image_url)
    }, [image_url])

    return (
        <div>
            <Helmet>
                <meta data-charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
                <meta name="theme-color" content="#000000" />
                <meta
                    name="description"
                    content={description}
                />

                {/* <meta data-name=”twitter:card” content="your one stop shop for finding your dream home"/> */}
                <meta property="og:title" content={`${title}`} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content={image_url} />
                <meta property="og:type" content="website"/>

                <meta property="twitter:title" content={title} />
                <meta property="twitter:description" content={description} />
                <meta property="twitter:url" content={window.location.href} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:image" content={image_url} />

                <title>{title}</title>
            </Helmet>
        </div>
    )
}

Meta.defaultProps = {
    title: 'Aqivah, Welcome Home',
    description: "Our mission is to make the process of aquiring, renting, leasing and selling all kinds of property quick, pleasurable, easy and secure.Discover why Aqivah is the right choice when buying or renting your next property.Draw the needed attention to your property, let Aqivah flaunt your property.",
    image_url: "",
    url: "https://aqivah.com"
}

export default Meta