import React, { lazy, Suspense, useEffect } from "react";
import useRefreshToken from "./Hooks/useRefreshToken";
import useAppInitialize from "./Hooks/useAppInitialize";
import useWebSocketReconnect from "./Hooks/useWebSocketReconnect";
import useReconnectOnInternetReconnection from "./Hooks/useReconnectOnInternetReconnection";
import { PrivateRoute } from "./components/PrivateRoutes";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LaunchPage from "./screens/launch-page/launch-page";
import Login from "./screens/authentication-pages/login-page";
import RegisterSeeker from "./screens/authentication-pages/register-seeker";
import CompleteOrganizationRegistration from "./screens/authentication-pages/complete-organization-registration";
import AcceptOrganizationInvitation from "./screens/authentication-pages/accept-organization-invitation";
import ForgotPassword from "./screens/forgot-password-page/forgot-password-page";
import Register from "./screens/register-page/register-page";
import { OwnerRegistration } from "./screens/register-page/owner-registration";
import { OrganizationRegistration } from "./screens/register-page/organization-registration";
import AddProperty from "./screens/add-property-page/add-property-page";
import { ResetPassword } from "./screens/reset-password-page/reset-password-page";
import Redirect from "./aqivah-info-page/Info_Redirect_page";
import SeekerDashboard from "./screens/seeker-dashboard/seeker-dashboard";
import AqivahDashboard from "./screens/aqivah-dashboard/aqivah-dashboard";
import { Organization } from "./screens/organization/organization";
import { OrganizationDashboard } from "./screens/organization-dashboard/organization";
import Lost404Page from "./screens/404Page/404Page";
import PolicyDoc from "./screens/privacy-policy/privacy-policy";
import TermsOfService from "./screens/terms-of-service/terms-of-service";
import ConfirmRegistration from "./screens/confirm-registration/confirm-registration";
import { PropertyPage } from "./screens/property-page/property-page";
import { PropertySearchPage } from "./screens/property-search-page/property-search-page";
import { ListPropertyPage } from "./screens/property-listing/list-property";
import { ListingInfo } from "./screens/property-listing/listing-info";
import axios, { AxiosResponse } from "axios";
import { AdminProvider } from "./common/admin-provider/admin-provider";
import { OrganizationProvider } from "./common/organization-provider/organization-provider";
import { AUTH_TOKEN_KEY, ORGANIZATION_KEY, USER_KEY } from "./common";
import { ExistingUserOwnerRegistration } from "./screens/register-page/existing-user-owner-registration";
import { PreferenceListing } from "./screens/preference-listing/preference-listing";
import "./App.scss";

const LandingPage = lazy(() => import("./screens/landing-page/landing-page-2"));
const SearchAqivah = lazy(() => import("./aqivah-search/aqivah-search"));
const InfoPage = lazy(() => import("./aqivah-info-page/aqivah-info-page"));

function App(props) {
    const domains = {
        "about.aqivah.com": <InfoPage />,
        "www.about.aqivah.com": <InfoPage />,
        "aqivah.com": <SearchAqivah />,
        "www.aqivah.com": <SearchAqivah />,
        "app.aqivah.com": <LandingPage />,
        "www.app.aqivah.com": <LandingPage />,
        localhost: <LandingPage />,
        "aqivah-test.netlify.app": <LandingPage />,
        "www.aqivah-test.netlify.app": <LandingPage />
    };

    axios.interceptors.request.use(req => {
        const token = localStorage.getItem(AUTH_TOKEN_KEY) ? JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY) as any) : null;
        req.baseURL = process.env.REACT_APP_BASEURL;
        if (token) {
            (req as any).headers["Authorization"] = "Bearer " + token;
        }
        return req;
    });

    axios.interceptors.response.use(
        res => res,
        err => {
            console.error("A request error occurred: ", err);

            if (
                (err.response as AxiosResponse)?.status === 401 &&
                window.location.pathname !== `/login` &&
                window.location.pathname !== `/complete-registration` &&
                window.location.pathname !== `/register`
            ) {
                let redirectPath = `/login`;
                localStorage.removeItem(AUTH_TOKEN_KEY);
                localStorage.removeItem(ORGANIZATION_KEY);
                localStorage.removeItem(USER_KEY);

                if (window.location.pathname) {
                    redirectPath += `?next=${window.location.pathname}`;
                }

                window.location.href = redirectPath;
                // openLoginModal();
            }

            throw err;
        }
    );

    useAppInitialize();

    useRefreshToken();

    useWebSocketReconnect();

    useReconnectOnInternetReconnection();

    useEffect(function myFunction() {
        // Remove all iframes
        const elements = document.getElementsByTagName("iframe");

        while (elements.length) {
            if (elements[0].parentNode) elements[0].parentNode.removeChild(elements[0]);
        }
    }, []);

    return (
        <div className="app-body">
            <Suspense fallback={<LaunchPage />}>
                <header>
                    <Router>
                        <Routes>
                            <Route
                                path="/"
                                element={<AdminProvider no_auth>{domains[window.location.hostname] || <LandingPage />}</AdminProvider>}
                            />
                            <Route
                                path="/home"
                                element={
                                    <AdminProvider no_auth>
                                        <LandingPage />
                                    </AdminProvider>
                                }
                            />
                            <Route
                                path="/login"
                                element={
                                    <AdminProvider>
                                        <Login />
                                    </AdminProvider>
                                }
                            />
                            <Route
                                path="/register"
                                element={
                                    <AdminProvider>
                                        <RegisterSeeker />
                                    </AdminProvider>
                                }
                            />
                            <Route
                                path="/complete-organization-registration"
                                element={
                                    <AdminProvider>
                                        <CompleteOrganizationRegistration />
                                    </AdminProvider>
                                }
                            />
                            <Route
                                path="/accept-organization-invite"
                                element={
                                    <AdminProvider restricted>
                                        <AcceptOrganizationInvitation />
                                    </AdminProvider>
                                }
                            />
                            <Route
                                path="/forgotpassword"
                                element={
                                    <AdminProvider>
                                        <ForgotPassword />
                                    </AdminProvider>
                                }
                            />
                            <Route path="/register" element={<Register />} />
                            <Route path="/owner-registration" element={<OwnerRegistration />} />
                            <Route
                                path="/seeker-owner-registration"
                                element={
                                    <AdminProvider restricted>
                                        <ExistingUserOwnerRegistration />
                                    </AdminProvider>
                                }
                            />
                            <Route path="/organization-registration" element={<OrganizationRegistration />} />
                            <Route path="/confirm-registration" element={<ConfirmRegistration />} />
                            <Route
                                path="/search-property"
                                element={
                                    <AdminProvider no_auth>
                                        <PropertySearchPage />
                                    </AdminProvider>
                                }
                            />

                            <Route
                                path="/info-list-property"
                                element={
                                    <AdminProvider>
                                        <ListingInfo />
                                    </AdminProvider>
                                }
                            />

                            <Route
                                path="/list-property"
                                element={
                                    <AdminProvider restricted owner_restricted>
                                        <ListPropertyPage />
                                    </AdminProvider>
                                }
                            />

                            <Route path="/add_property" element={<AddProperty />} />
                            <Route path="/add_request" element={<PreferenceListing />} />
                            <Route
                                path="/owner-dashboard"
                                element={
                                    <AdminProvider restricted>
                                        <AqivahDashboard />
                                    </AdminProvider>
                                }
                            />

                            <Route
                                path="/organization-dashboard"
                                element={
                                    <OrganizationProvider restricted>
                                        <OrganizationDashboard />
                                    </OrganizationProvider>
                                }
                            />

                            <Route
                                path="/org/:id"
                                element={
                                    <AdminProvider>
                                        <Organization />
                                    </AdminProvider>
                                }
                            />

                            <Route
                                path="/seeker_dashboard"
                                element={
                                    <PrivateRoute>
                                        <SeekerDashboard />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/property/:id"
                                element={
                                    <AdminProvider no_auth>
                                        <PropertyPage />
                                    </AdminProvider>
                                }
                            />
                            <Route path="/aqivah_search" element={<SearchAqivah />} />
                            <Route path="/aqivah_info" element={<InfoPage />} />
                            <Route path="/privacy-policy" element={<PolicyDoc />} />
                            <Route path="/terms-of-service" element={<TermsOfService />} />
                            {window.location.hostname === "about.aqivah.com" || window.location.hostname === "www.about.aqivah.com" ? (
                                <Route path="/:id" element={<Redirect />} />
                            ) : (
                                <Route path="/aqivah_info/:id" element={<Redirect />} />
                            )}
                            <Route path="/reset_password/:email/:code" element={<ResetPassword />} />
                            <Route path="*" element={<Lost404Page />} />
                        </Routes>
                    </Router>
                </header>
            </Suspense>
        </div>
    );
}

export default App;
