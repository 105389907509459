import React, { useState } from "react";
import { SearchContext, ILocation } from "./search-context";

export const SearchProvider = ({ children }) => {
    const [propertyFilters, setPropertyFilters] = useState<any>({
        bedrooms: 0,
        bathrooms: 0,
        acquisition: "",
        type: "",
        price: { min: 0, max: 0 },
        category: "residential",
        currency: "",
        stay_date: null,
        location: null,
        amenities: [],
        rules: {},
        utilities: {}
    });

    const handleFilterChange = (e: any) => {
        setPropertyFilters({ ...propertyFilters, [e.target.name]: e.target.value });
    };

    const handleLocationChange = (value: ILocation) => {
        setPropertyFilters({ ...propertyFilters, location: value });
    };

    const handleLocationClear = () => {
        setPropertyFilters({ ...propertyFilters, location: null });
    };

    const handleBedroomChange = e => {
        if (e === "bedroom-plus") {
            if (propertyFilters.bedrooms! < 9) {
                setPropertyFilters({ ...propertyFilters, bedrooms: propertyFilters.bedrooms + 1 });
            }
        }
        if (e === "bedroom-minus") {
            if (propertyFilters.bedrooms > 1) {
                setPropertyFilters({ ...propertyFilters, bedrooms: propertyFilters.bedrooms - 1 });
            }
        }
    };

    const handleBathroomChange = e => {
        if (e === "bathroom-plus") {
            if (propertyFilters.bathrooms! < 9) {
                setPropertyFilters({ ...propertyFilters, bathrooms: propertyFilters.bathrooms + 1 });
            }
        }
        if (e === "bathroom-minus") {
            if (propertyFilters.bathrooms > 1) {
                setPropertyFilters({ ...propertyFilters, bathrooms: propertyFilters.bathrooms - 1 });
            }
        }
    };

    const handleReset = () => {
        setPropertyFilters({
            bedrooms: 0,
            bathrooms: 0,
            acquisition: "",
            type: "",
            price: { min: 0, max: 0 },
            category: "residential",
            currency: "",
            location: null
        });
    };

    return (
        <SearchContext.Provider
            value={{
                propertyFilters,
                handleFilterChange,
                setPropertyFilters,
                handleBedroomChange,
                handleBathroomChange,
                handleLocationChange,
                handleLocationClear,
                handleReset
            }}>
            {children}
        </SearchContext.Provider>
    );
};
