import React, { useState, useEffect } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import TextField from "@mui/material/TextField";

export const useLocationSearch = () => {
    const [autocomplete, setAutocomplete] = useState<any>(null);
    const [query, setQuery] = useState("");
    const [place, setPlace] = useState<any>(null);

    function onLoad(auto: any) {
        console.log("autocomplete: ", auto);
        setAutocomplete(auto);
    }

    const handlePlaceChanged = () => {
        if (autocomplete) {
            const place = autocomplete.getPlace();
            console.log("Sorcery", { ...place });
            setPlace(place);
            setQuery(place.formatted_address);
            console.log(place.geometry.location.lat(), place.geometry.location.lng());
        }
    };

    type FieldSize = "small" | "medium";

    const InputField = (size: FieldSize = "small") => (
        <div>
            <LoadScript libraries={["places"]} googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}>
                <Autocomplete
                    onLoad={onLoad}
                    options={{
                        types: ["geocode"],
                        componentRestrictions: { country: "gh" }
                    }}
                    onPlaceChanged={handlePlaceChanged}>
                    <TextField
                        fullWidth
                        className=""
                        placeholder="Enter a location"
                        size={size}
                        value={query}
                        onChange={event => setQuery(event.target.value)}
                        name="price"
                        style={{ borderRadius: 0 }}
                    />
                </Autocomplete>
            </LoadScript>
        </div>
    );
    return [InputField, place];
};
