import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/Header";
import Grid from "@mui/material/Grid";
import PropertyCard from "./property-card";
import PropertyCardScrapper from "./property-card-scrapper";
import { Link } from "react-router-dom";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import Grow from "@mui/material/Grow";
import "./property-search-page.scss";
import { SearchBar } from "./components/search-bar";
import { SearchContext, IPropertyFilters } from "../../context/search-context/search-context";
import pin from "../../assets/pin.png";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

export const PropertySearchPage = ({ modal = false }) => {
    const [load, setLoad] = useState(false);
    const [properties, setProperties] = useState<any>([]);
    const [scrapperProperties, setScrapperProperties] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [locations, setLocations] = useState<any>([]);

    const mapContainerStyle = {
        width: "100%",
        height: "500px"
    };

    const center = {
        lat: 5.7348119, // Initial latitude of the map
        lng: 0.0302354 // Initial longitude of the map
    };

    const redMarkerIcon = {
        url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
        scaleSize: { width: 40, height: 40 }
    };

    const { propertyFilters } = useContext(SearchContext);

    useEffect(() => {
        setLoad(true);
        search();
    }, []);

    function search() {
        setLoading(true);
        console.log("filters here ->", propertyFilters);

        const min_price = propertyFilters?.price?.min;
        const max_price = propertyFilters?.price?.max;
        // delete propertyFilters.price;
        axios
            .post("/properties/search", {
                ...propertyFilters,
                min_price: min_price === 0 ? "" : min_price,
                max_price: max_price === 0 ? "" : max_price,
                bedrooms: propertyFilters?.bedrooms === 0 ? "" : propertyFilters?.bedrooms,
                bathrooms: propertyFilters?.bathrooms === 0 ? "" : propertyFilters?.bathrooms,
                location: propertyFilters?.location //|| [-0.2087533, 5.653658]
            })
            .then((res: any) => {
                setScrapperProperties(res.data.result.scrapperProperties);
                setProperties(res.data.result.properties);
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
            })
            .then(() => setLoading(false));
    }

    function constructSearchTitle(propertyFilters: IPropertyFilters) {
        const constructor: any = {
            bedrooms: propertyFilters?.bedrooms && propertyFilters?.bedrooms > 0 ? `${propertyFilters.bedrooms} bedroom` : ""
        };

        if (propertyFilters.category === "Residential") {
            return ``;
        }
    }

    useEffect(() => {
        properties.map((property: any) => {
            return setLocations([
                ...locations,
                {
                    lat: property?.location?.coordinates[0] || 5.7348119,
                    lng: property?.location?.coordinates[1] || 0.0302354,
                    name: property?.location_details?.town ? property.location_details?.town : property.location_details
                }
            ]);
        });
        console.log("locations", locations);
    }, [properties]);

    useEffect(() => {
        console.log(locations);
    }, [locations]);

    return (
        <div id="property-search-page" className={`${!modal ? "py-5" : ""}`}>
            {!modal && <Header />}

            <Backdrop open={loading} style={{ zIndex: "5" }}>
                <span className="spinner-border text-white" role="status"></span>
            </Backdrop>

            <div className="d-flex justify-content-center align-items-center mb-4 sp-search">
                <div className="container-sm">
                    <SearchBar search={search} />
                </div>
            </div>

            <div className={`${!modal ? "container-sm" : ""} py-5`}>
                <Grid container spacing={2} className="mb-5">
                    <Grid item xs={12} md={!modal ? 8 : 12} lg={!modal ? 8 : 12}>
                        <Grid container spacing={2}>
                            {properties.map((property: any, i: any) => (
                                <Grow key={i} in={load} style={{ transformOrigin: "0 0 0" }} {...(load ? { timeout: 400 * i } : {})}>
                                    <Grid item xs={12} md={!modal ? 12 : 12} lg={!modal ? 12 : 12}>
                                        <Link className="text-decoration-none" to={`/property/${property._id}`}>
                                            <PropertyCard property={property} />
                                        </Link>
                                    </Grid>
                                </Grow>
                            ))}
                        </Grid>
                    </Grid>
                    {!modal && (
                        <Grid item xs={12} md={4} lg={4} style={{ position: "relative" }}>
                            <div className="w-100" style={{ position: "sticky", top: "130px" }}>
                                <LoadScript libraries={["places"]} googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                                    <GoogleMap
                                        mapContainerStyle={mapContainerStyle}
                                        center={center}
                                        zoom={8} // Adjust the zoom level as needed
                                    >
                                        {locations.map((location, index) => (
                                            <Marker icon={pin} key={index} position={location} />
                                        ))}
                                    </GoogleMap>
                                </LoadScript>
                            </div>
                        </Grid>
                    )}
                </Grid>
                {scrapperProperties
                    ? scrapperProperties.length > 0 &&
                      !modal && (
                          <>
                              <hr className="mb-4" />
                              <h4 className="fw-bold">Discover related results from other real estate companies.</h4>
                              <Grid container spacing={2} className="mb-5">
                                  <Grid item xs={12} md={8} lg={8}>
                                      <Grid container spacing={2}>
                                          {scrapperProperties.map((property: any, i: any) => {
                                              if (property.title == "undefined" && property.bedrooms == "undefined") return "";
                                              else
                                                  return (
                                                      <Grow
                                                          key={i}
                                                          in={load}
                                                          style={{ transformOrigin: "0 0 0" }}
                                                          {...(load ? { timeout: 300 * i } : {})}>
                                                          <Grid item xs={12} md={12} lg={12}>
                                                              <Link className="text-decoration-none" to={"#"}>
                                                                  <PropertyCardScrapper property={property} />
                                                              </Link>
                                                          </Grid>
                                                      </Grow> // cvNT[8_@4hib]:fvCKofF g6|iLc45@H32m
                                                  );
                                          })}
                                      </Grid>
                                  </Grid>
                                  <Grid item xs={12} md={4} lg={4}></Grid>
                              </Grid>
                          </>
                      )
                    : ""}
            </div>
        </div>
    );
};
