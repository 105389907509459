import React, { useContext } from "react";
import Radio from "antd/es/radio";
import Checkbox from "antd/es/checkbox";
import { SearchContext } from "../../../context/search-context/search-context";
import Grid from "@mui/material/Grid";

export const OtherFilters = () => {
    const { propertyFilters, handleFilterChange, setPropertyFilters } = useContext(SearchContext);

    const container: any = {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
    };

    const handleAmenitiesChange = e => {
        const name = e.target.name;
        if (!propertyFilters?.amenities?.includes(name)) {
            setPropertyFilters(prevState => ({ ...prevState, amenities: [...prevState.amenities, name] }));
        } else {
            setPropertyFilters(prevState => ({ ...prevState, amenities: prevState.amenities.filter(item => item !== name) }));
        }
    };

    return (
        <div className="d-flex flex-column justify-content-start align-items-center">
            <span className="filter-title mb-3">Others</span>
            <div className="d-flex flex-column w-100 justify-content-start align-items-start">
                <Grid container spacing={2}>
                    <Grid style={container} item xs={12} sm={12} lg={12} md={12}>
                        <div className="d-flex flex-column justify-content-start align-items-start user-select-none">
                            <Radio
                                name="gym"
                                checked={propertyFilters?.amenities?.includes("gym")}
                                onClick={handleAmenitiesChange}
                                value={"gym"}>
                                Gym
                            </Radio>
                            <Radio
                                name="garden"
                                checked={propertyFilters?.amenities?.includes("garden")}
                                onClick={handleAmenitiesChange}
                                value={"garden"}>
                                Garden
                            </Radio>
                            <Radio
                                name="airconditioning"
                                checked={propertyFilters?.amenities?.includes("airconditioning")}
                                onClick={handleAmenitiesChange}
                                value={"airconditioning"}>
                                Air conditioning
                            </Radio>
                            <Radio
                                name="swimming_pool"
                                checked={propertyFilters?.amenities?.includes("swimming_pool")}
                                onClick={handleAmenitiesChange}
                                value={"swimming_pool"}>
                                Swimming Pool
                            </Radio>
                        </div>
                        {/* </Radio.Group> */}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
