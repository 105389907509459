import React from "react";
import Card from "antd/es/card";
import Progress from "antd/es/progress";
import Tooltip from "antd/es/tooltip";
import Grid from "@mui/material/Grid";

export const Board = () => {
    return (
        <div className="px-2">
            <Grid container spacing={5}>
                <Grid item xs={12} md={6} lg={6} className="">
                    <Card title="Properties" style={{ backgroundColor: "#f3f4f7" }} bordered={false} className="round-edge">
                        <div className="property-content d-flex flex-column justify-content-start align-items-start">
                            <Tooltip title="3 sold, 3 rented, 4 more to go">
                                <Progress percent={75} success={{ percent: 30 }} type="circle" />
                            </Tooltip>

                            <p style={{ marginTop: "20px" }}>Sold Items</p>
                        </div>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6} lg={6} className="">
                    <Card title="Requests" bordered={false}>
                        <div className="property-content d-flex flex-column justify-content-start align-items-start">
                            <Tooltip title="3 sold, 3 rented, 4 more to go">
                                <Progress
                                    type="circle"
                                    strokeColor={{
                                        "0%": "#108ee9",
                                        "100%": "#87d068"
                                    }}
                                    percent={100}
                                />
                            </Tooltip>

                            <p style={{ marginTop: "20px" }}>Closed deals</p>
                        </div>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6} lg={6} className="">
                    <Card title="Advertisements" bordered={false}>
                        <div className="property-content">
                            <Progress
                                type="circle"
                                strokeColor={{
                                    "0%": "#108ee9",
                                    "100%": "#87d068"
                                }}
                                percent={90}
                            />

                            <p style={{ marginTop: "20px" }}>Ads sold</p>
                        </div>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6} lg={6} className="">
                    <Card title="Documents" bordered={false} style={{ backgroundColor: "#f3f4f7" }} className="round-edge">
                        <div className="property-content">
                            <Tooltip title="3 sold, 3 rented, 4 more to go">
                                <Progress percent={75} success={{ percent: 30 }} type="circle" status="exception" />
                            </Tooltip>

                            <p style={{ marginTop: "20px" }}>Verified documents</p>
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};
