import React, { useState, useEffect, useContext } from "react";
import Tabs from "antd/es/tabs";
import { OrganizationHeader } from "./components/header/header";
import { Link, useParams } from "react-router-dom";
import Header from "../../components/Header";
import Backdrop from "@mui/material/Backdrop";
import "./organization.scss";
import axios from "axios";
import { PopupProviderContext } from "../../common/popup-alerts-provider/popup-context";
import { OrganizationProviderContext } from "../../common/organization-provider/organization-provider-context";
import { logError } from "../../utils/logError";
import { Users } from "./screens/users/users";
import { Properties } from "./screens/properties/properties";

export const OrganizationDashboard = () => {
    const [tab, setTab] = useState("2");
    const { TabPane } = Tabs;
    const [organization, setOrganization] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const { flashError } = useContext(PopupProviderContext);
    const { organization: org } = useContext(OrganizationProviderContext);

    const handleTabChange = (value: any) => {
        setTab(value);
    };

    function fetchOrganization() {
        setLoading(true);
        axios
            .get(`/organizations/single/${org?.url_name}`)
            .then((res: any) => setOrganization(res.data.result.organization))
            .catch((err: any) => {
                flashError("Could not load organization", logError(err));
            })
            .then(() => setLoading(false));
    }

    useEffect(() => {
        fetchOrganization();
    }, []);

    return (
        <div id="organization-dashboard" className="py-5">
            <Header />
            <Backdrop open={loading} style={{ zIndex: "5" }}>
                <div className="spinner-border text-white" role="status"></div>
            </Backdrop>
            {organization && (
                <div className="container-sm py-5" style={{ height: "100%" }}>
                    <header className="mt-4 mb-4">
                        <h4 className="fw-bold">{organization?.name}</h4>
                    </header>
                    <Tabs onChange={handleTabChange} className="mt-5" activeKey={tab}>
                        <TabPane tab="Properties" key="2">
                            <Properties />
                        </TabPane>
                        <TabPane tab="Users" key="3">
                            <Users />
                        </TabPane>
                        <TabPane tab="Requests" key="4"></TabPane>
                        <TabPane tab="Documents" key="5"></TabPane>
                        <TabPane tab="Advertisements" key="6"></TabPane>
                        <TabPane tab="Messages" key="7"></TabPane>
                        <TabPane tab="Profile" key="8"></TabPane>
                    </Tabs>
                </div>
            )}
            {!organization && !loading && (
                <div style={{ height: "100vh" }} className="container-sm d-flex justify-content-center align-items-center">
                    <Link style={{ textDecoration: "underline" }} to="/">
                        Go Back
                    </Link>
                </div>
            )}
        </div>
    );
};
