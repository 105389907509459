import React, { useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import { PropertyContext } from "../../context/property-context/property-context";
import { residentialPropertyTypes } from "./property-types";
import Radio from "antd/es/radio";
import "./list-property.scss";
import { FormLabel } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import numeral from "numeral";
import Compressor from "compressorjs";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import Image from "antd/es/image";
import { PopupProviderContext } from "../../common/popup-alerts-provider/popup-context";
import { logError } from "../../utils/logError";
import { Divider } from "antd";
import DatePicker from "antd/es/date-picker";
import moment from "moment";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { useLocationSearch } from "../../Hooks/use-location";

const useStyles = makeStyles(theme => ({
    textField: {
        fontSize: "13px"
    }
}));

const { RangePicker } = DatePicker;

export const ResidentialForm = () => {
    const classes = useStyles();

    const { flashSuccess, flashError } = useContext(PopupProviderContext);
    const [InputField, place] = useLocationSearch();
    const [uploading, setUploading] = useState<any>(false);
    const [deleteId, setDeleteId] = useState("");
    const [amenities, setAmenities] = useState<any>([]);
    const [rules, setRules] = useState({});

    // const [autocomplete, setAutocomplete] = useState<any>(null);
    // const [query, setQuery] = useState("");
    // const [place, setPlace] = useState<any>(null);

    // function onLoad(auto: any) {
    //     console.log("autocomplete: ", auto);
    //     setAutocomplete(auto);
    // }

    // const handlePlaceChanged = () => {
    //     if (autocomplete) {
    //         const place = autocomplete.getPlace();
    //         console.log("Sorcery", { ...place });
    //         setPlace(place);
    //         setQuery(place.formatted_address);
    //         console.log(place.geometry.location.lat(), place.geometry.location.lng());
    //     }
    // };

    const { PROPERTY_UPLOAD, property, reset, setRefresh, events, setEvents } = useContext(PropertyContext);

    const currencyOptions = [
        { label: <i className="fa-solid fa-cent-sign"></i>, value: "GHC" },
        { label: <i className="fa-solid fa-dollar-sign"></i>, value: "USD" }
    ];

    const currentYear: number = new Date().getFullYear();

    const validationSchemaResidential = Yup.object({
        type: Yup.string().required("Property type is required"),
        acquisition: Yup.string()
            .oneOf(["buy", "rent", "short_stay"], "Incorrect value selected")
            .required("Acquisition type is required"),
        stay_start_date: Yup.string().when("acquisition", {
            is: "short_stay",
            then: () => Yup.string().required("Start date is required"),
            otherwise: () => Yup.string()
        }),
        stay_end_date: Yup.string().when("acquisition", {
            is: "short_stay",
            then: () => Yup.string().required("End date is required"),
            otherwise: () => Yup.string()
        }),
        payment_plan: Yup.string(),
        bedrooms: Yup.number()
            .min(1, "Bedroom number must be at least one")
            .max(15, "Bedroom cannot exceed 15")
            .required("Number of bedrooms is required"),
        bathrooms: Yup.number()
            .min(1, "Bathroom number must be at least one")
            .max(15, "Bathroom cannot exceed 15")
            .required("Number of bathrooms is required"),
        description: Yup.string().required("Please provide a detailed description of this property"),
        price: Yup.number().min(1, "minimum value is 1").required("Property price is required"),
        currency: Yup.string().required("Please select the currency"),
        location: Yup.string(),
        buildYear: Yup.number()
            .min(1500, "Please enter a valid build year")
            .max(currentYear, "Build year cannot be more than current year"),
        tags: Yup.array(),
        amenities: Yup.array(),
        images: Yup.array().required("Please include at least 3 images").min(3, "A minimum of 3 images required for this property"),
        water: Yup.string().required("Water source is required"),
        water_storage: Yup.string(),
        electricity: Yup.string().required("Electricity source is required"),
        electricity_backups: Yup.string(),
        garage: Yup.string(),
        parking_space: Yup.number().min(0, "Parking space cannot be less than 0")
    });

    const formikResidential = useFormik({
        initialValues: {
            category: "",
            acquisition: "",
            stay_start_date: "",
            stay_end_date: "",
            payment_plan: "",
            type: "",
            bedrooms: 1,
            bathrooms: 1,
            description: "",
            price: "",
            currency: "GHC",
            buildYear: "",
            tags: "",
            amenities: "",
            images: [] as any,
            water: "",
            water_storage: "",
            electricity: "",
            electricity_backups: "",
            garage: "",
            parking_space: ""
        },
        validationSchema: validationSchemaResidential,
        onSubmit: values => {
            setEvents([...events, PROPERTY_UPLOAD]);
            axios
                .post("/properties/create-residential", {
                    ...values,
                    category: property.category,
                    location_details: {
                        town: place?.address_components[0]?.long_name,
                        city: "Accra",
                        region: place?.address_components[3]?.long_name,
                        latitude: place.geometry.location.lat(),
                        longitude: place.geometry.location.lng()
                    },
                    location: {
                        type: "Point",
                        coordinates: [place.geometry.location.lng(), place.geometry.location.lat()]
                    },
                    utilities: {
                        water: values.water,
                        water_storage: values.water_storage,
                        electricity: values.electricity,
                        electricity_backups: values.electricity_backups,
                        garage: values.garage,
                        parking_space: values.parking_space
                    },
                    rules: rules,
                    amenities: amenities
                })
                .then((res: any) => {
                    console.log(res);
                    flashSuccess("Property uploaded successfully");
                    setRefresh(true);
                    setTimeout(() => {
                        reset();
                    }, 1200);
                })
                .catch(err => {
                    console.log(err);
                    flashError("Something went wrong", logError(err));
                })
                .then(() => setEvents(events.filter((event: any) => event !== PROPERTY_UPLOAD)));
        }
    });

    const handleImageUpload = async (values: any) => {
        const fileList = new FormData();

        Array.from(values.target.files).forEach((file: any) => {
            fileList.append("files", file);
        });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };

        axios
            .post("properties/image-upload", fileList, config)
            .then((res: any) => {
                console.log(res);
                console.log(res.data);
                formikResidential.setFieldValue("images", [...formikResidential.values.images, ...res.data.filelocation]);
                setUploading(false);
            })
            .catch((err: any) => {
                setUploading(false);
                flashError("Something went wrong", "Could not complete process");
            });
        setUploading(true);
    };

    const handleFileDelete = (file: any) => {
        setDeleteId(file);
        axios
            .post("auth/delete-file", { filename: file })
            .then((res: any) => {
                console.log(res);
                const newLocation = formikResidential.values.images.filter(files => files.name !== file);

                formikResidential.setFieldValue("images", newLocation);
            })
            .catch(err => {
                console.log(err);
                flashError("Something went wrong", "Could not complete process");
            })
            .then(() => setDeleteId(""));
    };

    const handleUploadClick = () => {
        (document as any).getElementById("upload-btn").click();
    };

    const priceText: any = {
        DAILY: "p/dy",
        WEEKLY: "p/wk",
        MONTHLY: "p/mth",
        YEARLY: "p/yr"
    };

    const onPriceChange = e => {
        console.log(e.target.value);
        const value = e.target.value.replaceAll(",", "");
        const re = /^[0-9\b]+$/;
        if (value === "" || re.test(value)) {
            const value_to_number = Number(value);
            formikResidential.setFieldValue("price", value_to_number);
        }
    };

    const handleAcquisitionChange = (e, value) => {
        if (e.target.checked) {
            if (value === "buy") formikResidential.setFieldValue("payment_plan", "");
            formikResidential.setFieldValue("acquisition", value);
        }
    };

    const handleBedroomChange = e => {
        console.log(formikResidential.values);
        if (e === "bedroom-plus") {
            if (formikResidential.values.bedrooms! < 9)
                formikResidential.setFieldValue("bedrooms", formikResidential.values.bedrooms + 1);
        }
        if (e === "bedroom-minus") {
            if (formikResidential.values.bedrooms > 1)
                formikResidential.setFieldValue("bedrooms", formikResidential.values.bedrooms - 1);
        }
    };

    const handleBathroomChange = e => {
        if (e === "bathroom-plus") {
            if (formikResidential.values.bathrooms! < 9)
                formikResidential.setFieldValue("bathrooms", formikResidential.values.bathrooms + 1);
        }
        if (e === "bathroom-minus") {
            if (formikResidential.values.bathrooms > 1)
                formikResidential.setFieldValue("bathrooms", formikResidential.values.bathrooms - 1);
        }
    };

    const handleDateRangeChange = (date: any, date_array: Array<string>) => {
        formikResidential.setFieldValue("stay_start_date", date_array[0]);
        formikResidential.setFieldValue("stay_end_date", date_array[1]);
    };

    const customStartEndFormat = value => {
        return `${moment(value).format("DD-MMM-YYYY")}`;
    };
    const handleCurrencyChange = e => {
        formikResidential.setFieldValue("currency", e.target.value);
    };

    const amenityChange = (amenity: string) => {
        if (amenities.includes(amenity)) {
            setAmenities(amenities.filter((item: string) => item !== amenity));
        } else {
            setAmenities([...amenities, amenity]);
        }
    };

    const handleRuleChange = (e: any) => {
        if (e.target.checked) {
            setRules({ ...rules, [e.target.name]: true });
        } else {
            setRules({ ...rules, [e.target.name]: false });
        }
    };

    return (
        <form onSubmit={formikResidential.handleSubmit}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <FormControl sx={{}} component="fieldset" variant="outlined">
                        <FormLabel component="legend">Acquisition</FormLabel>
                        <FormGroup row sx={{ display: "flex" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formikResidential.values.acquisition === "buy"}
                                        value="buy"
                                        onChange={e => handleAcquisitionChange(e, "buy")}
                                        name="acquisition"
                                    />
                                }
                                label="For Sale"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formikResidential.values.acquisition === "rent"}
                                        value="rent"
                                        onChange={e => handleAcquisitionChange(e, "rent")}
                                        name="acquisition"
                                    />
                                }
                                label="For Rent"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formikResidential.values.acquisition === "short_stay"}
                                        value="rent"
                                        onChange={e => handleAcquisitionChange(e, "short_stay")}
                                        name="acquisition"
                                    />
                                }
                                label="Short Stay"
                            />
                        </FormGroup>
                        <FormHelperText error>{formikResidential.errors.acquisition}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                        <FormLabel component="legend">Property Type</FormLabel>
                        <Select
                            className={classes.textField}
                            name="type"
                            size="medium"
                            value={formikResidential.values.type}
                            label="Property Type"
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={() => (
                                <div style={{ display: "flex", flexWrap: "wrap", fontWeight: "bold" }}>
                                    {formikResidential.values.type && (
                                        <Chip
                                            size="small"
                                            color="info"
                                            key={formikResidential.values.type}
                                            label={formikResidential.values.type}
                                        />
                                    )}
                                </div>
                            )}
                            onChange={formikResidential.handleChange}
                            error={formikResidential.touched.type && formikResidential.errors.type ? true : false}>
                            {residentialPropertyTypes.map((type: any, k: any) => (
                                <MenuItem key={k} value={type.value}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {formikResidential.touched.type && formikResidential.errors.type && (
                            <FormHelperText error>{formikResidential.errors.type}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                {(formikResidential.values.acquisition === "rent" || formikResidential.values.acquisition === "short_stay") && (
                    <Grid item xs={12} lg={6}>
                        <FormControl fullWidth>
                            <FormLabel component="legend">Payment Plan</FormLabel>
                            <Select
                                className={classes.textField}
                                name="payment_plan"
                                size="medium"
                                value={formikResidential.values.payment_plan}
                                label="Property Type"
                                onChange={formikResidential.handleChange}
                                error={formikResidential.touched.payment_plan && formikResidential.errors.payment_plan ? true : false}>
                                <MenuItem value={"DAILY"}>Daily payment</MenuItem>
                                <MenuItem value={"WEEKLY"}>Weekly payment</MenuItem>
                                <MenuItem value={"MONTHLY"}>Monthly payment</MenuItem>
                                <MenuItem value={"YEARLY"}>Yearly payment</MenuItem>
                            </Select>
                            {formikResidential.touched.payment_plan && formikResidential.errors.payment_plan && (
                                <FormHelperText error>{formikResidential.errors.payment_plan}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                )}

                {formikResidential.values.acquisition === "short_stay" && (
                    <Grid item xs={6} lg={6}>
                        <FormControl fullWidth>
                            <FormLabel component="legend">Short Stay Period</FormLabel>
                            <RangePicker
                                style={{ height: "55px" }}
                                size="large"
                                format={customStartEndFormat}
                                allowClear
                                onChange={handleDateRangeChange}
                            />
                        </FormControl>
                    </Grid>
                )}

                <hr className="mb-5 mt-5" style={{ width: "100%" }} />
                <Grid item xs={12} lg={8}>
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <FormControl>
                                <FormLabel className="mb-3">Currency</FormLabel>
                                <Radio.Group
                                    size="large"
                                    style={{ fontSize: "8px", marginBottom: "0" }}
                                    options={currencyOptions}
                                    name="currency"
                                    onChange={handleCurrencyChange}
                                    value={formikResidential.values.currency}
                                    optionType="button"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={8} className="d-flex align-items-end">
                            <FormControl>
                                <FormLabel className="mb-3">Price</FormLabel>
                                <TextField
                                    fullWidth
                                    className=""
                                    size="small"
                                    name="price"
                                    style={{ borderRadius: 0 }}
                                    value={numeral(formikResidential.values.price).format("0,0")}
                                    onChange={e => onPriceChange(e)}
                                    InputProps={{
                                        startAdornment:
                                            formikResidential.values.currency === "USD" ? (
                                                <i className="fa-solid fa-dollar-sign"></i>
                                            ) : (
                                                <i className="fa-solid fa-cent-sign"></i>
                                            ),
                                        endAdornment:
                                            formikResidential.values.acquisition === "rent" ||
                                                formikResidential.values.acquisition === "short_stay" ? (
                                                <small> {priceText[formikResidential.values.payment_plan]}</small>
                                            ) : (
                                                ""
                                            )
                                    }}
                                    error={formikResidential.touched.price && formikResidential.errors.price ? true : false}
                                    variant="outlined"
                                />
                                {formikResidential.touched.price && formikResidential.errors.price && (
                                    <FormHelperText error>{formikResidential.errors.price}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} lg={4}>
                    <FormControl fullWidth>
                        <FormLabel component="legend" className="mb-3">
                            Build Year
                        </FormLabel>
                        <TextField
                            className={classes.textField}
                            name="buildYear"
                            placeholder="Build year"
                            size="small"
                            type="number"
                            value={formikResidential.values.buildYear}
                            onChange={formikResidential.handleChange}
                            error={formikResidential.touched.buildYear && formikResidential.errors.buildYear ? true : false}
                        />
                        {formikResidential.touched.buildYear && formikResidential.errors.buildYear && (
                            <FormHelperText error>{formikResidential.errors.buildYear}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid item xs={12} lg={12} className="mb-4">
                    <FormControl fullWidth>
                        <FormLabel component="legend">Property Description</FormLabel>
                        <TextField
                            className={classes.textField}
                            name="description"
                            multiline
                            maxRows={16}
                            minRows={4}
                            value={formikResidential.values.description}
                            onChange={formikResidential.handleChange}
                            error={formikResidential.touched.description && formikResidential.errors.description ? true : false}
                        />
                        {/* <RichTextEditor /> */}
                        {formikResidential.touched.description && formikResidential.errors.description && (
                            <FormHelperText error>{formikResidential.errors.description}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid item xs={12} lg={6} className="mb-4">
                    <FormControl fullWidth style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {/* <FormLabel component="legend">Bedrooms</FormLabel> */}
                        <div className="d-flex justify-items-center mb-2">
                            <button
                                type="button"
                                onClick={() => handleBedroomChange("bedroom-plus")}
                                name="bedroom-plus"
                                className="counter-button-l">
                                <i className="fa-solid fa-plus"></i>
                            </button>
                            <div style={{ fontSize: "bold", padding: "0 10px" }}>
                                <Chip color="default" style={{ padding: "0, 30px" }} label={formikResidential.values.bedrooms} />
                            </div>
                            <button
                                type="button"
                                onClick={() => handleBedroomChange("bedroom-minus")}
                                name="bedroom-minus"
                                className="counter-button-l">
                                <i className="fa-solid fa-minus"></i>
                            </button>
                        </div>
                        <FormHelperText>Bedrooms</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormControl fullWidth style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {/* <FormLabel component="legend">Bathrooms</FormLabel> */}
                        <div className="d-flex justify-items-center mb-2">
                            <button
                                type="button"
                                onClick={() => handleBathroomChange("bathroom-plus")}
                                name="bathroom-plus"
                                className="counter-button-l">
                                <i className="fa-solid fa-plus"></i>
                            </button>
                            <div style={{ fontSize: "bold", padding: "0 10px" }}>
                                <Chip color="default" label={formikResidential.values.bathrooms} />
                            </div>
                            <button
                                type="button"
                                onClick={() => handleBathroomChange("bathroom-minus")}
                                name="bathroom-minus"
                                className="counter-button-l">
                                <i className="fa-solid fa-minus"></i>
                            </button>
                        </div>
                        <FormHelperText>Bathrooms</FormHelperText>
                    </FormControl>
                </Grid>

                <hr className="mb-5 mt-5" style={{ width: "100%" }} />
                <Grid item xs={12} lg={12}>
                    <Grid item xs={12}>
                        <h6 className="fw-bold">Property Location</h6>
                    </Grid>
                    {InputField("medium")}
                </Grid>
                <hr className="mb-5 mt-5" style={{ width: "100%" }} />

                <Grid item xs={12}>
                    <h6 className="fw-bold">Amenities</h6>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={3} lg={3} md={4} sm={6}>
                            <FormControl
                                onClick={() => amenityChange("gym")}
                                style={{
                                    border: `1px solid ${amenities.includes("gym") ? "#1873cd" : "transparent"}`,
                                    padding: "10px 10px",
                                    borderRadius: "6px",
                                    cursor: "pointer",
                                    userSelect: "none"
                                }}
                                className="d-flex flex-column justify-content-start align-items-center">
                                <i
                                    style={{ color: amenities.includes("gym") ? "#1873cd" : "#bbd9f7" }}
                                    className="fa-solid fa-dumbbell"></i>
                                Gym
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} lg={3} md={4} sm={6}>
                            <FormControl
                                onClick={() => amenityChange("swimming_pool")}
                                style={{
                                    border: `1px solid ${amenities.includes("swimming_pool") ? "#1873cd" : "transparent"}`,
                                    padding: "10px 10px",
                                    borderRadius: "6px",
                                    cursor: "pointer",
                                    userSelect: "none"
                                }}
                                className="d-flex flex-column justify-content-start align-items-center">
                                <i
                                    style={{ color: amenities.includes("swimming_pool") ? "#1873cd" : "#bbd9f7" }}
                                    className={`fa-solid fa-water-ladder`}></i>
                                Swimming Pool
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} lg={3} md={4} sm={6}>
                            <FormControl
                                onClick={() => amenityChange("airconditioning")}
                                style={{
                                    border: `1px solid ${amenities.includes("airconditioning") ? "#1873cd" : "transparent"}`,
                                    padding: "10px 10px",
                                    borderRadius: "6px",
                                    cursor: "pointer",
                                    userSelect: "none"
                                }}
                                className="d-flex flex-column justify-content-start align-items-center">
                                <i
                                    style={{ color: amenities.includes("airconditioning") ? "#1873cd" : "#bbd9f7" }}
                                    className="fa-solid fa-snowflake"></i>
                                Air Conditioning
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} lg={3} md={4} sm={6}>
                            <FormControl
                                onClick={() => amenityChange("garden")}
                                style={{
                                    border: `1px solid ${amenities.includes("garden") ? "#1873cd" : "transparent"}`,
                                    padding: "10px 10px",
                                    borderRadius: "6px",
                                    cursor: "pointer",
                                    userSelect: "none"
                                }}
                                className="d-flex flex-column justify-content-start align-items-center">
                                <i
                                    style={{ color: amenities.includes("garden") ? "#1873cd" : "#bbd9f7" }}
                                    className="fa-solid fa-tree"></i>
                                Garden
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                <hr className="mb-3 mt-3" style={{ width: "100%" }} />

                <Grid item xs={12}>
                    <h6 className="fw-bold">Utilities</h6>
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                    <FormControl fullWidth>
                        <FormLabel component="legend">Main Electricity Source</FormLabel>
                        <Select
                            className={classes.textField}
                            name="electricity"
                            size="medium"
                            value={formikResidential.values.electricity}
                            label="Main Electricity Source"
                            onChange={formikResidential.handleChange}
                            error={formikResidential.touched.electricity && formikResidential.errors.electricity ? true : false}>
                            <MenuItem value={"ecg"}>ECG</MenuItem>
                            <MenuItem value={"solar"}>Solar</MenuItem>
                            <MenuItem value={"generator"}>Generator</MenuItem>
                            <MenuItem value={"none"}>None</MenuItem>
                        </Select>
                        {formikResidential.touched.electricity && formikResidential.errors.electricity && (
                            <FormHelperText error>{formikResidential.errors.electricity}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                    <FormControl fullWidth>
                        <FormLabel component="legend">Backup Electricity Source</FormLabel>
                        <Select
                            className={classes.textField}
                            name="electricity_backups"
                            size="medium"
                            value={formikResidential.values.electricity_backups}
                            label="Backup Electricity Source"
                            onChange={formikResidential.handleChange}
                            error={
                                formikResidential.touched.electricity_backups && formikResidential.errors.electricity_backups
                                    ? true
                                    : false
                            }>
                            <MenuItem value={"solar"}>Solar</MenuItem>
                            <MenuItem value={"generator"}>Generator</MenuItem>
                            <MenuItem value={"none"}>None</MenuItem>
                        </Select>
                        {formikResidential.touched.electricity_backups && formikResidential.errors.electricity_backups && (
                            <FormHelperText error>{formikResidential.errors.electricity_backups}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                    <FormControl fullWidth>
                        <FormLabel component="legend">Water Source</FormLabel>
                        <Select
                            className={classes.textField}
                            name="water"
                            size="medium"
                            value={formikResidential.values.water}
                            label="Water source"
                            onChange={formikResidential.handleChange}
                            error={formikResidential.touched.water && formikResidential.errors.water ? true : false}>
                            <MenuItem value={"GWCL"}>Ghana Water Company Ltd.</MenuItem>
                            <MenuItem value={"tanker_service"}>Tanker Services</MenuItem>
                            <MenuItem value={"none"}>Bore Hole</MenuItem>
                            <MenuItem value={"none"}>None</MenuItem>
                        </Select>
                        {formikResidential.touched.water && formikResidential.errors.water && (
                            <FormHelperText error>{formikResidential.errors.water}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                    <FormControl fullWidth>
                        <FormLabel component="legend">Water Storage</FormLabel>
                        <Select
                            className={classes.textField}
                            name="water_storage"
                            size="medium"
                            value={formikResidential.values.water_storage}
                            label="Water source"
                            onChange={formikResidential.handleChange}
                            error={formikResidential.touched.water_storage && formikResidential.errors.water_storage ? true : false}>
                            <MenuItem value={"tank"}>Tank</MenuItem>
                            <MenuItem value={"none"}>None</MenuItem>
                        </Select>
                        {formikResidential.touched.water_storage && formikResidential.errors.water_storage && (
                            <FormHelperText error>{formikResidential.errors.water_storage}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                    <FormControl fullWidth>
                        <FormLabel component="legend">Parking Space</FormLabel>
                        <TextField
                            className={classes.textField}
                            type="number"
                            name="parking_space"
                            size="medium"
                            value={formikResidential.values.parking_space}
                            label="Parking space"
                            onChange={formikResidential.handleChange}
                            error={formikResidential.touched.parking_space && formikResidential.errors.parking_space ? true : false}
                        />
                        {formikResidential.touched.parking_space && formikResidential.errors.parking_space && (
                            <FormHelperText error>{formikResidential.errors.parking_space}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid item lg={6} md={6} sm={12}></Grid>

                <Grid item lg={6} md={6} sm={12}>
                    <FormControl fullWidth>
                        <FormLabel component="legend">Is Smoking Allowed?</FormLabel>
                        <Radio.Group name="smoking" onChange={handleRuleChange}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                        {formikResidential.touched.parking_space && formikResidential.errors.parking_space && (
                            <FormHelperText error>{formikResidential.errors.parking_space}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                    <FormControl fullWidth>
                        <FormLabel component="legend">Are Pets Allowed?</FormLabel>
                        <Radio.Group name="pets" onChange={handleRuleChange}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                        {formikResidential.touched.parking_space && formikResidential.errors.parking_space && (
                            <FormHelperText error>{formikResidential.errors.parking_space}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                    <FormControl fullWidth>
                        <FormLabel component="legend">Are Parties Allowed?</FormLabel>
                        <Radio.Group name="parties" onChange={handleRuleChange}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                        {formikResidential.touched.parking_space && formikResidential.errors.parking_space && (
                            <FormHelperText error>{formikResidential.errors.parking_space}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                    <FormControl fullWidth>
                        <FormLabel component="legend">Noise making allowed?</FormLabel>
                        <Radio.Group name="parties" onChange={handleRuleChange}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                        {formikResidential.touched.parking_space && formikResidential.errors.parking_space && (
                            <FormHelperText error>{formikResidential.errors.parking_space}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid xs={12} item>
                    <Button type="button" variant="text" color="primary" onClick={handleUploadClick}>
                        <i className="fa-solid fa-plus me-2" style={{ fontSize: "18px" }}></i>
                        Upload Images
                    </Button>
                    {uploading && <small className="ms-2">uploading files...</small>}
                    <input hidden multiple type="file" onChange={handleImageUpload} id="upload-btn" />

                    <Grid container spacing={2}>
                        {formikResidential.values.images.map((image: any, k: any) => (
                            <Grid item xs={3} sm={4} key={k} className="mb-3">
                                <div className="d-flex flex-column justify-content-start align-items-center">
                                    <Image style={{ height: "90px", width: "auto" }} src={image.location} alt="" />
                                    {image.name === deleteId ? (
                                        <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="visually-hidden">deleting...</span>
                                        </div>
                                    ) : (
                                        <i
                                            role="button"
                                            style={{ color: "red" }}
                                            onClick={() => handleFileDelete(image.name)}
                                            className="fa-solid fa-trash-can"></i>
                                    )}
                                </div>
                            </Grid>
                        ))}
                        {formikResidential.errors.images && <FormHelperText error>{formikResidential.errors.images}</FormHelperText>}
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginTop: "40px" }}>
                    <Button type="submit" fullWidth variant="contained" color="primary">
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};
