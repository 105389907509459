import React, { useState, useContext } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
//import Link from '@mui/material/Link';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import aqivahLogo from "../../assets/images/AqIcPurpleNoText.png";
import Header from "../../components/Header";
import { AUTH_TOKEN_KEY, USER_KEY, ORGANIZATION_KEY } from "../../common";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { PopupProviderContext } from "../../common/popup-alerts-provider/popup-context";
import { useQueryParams } from "../../utils/use-query-params";
import Backdrop from "@mui/material/Backdrop";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link /*color="inherit"*/ to="#">Aqivah</Link> {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh"
    },
    image: {
        //backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundImage:
            "url(https://images.unsplash.com/photo-1430285561322-7808604715df?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80)",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center"
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        margin: theme.spacing(1),
        //backgroundColor: theme.palette.secondary.main
        backgroundColor: "tranparent"
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export default function Login(props) {
    const classes = useStyles();
    const { flashSuccess, flashError } = useContext(PopupProviderContext);
    const qp = useQueryParams();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const validationSchemaLogin = Yup.object({
        email: Yup.string().email("Invalid email format").required("Email is required"),
        password: Yup.string().required("Password is required")
    });

    const logError = err => {
        if (!err.response) return err.error;
        return err.response.data.error;
    };

    const formikLogin = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: validationSchemaLogin,
        onSubmit: values => {
            console.log("login init");
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            setLoading(true);
            setSuccess(false);
            setError("");
            axios
                .post("/auth/login", values, config)
                .then((res: any) => {
                    console.log(res);
                    localStorage.removeItem(ORGANIZATION_KEY);
                    localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(res.data.token));
                    localStorage.setItem(USER_KEY, JSON.stringify(res.data.user));
                    setError("");
                    setLoading(false);
                    flashSuccess("Logged in!");
                    // refreshAdmin();
                    setTimeout(() => {
                        let next = qp.next + "?";
                        if (qp.org) {
                            next += `org=${qp.org}`;
                        }
                        if (qp.key) {
                            next += `&key=${qp.key}`;
                        }
                        window.location.href = next || "/";
                    }, 2000);
                })
                .catch(err => {
                    console.log(err);
                    flashError("Something went wrong!", logError(err));
                    console.log(err.response.data.error);
                    setLoading(false);
                    setSuccess(false);
                });
        }
    });

    return (
        <Grid container component="main" className={classes.root}>
            {/* <Header/> */}
            <Backdrop open={loading} style={{ zIndex: "5" }}>
                <span className="spinner-border text-white" role="status"></span>
            </Backdrop>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        {/* <LockOutlinedIcon /> */}
                        <img src={aqivahLogo} alt="" />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={classes.form} onSubmit={formikLogin.handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={formikLogin.values.email}
                            onChange={formikLogin.handleChange}
                            error={formikLogin.touched.email && formikLogin.errors.email ? true : false}
                            helperText={formikLogin.touched.email && formikLogin.errors.email}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            id="password"
                            type={showPassword ? "text" : "password"}
                            value={formikLogin.values.password}
                            onChange={formikLogin.handleChange}
                            error={formikLogin.touched.password && formikLogin.errors.password ? true : false}
                            helperText={formikLogin.touched.password && formikLogin.errors.password}
                            InputProps={{
                                endAdornment:
                                    formikLogin.values.password !== "" ? (
                                        !showPassword ? (
                                            <i onClick={() => setShowPassword(!showPassword)} className="fas fa-eye"></i>
                                        ) : (
                                            <i onClick={() => setShowPassword(!showPassword)} className="fas fa-eye-slash"></i>
                                        )
                                    ) : null
                            }}
                            autoComplete="current-password"
                        />
                        <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
                        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link to="#" /*variant="body2"*/>Forgot password?</Link>
                            </Grid>
                            <Grid item>
                                <Link to="/register" /*variant="body2"*/>{"Don't have an account? Sign Up"}</Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}
