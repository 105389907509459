import React, { useEffect } from "react";
import { Organization, OrganizationProviderContext } from "./organization-provider-context";
import { useState } from "react";
import axios, { AxiosResponse } from "axios";
import { AUTH_TOKEN_KEY, ORGANIZATION_KEY } from "../keys/keys";
import { useNavigate } from "react-router-dom";
import Spin from "antd/es/spin";

interface IOrganizationProvider {
    restricted?: boolean;
    children: any;
}

export const OrganizationProvider = ({ children, restricted }: IOrganizationProvider): JSX.Element => {
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [fetching, setFetching] = useState(false);
    const navigate = useNavigate();

    const refreshOrganization = (): void => {
        const token: any = localStorage.getItem(AUTH_TOKEN_KEY) ? JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY) as any) : null;
        const organization: any = localStorage.getItem(ORGANIZATION_KEY)
            ? JSON.parse(localStorage.getItem(ORGANIZATION_KEY) as any)
            : null;
        setOrganization(organization);
        if (restricted && !token) {
            navigate("/");
            return;
        }
        if (restricted && token) {
            setFetching(true);
            axios
                .get("/organizations/user")
                .then(res => {
                    localStorage.setItem(ORGANIZATION_KEY, JSON.stringify(res.data.organization));
                    setOrganization(res.data.organization);
                    setFetching(false);
                })
                .catch(err => {
                    navigate("/");
                    setFetching(false);
                })
                .then(() => setFetching(false));
        }
    };

    const checkAdmin = (): void => {
        const token: any = localStorage.getItem(AUTH_TOKEN_KEY) ? JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY) as any) : null;
        const user = localStorage.getItem(ORGANIZATION_KEY) ? JSON.parse(localStorage.getItem(ORGANIZATION_KEY) as any) : null;

        if (restricted && user) {
            return;
        }
        if (restricted && !user) {
            // navigate("/");
            window.location.href = "/";
            return;
        }
    };

    useEffect(refreshOrganization, []);
    useEffect(checkAdmin, [window.location.pathname]);

    return (
        <OrganizationProviderContext.Provider value={{ refreshOrganization, organization }}>
            {restricted && fetching && (
                <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin />
                </div>
            )}
            {((restricted && organization) || !restricted) && children}
        </OrganizationProviderContext.Provider>
    );
};
