
export const productionClientUserPool = {
    UserPoolId: "us-west-2_rl3kALmht",
    ClientId: "6jiqt2ltrsjqcklq1afants6b8",
    region: "us-west-2"
}

export const testClientUserPool = {
    UserPoolId: 'us-west-2_MksRVgimN',
    ClientId: 'd2f9dmun9ih1bv5fgrvqid9vl',
    region: "us-west-2",
}

export const developmentClientUserPool = {
    UserPoolId: 'us-west-2_kiVIWuU8t',
    ClientId: '6qjc65mgtr9hntfb6hak9ved85',
    region: "us-west-2",
}

export let currentUserPool = process.env.REACT_APP_ENVIRONMENT === "production" ? productionClientUserPool : testClientUserPool