import React, { useEffect } from "react";
import { PopupProviderContext } from "./popup-context";
import Modal from "antd/es/modal";
import success from "../../assets/popup/success.png";
import error from "../../assets/popup/error.png";
import info from "../../assets/popup/info.png";
import "./alert.scss";

interface IAdminProvider {
    children: any;
}

export const PopupProvider = ({ children }: IAdminProvider): JSX.Element => {
    const [openSuccess, setOpenSuccess] = React.useState<boolean>(false);
    const [openError, setOpenError] = React.useState<boolean>(false);
    const [openInfo, setOpenInfo] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState("");
    const [title, setTitle] = React.useState("");

    const flashSuccess = (title: string, message: string, timer = 3000) => {
        setOpenSuccess(true);
        setMessage(message || "");
        setTitle(title || "");
        setTimeout(() => {
            setOpenSuccess(false);
        }, timer);
    };
    const flashError = (title: string, message: string, timer = 3000) => {
        setOpenError(true);
        setMessage(message || "");
        setTitle(title || "");
        setTimeout(() => {
            setOpenError(false);
        }, timer);
    };
    const flashInfo = (title: string, message: string, timer = 3000) => {
        setOpenInfo(true);
        setMessage(message || "");
        setTitle(title || "");
        setTimeout(() => {
            setOpenInfo(false);
        }, timer);
    };

    const reset = () => {
        setOpenSuccess(false);
        setOpenError(false);
        setOpenInfo(false);
        setMessage("");
        setTitle("");
    };

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    return (
        <PopupProviderContext.Provider value={{ flashSuccess, flashError, flashInfo }}>
            <Modal open={openSuccess} zIndex={2000} width={400} footer={null} onCancel={reset}>
                <div
                    style={{ width: "100%" }}
                    className="d-flex flex-column justify-content-start align-items-center"
                    id="success-popup">
                    <img src={success} style={{ width: "auto", height: "100px" }} alt="" />
                    <h6 className="fw-bold">{title}</h6>
                    <p>{message}</p>
                </div>
            </Modal>
            <Modal open={openError} zIndex={2000} width={400} footer={null} onCancel={reset}>
                <div
                    style={{ width: "100%" }}
                    id="success-popup"
                    className="d-flex flex-column justify-content-start align-items-center">
                    <img src={error} style={{ width: "auto", height: "100px" }} alt="" />
                    <h6 className="fw-bold">{title}</h6>
                    <p>{message}</p>
                </div>
            </Modal>
            <Modal open={openInfo} zIndex={2000} width={400} footer={null} onCancel={reset}>
                <div
                    style={{ width: "100%" }}
                    id="success-popup"
                    className="d-flex flex-column justify-content-start align-items-center">
                    <img src={info} style={{ width: "auto", height: "100px" }} alt="" />
                    <h6 className="fw-bold">{title}</h6>
                    <p>{message}</p>
                </div>
            </Modal>
            {children}
        </PopupProviderContext.Provider>
    );
};
