import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Divider from "antd/es/divider";
import numeral from "numeral";
import Favorite from "@mui/icons-material/Favorite";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Checkbox from "@mui/material/Checkbox";
import "./property-search-page.scss";

interface IPropertyCard {
    property: any;
}

export default function PropertyCard({ property }: IPropertyCard) {
    const [screenType, setScreenType] = useState("desktop");

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 760) {
                setScreenType("desktop");
            }

            if (window.innerWidth <= 760) {
                setScreenType("mobile");
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });

    const acquisition = {
        buy: "For Sale",
        rent: "For Rent",
        short_stay: "Short Stay"
    };

    const paymentPlan = {
        DAILY: "per/day",
        WEEKLY: "per/wk",
        MONTHLY: "per/mth",
        YEARLY: "per/yr"
    };

    const currency = {
        USD: "$ ",
        GHC: (
            <>
                GH<i className="fa-solid fa-cent-sign me-1"></i>
            </>
        )
    };

    const label = { inputProps: { "aria-label": "Checkbox demo" } };

    return screenType === "mobile" ? (
        <Card
            id="property-card-m0"
            className={`search-property-card-m0`}
            /*sx={{ maxWidth: 355 }}*/ sx={{ maxWidth: "100%", padding: 0 }}>
            <div className="acquisition-tag d-flex justify-content-center align-items-center">{acquisition[property.acquisition]}</div>
            <CardMedia
                component="img"
                height="225"
                width="50%"
                image={property.images[0].location ? property.images[0].location : property.images[0]}
            />
            <CardContent sx={{ width: "100%", height: "100%" }}>
                <div className="d-flex justify-content-between align-items-center mb-1">
                    <h6 className="fw-bold tag-text">{property.type}</h6>
                    {/* <i style={{ color: "#3089e8", fontSize: "20px" }} className="fa-regular fa-heart"></i> */}
                    <Checkbox {...label} icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
                </div>
                <div className="d-flex mb-1">
                    <h6 className="fw-bold location-text">
                        {property.location_details?.town ? property.location_details?.town : property.location_details}
                    </h6>
                </div>
                <div className="d-flex align-items-center mb-5">
                    <h5 className="fw-bold">
                        {currency[property.currency]}
                        {numeral(property.price || "").format("0,0")}
                    </h5>
                    <span className="ms-1 fw-bold">{property.payment_plan && paymentPlan[property.payment_plan]}</span>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-2">
                    <span className="d-flex align-items-center" style={{ fontSize: "11px" }}>
                        <i style={{ color: "#cecece", fontSize: "15px" }} className="fa-solid fa-bed me-1"></i>
                        {property.bedrooms} Beds
                    </span>
                    <Divider type="vertical" />
                    <span className="d-flex align-items-center" style={{ fontSize: "11px" }}>
                        <i style={{ color: "#cecece", fontSize: "15px" }} className="fa-solid fa-shower me-1"></i>
                        {property.bathrooms} Baths
                    </span>
                    <Divider type="vertical" />
                    <span className="d-flex align-items-center" style={{ fontSize: "11px" }}>
                        <i style={{ color: "#cecece", fontSize: "15px" }} className="fa-solid fa-ruler-combined me-1"></i> 1720 ft
                        <sup>2</sup>
                    </span>
                </div>

                {/* <div className="d-flex fw-light listing-line">Listing by Murdock Square Group (Accra, Ghana)</div> */}
            </CardContent>
        </Card>
    ) : (
        <div id="property-card-m0-mobile" className="d-flex">
            <div className="acquisition-tag d-flex justify-content-center align-items-center">{acquisition[property.acquisition]}</div>
            <img
                src={property.images[0].location ? property.images[0].location : property.images[0]}
                style={{
                    minWidth: "350px",
                    maxWidth: "350px",
                    minHeight: "225px",
                    maxHeight: "225px",
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                }}
                alt=""
            />

            <div className="d-flex flex-column justify-content-start align-items-start p-3 w-100">
                <div className="d-flex justify-content-between align-items-center mb-1 w-100">
                    <h6 className="fw-bold tag-text">{property?.type}</h6>
                    {/* <i style={{ color: "#3089e8", fontSize: "20px" }} className="fa-regular fa-heart"></i> */}
                    <Checkbox {...label} icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
                </div>
                <div className="d-flex mb-1">
                    <h6 className="fw-bold location-text">
                        {property.location_details?.town ? property.location_details?.town : property.location_details}
                    </h6>
                </div>
                <div className="d-flex mb-5">
                    <h5 className="fw-bold">
                        {currency[property.currency]}
                        {numeral(property.price || "").format("0,0")}
                    </h5>
                </div>

                <div className="flex-1-1"></div>

                <div className="d-flex justify-content-between align-items-center mb-2">
                    <span className="d-flex align-items-center" style={{ fontSize: "11px" }}>
                        <i style={{ color: "#cecece", fontSize: "15px" }} className="fa-solid fa-bed me-1"></i>
                        {property.bedrooms} Beds
                    </span>
                    <Divider type="vertical" />
                    <span className="d-flex align-items-center" style={{ fontSize: "11px" }}>
                        <i style={{ color: "#cecece", fontSize: "15px" }} className="fa-solid fa-shower me-1"></i>
                        {property.bathrooms} Baths
                    </span>
                    <Divider type="vertical" />
                    <span className="d-flex align-items-center" style={{ fontSize: "11px" }}>
                        <i style={{ color: "#cecece", fontSize: "15px" }} className="fa-solid fa-ruler-combined me-1"></i> 1720 ft
                        <sup>2</sup>
                    </span>
                </div>

                {/* <div className="d-flex fw-light listing-line">Listing by Murdock Square Group (Accra, Ghana)</div> */}
            </div>
        </div>
    );
}
