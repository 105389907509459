import React, { useState, useEffect } from "react";
import { FaRegEye } from "react-icons/fa";
import { RiEyeCloseLine } from "react-icons/ri";
import { UserPool } from "../../config/UserPool";
import { CognitoUser } from "amazon-cognito-identity-js";
import { StyleSheet, css } from "../../config/my-aphrodite";
import Header from "../../components/Header";
import Footer from "../../components/Footer/Footer";
import "../../CSS_Styling/LoginPageCSS.css";
import "../../CSS_Styling/LandingPageCSS.css";
import "../../CSS_Styling/LoginPageCSS.css";
import "../../CSS_Styling/ResetPasswordCSS.css";
import { Spin, Alert } from "antd";

export const ResetPassword = (props: any) => {
    const [email, setEmail] = useState<any>("");
    const [confirmationCode, setConfirmationCode] = useState<any>("");
    const [confirmation_success, setConfirmationSuccess] = useState<boolean>(false);
    const [resend_success, setResendSuccess] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<any>("");

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPassword2, setShowPassword2] = useState<boolean>(false);

    const [password, setPassword] = useState<string>("");
    const [confirm_password, setConfirmPassword] = useState<string>("");
    const [failure, setFailure] = useState<boolean>(false);
    const [loginLoading, setLoginLoading] = useState<boolean>(false);
    const [resendLoading, setResendLoading] = useState<boolean>(false);

    useEffect(() => {
        let params = props.match.params;

        setEmail(params.email);
        setConfirmationCode(params.code);
    }, []);

    const styles = StyleSheet.create({
        box_input_submit: {
            borderBottom: "none",
            cursor: "pointer",
            background: "#ab32e5",
            color: "#fff",
            marginBottom: "0",
            textTransform: "uppercase",
            borderRadius: "10px 10px 10px 10px",
            padding: "10px 0",
            width: "90%",
            fontSize: "12px",
            boxSizing: "border-box",
            boxShadow: "none",
            outline: "none",
            border: "none"
        },

        box_input_resend: {
            borderBottom: "none",
            cursor: "pointer",
            background: "#ab32e5",
            color: "#fff",
            marginBottom: "0",
            textTransform: "uppercase",
            borderRadius: "10px 10px 10px 10px",
            padding: "10px 0",
            width: "30%",
            fontSize: "12px",
            boxSizing: "border-box",
            boxShadow: "none",
            outline: "none",
            border: "none"
        }
    });

    const resetFormSubmit = (e: any) => {
        e.preventDefault();
        setLoginLoading(true);
        setFailure(false);
        setErrorMessage("");
        setResendLoading(false);
        setResendSuccess(false);

        if (password !== confirm_password) {
            setLoginLoading(false);
            setFailure(true);
            setErrorMessage("Passwords do not match!");
            setConfirmationSuccess(false);
            throw "Passwords do not match!";
        } else {
            const getUser = new CognitoUser({
                Username: email,
                Pool: UserPool
            });

            getUser.confirmPassword(confirmationCode, password, {
                onSuccess: () => {
                    //no data is returned on success
                    console.log("onSuccess: ");
                    setLoginLoading(false);
                    setConfirmationSuccess(true);
                },
                onFailure: err => {
                    console.log("onFailure: ", err);
                    setLoginLoading(false);
                    setFailure(true);
                    setErrorMessage(err.message);
                }
            });
        }
    };

    const resendCode = () => {
        setResendLoading(true);
        setResendSuccess(false);
        setFailure(false);
        setErrorMessage("");

        const getUser = new CognitoUser({
            Username: email,
            Pool: UserPool
        });

        getUser.resendConfirmationCode((err, data) => {
            if (err) {
                console.log(err);
                setFailure(true);
                setErrorMessage(err.message);
                setResendLoading(false);
                setResendSuccess(false);
            } else {
                console.log(data);
                setResendLoading(false);
                setResendSuccess(true);
                setFailure(false);
            }
        });
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
            <Header />
            <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", height: "100vh" }}>
                <div className="box-reset-password ">
                    <div className="login-text">
                        <div className="text-section">
                            {" "}
                            <h2 className="auth_header_text">Reset Password </h2>
                        </div>
                    </div>
                    {failure ? (
                        <div className="auth-spinner">
                            {" "}
                            <Alert message={errorMessage} type="error" showIcon />{" "}
                        </div>
                    ) : (
                        ""
                    )}
                    {confirmation_success ? (
                        <div className="auth-spinner">
                            {" "}
                            <Alert message={"Success"} type="success" showIcon />{" "}
                        </div>
                    ) : (
                        ""
                    )}
                    {resend_success ? (
                        <div className="auth-spinner">
                            {" "}
                            <Alert message={`Code resent to email:${email}`} type="success" showIcon />{" "}
                        </div>
                    ) : (
                        ""
                    )}

                    <form onSubmit={e => resetFormSubmit(e)}>
                        <div className="input-holder">
                            <input
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                name=""
                                required={true}
                            />
                            <label className="label" htmlFor="">
                                * New Password
                            </label>
                            {password !== "" ? (
                                !showPassword ? (
                                    <FaRegEye className="show_password" onClick={() => setShowPassword(!showPassword)} />
                                ) : (
                                    <RiEyeCloseLine className="show_password" onClick={() => setShowPassword(!showPassword)} />
                                )
                            ) : null}
                        </div>

                        <div className="input-holder">
                            <input
                                type={showPassword2 ? "text" : "password"}
                                value={confirm_password}
                                onChange={e => setConfirmPassword(e.target.value)}
                                name=""
                                required={true}
                            />
                            <label className="label" htmlFor="">
                                *Confirm Password
                            </label>
                            {confirm_password !== "" ? (
                                !showPassword2 ? (
                                    <FaRegEye className="show_password" onClick={() => setShowPassword2(!showPassword2)} />
                                ) : (
                                    <RiEyeCloseLine className="show_password" onClick={() => setShowPassword2(!showPassword2)} />
                                )
                            ) : null}
                        </div>

                        <div className="input-holder">
                            <button type="submit" name="" className={css(styles.box_input_submit)} value={"Submit"}>
                                {loginLoading ? <Spin spinning={true} size="small"></Spin> : "Submit"}
                            </button>
                        </div>
                    </form>

                    <div className="forgot-password">
                        <p
                            onClick={() => {
                                /*closeLogin(); openSignUp()*/
                            }}>
                            Register
                        </p>
                        <button onClick={() => resendCode()} name="" className={css(styles.box_input_resend)} value={"Resend Code"}>
                            {resendLoading ? <Spin spinning={true} size="small"></Spin> : "Resend Code"}
                        </button>

                        {/* <p onClick={() => { closeLogin(); openForgotPassword() }}>Forgot Password or Username?</p> */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
