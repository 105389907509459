import { createContext } from "react";

interface IPrice {
    min: number;
    max: number;
}

export interface ILocation {
    longitude: number;
    latitude: number;
}

interface Utilities {
    water: "gwcl" | "tanker_service" | "borehole" | "none";
    water_storage: "tank" | "none";
    electricity: "ecg" | "solar" | "generator" | "none";
    electricity_backups: Array<"solar" | "generator" | "none">;
    garage: boolean;
    parking_space: number;
}

interface Rules {
    pets?: boolean;
    smoking?: boolean;
    parties?: boolean;
    noise?: boolean;
    other?: string;
}

interface StayDate {
    start: Date | string;
    end: Date | string;
}

type Category = "Residential" | "Commercial" | "Farmland" | "Events";

type Amenities = "garden" | "gym" | "airconditioning" | "swimming_pool" | "";
export interface IPropertyFilters {
    bedrooms?: number;
    bathrooms?: number;
    acquisition?: "buy" | "rent" | "short_stay" | "";
    stay_date?: null | StayDate;
    type?: string;
    price?: IPrice;
    category?: Category;
    currency?: string;
    location?: ILocation;
    amenities?: Array<Amenities>;
    utilities?: Utilities;
    rules?: Rules;
}
interface ISearchContext {
    propertyFilters?: IPropertyFilters;
    setPropertyFilters: (value: any) => void;
    handleFilterChange: (value: any) => void;
    handleBedroomChange: (value: any) => void;
    handleBathroomChange: (value: any) => void;
    handleLocationChange: (value: ILocation) => void;
    handleLocationClear: () => void;
    handleReset: () => void;
}

export const SearchContext = createContext<ISearchContext>(null);
