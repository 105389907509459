import gql from "graphql-tag";

export const CreateFeedBack = () => gql`
    mutation ($email: AWSEmail!, $feedback: String!) {
        createFeedback(feedback: { email: $email, body: $feedback }) {
            id
            email
            body
        }
    }
`;

export const CreateRequest = gql`
    mutation ($email: AWSEmail!, $request: String!) {
        createRequestForm(input: { email: $email, request: $request }) {
            id
            email
            request
        }
    }
`;

export const CreateUser = gql`
    mutation ($firstName: String!, $lastName: String!, $mobile: String, $email: String!, $password: String!) {
        createUser(user: { firstName: $firstName, lastName: $lastName, mobile: $mobile, email: $email, password: $password }) {
            id
            firstName
            lastName
            mobile
            email
        }
    }
`;

// Update User, from the docs, the user id has to be passed as an argument. This should come from the back
export const UpdateUser = gql`
    mutation ($firstname: String, $lastname: String, $email: AWSEmail, $status: UserStatus) {
        updateUser(user: { firstname: $firstname, lastname: $lastname, email: $email, status: $status }) {
            firstname
            lastname
            email
            status
        }
    }
`;

export const DeleteUser = gql`
    mutation ($id: ID!) {
        deleteUser(id: $id) {
            id
        }
    }
`;

// New Mutations

export const createRes = gql`
    mutation CreateResidential(
        $ownerId: ID!
        $acquisition: String!
        $price: Float!
        $bedrooms: Int!
        $bathrooms: Int!
        $amenities: [String!]
        $description: String
        $location: PropertyLocationInput!
        $images: [String!]!
    ) {
        createResidential(
            property: {
                ownerId: $ownerId
                acquisition: $acquisition
                price: $price
                amenities: $amenities
                description: $description
                location: $location
                bedrooms: $bedrooms
                bathrooms: $bathrooms
                images: $images
            }
        ) {
            id
            price
            bedrooms
            bathrooms
            description
        }
    }
`;

export const CreateResidential_ = () => gql`
    mutation (
        $ownerId: ID!
        #$type: String!
        $acquisition: String!
        # $furnished: PropertyState!
        $price: Float!
        $bedrooms: Int!
        $bathrooms: Int!
        $amenities: [String!]
        $description: String
        $location: PropertyLocationInput!
        $images: [String!]!
    ) {
        createResidential(
            property: {
                #type: $type
                ownerId: $ownerId
                acquisition: $acquisition
                #state: $furnished
                price: $price
                amenities: $amenities
                description: $description
                location: $location
                bedrooms: $bedrooms
                bathrooms: $bathrooms
                images: $images
            }
        ) {
            acquisition
            price
            description
            bedrooms
            bathrooms
            #images
        }
    }
`;

export const CreateCommercial_ = () => gql`
    mutation (
        $type: CommercialType!
        $state: PropertyState!
        $acquisition: AcquisitionType!
        $amenities: [String!]
        $parkingCapacity: Int
        $price: PriceInput!
        $description: String
        $location: CreatePropertyLocationInput!
        $images: [String!]
    ) {
        createCommercial(
            property: {
                type: $type
                acquisition: $acquisition
                amenities: $amenities
                parkingCapacity: $parkingCapacity
                price: $price
                description: $description
                location: $location
                images: $images
            }
        ) {
            id
        }
    }
`;

export const CreateAgricultural_FarmLand_ = () => gql`
    mutation (
        $type: Agricultural_FarmlandType!
        $acquisition: AcquisitionType!
        $price: PriceInput!
        $size: LandSizeInput!
        $amenities: [String!]
        $description: String
        $location: CreatePropertyLocationInput!
        $images: [String!]
    ) {
        createAgricultural_Farmland(
            property: {
                type: $type
                acquisition: $acquisition
                price: $price
                size: $size
                amenities: $amenities
                description: $description
                location: $location
                images: $images
            }
        ) {
            id
        }
    }
`;

export const CreateParties_Event_ = () => gql`
    mutation (
        $type: Parties_EventType!
        $acquisition: AcquisitionType!
        $price: PriceInput
        $parkingCapacity: Int!
        $seating: Int!
        $amenities: [String!]
        $description: String
        $location: CreatePropertyLocationInput!
        $images: [String!]
    ) {
        createParties_Event(
            properties: {
                type: $type
                acquisition: $acquisition
                price: $price
                parkingCapacity: $parkingCapacity
                seating: $seating
                amenities: $amenities
                description: $description
                location: $location
                images: $images
            }
        ) {
            id
        }
    }
`;

export const CreateFeedBack_ = () => gql`
    mutation {
        createFeedback(feedback: [{ email: "johncoleman951@gmail.com", body: "This is a test. All systems go." }]) {
            body
        }
    }
`;

export const CreatePropertyRequest = () => gql`
    mutation ($type: PropertyType, $acquisition: AcquisitionType, $location: RequestLocationInput) {
        createPropertyRequests(requests: [{ type: $type, acquisition: $acquisition, location: $location }]) {
            type
            acquisition
            location
        }
    }
`;

export const UpdateResidential_ = () => gql`
    mutation (
        $id: ID!
        $category: PropertyCategory
        $type: ResidentialType
        $acquisition: AcquisitionType
        $furnished: PropertyState
        $price: PriceInput
        $bedrooms: Int
        $bathrooms: Int
        $amenities: [String!]
        $description: String
        $location: CreatePropertyLocationInput
        $images: [String!]
    ) {
        updateResidential(
            properties: {
                id: $id
                category: $category
                type: $type
                acquisition: $acquisition
                state: $furnished
                price: $price
                amenities: $amenities
                description: $description
                location: $location
                bedrooms: $bedrooms
                bathrooms: $bathrooms
                images: $images
                amenities: [""]
            }
        ) {
            id
            price {
                currency
                figure
                duration
            }
            description
            bedrooms
            bathrooms
        }
    }
`;

export const UpdateCommercial_ = () => gql`
    mutation (
        $id: ID!
        $category: PropertyCategory
        $type: CommercialType
        $state: PropertyState
        $acquisition: AcquisitionType
        $amenities: [String!]
        $parkingCapacity: Int
        $price: PriceInput!
        $description: String
        $location: CreatePropertyLocationInput
        $images: [String!]
    ) {
        updateCommercial(
            properties: [
                {
                    id: $id
                    category: $category
                    type: $type
                    state: $state
                    acquisition: $acquisition
                    amenities: $amenities
                    parkingCapacity: $parkingCapacity
                    price: $price
                    description: $description
                    location: $location
                }
            ]
        )
    }
`;

export const UpdateAgricultural_Farmland_ = () => gql`
    mutation (
        $id: ID!
        $category: PropertyCategory
        $type: Agricultural_FarmlandType!
        $acquisition: AcquisitionType!
        $price: PriceInput!
        $size: LandSizeInput!
        $amenities: [String!]
        $description: String
        $location: CreatePropertyLocationInput!
        $images: [String!]
    ) {
        updateAgricultural_Farmland(
            properties: [
                {
                    id: $id
                    category: $category
                    type: $type
                    acquisition: $acquisition
                    pice: $price
                    size: $size
                    amenities: $amenities
                    description: $description
                    location: $location
                }
            ]
        )
    }
`;

export const UpdateParties_Events_ = () => gql`
    mutation (
        $id: ID!
        $category: PropertyCategory
        $type: Parties_EventType!
        $acquisition: AcquisitionType!
        $price: PriceInput
        $parkingCapacity: Int!
        $seating: Int!
        $amenities: [String!]
        $description: String
        $location: CreatePropertyLocationInput!
        $images: [String!]
    ) {
        updateParties_Event(
            properties: [
                {
                    id: $id
                    category: $category
                    type: $type
                    acquisition: $acquisition
                    price: $price
                    parkingCapacity: $parkingCapacity
                    seating: $seating
                    amenities: $amenities
                    description: $description
                    location: $location
                }
            ]
        )
    }
`;

export const UpdateUser_ = () => gql`
    mutation (
        $firstname: String
        $lastname: String
        $middlename: String
        $phone: CreatePhoneInput
        $email: AWSEmail
        $status: UserStatus
        $ownerType: OwnerType
    ) {
        updateUser(
            input: [
                {
                    firstname: $firstname
                    lastname: $lastname
                    middlename: $middlename
                    phone: $phone
                    email: $email
                    status: $status
                    ownerType: $ownerType
                }
            ]
        )
    }
`;

export const DeleteProperty = () => gql`
    mutation ($category: PropertyCategory!, $id: ID!) {
        deleteProperties(properties: [{ category: $category, id: $id }])
    }
`;
