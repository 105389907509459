import React, { useState, useContext } from "react";
import Header from "../../components/Header";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { PopupProviderContext } from "../../common/popup-alerts-provider/popup-context";
import BathtubIcon from "@mui/icons-material/Bathtub";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import FormHelperText from "@mui/material/FormHelperText";
import { AuthenticationContext } from "../../context/auth-context/auth-context";
import { AdminProviderContext } from "../../common/admin-provider/admin-provider-context";
import { AUTH_TOKEN_KEY, USER_KEY } from "../../common";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { useLocationSearch } from "../../Hooks/use-location";
import { logError } from "../../utils/logError";

import "./preference-listing.scss";

export const PreferenceListing = () => {
    const CREATE_REQUEST = "create-request";

    const [events, setEvents] = useState<any>([]);
    const [min_price, setMinPrice] = useState<any>(0);
    const [max_price, setMaxPrice] = useState<any>(0);
    const [formattedMinNumber, setFormattedMinPrice] = useState<any>("");
    const [formattedMaxNumber, setFormattedMaxPrice] = useState<any>("");
    const [bedroom, setBedroom] = useState<number>(0);
    const [bathroom, setBathroom] = useState<number>(0);
    const [matchType, setMatchType] = useState<any>({
        exact: false,
        similar: false,
        broad: false
    });

    const { flashSuccess, flashError } = useContext(PopupProviderContext);
    const { openLoginModal } = useContext(AuthenticationContext);
    const { user } = useContext(AdminProviderContext);
    const [InputField, place] = useLocationSearch();

    const validationSchema = Yup.object({
        category: Yup.string().required("Category is required"),
        acquisition: Yup.string().required("Purchase type is required"),
        type: Yup.string(),
        additional_info: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            category: "",
            acquisition: "",
            type: "",
            additional_info: ""
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            setEvents([...events, CREATE_REQUEST]);
            const token: any = localStorage.getItem(AUTH_TOKEN_KEY) ? JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY) as any) : null;
            if (!token) {
                openLoginModal();
            } else {
                axios
                    .post("/requests/create-request", {
                        ...values,
                        min_price,
                        max_price,
                        matchType,
                        bedrooms: bedroom,
                        bathrooms: bathroom,
                        location_details: {
                            town: place?.address_components[0]?.long_name,
                            region: place?.address_components[3]?.long_name,
                            latitude: place.geometry.location.lat(),
                            longitude: place.geometry.location.lng()
                        },
                        location: {
                            type: "Point",
                            coordinates: [place.geometry.location.lng(), place.geometry.location.lat()]
                        }
                    })
                    .then((res: any) => {
                        console.log(res);
                        flashSuccess("Request submitted successfully");
                        setTimeout(() => {
                            clearFields();
                        }, 1200);
                    })
                    .catch(err => {
                        console.log(err);
                        flashError("Something went wrong", logError(err));
                    })
                    .then(() => setEvents(events.filter((event: any) => event !== CREATE_REQUEST)));
            }
        }
    });

    function clearFields() {
        formik.values.category = "";
        formik.values.type = "";
        formik.values.acquisition = "";
        formik.values.additional_info = "";
        setMinPrice(0);
        setMaxPrice(0);
        setMatchType({
            exact: false,
            similar: false,
            broad: false
        });
    }

    const handleBathroom = e => {
        if (e === "plus") {
            if (bathroom < 9) {
                setBathroom(bathroom + 1);
            }
        }

        if (e === "minus") {
            if (bathroom > 1) {
                setBathroom(bathroom - 1);
            }
        }
    };

    const handleBedroom = e => {
        console.log(e);
        if (e === "plus") {
            if (bedroom < 9) {
                setBedroom(bedroom + 1);
            }
        }

        if (e === "minus") {
            if (bedroom > 1) {
                setBedroom(bedroom - 1);
            }
        }
    };

    const handleMinPrice = e => {
        let price_ = e.target.value.replaceAll(",", "");
        if (Number(price_)) {
            setMinPrice(Number(price_));
            setFormattedMinPrice(formatNumber(Number(price_)));
        }
        if (!Number(price_)) {
            console.log(Number(price_));
            let number = formattedMinNumber.replaceAll(",", "");
            if (Number(number) === Infinity) {
                setFormattedMinPrice("");
                setMinPrice(0);
            } else {
                setMinPrice(Number(price_));
                Number(price_) === 0 ? setFormattedMinPrice("") : setFormattedMinPrice(formatNumber(Number(price_)));
            }
        }
    };

    const handleMaxPrice = e => {
        let price_ = e.target.value.replaceAll(",", "");
        if (Number(price_)) {
            setMaxPrice(Number(price_));
            setFormattedMaxPrice(formatNumber(Number(price_)));
        }
        if (!Number(price_)) {
            console.log(Number(price_));
            let number = formattedMaxNumber.replaceAll(",", "");
            if (Number(number) === Infinity) {
                setFormattedMaxPrice("");
                setMaxPrice(0);
            } else {
                setMaxPrice(Number(price_));
                Number(price_) === 0 ? setFormattedMaxPrice("") : setFormattedMaxPrice(formatNumber(Number(price_)));
            }
        }
    };

    const handleMatching = e => {
        setMatchType({ [e.target.name]: true });
    };

    const formatNumber = (num: any) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    };
    return (
        <div id="preference-listing" className="py-5">
            <Header />
            <div className="container form-container py-5">
                <form onSubmit={formik.handleSubmit}>
                    <h5 className="text-center fw-bold mb-5">Create Property Preference</h5>
                    <h6 className="fw-bold mb-1">Details</h6>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="category_field">Category</InputLabel>
                                <Select
                                    labelId="category_field"
                                    id="category"
                                    name="category"
                                    value={formik.values.category}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    label="Category"
                                    error={formik.touched.type && formik.errors.type ? true : false}>
                                    <MenuItem value={"residential"}>Residential</MenuItem>
                                    <MenuItem value={"commercial"}>Commercial</MenuItem>
                                    <MenuItem value={"agricultural"}>Agricultural</MenuItem>
                                    <MenuItem value={"event"}>Party and Events</MenuItem>
                                </Select>
                                {formik.touched.category && formik.errors.category && (
                                    <FormHelperText error>{formik.errors.category}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="prop_type">Property type</InputLabel>
                                <Select
                                    id="type"
                                    labelId="prop_type"
                                    name="type"
                                    value={formik.values.type}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    label="Property type"
                                    error={formik.touched.type && formik.errors.type ? true : false}>
                                    {formik.values.category === "residential"
                                        ? [
                                              <MenuItem key={"house"} value={"house"}>
                                                  House
                                              </MenuItem>,
                                              <MenuItem key={"bungalow"} value={"bungalow"}>
                                                  Bungalow
                                              </MenuItem>,
                                              <MenuItem key={"townhouse"} value={"townhouse"}>
                                                  Townhouse
                                              </MenuItem>,
                                              <MenuItem key={"aapartment"} value={"aapartment"}>
                                                  Apartment
                                              </MenuItem>
                                          ]
                                        : formik.values.category === "commercial"
                                        ? [
                                              <MenuItem key={"office"} value={"office"}>
                                                  Office
                                              </MenuItem>,
                                              <MenuItem key={"shop"} value={"shop"}>
                                                  Shop
                                              </MenuItem>,
                                              <MenuItem key={"shipping_container"} value={"shipping_container"}>
                                                  Shipping Container
                                              </MenuItem>
                                          ]
                                        : formik.values.category === "agricultural"
                                        ? [
                                              <MenuItem key={"fish_farm"} value={"fish_farm"}>
                                                  Fish farm
                                              </MenuItem>,
                                              <MenuItem key={"crop_farm"} value={"crop_farm"}>
                                                  Crop farm
                                              </MenuItem>,
                                              <MenuItem key={"livestock"} value={"livestock"}>
                                                  Livestock
                                              </MenuItem>
                                          ]
                                        : formik.values.category === "event"
                                        ? [
                                              <MenuItem key={"church"} value={"church"}>
                                                  Church
                                              </MenuItem>,
                                              <MenuItem key={"theatre"} value={"theatre"}>
                                                  Theatre
                                              </MenuItem>
                                          ]
                                        : [
                                              <MenuItem key={"house"} value={"house"}>
                                                  House
                                              </MenuItem>,
                                              <MenuItem key={"bungalow"} value={"bungalow"}>
                                                  Bungalow
                                              </MenuItem>,
                                              <MenuItem key={"townhouse"} value={"townhouse"}>
                                                  Townhouse
                                              </MenuItem>,
                                              <MenuItem key={"apartment"} value={"apartment"}>
                                                  Apartment
                                              </MenuItem>
                                          ]}
                                </Select>
                                {formik.touched.type && formik.errors.type && (
                                    <FormHelperText error>{formik.errors.type}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={5}>
                            <FormControl fullWidth>
                                <InputLabel id="acquisition_field">Acquisition Type</InputLabel>
                                <Select
                                    labelId="acquisition_field"
                                    value={formik.values.acquisition}
                                    name="acquisition"
                                    onChange={formik.handleChange}
                                    fullWidth
                                    label="Sale type"
                                    error={formik.touched.acquisition && formik.errors.acquisition ? true : false}>
                                    <MenuItem value={"rent"}>Rent</MenuItem>
                                    <MenuItem value={"sell"}>Buy</MenuItem>
                                </Select>
                                {formik.touched.acquisition && <FormHelperText error>{formik.errors.acquisition}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        <Grid style={{ display: "flex", justifyContent: "space-between" }} item xs={12} sm={7}>
                            <TextField
                                id="price"
                                name="price"
                                label={"Min Price GHC"}
                                type={"text"}
                                fullWidth
                                value={formattedMinNumber}
                                onChange={handleMinPrice}
                                style={{ marginRight: "10px" }}
                            />
                            <TextField
                                id="price"
                                name="price"
                                label={"Max Price GHC"}
                                type={"text"}
                                fullWidth
                                value={formattedMaxNumber}
                                onChange={handleMaxPrice}
                            />
                        </Grid>

                        {formik.values.category === "residential" && (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    style={{
                                        padding: "30px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        alignItems: "center"
                                    }}>
                                    <Typography variant="subtitle1">
                                        <LocalHotelIcon color="primary" style={{ fontSize: "30px" }} />
                                    </Typography>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <div className="bath_buttons" onClick={() => handleBedroom("plus")}>
                                            <PlusOutlined />
                                        </div>

                                        <input disabled={true} value={bedroom} className="bath-input" />

                                        <div className="bath_buttons" onClick={() => handleBedroom("minus")}>
                                            <MinusOutlined />
                                        </div>
                                    </div>
                                    <FormHelperText style={{ color: "black" }}> Bedroom number</FormHelperText>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    style={{
                                        padding: "30px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        alignItems: "center"
                                    }}>
                                    <Typography variant="subtitle1">
                                        <BathtubIcon color="primary" style={{ fontSize: "30px" }} />
                                    </Typography>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <div className="bath_buttons" onClick={() => handleBathroom("plus")}>
                                            <PlusOutlined />
                                        </div>

                                        <input disabled={true} value={bathroom} className="bath-input" />

                                        <div className="bath_buttons" onClick={() => handleBathroom("minus")}>
                                            <MinusOutlined />
                                        </div>
                                    </div>
                                    <FormHelperText style={{ color: "black" }}> Bathroom number</FormHelperText>
                                </Grid>
                            </>
                        )}

                        <Grid style={{ marginTop: "50px" }} item xs={12}>
                            <h6 className="fw-bold">Additional Information</h6>

                            <FormHelperText style={{ color: "grey" }}>
                                {" "}
                                We know searching for a new home is more than just selecting a price and location. We'd love to hear
                                any other requirements you have.
                            </FormHelperText>
                            <TextField
                                style={{ marginTop: "20px" }}
                                id="additional_info"
                                name="additional_info"
                                placeholder="Enter additional information"
                                fullWidth
                                value={formik.values.additional_info}
                                onChange={formik.handleChange}
                                multiline
                                rows={2}
                            />
                        </Grid>
                    </Grid>

                    <Grid style={{ marginTop: "50px" }} item xs={12}>
                        <h6 className="fw-bold">Location</h6>
                        {InputField("medium")}
                    </Grid>

                    <Grid style={{ marginTop: "50px", marginBottom: "50px" }} container spacing={3}>
                        <Grid item className="d-flex flex-column justify-content-start align-items-start" xs={12}>
                            <h6 className="fw-bold">Property matching</h6>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        name="exact"
                                        checked={matchType.exact ? matchType.exact : false}
                                        onChange={handleMatching}
                                    />
                                }
                                label="Only show me exact matches"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        name="similar"
                                        checked={matchType.similar ? matchType.similar : false}
                                        onChange={handleMatching}
                                    />
                                }
                                label="Notify me of similar properties nearby"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        name="broad"
                                        checked={matchType.broad ? matchType.broad : false}
                                        onChange={handleMatching}
                                    />
                                }
                                label="I would consider broadening my search"
                            />
                        </Grid>
                    </Grid>

                    <div>
                        <Button fullWidth variant="contained" color="primary" type={"submit"}>
                            Submit
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};
