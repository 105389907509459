import React, { useState } from "react";
import Typography from "antd/es/typography";
import Alert from "antd/es/alert";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import axios from "axios";
import { AxiosError } from "axios";
import { css } from "../../config/my-aphrodite";
import styles from "../../Styles/AuthenticationFormsStyle";
import * as Yup from "yup";
import { useFormik } from "formik";
import Spin from "antd/es/spin";
import { makeStyles } from "@mui/styles";

export const ForgotPassword = ({ openSignUp, openLogin }) => {
    const { Title } = Typography;

    const [forgotPasswordDetails, setForgotPasswordDetails] = useState({ success: false, loading: false, error: "" });
    const [resetPasswordDetails, setResetPasswordDetails] = useState({ success: false, loading: false, error: "" });
    const [code, setCode] = useState<any>({});
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState<boolean>(false);

    const useStyles = makeStyles(theme => ({
        root: {
            height: "100vh"
        },
        image: {
            backgroundImage: "url(https://source.unsplash.com/random)",
            backgroundRepeat: "no-repeat",
            backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center"
        },
        paper: {
            margin: theme.spacing(8, 4),
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(1)
        },
        submit: {
            margin: theme.spacing(3, 0, 2)
        }
    }));

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const logError = (err: AxiosError<any>): string => {
        if (!err.response) return err.message;
        return err.response.data.message;
    };

    const validationSchemaRequestPasswordReset = Yup.object({
        email: Yup.string().email("Invalid email format").required("Email is required")
    });

    const formikRequestPasswordReset = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: validationSchemaRequestPasswordReset,
        onSubmit: values => {
            console.log(values);
        }
    });

    const validationSchemaSetNewPassword = Yup.object({
        code: Yup.string().required("Required! Confirmation code sent to your email"),
        newPassword: Yup.string()
            .required("Password is required")
            .matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Password must contain at least 8 characters, one uppercase, one number and one special case"
            ),
        confirmNewPassword: Yup.string()
            .required("Passwords do not match")
            .when("newPassword", {
                is: password => (password && password.length > 0 ? true : false),
                then: Yup.string().oneOf([Yup.ref("newPassword")], "Password doesn't match")
            })
    });

    const formikSetNewPassword = useFormik({
        initialValues: {
            code: "",
            newPassword: "",
            confirmNewPassword: ""
        },
        validationSchema: validationSchemaSetNewPassword,
        onSubmit: values => {
            console.log(values);
        }
    });

    return (
        <div>
            {!forgotPasswordDetails.success && (
                <div>
                    <div className="login-text">
                        <div className="text-section">
                            {" "}
                            <Title level={2}>Reset Password</Title>
                        </div>
                    </div>
                    {resetPasswordDetails.error && (
                        <div className="auth-spinner">
                            {" "}
                            <Alert message={resetPasswordDetails.error} type="error" showIcon />{" "}
                        </div>
                    )}
                    {resetPasswordDetails.success && (
                        <div className="auth-spinner">
                            {" "}
                            <Alert message={"Password reset successful"} type="success" showIcon />{" "}
                        </div>
                    )}

                    <form onSubmit={formikRequestPasswordReset.handleSubmit}>
                        <div className="input-holder">
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="login_email_f1"
                                    label="Email"
                                    name="email"
                                    autoFocus
                                    value={formikRequestPasswordReset.values.email}
                                    onChange={formikRequestPasswordReset.handleChange}
                                    error={
                                        formikRequestPasswordReset.touched.email && Boolean(formikRequestPasswordReset.errors.email)
                                    }
                                    helperText={formikRequestPasswordReset.touched.email && formikRequestPasswordReset.errors.email}
                                    size="small"
                                />
                            </Grid>
                        </div>

                        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                            {forgotPasswordDetails.loading ? <Spin spinning={true} size="small"></Spin> : "Request Code"}
                        </Button>
                    </form>

                    <Grid container style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Link
                            onClick={() => {
                                openLogin();
                            }}
                            variant="body2">
                            Login
                        </Link>

                        <Link
                            onClick={() => {
                                openSignUp();
                            }}
                            variant="body2">
                            Register
                        </Link>
                    </Grid>
                </div>
            )}

            {forgotPasswordDetails.success && (
                <div>
                    <div className="login-text">
                        <div className="text-section">
                            {" "}
                            <Title level={2}>Set new password</Title>
                        </div>
                    </div>
                    {forgotPasswordDetails.error ? (
                        <div className="auth-spinner">
                            {" "}
                            <Alert message={forgotPasswordDetails.error} type="error" showIcon />{" "}
                        </div>
                    ) : (
                        ""
                    )}
                    {forgotPasswordDetails.success ? (
                        <div className="auth-spinner">
                            {" "}
                            <Alert message={`Code delivered to ${code.CodeDeliveryDetails.Destination}`} type="info" showIcon />{" "}
                        </div>
                    ) : (
                        ""
                    )}

                    <form onSubmit={formikSetNewPassword.handleSubmit}>
                        <div className="input-holder">
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="login_email_f2"
                                    label="Confirmation Code"
                                    name="code"
                                    autoFocus
                                    autoComplete="code"
                                    value={formikSetNewPassword.values.code}
                                    onChange={formikSetNewPassword.handleChange}
                                    error={formikSetNewPassword.touched.code && Boolean(formikSetNewPassword.errors.code)}
                                    helperText={formikSetNewPassword.touched.code && formikSetNewPassword.errors.code}
                                    size="small"
                                />
                            </Grid>
                        </div>

                        <div className={css(styles.box_form_div_password)} style={{ marginTop: "20px" }}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="login_password_2"
                                    label="New Password"
                                    name="newPassword"
                                    type={showNewPassword ? "text" : "password"}
                                    value={formikSetNewPassword.values.newPassword}
                                    onChange={formikSetNewPassword.handleChange}
                                    error={
                                        formikSetNewPassword.touched.newPassword && Boolean(formikSetNewPassword.errors.newPassword)
                                    }
                                    helperText={formikSetNewPassword.touched.newPassword && formikSetNewPassword.errors.newPassword}
                                    size="small"
                                    InputProps={{
                                        endAdornment:
                                            formikSetNewPassword.values.newPassword !== "" &&
                                            (!showNewPassword ? (
                                                <i onClick={() => setShowNewPassword(!showNewPassword)} className="fas fa-eye"></i>
                                            ) : (
                                                <i
                                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                                    className="fas fa-eye-slash"></i>
                                            ))
                                    }}
                                />
                            </Grid>
                        </div>

                        <div className={css(styles.box_form_div_password)} style={{ marginTop: "20px" }}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="login_password_3"
                                    label="Confirm New Password"
                                    name="confirmNewPassword"
                                    type={showConfirmNewPassword ? "text" : "password"}
                                    value={formikSetNewPassword.values.confirmNewPassword}
                                    onChange={formikSetNewPassword.handleChange}
                                    error={
                                        formikSetNewPassword.touched.confirmNewPassword &&
                                        Boolean(formikSetNewPassword.errors.confirmNewPassword)
                                    }
                                    helperText={
                                        formikSetNewPassword.touched.confirmNewPassword &&
                                        formikSetNewPassword.errors.confirmNewPassword
                                    }
                                    size="small"
                                    InputProps={{
                                        endAdornment:
                                            formikSetNewPassword.values.confirmNewPassword !== "" &&
                                            (!showConfirmNewPassword ? (
                                                <i
                                                    onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                                                    className="fas fa-eye"></i>
                                            ) : (
                                                <i
                                                    onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                                                    className="fas fa-eye-slash"></i>
                                            ))
                                    }}
                                />
                            </Grid>
                        </div>

                        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                            {resetPasswordDetails.loading ? <Spin spinning={true} size="small"></Spin> : "Reset Password"}
                        </Button>
                    </form>

                    <Grid container style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Link
                            onClick={() => {
                                openLogin();
                            }}
                            variant="body2">
                            Login
                        </Link>

                        <Link
                            onClick={() => {
                                openSignUp();
                            }}
                            variant="body2">
                            Register
                        </Link>
                    </Grid>
                </div>
            )}
        </div>
    );
};
