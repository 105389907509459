import React from 'react'
import '../CSS_Styling/NotificationsCSS.css'
import { useDispatch, useSelector } from 'react-redux'
import { Link  } from 'react-router-dom'

const NotificationBar = ({title}) => {

    // @ts-ignore: Unreachable code error
    const newMessages = useSelector(state => state.userNewMessageNotification)
    const { messages } = newMessages

    return (
        <div className="notifications">
            <span className="notifications-title">{title ? title : "Notifications"}</span>
            <section className="notifications-body">
                <Link to={`/owner-dashboard/#messages/${messages[messages.length-1] && messages[messages.length -1]._id}`}> 
                    <p> {messages.length > 0 ? `${messages.length} new messages from: Ethan Hunt` : "No new messages"}  </p> 
                </Link>       
            </section>
            
            <section className="other-notifications">
                <p>No new notifications</p>
            </section>
        </div>
    )
}

export default NotificationBar
