import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Typography, Spin, Alert } from "antd";
import { makeStyles } from "@mui/styles";
import { confirmRegisteredUser, resendConfirmationCode, loginUser } from "../../Actions/userActions";
import { USER_LOGIN_RESET } from "../../constants/userConstants";
import * as Yup from "yup";
import { useFormik } from "formik";

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh"
    },
    image: {
        backgroundImage:
            "url(https://images.unsplash.com/photo-1593604340846-4fbe9763a8f3?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80)",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center"
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export default function ConfirmRegistration() {
    const classes = useStyles();
    const { Title } = Typography;

    const navigate = useNavigate();

    // @ts-ignore: Unreachable code error
    const confirmUser = useSelector(state => state.confirmUser);
    const {
        message: confirmMessage,
        loading: confirmLoading,
        success: confirmSuccess,
        error: confrimError,
        resendSuccess,
        resendInfo,
        resendError
    } = confirmUser;

    // @ts-ignore: Unreachable code error
    const userLogin = useSelector(state => state.userLogin);
    const { loading: loginLoading, success: loginSuccess, error: loginError } = userLogin;

    const [state, setState] = useState("confirm-user");
    const [showPassword, setShowPassword] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (confirmSuccess) setState("login");
    }, [confirmSuccess]);

    useEffect(() => {
        if (loginSuccess)
            setTimeout(() => {
                return navigate(-1);
            }, 2000);
    }, [loginSuccess]);

    const validationSchemaConfirmRegistration = Yup.object({
        email: Yup.string().email("Invalid email format").required("Email is required"),
        confirmationCode: Yup.string().required("Confirmation code required")
    });

    const formikConfirmRegistration = useFormik({
        initialValues: {
            email: "",
            confirmationCode: ""
        },
        validationSchema: validationSchemaConfirmRegistration,
        onSubmit: values => {
            dispatch(
                confirmRegisteredUser({
                    confirmationCode: values.confirmationCode,
                    email: values.email.trim().toLowerCase()
                })
            );
        }
    });

    const validationSchemaLogin = Yup.object({
        email: Yup.string().email("Invalid email format").required("Email is required"),
        password: Yup.string().required("Password is required")
    });

    const formikLogin = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: validationSchemaLogin,
        onSubmit: values => {
            dispatch({
                type: USER_LOGIN_RESET
            });
            dispatch(
                loginUser({
                    email: values.email.trim().toLowerCase(),
                    password: values.password
                })
            );
        }
    });

    const handleResendCode = () => {
        dispatch(resendConfirmationCode({ email: formikConfirmRegistration.values.email }));
    };

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />

            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>

                    {state === "confirm-user" && <Title level={2}>Confirm Registration</Title>}
                    {state === "login" && <Title level={2}>Login</Title>}

                    {confirmSuccess && <Alert type="success" message={confirmMessage} showIcon />}
                    {confrimError && <Alert type="error" showIcon message={confrimError} />}
                    {resendError && <Alert type="error" showIcon message={resendError} />}
                    {confirmLoading && <Spin spinning={true} size="small"></Spin>}
                    {resendSuccess && (
                        <Alert type="info" message={`Code sent to ${resendInfo?.CodeDeliveryDetails?.Destination}`} showIcon />
                    )}

                    {loginError && <Alert message={loginError} type="error" showIcon />}
                    {loginSuccess && <Alert message={"Signed in"} type="success" showIcon />}

                    {state === "confirm-user" && (
                        <form className={classes.form} onSubmit={formikConfirmRegistration.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        name="email"
                                        value={formikConfirmRegistration.values.email}
                                        onChange={formikConfirmRegistration.handleChange}
                                        error={
                                            formikConfirmRegistration.touched.email && Boolean(formikConfirmRegistration.errors.email)
                                        }
                                        helperText={formikConfirmRegistration.touched.email && formikConfirmRegistration.errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="confirmationCode"
                                        label="Confirmation Code"
                                        type="text"
                                        id="confirmation-code"
                                        value={formikConfirmRegistration.values.confirmationCode}
                                        onChange={formikConfirmRegistration.handleChange}
                                        error={
                                            formikConfirmRegistration.touched.confirmationCode &&
                                            Boolean(formikConfirmRegistration.errors.confirmationCode)
                                        }
                                        helperText={
                                            formikConfirmRegistration.touched.confirmationCode &&
                                            formikConfirmRegistration.errors.confirmationCode
                                        }
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        color="default"
                                        onClick={handleResendCode}
                                        className={classes.submit}>
                                        Resend Code
                                    </Button>
                                </Grid>

                                <Grid item xs={8}>
                                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}

                    {state === "login" && (
                        <form className={classes.form} onSubmit={formikLogin.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        name="email"
                                        value={formikLogin.values.email}
                                        onChange={formikLogin.handleChange}
                                        error={formikLogin.touched.email && Boolean(formikLogin.errors.email)}
                                        helperText={formikLogin.touched.email && formikLogin.errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Password"
                                        name="password"
                                        type={showPassword ? "text" : "password"}
                                        value={formikLogin.values.password}
                                        onChange={formikLogin.handleChange}
                                        error={formikLogin.touched.password && Boolean(formikLogin.errors.password)}
                                        helperText={formikLogin.touched.password && formikLogin.errors.password}
                                        InputProps={{
                                            endAdornment:
                                                formikLogin.values.password !== "" ? (
                                                    !showPassword ? (
                                                        <i onClick={() => setShowPassword(!showPassword)} className="fas fa-eye"></i>
                                                    ) : (
                                                        <i
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            className="fas fa-eye-slash"></i>
                                                    )
                                                ) : null
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                                Login
                            </Button>
                        </form>
                    )}
                </div>
            </Grid>
        </Grid>
    );
}
