import {SORT_PRICE_LOW, SORT_PRICE_HIGH, SORT_RESET, SORT_MOST_RECENT } from '../constants/sortConstants'

export const sortResultsReducer = (state={sort: ''}, action) => {
   switch(action.type){
       case SORT_PRICE_LOW:
           return {sort: action.payload}
        case SORT_PRICE_HIGH:
           return {sort: action.payload}
        case SORT_MOST_RECENT:
            return {sort: action.payload}
        case SORT_RESET:
            return {sort: ''}
        default:
            return state
   }
}