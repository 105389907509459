import React, { useState, useEffect, useContext } from "react";
import { AppBar, Toolbar, Button, IconButton, Link, MenuItem, Menu, Badge } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Aqivah_Icon from "../assets/images/AqIcPurpleNoText.png";
import SearchIcon from "@mui/icons-material/Search";
import { useSignInModel } from "./AuthenticationForms";
import { Dropdown, Menu as AntMenu } from "antd";
import { AdminProviderContext } from "../common/admin-provider/admin-provider-context";
import { OrganizationProviderContext } from "../common/organization-provider/organization-provider-context";
import axios, { AxiosResponse } from "axios";
//import CssBaseline from '@mui/material/CssBaseline';
//import PropTypes from 'prop-types'
import { setCurrentHostname } from "../Actions/userActions";
import "../CSS_Styling/Header.css";
import NotificationBar from "./NotificationBar";
import Modal from "antd/es/modal";
import { Login } from "./authentication/login";
import { SignUp } from "./authentication/signup";
import { ForgotPassword } from "./authentication/forgot-password";
import { AUTH_TOKEN_KEY, ORGANIZATION_KEY, USER_KEY } from "../common";
import "./Header.scss";

// interface Props {
//     /**
//      * Injected by the documentation to work in an iframe.
//      * You won't need it on your project.
//      */
//     window?: () => Window;
//     children: React.ReactElement;
// }

// function HideOnScroll(props: Props) {
//     const { children, window } = props;
//     // Note that you normally won't need to set the window ref as useScrollTrigger
//     // will default to window.
//     // This is only being set here because the demo is in an iframe.
//     const trigger = useScrollTrigger({ target: window ? window() : undefined });

//     return (
//         <Slide appear={false} direction="down" in={!trigger}>
//             {children}
//         </Slide>
//     );
// }

const useStyles: any = makeStyles((theme: any) => ({
    header: {
        //backgroundColor: "transparent",
        backgroundColor: "tranparent",
        paddingRight: "5px",
        paddingLeft: "5px",
        "@media (max-width: 900px)": {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    headerApp: {
        //backgroundColor: "transparent",
        backgroundColor: "hsl(0,0%,0%, 0.6)",
        paddingRight: "5px",
        paddingLeft: "5px",
        "@media (max-width: 900px)": {
            paddingLeft: 0,
            paddingRight: 0
        },
        zIndex: 400
    },
    logo: {
        fontFamily: "Work Sans, sans-serif",
        fontWeight: 600,
        color: "#FFFEFE",
        textAlign: "left"
    },
    menuButton: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        size: "18px",
        marginLeft: "38px"
    },
    // toolbar: {
    //   display: "flex",
    //   justifyContent: "space-between",
    //   background:'transparent',
    // },
    toolbar: {
        //borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: "hsl(0,0%,0%, 0.1)",
        color: "white",
        zIndex: 400
    },
    toolbarTitle: {
        flex: 1
    },
    toolbarSecondary: {
        justifyContent: "space-between",
        overflowX: "auto",
        backgroundColor: "hsl(0,0%,0%, 0.1)",
        color: "white"
    },
    toolbarSecondaryMobile: {
        justifyContent: "space-between",
        overflowX: "auto",
        backgroundColor: "white",
        color: "white"
    },
    toolbarSecondarySearch: {
        justifyContent: "space-between",
        overflowX: "auto",
        backgroundColor: "transparent !important",
        color: "white"
    },
    toolbarLink: {
        padding: theme.spacing(1),
        flexShrink: 0,
        color: "white !important",
        fontSize: "0.875rem "
    },
    dropdownLink: {
        padding: theme.spacing(1),
        flexShrink: 0,
        color: "black !important",
        fontSize: "0.905rem "
    },
    drawerContainer: {
        padding: "20px 80px 0 0"
    },
    drawerTop: {
        borderBottom: "1px solid grey",
        height: "20vh",
        dispaly: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center"
    },
    drawerImg: {
        width: "30px",
        height: "30px",
        borderRadius: "50%"
    },
    profileName: {
        fontSize: "20px",
        width: "100%"
    }
}));

interface Values {
    page?: null | any;
}

const Header = ({ page = null }: Values) => {
    // axios.interceptors.response.use(
    //     res => res,
    //     err => {
    //         console.error("A request error occurred: ", err);

    //         if (
    //             (err.response as AxiosResponse)?.status == 401 &&
    //             window.location.pathname !== `/login` &&
    //             window.location.pathname !== `/complete-registration` &&
    //             window.location.pathname !== `/register`
    //         ) {
    //             handleSignIn();
    //         }

    //         throw err;
    //     }
    // );

    const { header, headerApp, menuButton, drawerContainer } = useStyles();
    const classes = useStyles();

    const token = localStorage.getItem(AUTH_TOKEN_KEY) || null;

    // @ts-ignore: Unreachable code error
    const currentHostname = useSelector(state => state.userCurrentHostname);

    // @ts-ignore: Unreachable code error
    const newMessages = useSelector(state => state.userNewMessageNotification);
    const { messages } = newMessages;

    useEffect(() => {
        if (messages.length > 0) {
            setOpenSnackBar(true);
        }
    }, [messages]);

    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false
    });

    const [menu, setMenu] = useState(false);
    const [notification, setNotification] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [toggle, setToggle] = useState<any>(0);
    const [openLogin, setOpenLogin] = useState(false);
    const [openSignUp, setOpenSignUp] = useState(false);
    const [openForgotPassword, setOpenForgotPassword] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

    const { user, refreshAdmin } = useContext(AdminProviderContext) || {};
    const { organization } = useContext(OrganizationProviderContext) || {};

    const { mobileView, drawerOpen } = state;

    const sections = [
        {
            title: "List Property",
            url: user?.user_type == "owner" || user?.user_type == "organization_member" ? "/list-property" : "/info-list-property"
        },
        { title: "List Preference", url: "/add_request" }
    ];

    const headersData = [
        {
            label: "Search",
            href: "https://aqivah.com",
            icon: "fas fa-search"
        },
        {
            label: "About",
            href: "https://about.aqivah.com",
            icon: "fas fa-info-circle"
        },
        {
            label: "List Property",
            //href: user?.user_type == "owner" || user?.user_type == "organization_member" ? "/list-property" : "/info-list-property",
            href: "/list-property",
            icon: "fas fa-house-user"
        },
        {
            label: "Search Preference",
            href: "/add_request",
            icon: "fas fa-home"
        }
    ];

    const search_sections = [
        { title: "Market Property", url: "/list-property" },
        { title: "Find Property", url: "/add_request" }
    ];

    const info_sections = [
        { title: "Market Property", url: "/list-property" },
        { title: "Find Property", url: "/add_request" }
    ];

    const tabs = currentHostname === "search" ? search_sections : currentHostname === "about" ? info_sections : sections;

    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setState(prevState => ({ ...prevState, mobileView: true }))
                : setState(prevState => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();

        window.addEventListener("resize", () => setResponsiveness());
    }, []);

    const handleSignUp = () => {
        setOpenSignUp(true);
        setOpenLogin(false);
        setOpenForgotPassword(false);
    };

    function handleSignIn() {
        setOpenLogin(true);
        setOpenSignUp(false);
        setOpenForgotPassword(false);
    }

    const handleCloseLogin = () => {
        setOpenLogin(false);
    };

    const handleCloseSignUp = () => {
        setOpenSignUp(false);
    };

    const closeForgotPassword = () => {
        setOpenForgotPassword(false);
    };

    const handleOpenForgotPassword = () => {
        setOpenForgotPassword(true);
        setOpenSignUp(false);
        setOpenLogin(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenu(true);
        setAnchorEl(event.currentTarget);
    };

    const handleNotification = (event: React.MouseEvent<HTMLButtonElement>) => {
        setNotification(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenu(false);
        setAnchorEl(null);
    };

    const handleCloseNotification = () => {
        setNotification(false);
        setAnchorEl(null);
    };

    const handleLogOut = () => {
        console.log(user);
        localStorage.removeItem(AUTH_TOKEN_KEY);
        localStorage.removeItem(USER_KEY);
        localStorage.removeItem(ORGANIZATION_KEY);
        refreshAdmin();

        window.location.href = "/";
    };

    const solutions = ["app", "search", "about"];

    const toggleSolutions = () => {
        if (toggle >= 2) {
            setToggle(0);
            dispatch(setCurrentHostname(solutions[toggle]));
        } else {
            setToggle(toggle + 1);
            dispatch(setCurrentHostname(solutions[toggle]));
        }
    };

    const closeSnackBar = () => {
        setOpenSnackBar(false);
    };

    const dropMenu = (
        <AntMenu style={{ marginTop: "20px" }}>
            <AntMenu.Item key="0">
                <RouterLink className={classes.dropdownLink} to={"/owner-dashboard/?tab=profile"}>
                    Profile
                </RouterLink>
            </AntMenu.Item>

            <AntMenu.Item key="1">
                <RouterLink className={classes.dropdownLink} to={"/owner-dashboard/"}>
                    Dashboard
                </RouterLink>
            </AntMenu.Item>

            <AntMenu.Item key="2">
                <RouterLink className={classes.dropdownLink} to={"/owner-dashboard/?tab=profile"}>
                    My Acccount{" "}
                </RouterLink>
            </AntMenu.Item>
            <AntMenu.Item key="3">
                <RouterLink className={classes.dropdownLink} onClick={handleLogOut} to={"#"}>
                    <i style={{ color: "red" }} className="fa-solid fa-power-off me-2"></i>Logout
                </RouterLink>
            </AntMenu.Item>
            {/* <AntMenu.Divider />
          <AntMenu.Item key="3">3rd menu item</AntMenu.Item> */}
        </AntMenu>
    );

    const displayDesktop = () => {
        return (
            <>
                {/* <Toolbar className={classes.toolbar}>
                    <a href={'/'}>
                        <img style={{ width: '20px', height: 'auto' }} src={Aqivah_Icon} />
                    </a>
                    <Typography
                        component="h2"
                        variant="h5"
                        color="primary"
                        align="center"
                        noWrap
                        className={classes.toolbarTitle}
                    >
                        {title}
                    </Typography>
                    <IconButton>
                        <SearchIcon />
                    </IconButton>
                    <Button variant="outlined" size="small">
                        Sign up
                </Button>
                </Toolbar> */}
                {/* <HideOnScroll {...props}> */}
                <Toolbar
                    id="main-header"
                    component="nav"
                    variant="dense"
                    className={currentHostname === "search" ? classes.toolbarSecondarySearch : classes.toolbarSecondary}>
                    {currentHostname !== "search" && (
                        <MenuItem>
                            <a href={"/"}>
                                <img style={{ width: "30px", height: "auto" }} alt="Aqivah Icon" src={Aqivah_Icon} />
                            </a>
                        </MenuItem>
                    )}

                    {tabs.map(
                        (section, i) =>
                            section.title === "About" || section.title === "Search" || section.title === "App" ? (
                                <MenuItem key={i}>
                                    <RouterLink to={section.url} className={classes.toolbarLink} rel="noopener">
                                        {section.title}
                                    </RouterLink>
                                </MenuItem>
                            ) : (
                                <MenuItem key={i}>
                                    <RouterLink to={section.url} className={classes.toolbarLink} rel="noopener">
                                        {section.title}
                                    </RouterLink>
                                </MenuItem>
                            )

                        // <Link
                        //     color="inherit"
                        //     noWrap
                        //     key={section.title}
                        //     variant="body2"
                        //     href={section.url}
                        //     className={classes.toolbarLink}
                        //     rel="noopener"
                        // >
                        //     {section.title}
                        // </Link>
                    )}

                    {currentHostname !== "search" && (
                        <MenuItem>
                            <a href={"https://aqivah.com"}>
                                <IconButton style={{ color: "white" }}>
                                    <SearchIcon />
                                </IconButton>
                            </a>
                        </MenuItem>
                    )}

                    {user && user.user_type === "seeker" && (
                        <MenuItem>
                            <RouterLink className="owner-action-button" to="/info-list-property">
                                Register as an Owner
                            </RouterLink>
                        </MenuItem>
                    )}

                    {user && (user.user_type === "owner" || user.user_type === "organization_member") && (
                        <MenuItem>
                            <RouterLink className={classes.toolbarLink} to="/owner-dashboard">
                                Dashboard
                            </RouterLink>
                        </MenuItem>
                    )}

                    {organization && (
                        <MenuItem>
                            <RouterLink to={"/org"} className={classes.toolbarLink} rel="noopener">
                                Organization Dashboard
                            </RouterLink>
                        </MenuItem>
                    )}

                    <RouterLink
                        to={`/owner-dashboard?id=${messages[messages.length - 1] ? messages[messages.length - 1]._id : ""}/#messages/${
                            messages[messages.length - 1] && messages[messages.length - 1]._id
                        }`}>
                        <Snackbar open={openSnackBar} autoHideDuration={20000} /*onClick={handleClick}*/ onClose={closeSnackBar}>
                            <div style={{ width: "100vw", display: "flex" }}>
                                <Alert elevation={6} variant="filled" onClose={closeSnackBar} severity={"info"}>
                                    New message received
                                </Alert>
                            </div>
                        </Snackbar>
                    </RouterLink>
                    <>
                        <Dropdown overlay={<NotificationBar title={"Notifications"} />}>
                            <IconButton aria-label="show new notifications" color="inherit">
                                <Badge badgeContent={messages.length > 0 ? messages.length : null} color="primary">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Dropdown>
                    </>

                    {(window.location.hostname === "app-aqivah.netlify.app" || window.location.hostname === "localhost") && (
                        <Button
                            onClick={toggleSolutions}
                            style={{ color: "white", border: "1px solid white" }}
                            variant="outlined"
                            size="small">
                            Toggle: {currentHostname}
                        </Button>
                    )}

                    {!token ? (
                        <Button
                            onClick={handleSignIn}
                            style={{ color: "white", border: "1px solid white" }}
                            variant="outlined"
                            size="small">
                            Login
                        </Button>
                    ) : (
                        <>
                            <Dropdown overlay={dropMenu}>
                                <Button style={{ color: "white" }} aria-controls="simple-menu" aria-haspopup="true" size="small">
                                    <i style={{ marginRight: "3px" }} className="fas fa-user"></i>
                                    My Aqivah
                                </Button>
                            </Dropdown>
                        </>
                    )}
                </Toolbar>
                {/* </HideOnScroll> */}
            </>
        );
    };

    const displayMobile = () => {
        const handleDrawerOpen = () => setState(prevState => ({ ...prevState, drawerOpen: true }));
        const handleDrawerClose = () => {
            setState(prevState => ({ ...prevState, drawerOpen: false }));
            document.body.style.removeProperty("overflow"); //@@ hack for antd Drawer setting style of hidden to document body, even after closing.
        };

        return (
            <Toolbar className={classes.toolbarSecondaryMobile}>
                <IconButton
                    style={{ color: "black" }}
                    {...{
                        edge: "start",
                        "aria-label": "menu",
                        "aria-haspopup": "true",
                        onClick: handleDrawerOpen
                    }}>
                    <MenuIcon />
                </IconButton>

                <Drawer
                    {...{
                        anchor: "left",
                        open: drawerOpen,
                        onClose: handleDrawerClose
                    }}>
                    {token ? (
                        <div className="drawerTop">
                            <span onClick={handleDrawerClose} className="close-menu">
                                {" "}
                                <i className="fas fa-times"></i>{" "}
                            </span>
                            <img className={classes.drawerImg} alt="Aqivah logo" src={Aqivah_Icon} />
                            <RouterLink to={"/owner-dashboard/#profile"} className="profileName">
                                {" "}
                                {token
                                    ? user?.first_name + " " + user?.last_name
                                    : "Account"} <i className="fas fa-angle-right"></i>{" "}
                            </RouterLink>
                        </div>
                    ) : (
                        <div className="drawerTop">
                            <span onClick={handleDrawerClose} className="close-menu">
                                {" "}
                                <i className="fas fa-times"></i>{" "}
                            </span>
                            <img className={classes.drawerImg} alt="Aqivah logo" src={Aqivah_Icon} />
                            <span
                                onClick={() => {
                                    handleDrawerClose();
                                    handleSignUp();
                                }}
                                className="profileName">
                                {" "}
                                {/* {token
                                    ? user?.first_name + " " + user?.last_name
                                    : "Account"} <i className="fas fa-angle-right"></i>{" "} */}
                            </span>
                        </div>
                    )}

                    <div className={drawerContainer}>
                        {getDrawerChoices()}

                        {token && (
                            <>
                                <Link
                                    {...{
                                        component: RouterLink,
                                        color: "inherit",
                                        style: { textDecoration: "none" },
                                        key: "dashboard",
                                        to: "/owner-dashboard"
                                    }}>
                                    <MenuItem>
                                        {" "}
                                        <i style={{ marginRight: "5px" }} className="fas fa-chart-bar"></i> Dashboard
                                    </MenuItem>
                                </Link>

                                <Link
                                    {...{
                                        component: RouterLink,
                                        color: "inherit",
                                        style: { textDecoration: "none" },
                                        key: "profile",
                                        to: "/owner-dashboard/#profile"
                                    }}>
                                    <MenuItem>
                                        {" "}
                                        <i style={{ marginRight: "5px" }} className="far fa-id-badge"></i> Profile
                                    </MenuItem>
                                </Link>
                                <Link
                                    onClick={handleLogOut}
                                    {...{
                                        component: RouterLink,
                                        color: "inherit",
                                        style: { textDecoration: "none" },
                                        key: "sign out",
                                        to: "#"
                                    }}>
                                    <MenuItem>
                                        {" "}
                                        <i style={{ color: "#d63855", marginRight: "5px" }} className="fas fa-power-off"></i> Sign Out
                                    </MenuItem>
                                </Link>
                            </>
                        )}
                    </div>
                </Drawer>

                <div>
                    <a href={"/"}>
                        <img alt="Aqivah logo" style={{ width: "30px", height: "auto" }} src={Aqivah_Icon} />
                    </a>
                </div>

                {!token ? (
                    <Button
                        onClick={handleSignIn}
                        style={{ color: "black", border: "1px solid black" }}
                        variant="outlined"
                        size="small">
                        Login
                    </Button>
                ) : (
                    <>
                        <Button
                            style={{ color: "black" }}
                            onClick={handleClick}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            size="small">
                            <i style={{ fontSize: "15px" }} className="fas fa-user"></i>
                        </Button>
                        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={menu} onClose={handleClose} color="inherit">
                            <RouterLink className={classes.dropdownLink} to={"/owner-dashboard#/profile"}>
                                <MenuItem onClick={handleClose}>Profile</MenuItem>
                            </RouterLink>
                            {user && (user.user_type === "owner" || user.user_type === "organization_member") && (
                                <RouterLink className={classes.dropdownLink} to={"/owner-dashboard#/"}>
                                    <MenuItem onClick={handleClose}>Dashboard</MenuItem>
                                </RouterLink>
                            )}
                            <RouterLink className={classes.dropdownLink} to={"#"}>
                                <MenuItem onClick={handleClose}>My account</MenuItem>
                            </RouterLink>
                            <RouterLink className={classes.dropdownLink} to={"#"}>
                                <MenuItem onClick={handleLogOut}>Logout</MenuItem>
                            </RouterLink>
                        </Menu>
                    </>
                )}
            </Toolbar>
        );
    };

    const getDrawerChoices = () => {
        return headersData.map(({ label, href, icon }, i) => {
            return label === "About" || label === "Search" ? (
                <a style={{ color: "black", fontSize: "14px !important" }} key={i} href={href} rel="noopener">
                    <MenuItem key={label}>
                        {" "}
                        <i style={{ marginRight: "5px" }} className={icon}></i> {label}
                    </MenuItem>
                </a>
            ) : (
                <Link
                    {...{
                        component: RouterLink,
                        to: href,
                        color: "inherit",
                        style: { textDecoration: "none" },
                        key: label
                    }}>
                    <MenuItem>
                        {" "}
                        <i style={{ marginRight: "5px" }} className={icon}></i> {label}{" "}
                    </MenuItem>
                </Link>
            );
        });
    };

    const getMenuButtons = () => {
        return headersData.map(({ label, href }) => {
            return (
                <Button
                    {...{
                        key: label,
                        color: "inherit",
                        to: href,
                        component: RouterLink,
                        className: menuButton
                    }}>
                    {label}
                </Button>
            );
        });
    };

    return (
        <header>
            <Modal open={openLogin} destroyOnClose onCancel={/*handleLoginCancel*/ handleCloseLogin} footer={null} width={450}>
                <Login openSignUp={handleSignUp} closeLogin={handleCloseLogin} openForgotPassword={handleOpenForgotPassword} />
            </Modal>

            <Modal open={openSignUp} destroyOnClose onCancel={/*handleLoginCancel*/ handleCloseSignUp} footer={null} width={500}>
                <SignUp closeSignUp={handleCloseSignUp} openLogin={handleSignIn} />
            </Modal>

            <Modal open={openForgotPassword} destroyOnClose onCancel={closeForgotPassword} footer={null} width={450}>
                <ForgotPassword openSignUp={handleSignUp} openLogin={handleSignIn} />
            </Modal>
            <AppBar className={currentHostname === "search" && !page ? header : headerApp} color="transparent">
                {/* {signUpForm}
                {loginForm} */}
                {mobileView ? displayMobile() : displayDesktop()}
                {/* <Alert icon={false} severity="info">
                    <RouterLink to="/"> Home </RouterLink> {">"} <RouterLink to="/add_property"> Add Property </RouterLink> 
                </Alert> */}
            </AppBar>
        </header>
    );
};

export default Header;

// Header.defaultProps = {
//     title: '',
// }

// Header.propTypes = {
//     title: PropTypes.string,
// }
