
import {io} from 'socket.io-client'

export const test_url = "https://aqivah-message-app-test.herokuapp.com"
export const production_url = "https://aqivah-message-app-production.herokuapp.com"
export const local_url = "http://localhost:5000"
export const current_url = test_url

const messageUrl = () => {
    const test_url = "https://aqivah-message-app-test.herokuapp.com"
    const production_url = "https://aqivah-message-app-production.herokuapp.com"
    const local_url = "http://localhost:5000"
    const current_url = test_url

    if(process.env.REACT_APP_ENVIRONMENT === "production"){
        return production_url
    }
    
    if(process.env.REACT_APP_ENVIRONMENT === "development"){
        return test_url
    }

    return production_url
}

export const socket = io(messageUrl() as string, {reconnectionAttempts: 2, reconnectionDelay: 15000, randomizationFactor: 2})