import React from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Icon_White_Text from "../../assets/images/AqIcWhite.png";
import "./Footer.scss";

interface IFooterPage {
    page?: string | null;
}
const Footer = ({ page = null }: IFooterPage) => {
    const footer_info = () => {
        if (window.location.hostname === "about.aqivah.com" || window.location.hostname === "www.about.aqivah.com") {
            return "/";
        } else {
            return "https://about.aqivah.com/";
        }
    };

    const aqivah_search = () => {
        if (window.location.hostname === "aqivah.com" || window.location.hostname === "www.aqivah.com") {
            return "/";
        } else if (window.location.hostname === "about.aqivah.com" || window.location.hostname === "www.about.aqivah.com") {
            return "https://aqivah.com";
        } else if (window.location.hostname === "localhost") {
            return "https://aqivah.com";
        } else {
            return "https://aqivah.com";
        }
    };

    //TODO: Add Aqivah Logo to footer

    return (
        <div id="footer">
            <div className="main-icon">
                <img
                    style={{ width: 130, height: 130 }}
                    alt="Aqivah icon, footer."
                    /*className={css(styles.footer_image)}*/ src={Icon_White_Text}
                />
            </div>
            {page !== "info" && (
                <Grid container className="grid-container" spacing={5}>
                    <Grid className="footer-column" item xs={12} sm={6} md={3}>
                        <h4 className="footer-header">Explore</h4>
                        <a href={aqivah_search()} rel="noopener" className="footer-links">
                            Search Aqivah
                        </a>
                    </Grid>

                    <Grid className="footer-column" item xs={12} sm={6} md={3}>
                        <h4 className="footer-header">Company</h4>
                        <a href={footer_info()} rel="noopener" className="footer-links">
                            About Aqivah
                        </a>
                        <Link to="/owner-registration" className="footer-links">
                            Register as an Owner
                        </Link>
                        <Link to="/organization-registration" className="footer-links">
                            Register as an Organization
                        </Link>
                    </Grid>

                    <Grid className="footer-column" item xs={12} sm={6} md={3}>
                        <h4 className="footer-header">Legal</h4>
                        <Link className="footer-links" to="/privacy-policy">
                            Privacy Policy
                        </Link>
                        <Link className="footer-links" to="/terms-of-service">
                            Terms of Service
                        </Link>
                    </Grid>

                    <Grid className="footer-column" item xs={12} sm={6} md={3}>
                        <h4 className="footer-header">Contact</h4>
                        <span>
                            <i className="fa-solid fa-envelope footer-icon" />
                            <i className="fa-brands fa-facebook footer-icon" />
                            <i className="fa-brands fa-twitter footer-icon" />
                        </span>
                    </Grid>
                </Grid>
            )}

            {page === "info" && (
                <div className="info-page-sublinks">
                    <a href={footer_info()} className="footer-links" rel="noopener">
                        About
                    </a>
                    <a href={footer_info() + "connect"} className="footer-links" rel="noopener">
                        Connect
                    </a>
                    <a href={footer_info() + "insight"} className="footer-links" rel="noopener">
                        Insight
                    </a>
                    <a href={footer_info() + "legal"} className="footer-links" rel="noopener">
                        Legal
                    </a>
                    <a href={footer_info() + "contact"} className="footer-links" rel="noopener">
                        Contact
                    </a>
                    <a href={footer_info() + "careers"} className="footer-links" rel="noopener">
                        Careers
                    </a>
                </div>
            )}

            <div className="final-line">Aqivah &#169; {new Date().getFullYear()} &nbsp; | &nbsp; All rights reserved </div>
        </div>
    );
};

export default Footer;
