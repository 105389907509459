import React, {useState} from 'react';
import { Card, Button, Dropdown, Menu, Modal, Descriptions, Carousel } from 'antd';
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { saved, recommended } from '../data/interests';

const { Meta } = Card;

const Interests = () => {
	const [visible, setVisible] = useState<boolean>(false)

	const menu = (
		<Menu>
			<Menu.Item key="0">
				<a
				    href="javascript:;"
					onClick={(e) => {
						e.preventDefault();
						setVisible(true)
					}}
				>
					More
				</a>
			</Menu.Item>
		</Menu>
	);

	const handleOk = (e) => {
		console.log(e);
		setVisible(false)
	};

	const handleCancel = (e) => {
		console.log(e);
		setVisible(false)
	};
		return (
			<div className="site-card-border-less-wrapper">
				<Card title="Saved" bordered={false}>
					<div className="saved-container">
						{saved.map((savedItem) => {
							return (
								<div className="card-content">
									<Card
										hoverable
										style={{ height: 250, marginTop: '10px', marginRight: '20px' }}
										cover={<img alt="property" src={savedItem.image} height="150px" />}
									>
										<Meta title={savedItem.type} description={savedItem.description} />
										<Button
											style={{
												position: 'absolute',
												right: '10px',
												bottom: '5px',
												borderWidth: '0px'
											}}
											icon={<DeleteOutlined />}
										/>
									</Card>
								</div>
							);
						})}
					</div>
				</Card>

				<Card title="Recommendations" bordered={false}>
					<div className="recommendations-container">
						{recommended.map((recommendedItem) => {
							return (
								<div className="recommendations-card">
									<Card
										hoverable
										style={{ height: 250, marginTop: '10px', marginRight: '20px' }}
										cover={<img alt="property" src={recommendedItem.image} height="150px" />}
									>
										<Meta title={recommendedItem.type} description={recommendedItem.description} />
										<Dropdown overlay={menu} trigger={[ 'click' ]} placement="topRight">
											<a
												href="javascript:;"
												style={{ color: '#a1a2a8', textDecoration: 'none' }}
												className="ant-dropdown-link"
												onClick={(e) => e.preventDefault()}
											>
												<MoreOutlined style={{ marginLeft: '98%' }} />
											</a>
										</Dropdown>
									</Card>
								</div>
							);
						})}
					</div>
				</Card>

				<Modal title="Apartment" visible={visible} onOk={handleOk} onCancel={handleCancel}>
					<Carousel autoplay dots={true}>
						<div>
							<img
								height="250px"
								width="100%"
								alt="property"
								src="https://cdn.pixabay.com/photo/2016/11/18/17/46/architecture-1836070__340.jpg"
							/>
						</div>
						<div>
							<img
								height="250px"
								width="100%"
								alt="property"
								src="https://media.istockphoto.com/photos/bohemian-living-room-interior-3d-render-picture-id1182454657?b=1&k=6&m=1182454657&s=170667a&w=0&h=KnmxqIVEZ98YHhcXzIauU1mBwF5XxdP8dUmrwQEAmbs="
							/>
						</div>
						<div>
							<img
								height="250px"
								width="100%"
								alt="property"
								src="https://cdn.pixabay.com/photo/2014/07/10/17/18/large-home-389271__340.jpg"
							/>
						</div>
						<div>
							<img
								height="250px"
								width="100%"
								alt="property"
								src="https://media.istockphoto.com/photos/modern-bright-and-airy-scandinavian-design-living-room-picture-id1188732010?b=1&k=6&m=1188732010&s=170667a&w=0&h=lxQW4ApH3My-KekR3cXSevWE4yOWaKkLFFxu7TB2Ub4="
							/>
						</div>
					</Carousel>
					<div style={{ marginTop: '20px', padding: '10px' }} />
					<Descriptions bordered={true} column={1}>
						<Descriptions.Item label="Category">Residential</Descriptions.Item>
						<Descriptions.Item label="Type">Apartment</Descriptions.Item>
						<Descriptions.Item label="Price">GHC 4500</Descriptions.Item>
						<Descriptions.Item label="Location">East Legon</Descriptions.Item>
					</Descriptions>
				</Modal>
			</div>
		);
}

export default Interests;
