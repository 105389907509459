import React, { useState } from "react";
import MyIcon from "../../assets/images/AqIcPurpleNoText.png";
import "../../CSS_Styling/LaunchPageCSS.css";

function LaunchPages() {
  const [aqivahIcon, setAqivahIcon] = useState<any>("");

  // useEffect(() => {

  //   if (!localStorage.getItem("aqivah_icon")) {
  //     const getBase64Image = (image: any) => {
  //       let img_file = new File(image, "aqivah_logo")
  //       console.log(img_file)
  //       let a
  //       let reader = new FileReader();
  //       reader.readAsDataURL(img_file[0]);
  //       reader.onload = function () {
  //         console.log(reader.result);
  //         a = reader.result
  //       };
  //       reader.onerror = function (error) {
  //         console.log('Error: ', error);
  //       };
  //       console.log(a)
  //       return a
  //     }
  //     let icon = getBase64Image(MyIcon)
  //     localStorage.setItem("aqivah_icon", getBase64Image(MyIcon))
  //   }else{
  //     // @ts-ignore: Unreachable code error
  //     setAqivahIcon(JSON.parse(localStorage.getItem("aqivah_icon")))
  //   }

  // }, [])
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={aqivahIcon.length > 1 ? aqivahIcon : MyIcon}
          className="App-logo"
          alt="logo"
        />
      </header>
    </div>
  );
}

export default LaunchPages;
