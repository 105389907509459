import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Avatar, Divider, Typography, Button, Upload, Switch } from 'antd';
import { CameraFilled } from '@ant-design/icons';
import UserIdentification from '../components/UserIdentification';

const { Paragraph } = Typography;

const Profile = () => {
	const [ lastName, setLastName ] = useState<string>('Kassim');
	const [ firstName, setFirstName ] = useState<string>('MohammedAwal');

	const [ phoneNumber, setPhoneNumber ] = useState<string>('0546741678');
	const [ email, setEmail ] = useState<string>('kassimawal10@gmail.com');

	const [ town, setTown ] = useState<string>('Legon');
	const [ country, setCountry ] = useState<string>('Ghana');

	const [ address, setAddress ] = useState<string>('H/No 56556');
	const [ digitalAddress, setDigitalAddress ] = useState<string>('AB756836');

	// const [ password, setPassword ] = useState('encrypted');

    // @ts-ignore: Unreachable code error
	const userProfile = useSelector(state => state.userProfile)
	const {user} = userProfile

	useEffect(()=> {
       if(user){
		  setFirstName(user.firstname)
		  setLastName(user.lastname)
		  setEmail(user.email)
		  setPhoneNumber(user.phone1)
	   }
	}, [user])


	return (
		<div>
			<section style={{ marginBottom: '84px' }}>
				<div id="profile-background">
					<div id="profile-block">
						<div id="profile-picture">
							<Upload>
								<Button
									shape="circle"
									className="change-profile-picture"
									style={{ borderColor: '#3492eb' }}
								>
									<CameraFilled style={{ fontSize: 20, color: '#3492eb' }} />
								</Button>
							</Upload>

							<Avatar
								size={128}
								src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ6MI8bdjR_9YQu4QpZnpsMgdf4MkAMfQzcvw&usqp=CAU"
								alt="user"
							/>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div id="user-info">
					<Divider>Basic Info</Divider>
					<div className="profile-row">
						<div id="first-block">
							<div className="profile-detail-block">
								<h4>First Name </h4>
								<Paragraph editable={{ onChange: setFirstName }}>{firstName}</Paragraph>
							</div>
						</div>
						<div id="second-block">
							<div className="profile-detail-block">
								<h4>Last Name </h4>
								<Paragraph editable={{ onChange: setLastName }}>{lastName}</Paragraph>
							</div>
						</div>
					</div>

					<div className="profile-row">
						<div id="first-block">
							<div className="profile-detail-block">
								<h4>Town</h4>
								<Paragraph editable={{ onChange: setTown }}>{town}</Paragraph>
							</div>
						</div>
						<div id="second-block">
							<div className="profile-detail-block">
								<h4>Country </h4>
								<Paragraph editable={{ onChange: setCountry }}>{country}</Paragraph>
							</div>
						</div>
					</div>

					<Divider>Contact Details</Divider>
					<div className="profile-row">
						<div id="first-block">
							<div className="profile-detail-block">
								<h4>Identification</h4>
								<UserIdentification />
							</div>
						</div>
						<div id="second-block">
							<div className="profile-detail-block">
								<h4>Phone Number</h4>

								<Paragraph editable={{ onChange: setPhoneNumber }}>{phoneNumber}</Paragraph>
							</div>
						</div>
					</div>

					<div className="profile-row">
						<div id="first-block">
							<div className="profile-detail-block">
								<h4>Email</h4>
								<Paragraph editable={{ onChange: setEmail }}>{email}</Paragraph>
							</div>
						</div>
						<div id="second-block">
							<div className="profile-detail-block">
								<h4>Address </h4>
								<Paragraph editable={{ onChange: setAddress }}>{address}</Paragraph>
							</div>
						</div>
					</div>

					<div className="profile-row">
						<div id="first-block">
							<div className="profile-detail-block">
								<h4>Digital Address </h4>
								<Paragraph editable={{ onChange: setDigitalAddress }}>{digitalAddress}</Paragraph>
							</div>
						</div>
					</div>

					{/* <Divider>Places</Divider>
					<div className="profile-row">
						<div id="first-block">
							<div className="profile-detail-block">
								<h4>First Name </h4>
								<Paragraph editable={{ onChange: setFirstName }}>{firstName}</Paragraph>
							</div>
						</div>
						<div id="second-block">
							<div className="profile-detail-block">
								<h4>Last Name </h4>
								<Paragraph editable={{ onChange: setLastName }}>{lastName}</Paragraph>
							</div>
						</div>
					</div>

					<div className="profile-row">
						<div id="first-block">
							<div className="profile-detail-block">
								<h4>Phone Number</h4>
								<Paragraph editable={{ onChange: setPhoneNumber }}>{phoneNumber}</Paragraph>
							</div>
						</div>
						<div id="second-block">
							<div className="profile-detail-block">
								<h4>Email </h4>
								<Paragraph editable={{ onChange: setEmail }}>{email}</Paragraph>
							</div>
						</div>
					</div> */}
					<Divider>Alerts</Divider>
					<h5 style={{ textAlign: 'center' }}>How would you like to receive notifications from Aqivah?</h5>
					<div className="profile-row">
						<div id="first-block">
							<div className="profile-detail-block">
								<h4>EMAILS </h4>
								<Switch />
							</div>
						</div>
						<div id="second-block">
							<div className="profile-detail-block">
								<h4>SMS </h4>
								<Switch />
							</div>
						</div>
					</div>

					<Divider>Privacy</Divider>
					<div className="profile-row">
						<div id="first-block">
							<div className="profile-detail-block">
								<Button type="primary">Change Password</Button>
							</div>
						</div>
						<div id="second-block">
							<div className="profile-detail-block">
								<Button
									type="default"
									style={{ backgroundColor: 'red', borderColor: 'red', color: 'white' }}
								>
									Delete Account
								</Button>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Profile;
