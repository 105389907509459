import React, { useState, useContext } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { PopupProviderContext } from "../../common/popup-alerts-provider/popup-context";
import { AdminProviderContext } from "../../common/admin-provider/admin-provider-context";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ownerImgage from "../../assets/owners_icons/business_owner.png";
import "./register.scss";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { logError } from "../../utils/logError";
import Spin from "antd/es/spin";
import Footer from "../../components/Footer/Footer";

export const OrganizationRegistration = () => {
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const { flashError, flashSuccess } = useContext(PopupProviderContext);
    const { refreshAdmin } = useContext(AdminProviderContext);

    let phone_regex = /^([026|26]).\d{7,8}$/;

    let url_name = /^[a-zA-Z0-9_]*$/;

    const validationSchemaOrganization = Yup.object({
        name: Yup.string().required("Organization name is required"),
        email: Yup.string().email("Invalid email address").required("Email is required"),
        phone: Yup.string().matches(phone_regex, "Phone number format is not valid").required("Phone number is required"),
        address: Yup.string().required("Please enter the address of the organization"),
        url_name: Yup.string()
            .matches(url_name, "No spaces, or special characters allowed except underscores")
            .max(12, "Shouldn't exceed 12 characters")
    });

    const formikOrganization = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            address: "",
            url_name: ""
        },
        validationSchema: validationSchemaOrganization,
        onSubmit: values => {
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            setLoading(true);
            axios
                .post("/organizations/register-organization", { ...values })
                .then((res: any) => {
                    refreshAdmin();
                    flashSuccess("Registration successful");
                    setLoading(false);
                    setTimeout(() => {
                        navigate("/owner-dashboard");
                    }, 1500);
                })
                .catch((err: any) => flashError("Something went wrong", logError(err)))
                .then(() => setLoading(false));
        }
    });

    return (
        <div id="organization-registration" className="pt-5">
            <Header />

            <div className="hero">
                <div className="container-sm">
                    <h3 className="fw-light text-center mb-4">Explore Your Selling Options</h3>
                    <p className="p-info-lg text-center">
                        Wherever you are in the selling journey, our hassle-free tools and guides will help you make the right move.
                    </p>
                </div>
            </div>

            <div className="image-background"></div>

            <div className="registration-section">
                <div className="container-xxm py-5">
                    <h3 className="text-center fw-light mb-5">Register As An Organization</h3>

                    <div>
                        <form onSubmit={formikOrganization.handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="name"
                                        fullWidth
                                        placeholder="Organization's Name"
                                        size="small"
                                        label="Organization's Name"
                                        value={formikOrganization.values.name}
                                        onChange={formikOrganization.handleChange}
                                        error={formikOrganization.touched.name && formikOrganization.errors.name ? true : false}
                                        helperText={formikOrganization.touched.name && formikOrganization.errors.name}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="email"
                                        fullWidth
                                        placeholder="Organization's Email"
                                        size="small"
                                        label="Organization's Email"
                                        value={formikOrganization.values.email}
                                        onChange={formikOrganization.handleChange}
                                        error={formikOrganization.touched.email && formikOrganization.errors.email ? true : false}
                                        helperText={formikOrganization.touched.email && formikOrganization.errors.email}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} style={{ marginTop: "20px" }}>
                                <Grid item xs={12} lg={5}>
                                    <TextField
                                        name="phone"
                                        fullWidth
                                        placeholder="Organization Phone Number"
                                        size="small"
                                        label="Organization's Phone/Contact Number"
                                        value={formikOrganization.values.phone}
                                        onChange={formikOrganization.handleChange}
                                        error={formikOrganization.touched.phone && formikOrganization.errors.phone ? true : false}
                                        helperText={formikOrganization.touched.phone && formikOrganization.errors.phone}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={7}>
                                    <TextField
                                        name="address"
                                        fullWidth
                                        placeholder="Organization Address"
                                        size="small"
                                        label="Address"
                                        value={formikOrganization.values.address}
                                        onChange={formikOrganization.handleChange}
                                        error={formikOrganization.touched.address && formikOrganization.errors.address ? true : false}
                                        helperText={formikOrganization.touched.address && formikOrganization.errors.address}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} style={{ marginTop: "20px", marginBottom: "20px" }}>
                                <Grid item xs={12} lg={5}>
                                    <TextField
                                        name="url_name"
                                        fullWidth
                                        placeholder="Add Organization Unique URL"
                                        size="small"
                                        label="Add Organization Unique URL"
                                        value={formikOrganization.values.url_name}
                                        onChange={formikOrganization.handleChange}
                                        error={
                                            formikOrganization.touched.url_name && formikOrganization.errors.url_name ? true : false
                                        }
                                        helperText={formikOrganization.touched.url_name && formikOrganization.errors.url_name}
                                    />
                                </Grid>
                            </Grid>

                            {/* <Grid container justify="center" style={{ margin: "20px 0 7px 0" }}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                name="acknowledge"
                                                checked={formikSignUp.values.acknowledge}
                                                onChange={formikSignUp.handleChange}
                                            />
                                        }
                                        label={
                                            <span
                                                style={{
                                                    color:
                                                        formikSignUp.touched.acknowledge && formikSignUp.errors.acknowledge
                                                            ? "red"
                                                            : "black"
                                                }}>
                                                <small>
                                                    By signing up, I accept the{" "}
                                                    <RouterLink
                                                        style={{ color: "#3f51b5" }}
                                                        to="/about"
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        {" "}
                                                        Aqivah Terms of Service{" "}
                                                    </RouterLink>
                                                    and acknowledge the{" "}
                                                    <RouterLink
                                                        to="/privacy-policy"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ textDecoration: "underline", color: "#3f51b5" }}>
                                                        Privacy Policy.
                                                    </RouterLink>
                                                </small>
                                            </span>
                                        }
                                    />
                                </Grid>
                            </Grid> */}

                            <Button type="submit" fullWidth variant="contained" color="primary">
                                {loading ? <Spin spinning={true} size="small"></Spin> : "Register"}
                            </Button>
                            <Grid container justify="flex-end" className="mt-3">
                                <Grid item>
                                    <Link /*onClick={() => openLogin()}*/ variant="body2">Already have an account? Sign in</Link>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};
