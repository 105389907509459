import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import { SearchContext } from "../../../context/search-context/search-context";
import DatePicker from "antd/es/date-picker";

export const ShortStayDuration = () => {
    const { propertyFilters, setPropertyFilters } = useContext(SearchContext);

    const { RangePicker } = DatePicker;

    const handleDateChange = (dates: any, dateStrings: any) => {
        console.log(dates, dateStrings, dateStrings[0].length);
        if (dateStrings[0].length > 0) {
            setPropertyFilters({ ...propertyFilters, stay_date: { start: dateStrings[0], end: dateStrings[1] } });
        } else {
            setPropertyFilters({ ...propertyFilters, stay_date: null });
        }
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} className="d-flex flex-column align-items-start justify-content-start">
                <span className="filter-title">Stay Duration</span>
                <RangePicker onChange={handleDateChange} />
            </Grid>
        </Grid>
    );
};
