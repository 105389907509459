import React, { useEffect } from "react";
import { StyleSheet, css } from "../config/my-aphrodite";
//import city_view from '../images/rsz_1city_view_dusk2_v1.jpg';
import Aqivah_Icon_White from "../assets/images/AqIcWhite.png";
import Meta from "../components/Helmet";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Header from "../components/Header";
import { useLocation, useParams, useNavigate } from "react-router-dom";

interface PageName {}
function Redirect() {
    const prop = {
        location: useLocation(),
        history: useNavigate(),
        params: useParams()
    };

    const AOS = require("aos");

    AOS.init();
    const year = new Date();

    const page_name = prop.params.id;

    useEffect(() => {
        let section = prop.params.id;
        console.log(prop.params);
        let scrollSection = document.getElementById(`${section}`);

        setTimeout(() => {
            if (scrollSection !== null) {
                scrollSection.scrollIntoView({
                    behavior: "smooth"
                    // block: 'center',
                    // inline: 'nearest',
                });
            }
        }, 300);
    }, [prop.params.id]);

    const scrollToSection = (section: string) => {
        let scrollSection = document.getElementById(section);
        if (scrollSection !== null) {
            scrollSection.scrollIntoView({
                behavior: "smooth"
            });
        }
    };

    const styles = StyleSheet.create({
        body: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100vw",
            backgroundColor: "white",
            zIndex: 2
        },

        about_us: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "80vw",
            //minHeight: '100vh',
            height: "auto !important",
            borderBottom: ".5px solid #805500",
            margin: "10vh 0 0 0",
            paddingTop: "11vh",
            paddingBottom: "5vh"
        },

        connect: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "80vw",
            //minHeight: '100vh',
            height: "auto !important",
            borderBottom: ".5px solid #805500",
            paddingTop: "11vh",
            paddingBottom: "5vh",
            backgroundColor: "white"
        },

        insight: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "80vw",
            //minHeight: '100vh',
            height: "auto !important",
            borderBottom: ".5px solid #805500",
            paddingTop: "11vh",
            paddingBottom: "5vh",
            backgroundColor: "white"
        },

        legal: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "80vw",
            //minHeight: '100vh',
            height: "auto !important",
            borderBottom: ".5px solid #805500",
            paddingBottom: "5vh",
            paddingTop: "11vh"
        },

        contact: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "80vw",
            height: "auto !important",
            borderBottom: ".5px solid #805500",
            paddingTop: "11vh",
            paddingBottom: "5vh"
        },

        form: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center"
        },

        career: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "80vw",
            //minHeight: '100vh',
            height: "auto !important",
            borderBottom: ".5px solid #805500",
            paddingTop: "11vh",
            paddingBottom: "5vh"
        },

        title: {
            width: "100%",
            fontSize: "2rem",
            fontFamily: 'TiemposFineWeb-Light,Georgia,Times,"Times New Roman",serif',
            color: "#805500",
            paddingLeft: "0",
            textDecoration: "underline",
            marginBottom: "5vh"
        },

        content: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center"
        },

        footer: {
            bottom: "0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100vw",
            background: "#4d4d4d",
            color: "white",
            paddingBottom: "5vh"
        },

        footer_image_holder: {
            display: "flex",
            width: "100vw",
            height: "auto",
            justifyContent: "center",
            marginBottom: "5vh"
        },

        footer_image: {
            width: "15vw",
            height: "auto"
        },

        navigation: {
            display: "flex",
            width: "50vw",
            justifyContent: "space-around",
            marginBottom: "7vh"
        },

        navigation_mobile: {
            "@media (max-width: 845px)": {
                display: "flex",
                width: "100vw !important",
                justifyContent: "space-around",
                marginBottom: "7vh"
            }
        },

        nav_buttons: {
            color: "white",
            cursor: "pointer",
            fontSize: "12px",
            transition: "0.3s",
            ":hover": {
                color: "#805500",
                textDecoration: "underline"
            }
        },

        nav_sub_1: {
            display: "flex",
            width: "20vw",
            justifyContent: "space-around"
        },

        footer_last_line: {
            fontSize: "12px",
            fontFamily: '"Sweet Sans Medium",Helvetica,Arial,sans-serif'
        },

        section_6_header: {
            fontFamily: '"Sweet Sans Medium",Helvetica,Arial,sans-serif', //'URW Chancery L, cursive',
            fontSize: ".75rem",
            fontWeight: 400,
            color: "#805500",
            textDecoration: "underline",
            textTransform: "uppercase"
        },

        form1: {
            width: "60%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            border: "1px solid grey",
            borderRadius: "10px 10px 10px 10px",
            paddingTop: "10vh",
            backgroundColor: "#e6e6e6",
            marginBottom: "3vh"
        },

        form2: {
            width: "40%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            border: "1px solid grey",
            borderRadius: "10px 10px 10px 10px",
            paddingTop: "10vh",
            backgroundColor: "#e6e6e6"
        },

        input: {
            width: "90%",
            backgroundColor: "#e6e6e6",
            marginTop: "7vh",
            boxSizing: "border-box",
            borderBottom: "1px solid grey",
            height: "20px",
            fontFamily: 'TiemposFineWeb-LightItalic,Georgia,Times,"Times New Roman",serif',
            fontSize: ".75rem"
        },

        input_area: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexWrap: "wrap",
            width: "90%",
            backgroundColor: "#e6e6e6",
            marginTop: "3vh",
            boxSizing: "border-box",
            borderBottom: "1px solid grey",
            height: "120px",
            fontFamily: 'TiemposFineWeb-LightItalic,Georgia,Times,"Times New Roman",serif',
            fontSize: ".75rem"
        },

        input_submit: {
            ":hover": {
                color: "white",
                backgroundColor: "#805500"
            },
            width: "40%",
            backgroundColor: "white",
            color: "#805500",
            border: "1px solid #805500",
            marginBottom: "7vh",
            marginTop: "1vh",
            boxSizing: "border-box",
            height: "40px",
            borderRadius: "8px 8px 8px 8px",
            transition: "0.6s",
            cursor: "pointer"
        },

        form1_mobile: {
            "@media (max-width: 845px)": {
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                border: "1px solid grey",
                borderRadius: "10px 10px 10px 10px",
                paddingTop: "10vh",
                backgroundColor: "#e6e6e6",
                marginBottom: "3vh"
            }
        }
    });

    return (
        <div>
            {/* {navMenu}
            {drawMenu} */}
            <Header />

            {/* @ts-ignore: Unreachable code error  */}
            <Meta title={`Aqivah - ${page_name?.charAt(0).toUpperCase() + page_name?.slice(1)}`} />

            <div className={css(styles.body)}>
                <div className={css(styles.about_us)} id="about-us">
                    <div className={css(styles.title)}>About Us</div>
                    <p className={css(styles.content)}>
                        We are an African technology company, solving problems in the real estate sector for buyers, renters and
                        investors
                    </p>
                </div>

                <div className={css(styles.connect)} id="advertise">
                    <div className={css(styles.title)}>Market Property with Aqivah</div>
                    <p className={css(styles.content)}>
                        Selling, Renting and Leasing with Aqivah
                        <br /> <br />
                        If you are looking to sell, rent out or lease out your property or assess the market and gauge response if you
                        are plain undecided, Aqivah affords you the most convenient way to advertise your property.
                        <br /> <br />
                        Aqivah guarantees that your property will obtain the needed exposure, access to more buyers, investors and
                        increased opportunities to sell, rent or lease, by showcasing your property continually to your targeted
                        audience, thereby ensuring that you achieve the maximum potential from your asset.
                        <br />
                        <br />
                        Whether selling, renting or leasing an estate or property, from urban homes to vibrant city centre offices to
                        rural farmlands, Aqivah, with its pristine setting, is here to help.
                    </p>
                </div>

                <div className={css(styles.connect)} id="acquire">
                    <div className={css(styles.title)}>Find Property with Aqivah</div>
                    <p className={css(styles.content)}>
                        Buying, Renting and Leasing with Aqivah
                        <br />
                        <br />
                        Aqivah provides you the simplest and stress-free way to search for property, whether you are looking to buy,
                        lease or rent.
                        <br /> <br />
                        Aqivah makes it easy for you to find “your way home” which is your next happy home whether you are a first-time
                        buyer, upsizing, downsizing or relocating
                        <br /> <br />
                        Here at Aqivah, we make it our business to understand your needs and pitch in to find the right property while
                        saving you all the hassle. Simply, we put you on “your way home” whether home is a mansion, a house, a flat, an
                        apartment, an office space, a retail space or even a venue for your event.
                    </p>
                </div>

                <div className={css(styles.connect)} id="connect">
                    <div className={css(styles.title)}>Connect</div>
                    <p className={css(styles.content)}>
                        Let’s get social
                        <br />
                        <br />
                        Join the Aqivah conversation on Facebook, LinkedIn or, Twitter, or visit us on Instagram or YouTube.
                        <br />
                        <br />
                        Facebook, LinkedIn, Twitter, Instagram, YouTube
                    </p>
                </div>

                <div className={css(styles.insight)} id="insight">
                    <div className={css(styles.title)}>Insight</div>
                    Inspiration to enable
                    <br />
                    <br />
                    Aqivah provides you the required guidance to make the right property decisions and stay up to date with the latest
                    happenings through our blogs and news. Our research and analysis into property market trends provides a glimpse
                    into the market to uncover real estate happenings which enables you find "your way home"
                    <br />
                    <br />
                    Blog News Insight
                </div>

                <div className={css(styles.legal)} id="legal">
                    <div className={css(styles.title)}>Legal</div>
                    <p className={css(styles.content)}>
                        What to expect
                        <br />
                        <br />
                        This platform is owned and controlled by Aqivah. By using this platform, you agree that you consent to, comply
                        with and are bound by the Aqivah policies, terms and conditions and any other documents set out below. All
                        content © copyright 2020 Aqivah. All rights reserved. Aqivah, incorporated and registered in Ghana.
                        <br />
                        <br />
                        Terms of Service, Privacy Statement
                    </p>
                </div>

                <div className={css(styles.contact)} id="contact">
                    <div className={css(styles.title)}>Contact Us</div>
                    <p className={css(styles.content)}>
                        Get in touch
                        <br />
                        <br />
                        We would love to hear from you to discuss all our services in more detail and how Aqivah can make a difference
                        to what you are looking to achieve.
                    </p>

                    <div className={css(styles.form1, styles.form1_mobile)}>
                        <a href="javascript:;" className={css(styles.section_6_header)}>
                            Contact form
                        </a>

                        <form className={css(styles.form)}>
                            <input placeholder="Name" className={css(styles.input)} type="text" />
                            <input placeholder="Email" className={css(styles.input)} type="text" />
                            <input placeholder="Mobile" className={css(styles.input)} type="text" />
                            <input placeholder="Company" className={css(styles.input)} type="text" />
                            <textarea placeholder="Your message" className={css(styles.input_area)} cols={40} rows={5} />
                            <input className={css(styles.input_submit)} type="submit" />
                        </form>
                    </div>
                </div>

                <div className={css(styles.career)} id="careers">
                    <div className={css(styles.title)}>Careers</div>
                    <p className={css(styles.content)}>
                        Make a difference
                        <br />
                        <br />
                        Aqivah is consistently looking to fill positions within its technology and business teams. Selected applicants
                        will work on projects that matter and assigned teams will rely on applicants’ skills and insights to create and
                        deliver delightful experiences. Working with us means an opportunity to hone your skills and work on real
                        projects that make an impact on your immediate society and the world.
                    </p>
                </div>

                <div className={css(styles.footer)} id="footer">
                    <div className={css(styles.footer_image_holder)}>
                        {" "}
                        <img alt="Aqivah logo" style={{ width: 125, height: 125 }} src={Aqivah_Icon_White} />
                    </div>
                    <div className={css(styles.navigation, styles.navigation_mobile)}>
                        <a
                            href="javascript:;"
                            onClick={() => scrollToSection("about-us")}
                            className={css(styles.nav_buttons)}
                            data-aos-once="true"
                            data-aos="fade-down"
                            data-aos-delay="100"
                            data-aos-offset="-100">
                            {" "}
                            About
                        </a>
                        <a
                            href="javascript:;"
                            onClick={() => scrollToSection("connect")}
                            className={css(styles.nav_buttons)}
                            data-aos-once="true"
                            data-aos="fade-down"
                            data-aos-delay="200"
                            data-aos-offset="-100">
                            Connect
                        </a>
                        <a
                            href="javascript:;"
                            onClick={() => scrollToSection("insight")}
                            className={css(styles.nav_buttons)}
                            data-aos-once="true"
                            data-aos="fade-down"
                            data-aos-delay="300"
                            data-aos-offset="-100">
                            Insight
                        </a>
                        <a
                            href="javascript:;"
                            onClick={() => scrollToSection("legal")}
                            className={css(styles.nav_buttons)}
                            data-aos-once="true"
                            data-aos="fade-down"
                            data-aos-delay="400"
                            data-aos-offset="-100">
                            Legal
                        </a>
                        <a
                            href="javascript:;"
                            onClick={() => scrollToSection("contact")}
                            className={css(styles.nav_buttons)}
                            data-aos-once="true"
                            data-aos="fade-down"
                            data-aos-delay="500"
                            data-aos-offset="-100">
                            Contact
                        </a>
                        <a
                            href="javascript:;"
                            onClick={() => scrollToSection("careers")}
                            className={css(styles.nav_buttons)}
                            data-aos-once="true"
                            data-aos="fade-down"
                            data-aos-delay="600"
                            data-aos-offset="-100">
                            Careers
                        </a>
                    </div>
                    <div className={css(styles.footer_last_line)}>
                        {" "}
                        Aqivah &#169; {year.getFullYear()} &nbsp; | &nbsp; All rights reserved{" "}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Redirect;
