import React, { useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import PropertyDetails from "./PropertyDetails";
import LocationDetails from "./LocationDetails";
import Review from "./Review";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/Header";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { CREATE_PROPERTY_RESET } from "../../constants/propertyConstants";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { PropertyContext } from "../../context/property-context/property-context";
import { useMutation } from "@apollo/client";
import { createRes } from "../../Graphql/mutations";

const useStyles = makeStyles(theme => ({
    appBar: {
        position: "relative"
    },
    layout: {
        width: "auto",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    layoutDashboard: {
        width: "auto",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    paper: {
        //background: '#ecf0f3',
        background: "white",
        boxSizing: "border-box",
        //boxShadow: "14px 14px 20px #cbced1, -14px -14px 20px white",
        boxShadow: "2px 2px 20px #aeaeae",
        marginTop: 0,
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: 0,
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3)
        },
        width: "auto",
        "@media (max-width: 900px)": {
            boxShadow: "none"
        }
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
        //background: '#ecf0f3',
        background: "white"
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    "& > * + *": {
        marginTop: theme.spacing(2)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

const steps = ["Property details", "Location details", "Review property"];

interface PageProp {
    page?: any | null;
}

export default function AddPropertyForm2({ page = null }: PageProp) {
    const prop = {
        location: useLocation(),
        history: useNavigate(),
        params: useParams()
    };

    const dispatch = useDispatch();

    const { status, handleStatus, handleLocationDetails, handlePropertyDetails, setStep, currentStep } = useContext(PropertyContext);

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [propertyDetails, setPropertyDetails] = React.useState({});
    const [open, setOpen] = React.useState(true);
    const [headerHeight, setHeaderHeight] = React.useState("");

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setTimeout(() => {
            let height = document.getElementById("header-height");
            console.log(height);
            //setHeaderHeight(height?.style.height)
        }, 3000);
    }, []);

    useEffect(() => {
        dispatch({ type: CREATE_PROPERTY_RESET });
    }, []);

    //  @ts-ignore: Unreachable code error
    // const propertyCreationSteps = useSelector(state => state.propertyCreationSteps);
    // const { activeStep: currentStep } = propertyCreationSteps;

    // const handlePropertyDetails = property_data => {
    //     console.log(property_data);
    //     if (prop.location.pathname !== "/edit") setPropertyDetails({ ...propertyDetails, ...property_data });
    //     setPropertyDetails({ ...propertyDetails, ...property_data });
    // };

    // const handleLocationDetails = location_data => {
    //     console.log(location_data);
    //     if (prop.location.pathname !== "/edit") setPropertyDetails({ ...propertyDetails, ...location_data });
    //     setPropertyDetails({ ...propertyDetails, ...location_data });
    // };

    const clearFields = () => {
        handlePropertyDetails({});
        handleLocationDetails({});
        setStep(0);
        setPropertyDetails({
            category: "",
            propertyType: "",
            description: "",
            short_description: "",
            price: 0,
            saleType: "",
            paymentPlan: "",
            furnishing: "",
            waterSource: "",
            images: "",
            bedroom: "",
            bathroom: "",
            currency: "",
            size: "",
            unit: "",
            parking_capacity: "",
            seating_capacity: "",
            region: "",
            location: "",
            city: ""
        });
    };

    useEffect(() => {
        if (status.success === true) {
            setTimeout(() => {
                clearFields();
                handleStatus({ loading: false, success: false, error: false });
            }, 2500);
        }
    }, [status]);

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <PropertyDetails
                        key={"propertydetails"}
                        //handlePropertyDetails={handlePropertyDetails}
                        //propertyDetails={propertyDetails}
                    />
                );
            case 1:
                return (
                    <LocationDetails
                        key={"locationdetails"}
                        //handleLocationDetails={handleLocationDetails}
                        //locationDetails={propertyDetails}
                    />
                );
            case 2:
                return <Review key={"review"} />;
            default:
                throw new Error("Unknown step");
        }
    }

    useEffect(() => {
        setActiveStep(currentStep);
    }, [currentStep]);

    return (
        <div id="listing-page">
            <CssBaseline />
            <Header page={"add_property"} />
            {/* <Alert icon={false} style={{marginTop:"60px"}} severity="info"> 
        		<Breadcrumbs>
            		<Link to="/">Home</Link>
            		<Link to="/owner-dashboard">List Property</Link>
					{(activeStep && activeStep === 0) && <Link to="#">Property Details</Link>}
					{(activeStep && activeStep === 1) && <Link to="#">Location Details</Link>}
					{(activeStep && activeStep === 1) && <Link to="#">Review Details</Link>}
        		</Breadcrumbs>
      		</Alert> */}

            <main className={page !== "dashboard" ? classes.layout : classes.layoutDashboard}>
                <Paper className={classes.paper} style={{ marginTop: page !== "dashboard" ? "100px" : "10px" }}>
                    <Typography component="h1" variant="h4" align="center">
                        List Property
                    </Typography>
                    <Stepper activeStep={activeStep} className={classes.stepper}>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    <React.Fragment>
                        {status.success === true ? (
                            <React.Fragment>
                                <Alert severity="success">Property created!</Alert>
                            </React.Fragment>
                        ) : status.loading ? (
                            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                                <CircularProgress color="primary" />
                            </Backdrop>
                        ) : (
                            <React.Fragment>{getStepContent(currentStep)}</React.Fragment>
                        )}
                    </React.Fragment>
                    {status.error && <Alert severity="error">{"Something went wrong!"}</Alert>}
                </Paper>
            </main>
        </div>
    );
}
