import React, { useState, useContext, useEffect } from "react";
import { Badge, Calendar } from "antd";
import { AdminProviderContext } from "../../../../common/admin-provider/admin-provider-context";
import axios from "axios";
import moment from "moment";

const getListData = (value: any, bookings: any) => {
    let listData;

    // const data = bookings.filter(booking => moment(booking.date).format("YYYY-MM-DD") === "2023-07-31");

    // console.log(value, data, moment(value).format("YYYY-MM-DD"));

    // data.map((item: any) => {
    //     listData = [{ type: "success", content: moment(item.date).format("hh:mm a") + " appointment" }];
    // });

    switch (value.date()) {
        case 8:
            listData = [
                { type: "warning", content: "This is warning event." },
                { type: "success", content: "This is usual event." }
            ];
            break;
        case 10:
            listData = [
                { type: "warning", content: "This is warning event." },
                { type: "success", content: "This is usual event." },
                { type: "error", content: "This is error event." }
            ];
            break;
        case 15:
            listData = [
                { type: "warning", content: "This is warning event" },
                { type: "success", content: "This is very long usual event。。...." },
                { type: "error", content: "This is error event 1." },
                { type: "error", content: "This is error event 2." },
                { type: "error", content: "This is error event 3." },
                { type: "error", content: "This is error event 4." }
            ];
            break;
        default:
    }
    return listData || [];
};

const getMonthData = (value: any) => {
    if (value.month() === 8) {
        return 1394;
    }
};

export const XCalendar: React.FC = () => {
    const [bookings, setBookings] = useState<Array<any> | null>(null);
    const { user } = useContext(AdminProviderContext);

    const fetchBookings = () => {
        axios
            .get("/properties/search-bookings", { params: { owner_id: user?._id } })
            .then((res: any) => {
                setBookings(res.data.result.bookings);
                console.log(res.data.result.bookings);
            })
            .catch(err => console.log(err))
            .then(() => {
                console.log("");
            });
    };
    const monthCellRender = (value: any) => {
        const num = getMonthData(value);
        return num ? (
            <div className="notes-month">
                <section>{num}</section>
                <span>Backlog number</span>
            </div>
        ) : null;
    };

    // const dateCellRender = (value: any) => {
    //     const listData = getListData(value, bookings);

    //     return (
    //         <ul className="events">
    //             {listData.map(item => (
    //                 <li key={item.content}>
    //                     <Badge status={item.type as any["status"]} text={item.content} />
    //                 </li>
    //             ))}
    //         </ul>
    //     );
    // };

    const dateCellRender = value => {
        console.log(bookings);
        const stringValue = value.format("DD/MM/YYYY");
        const listData = bookings ? bookings.filter(booking => moment(booking.date).format("DD/MM/YYYY") === stringValue) : [];
        return (
            <ul className="events">
                {listData.map(item => (
                    <li key={item.content}>
                        <Badge status={"success"} />
                        Viewing for {item.seeker.first_name} {item.seeker.last_name}
                    </li>
                ))}
            </ul>
        );
    };

    const cellRender = (current: any, info: any) => {
        if (info.type === "date") return dateCellRender(current);
        if (info.type === "month") return monthCellRender(current);
        return info.originNode;
    };

    useEffect(() => fetchBookings(), []);

    return <div id="calendar">{bookings && <Calendar cellRender={cellRender} />}</div>;
};
