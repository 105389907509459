import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { PopupProviderContext } from "../../../../common/popup-alerts-provider/popup-context";
import { AdminProviderContext } from "../../../../common/admin-provider/admin-provider-context";
import { logError } from "../../../../utils/logError";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./profile.scss";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Collapse from "antd/es/collapse";
import List from "antd/es/list";
import AttachImg from "../../../../assets/file-images/attached-file.png";
import PdfImg from "../../../../assets/file-images/pdf.png";
import ExcelImg from "../../../../assets/file-images/excel.png";
import WordImg from "../../../../assets/file-images/word.png";
import Img from "../../../../assets/file-images/image.png";
import ZipImg from "../../../../assets/file-images/zip.png";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const { Panel } = Collapse;

export const Profile = () => {
    const FETCH_USER_DETAILS = "fetch-user-details";
    const UPDATE_USER_DETAILS = "update-user-details";
    const OLD_PASSWORD = "old-password";
    const NEW_PASSWORD = "new-password";
    const CONFIRM_PASSWORD = "confirm-password";
    const CHANGE_PASSWORD = "change-password";
    const UPLOAD_SUCCESS = "upload-success";
    const DELETE_SUCCESS = "delete-success";

    const [events, setEvents] = useState<Array<string>>([]);
    const [show_passwords, setShowPasswords] = useState<Array<string>>([]);
    const [userDetails, setUserDetails] = useState<any>(null);
    const { flashError, flashSuccess } = useContext(PopupProviderContext);
    const [uploading, setUploading] = useState<any>(false);
    const [deleteId, setDeleteId] = useState("");
    const { user, refreshAdmin } = useContext(AdminProviderContext);

    const data = ["License to practice", "Business License", "Ghana Card ID"];

    const extentionsList: any = {
        pdf: PdfImg,
        png: Img,
        jpg: Img,
        jpeg: Img,
        doc: WordImg,
        docx: WordImg,
        xlsx: ExcelImg,
        csv: ExcelImg,
        rar: ZipImg
    };

    function fetchUserDetails() {
        setEvents([...events, FETCH_USER_DETAILS]);
        axios
            .get(`/auth/user`)
            .then((res: any) => {
                setUserDetails(res.data.user);
            })
            .catch((err: any) => flashError("Something went wrong!", logError(err)))
            .then(() => setEvents(events.filter((event: string) => event !== FETCH_USER_DETAILS)));
    }

    let phone_regex = /^([026|26]).\d{7,8}$/;
    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email format").required("Email is required"),
        phone: Yup.string().required("Phone number is required").matches(phone_regex, "Phone number format is not valid"),
        first_name: Yup.string().required("First name is required"),
        last_name: Yup.string().required("Last name is required")
    });

    const validationSchemaChangePassword = Yup.object({
        password: Yup.string().required("Old password is required"),
        new_password: Yup.string().required("New password is required"),
        confirm_password: Yup.string()
            .required("Confirm password is required")
            .when("new_password", {
                is: password => (password && password.length > 0 ? true : false),
                then: Yup.string().oneOf([Yup.ref("password")], "Passwords do not match")
            })
    });

    const formikProfileUpdate = useFormik({
        initialValues: {
            email: userDetails?.email || "jbrav@gmail.com",
            phone: userDetails?.phone || "",
            first_name: userDetails?.first_name || "John",
            last_name: userDetails?.last_name || "Bravo"
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: values => {
            setEvents([...events, UPDATE_USER_DETAILS]);
            axios
                .put(`/auth/update-user`, values)
                .then((res: any) => {
                    flashSuccess("Update successful");
                    fetchUserDetails();
                    refreshAdmin();
                })
                .catch((err: any) => flashError("Something went wrong!", logError(err)))
                .then(() => setEvents(events.filter((event: any) => event !== UPDATE_USER_DETAILS)));
        }
    });

    const formikChangePassword = useFormik({
        initialValues: {
            password: "",
            new_password: "",
            confirm_password: ""
        },
        validationSchema: validationSchemaChangePassword,
        onSubmit: values => {
            setEvents([...events, CHANGE_PASSWORD]);
            axios
                .post(`/auth/change-password/`)
                .then((res: any) => flashSuccess("Update successful"))
                .catch((err: any) => flashError("Something went wrong!", logError(err)))
                .then(() => setEvents(events.filter((event: any) => event !== CHANGE_PASSWORD)));
        }
    });

    const handleImageUpload = async (values: any) => {
        const fileList = new FormData();

        Array.from(values.target.files).forEach((file: any) => {
            fileList.append("files", file);
        });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };

        axios
            .post("auth/personal-documents/upload", fileList, config)
            .then((res: any) => {
                setUploading(false);
                fetchUserDetails();
                setEvents([...events, UPLOAD_SUCCESS]);
            })
            .catch((err: any) => {
                setUploading(false);
                flashError("Something went wrong", "Could not complete process");
            });
        setUploading(true);
    };

    const handleFileDelete = (file: any) => {
        setDeleteId(file);
        axios
            .post("auth/delete-file", { filename: file })
            .then((res: any) => {
                fetchUserDetails();
                setEvents([...events, DELETE_SUCCESS]);
            })
            .catch(err => {
                console.log(err);
                flashError("Something went wrong", "Could not complete process");
            })
            .then(() => setDeleteId(""));
    };

    const handleUploadClick = () => {
        (document as any).getElementById("upload-btn").click();
    };

    useEffect(fetchUserDetails, []);

    const onChange = (key: string | string[]) => {
        console.log(key);
    };

    return (
        <div id="profile-page">
            <Snackbar
                open={events.includes(DELETE_SUCCESS)}
                autoHideDuration={2000}
                onClose={() => setEvents(events.filter((event: any) => event != DELETE_SUCCESS))}>
                <Alert
                    onClose={() => setEvents(events.filter((event: any) => event != DELETE_SUCCESS))}
                    severity="success"
                    sx={{ width: "100%" }}>
                    <div style={{ color: "white" }}>Upload successful</div>
                </Alert>
            </Snackbar>

            <Snackbar
                open={events.includes(UPLOAD_SUCCESS)}
                autoHideDuration={3000}
                onClose={() => setEvents(events.filter((event: any) => event != UPLOAD_SUCCESS))}>
                <Alert
                    onClose={() => setEvents(events.filter((event: any) => event != UPLOAD_SUCCESS))}
                    severity="success"
                    sx={{ width: "100%" }}>
                    <div style={{ color: "white" }}>Upload successful</div>
                </Alert>
            </Snackbar>

            <header className="profile-header">
                <h3>Profile Page</h3>
                <div>
                    <button className="btn btn-primary btn-sm" onClick={fetchUserDetails}>
                        Refresh
                    </button>
                </div>
            </header>

            <Collapse className="mb-4">
                <Panel header={<h5 className="fw-bold">Personal Info</h5>} className="py-3" key="1">
                    <h6 className="mb-5 fw-bold px-5">Personal Info</h6>
                    <form style={{ width: "100%", background: "#fff" }} className="px-5" onSubmit={formikProfileUpdate.handleSubmit}>
                        <Grid container spacing={4}>
                            <Grid className="w-360" item sm={12} md={6} lg={6}>
                                <TextField
                                    name="first_name"
                                    size="small"
                                    placeholder="First Name"
                                    label="First Name"
                                    fullWidth
                                    value={formikProfileUpdate.values.first_name}
                                    onChange={formikProfileUpdate.handleChange}
                                    error={
                                        formikProfileUpdate.touched.first_name && formikProfileUpdate.errors.first_name ? true : false
                                    }
                                    helperText={formikProfileUpdate.touched.first_name && formikProfileUpdate.errors.first_name}
                                />
                            </Grid>
                            <Grid className="w-360" item sm={12} md={6} lg={6}>
                                <TextField
                                    name="last_name"
                                    size="small"
                                    fullWidth
                                    placeholder="Last Name"
                                    label="Last Name"
                                    value={formikProfileUpdate.values.last_name}
                                    onChange={formikProfileUpdate.handleChange}
                                    error={
                                        formikProfileUpdate.touched.last_name && formikProfileUpdate.errors.last_name ? true : false
                                    }
                                    helperText={formikProfileUpdate.touched.last_name && formikProfileUpdate.errors.last_name}
                                />
                            </Grid>
                            <Grid className="w-360" item sm={12} md={6} lg={6}>
                                <TextField
                                    name="phone"
                                    size="small"
                                    fullWidth
                                    placeholder="Phone Number"
                                    label="Phone Number"
                                    value={formikProfileUpdate.values.phone}
                                    onChange={formikProfileUpdate.handleChange}
                                    error={formikProfileUpdate.touched.phone && formikProfileUpdate.errors.phone ? true : false}
                                    helperText={formikProfileUpdate.touched.phone && formikProfileUpdate.errors.phone}
                                />
                            </Grid>
                            <Grid className="w-360" item sm={12} md={6} lg={6}>
                                <TextField
                                    name="email"
                                    size="small"
                                    fullWidth
                                    disabled
                                    placeholder="Email"
                                    label="Email"
                                    value={formikProfileUpdate.values.email}
                                    onChange={formikProfileUpdate.handleChange}
                                    error={formikProfileUpdate.touched.email && formikProfileUpdate.errors.email ? true : false}
                                    helperText={formikProfileUpdate.touched.email && formikProfileUpdate.errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} className="d-grid w-360">
                                {formikProfileUpdate.touched && (
                                    <Button size="small" type="submit" variant="contained">
                                        Submit
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </Panel>
            </Collapse>

            <Collapse className="mb-4">
                <Panel header={<h5 className="fw-bold">Documents</h5>} key="2" className="py-3">
                    <h6 className="mb-5 fw-bold px-5">Documents</h6>
                    <Grid container spacing={3} className="px-5 ">
                        <Grid item sm={12} md={8} lg={5}>
                            <input hidden multiple type="file" onChange={handleImageUpload} id="upload-btn" />
                            <List
                                size="small"
                                loading={uploading}
                                header={
                                    <div className="d-flex justify-content-between">
                                        Documents{" "}
                                        <button onClick={handleUploadClick} className="btn btn-primary btn-sm">
                                            Upload <i className="fa-solid fa-plus"></i>
                                        </button>
                                    </div>
                                }
                                // footer={<div>Footer</div>}
                                bordered
                                dataSource={userDetails?.owner_documents || []}
                                renderItem={(item: any) => (
                                    <List.Item className="d-flex justify-content-between">
                                        <>
                                            <a rel="noopener noreferrer" href={item.location}>
                                                {item.name}
                                            </a>
                                            <img
                                                alt="ext-img"
                                                style={{ width: "20px", height: "auto" }}
                                                className="ms-1"
                                                src={extentionsList[item.name.split(".")[1]] || AttachImg}
                                            />
                                        </>

                                        {item.name === deleteId ? (
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="visually-hidden">deleting...</span>
                                            </div>
                                        ) : (
                                            <i
                                                role="button"
                                                style={{ color: "red" }}
                                                onClick={() => handleFileDelete(item.name)}
                                                className="fa-solid fa-trash-can"></i>
                                        )}
                                    </List.Item>
                                )}
                            />
                        </Grid>
                    </Grid>
                </Panel>
            </Collapse>

            <Collapse className="mb-4">
                <Panel header={<h5 className="fw-bold">Change Password</h5>} key="2" className="py-3">
                    <h6 className="mb-5 fw-bold px-5">Change Password</h6>
                    <form onSubmit={formikChangePassword.handleSubmit} className="px-5 w-480">
                        <Grid container spacing={4}>
                            <Grid item xs={6} sm={12} md={12} lg={12}>
                                <TextField
                                    name="password"
                                    size="small"
                                    placeholder="Old Password"
                                    label="Old Password"
                                    fullWidth
                                    type={!show_passwords.includes(OLD_PASSWORD) ? "password" : "text"}
                                    InputProps={{
                                        endAdornment:
                                            formikChangePassword.values.password !== "" ? (
                                                !show_passwords.includes(OLD_PASSWORD) ? (
                                                    <i
                                                        onClick={() => setShowPasswords([...show_passwords, OLD_PASSWORD])}
                                                        className="fas fa-eye"></i>
                                                ) : (
                                                    <i
                                                        onClick={() =>
                                                            setShowPasswords(
                                                                show_passwords.filter((passes: any) => passes != OLD_PASSWORD)
                                                            )
                                                        }
                                                        className="fas fa-eye-slash"></i>
                                                )
                                            ) : null
                                    }}
                                    value={formikChangePassword.values.password}
                                    onChange={formikChangePassword.handleChange}
                                    error={
                                        formikChangePassword.touched.password && formikChangePassword.errors.password ? true : false
                                    }
                                    helperText={formikChangePassword.touched.password && formikChangePassword.errors.password}
                                />
                            </Grid>
                            <Grid item xs={6} sm={12} md={12} lg={12}>
                                <TextField
                                    name="new_password"
                                    size="small"
                                    placeholder="New Password"
                                    label="New Password"
                                    fullWidth
                                    type={!show_passwords.includes(NEW_PASSWORD) ? "password" : "text"}
                                    InputProps={{
                                        endAdornment:
                                            formikChangePassword.values.new_password !== "" ? (
                                                !show_passwords.includes(OLD_PASSWORD) ? (
                                                    <i
                                                        onClick={() => setShowPasswords([...show_passwords, NEW_PASSWORD])}
                                                        className="fas fa-eye"></i>
                                                ) : (
                                                    <i
                                                        onClick={() =>
                                                            setShowPasswords(
                                                                show_passwords.filter((passes: any) => passes != NEW_PASSWORD)
                                                            )
                                                        }
                                                        className="fas fa-eye-slash"></i>
                                                )
                                            ) : null
                                    }}
                                    value={formikChangePassword.values.new_password}
                                    onChange={formikChangePassword.handleChange}
                                    error={
                                        formikChangePassword.touched.new_password && formikChangePassword.errors.new_password
                                            ? true
                                            : false
                                    }
                                    helperText={formikChangePassword.touched.new_password && formikChangePassword.errors.new_password}
                                />
                            </Grid>
                            <Grid item xs={6} sm={12} md={12} lg={12}>
                                <TextField
                                    name="confirm_password"
                                    size="small"
                                    placeholder="Confirm New Password"
                                    label="Confirm New Password"
                                    fullWidth
                                    type={!show_passwords.includes(CONFIRM_PASSWORD) ? "password" : "text"}
                                    InputProps={{
                                        endAdornment:
                                            formikChangePassword.values.confirm_password !== "" ? (
                                                !show_passwords.includes(OLD_PASSWORD) ? (
                                                    <i
                                                        onClick={() => setShowPasswords([...show_passwords, CONFIRM_PASSWORD])}
                                                        className="fas fa-eye"></i>
                                                ) : (
                                                    <i
                                                        onClick={() =>
                                                            setShowPasswords(
                                                                show_passwords.filter((passes: any) => passes != CONFIRM_PASSWORD)
                                                            )
                                                        }
                                                        className="fas fa-eye-slash"></i>
                                                )
                                            ) : null
                                    }}
                                    value={formikChangePassword.values.confirm_password}
                                    onChange={formikChangePassword.handleChange}
                                    error={
                                        formikChangePassword.touched.confirm_password && formikChangePassword.errors.confirm_password
                                            ? true
                                            : false
                                    }
                                    helperText={
                                        formikChangePassword.touched.confirm_password && formikChangePassword.errors.confirm_password
                                    }
                                />
                            </Grid>
                            <Grid item className="d-grid">
                                {formikChangePassword.initialTouched && (
                                    // <Button size="small" type="submit" variant="contained">
                                    //     Submit
                                    // </Button>
                                    <button
                                        type="submit"
                                        disabled={events.includes(CHANGE_PASSWORD)}
                                        className="btn btn-primary btn-sm fw-bold">
                                        {events.includes(CHANGE_PASSWORD) && (
                                            <span className="spinner-border spinner-border-sm" role="status"></span>
                                        )}{" "}
                                        Submit
                                    </button>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </Panel>
            </Collapse>

            {user?.organization && (
                <Collapse className="mb-4">
                    <Panel header={<h5 className="fw-bold">Organization</h5>} key="2" className="py-3">
                        <h6 className="mb-5 fw-bold px-5">Organization</h6>
                        <div className="px-5">
                            {user?.organization && (
                                <div className="d-flex flex-column justify-content-start align-items-start">
                                    <span className="fw-bold">
                                        Name: <p>{user.organization.name}</p>
                                    </span>
                                    <span className="fw-bold">
                                        Date Joined: <p>{moment(user.organization.date_added).format("Do MMM YYYY, hh:mm a")}</p>
                                    </span>
                                    <button className="btn btn-danger btn-sm fw-bold">Leave Organization</button>
                                </div>
                            )}
                            {!user?.organization && <h6 className="fw-light">No organization</h6>}
                        </div>
                        <form action="" className="px-5 w-480"></form>
                    </Panel>
                </Collapse>
            )}

            <Collapse className="mb-4" defaultActiveKey={"2"}>
                <Panel header={<h5 className="fw-bold">Delete Account</h5>} key="2" className="py-3">
                    <div className="px-5">
                        <div className="d-flex flex-column justify-content-start align-items-start">
                            <button className="btn btn-danger btn-sm fw-bold">Delete Account</button>
                        </div>
                    </div>
                    <form action="" className="px-5 w-480"></form>
                </Panel>
            </Collapse>
        </div>
    );
};
