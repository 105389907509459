import {
    USER_REGISTER_SUCCESS,
    USER_REGISTER_REQUEST,
    USER_REGISTER_FAIL,
    USER_CONFIRM_REQUEST,
    USER_CONFIRM_SUCCESS,
    USER_CONFIRM_FAIL,
    USER_REGISTER_RESET,
    USER_CONFIRM_RESET,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGIN_RESET,
    USER_PROFILE_REQUEST,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAIL,
    USER_PROFILE_RESET,
    USER_OPEN_LOGIN_MODAL,
    USER_DOMAIN,
    USER_PROFILE_UPDATE_REQUEST,
    USER_PROFILE_UPDATE_SUCCESS,
    USER_PROFILE_UPDATE_FAIL,
    USER_PROFILE_UPDATE_RESET,
    USER_SEND_MESSAGE_REQUEST,
    USER_SEND_MESSAGE_SUCCESS,
    USER_SEND_MESSAGE_FAIL,
    USER_SEND_MESSAGE_ERROR,
    USER_FETCH_MESSAGES_REQUEST,
    USER_FETCH_MESSAGES_SUCCESS,
    USER_FETCH_MESSAGES_FAIL,
    USER_GET_NEW_MESSAGE_SUCCESS,
    USER_AUTH_EXPIRY_TIME,
    USER_AUTH_TOKEN_REFRESH,
    USER_OWNER_PROFILE_REQUEST,
    USER_OWNER_PROFILE_SUCCESS,
    USER_OWNER_PROFILE_FAIL,
    NEW_TOKEN,
    USER_PASSWORD_RESET_CODE_REQUEST,
    USER_PASSWORD_RESET_CODE_SUCCESS,
    USER_PASSWORD_RESET_CODE_FAIL,
    USER_PASSWORD_RESET_REQUEST,
    USER_PASSWORD_RESET_SUCCESS,
    USER_PASSWORD_RESET_FAIL,
    USER_PASSWORD_RESET_RESET,
    USER_OPEN_FORGOT_PASSWORD_MODAL,
    USER_PASSWORD_RESET_CODE,
    USER_CHANGE_PASSWORD_REQUEST,
    USER_CHANGE_PASSWORD_SUCCESS,
    USER_CHANGE_PASSWORD_FAIL,
    USER_CHANGE_PASSWORD_RESET,
    USER_PASSWORD_RESET_PROFILE_REQUEST,
    USER_PASSWORD_RESET_PROFILE_SUCCESS,
    USER_PASSWORD_RESET_PROFILE_FAIL,
    USER_PASSWORD_RESET_PROFILE_RESET,
    CONFIRM_RESEND_REQUEST,
    CONFIRM_RESEND_SUCCESS,
    CONFIRM_RESEND_FAIL
}
    from '../constants/userConstants'


export const registerUserReducer = (state = { loading: false, success: false, user: [] }, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true, success:false, user: [] }
        case USER_REGISTER_SUCCESS:
            return { loading: false, success: true, user: action.payload }
        case USER_REGISTER_FAIL:
            return { loading: false, success: false, error: action.payload }
        case USER_REGISTER_RESET:
            return { loading: false, success: false, user: [] }
        default:
            return state
    }
}

export const confirmUserReducer = (state = { loading: false, success: false }, action) => {
    switch (action.type) {
        case USER_CONFIRM_REQUEST:
            return { loading: true, success:false }
        case USER_CONFIRM_SUCCESS:
            return { loading: false, success: true, message: action.payload }
        case USER_CONFIRM_FAIL:
            return { loading: false, success: false, error: action.payload }
        case USER_CONFIRM_RESET:
            return { loading: false, success: false}
        case CONFIRM_RESEND_REQUEST:
            return {loading:true}
        case CONFIRM_RESEND_SUCCESS: 
            return {loading: false, resendSuccess:true, success:false, resendInfo: {...action.payload}}
        case CONFIRM_RESEND_FAIL:
            return {loading: false, success: false, resendError: action.payload}
        default:
            return state
    }
}

export const openLoginReducer = (state={open:false}, action) => {
    switch (action.type) {
        case USER_OPEN_LOGIN_MODAL:
            return {open: action.payload}
        default:
            return state
    }
}

export const openForgotPasswordReducer = (state={open:false}, action) => {
    switch(action.type) {
        case USER_OPEN_FORGOT_PASSWORD_MODAL:
            return {open: action.payload}
        default:
            return state
    }
}

export const userLoginReducer = (state = { user: {}, loading:false, success:false }, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST: 
            return {loading: true}
        case USER_LOGIN_SUCCESS:
            return {loading:false, success: true }
        case USER_LOGIN_FAIL: 
            return {loading:false, success:false, error: action.payload}
        case USER_LOGIN_RESET:
            return {loading: false, success: false}
        default:
            return state
    }
}

export const userForgotPasswordReducer = ( state = { code: {}, loading: false, success: false} , action ) => {
    switch(action.type) {
        case USER_PASSWORD_RESET_CODE_REQUEST:
            return {code: {}, loading: true}
        case USER_PASSWORD_RESET_CODE_SUCCESS:
            return {code: action.payload, loading:false, success: true}
        case USER_PASSWORD_RESET_CODE_FAIL:
            return {error: action.payload, loading: false, success: false}
        case USER_PASSWORD_RESET_CODE:
            return {code: {}, loading: false, success:false}
        default:
            return state
    }
}

export const userResetPasswordReducer = ( state= { code: {} , loading: false, success:false} , action ) => {
    switch(action.type) {
        case USER_PASSWORD_RESET_REQUEST:
            return {code: {}, loading: true}
        case USER_PASSWORD_RESET_SUCCESS:
            return {code: action.payload, loading: false, success: true}
        case USER_PASSWORD_RESET_FAIL:
            return {error: action.payload, loading: false, success: false}
        case USER_PASSWORD_RESET_RESET:
            return {code:{}, loading: false, success: false}
        default:
            return state
    }
}

export const userResetPasswordForProfileReducer = ( state= { code: {} , loading: false, success:false} , action ) => {
    switch(action.type) {
        case USER_PASSWORD_RESET_PROFILE_REQUEST:
            return {code: {}, loading: true}
        case USER_PASSWORD_RESET_PROFILE_SUCCESS:
            return {code: action.payload, loading: false, success: true}
        case USER_PASSWORD_RESET_PROFILE_FAIL:
            return {error: action.payload, loading: false, success: false}
        case USER_PASSWORD_RESET_PROFILE_RESET:
            return {code:{}, loading: false, success: false}
        default:
            return state
    }
}

export const userChangePasswordReducer = (state = {code: {}, loading: false, success: false}, action) => {
    switch(action.type) {
        case USER_CHANGE_PASSWORD_REQUEST:
            return {code: {}, loading: true, success: false}
        case USER_CHANGE_PASSWORD_SUCCESS:
            return {code: action.payload, loading: false, success: true}
        case USER_CHANGE_PASSWORD_FAIL:
            return {code: {}, loading: false, success: false, error: action.payload}
        case USER_CHANGE_PASSWORD_RESET:
            return {code: {}, loading: false, success: false}
        default:
            return state
    }
}

export const userProfileReducer = (state = { user: {firstname:'', lastname:''}, loading:false, success:false}, action) => {
    switch (action.type) {
        case USER_PROFILE_REQUEST:
            return {loading: true, success:false, user: {firstname:'', lastname:''} }

        case USER_PROFILE_SUCCESS:
            return {loading: false, user:action.payload, success: true}

        case USER_PROFILE_FAIL:
            return {loading: false, success:false, error: action.payload, user: {firstname:'', lastname:''}}

        case USER_PROFILE_RESET:
            return {loading: false, user:{}, success:false}
            
        default:
            return state
    }
}



export const userProfileUpdateReducer = (state= {userUpdate: {}, laoding:false, success: false}, action) => {
    switch(action.type) {
        case USER_PROFILE_UPDATE_REQUEST:
            return {loading:true, success:false, userUpdate: {}}
        case USER_PROFILE_UPDATE_SUCCESS:
            return {loading:false, success:true, userUpdate:action.payload}
        case USER_PROFILE_UPDATE_FAIL:
            return {loading: false, success:false, error: action.payload, userUpdate:{}}
        case USER_PROFILE_UPDATE_RESET:
            return {loading: false, success:false, userUpdate: {} }
        default:
            return state
    }
}


export const userCurrentHostnameReducer = (state = 'app', action) => {
    switch (action.type) {
        case USER_DOMAIN:
            return action.payload
        default:
            return state
    }
}


export const userMessagesReducer = (state= { messages: [], userList: [], loading: false, success: false }, action) => {
    switch(action.type) {
        case USER_SEND_MESSAGE_REQUEST:
            return {loading: true, success: false, messages: state.messages, userList: state.userList}
        case USER_SEND_MESSAGE_SUCCESS:
            return {loading: false, success: true, messages: [...state.messages, action.payload.message], userList: action.payload.userList }
        case USER_SEND_MESSAGE_ERROR:
            return {loading: false, success: true, messages: [...state.messages, action.payload.message], userList: action.payload.userList }
        case USER_SEND_MESSAGE_FAIL:
            return {loading: false, succss: false, messages: [...state.messages, action.payload.message], userList: action.payload.userList }
        case USER_FETCH_MESSAGES_REQUEST:
            return {loading: true, success: false, messages: [], userList: []}
        case USER_FETCH_MESSAGES_SUCCESS:
            return {loading: false, success: true, messages: [...state.messages, ...action.payload.messages], userList: [...action.payload.userList]}
        case USER_FETCH_MESSAGES_FAIL:
            return {loading: false, success:false, messages: state.messages, userList: state.userList, error: action.payload}
        case USER_GET_NEW_MESSAGE_SUCCESS:
            return {loading: false, success: true, messages: [...state.messages, action.payload.message], userList: action.payload.userList }
        default:
            return state
    }
}

export const userNewMessageNotificationReducer = (state = {messages:[], userList:[]}, action) => {
    switch(action.type){
        case USER_GET_NEW_MESSAGE_SUCCESS:
            return { messages: [...state.messages, action.payload.message], userList: [action.payload.message.from, ...state.userList] }
        default:
            return state
    }
}

export const newMessagesForScrollBar = (state={message:{}}, action) => {
    switch(action.type) {
        case USER_SEND_MESSAGE_SUCCESS:
            return {message: action.payload.message }
        case USER_GET_NEW_MESSAGE_SUCCESS:
            return { message: action.payload.message}
        default:
            return state
    }
}

export const userAuthenticationExpiryTimeReducer = (state = {exp_time: null, duration:86400000}, action) => {
    switch(action.type) {
        case USER_AUTH_EXPIRY_TIME: 
            return {exp_time: action.payload}
        default:
            return state
    }
}

export const userAuthTokenRefreshReducer = (state = {update: false}, action) => {
    switch(action.type) {
        case USER_AUTH_TOKEN_REFRESH:
            return {update: action.payload}
        default:
            return state
    }
}

export const userOwnerProfileReducer = (state={owner:[], loading:false, success:false}, action) => {
    switch(action.type) {
        case USER_OWNER_PROFILE_REQUEST:
            return {owner:[], loading:true, success:false}
        case USER_OWNER_PROFILE_SUCCESS:
            return {owner:action.payload, success:true, loading:false}
        case USER_OWNER_PROFILE_FAIL:
            return {owner:[], loading:false, success:false, error: action.payload}
        default:
            return state
    }
}
