import {
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_CONFIRM_REQUEST,
    USER_CONFIRM_SUCCESS,
    USER_CONFIRM_FAIL,
    USER_PROFILE_REQUEST,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAIL,
    USER_OPEN_LOGIN_MODAL,
    USER_DOMAIN,
    USER_LOGIN_RESET,
    USER_PROFILE_UPDATE_REQUEST,
    USER_PROFILE_UPDATE_SUCCESS,
    USER_PROFILE_UPDATE_FAIL,
    USER_SEND_MESSAGE_REQUEST,
    USER_SEND_MESSAGE_SUCCESS,
    USER_SEND_MESSAGE_FAIL,
    USER_FETCH_MESSAGES_REQUEST,
    USER_FETCH_MESSAGES_SUCCESS,
    USER_FETCH_MESSAGES_FAIL,
    USER_GET_NEW_MESSAGE_SUCCESS,
    USER_AUTH_EXPIRY_TIME,
    USER_AUTH_TOKEN_REFRESH,
    USER_OWNER_PROFILE_REQUEST,
    USER_OWNER_PROFILE_SUCCESS,
    USER_OWNER_PROFILE_FAIL,
    USER_SEND_MESSAGE_ERROR,
    NEW_TOKEN,
    USER_PASSWORD_RESET_CODE_REQUEST,
    USER_PASSWORD_RESET_CODE_SUCCESS,
    USER_PASSWORD_RESET_CODE_FAIL,
    USER_PASSWORD_RESET_CODE,
    USER_PASSWORD_RESET_REQUEST,
    USER_PASSWORD_RESET_SUCCESS,
    USER_PASSWORD_RESET_FAIL,
    USER_OPEN_FORGOT_PASSWORD_MODAL,
    USER_CHANGE_PASSWORD_REQUEST,
    USER_CHANGE_PASSWORD_FAIL,
    USER_CHANGE_PASSWORD_SUCCESS,
    USER_PASSWORD_RESET_PROFILE_REQUEST,
    USER_PASSWORD_RESET_PROFILE_SUCCESS,
    USER_PASSWORD_RESET_PROFILE_FAIL,
    CONFIRM_RESEND_SUCCESS,
    CONFIRM_RESEND_REQUEST,
    CONFIRM_RESEND_FAIL
} from "../constants/userConstants";
import { UserPool } from "../config/UserPool";
import { CognitoUserAttribute, CognitoUser, AuthenticationDetails, CookieStorage } from "amazon-cognito-identity-js";
import { CreateUser, DeleteUser, UpdateUser } from "../Graphql/mutations";
import { GetUser } from "../Graphql/queries";
import { client } from "../Graphql/client";
import AWS from "aws-sdk";
import axios from "axios";
import { current_url as api_url } from "../config/socketioConfig";
import { Domain } from "../config/Domain";

const { client_ } = client();

export const userRegistration = userInfo => async dispatch => {
    dispatch({
        type: USER_REGISTER_REQUEST
    });

    const formatPhoneNumber_GhanaFormat = num => {
        let a = num.toString();
        if (a.length === 10) {
            if (a.substring(0, 1) === "0") {
                console.log("+233" + a.substring(1, a.length));
                return "+233" + a.substring(1, a.length);
            } else {
                console.log("phone number format not valid: ERR0");
                return "";
            }
        } else if (a.length === 9) {
            if (a.substring(0) === "0") {
                console.log("number format incorrect: ERR1");
                return "";
            } else {
                console.log("+233" + a);
                return "+233" + a;
            }
        } else {
            console.log("invalid phone number length: ERR2");
            return "";
        }
    };

    let phone = formatPhoneNumber_GhanaFormat(userInfo.phone_number);
    console.log("user details: ", userInfo);
    console.log("phone: ", phone);
    console.log("phone_all: ", userInfo.phone_number);

    UserPool.signUp(
        userInfo.email.trim().toLowerCase(),
        userInfo.password.trim(),
        [
            new CognitoUserAttribute({ Name: "email", Value: userInfo.email.trim() }),
            new CognitoUserAttribute({
                Name: "phone_number",
                Value: formatPhoneNumber_GhanaFormat(userInfo.phone_number.trim())
            }),
            new CognitoUserAttribute({
                Name: "name",
                Value: userInfo.first_name.trim()
            }),
            new CognitoUserAttribute({
                Name: "family_name",
                Value: userInfo.last_name.trim()
            })
        ],
        [],
        (err: any, data: any) => {
            if (err) {
                console.log(err);
                dispatch({
                    type: USER_REGISTER_FAIL,
                    payload: err.message
                });
            } else {
                console.log(data);
                dispatch({
                    type: USER_REGISTER_SUCCESS,
                    payload: data
                });

                console.log("createUser details:", {
                    id: data.userSub,
                    email: userInfo.email.trim(),
                    phone: phone,
                    userInfoObject: userInfo
                });
                try {
                    // @ts-ignore: Unreachable code error
                    client_
                        .mutate({
                            mutation: CreateUser,
                            variables: {
                                id: data.userSub,
                                firstname: userInfo.first_name.trim(),
                                lastname: userInfo.last_name.trim(),
                                email: userInfo.email.trim(),
                                phone: { mobile: phone.trim() }
                            }
                        })
                        .then((res: any) => console.log(res));
                } catch (err) {
                    console.log("user not created,: ", err);
                }
            }
        }
    );
};

export const resendConfirmationCode = userInfo => dispatch => {
    dispatch({
        type: CONFIRM_RESEND_REQUEST
    });

    console.log(userInfo);
    const userData = {
        Username: userInfo.email.trim(),
        Pool: UserPool,
        UserPool,
        Storage: new CookieStorage({ domain: Domain(), secure: false })
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
            console.log(err);
            dispatch({
                type: CONFIRM_RESEND_FAIL,
                payload: err.message
            });
            return;
        }
        console.log("resend code: ", result);
        dispatch({
            type: CONFIRM_RESEND_SUCCESS,
            payload: result
        });
    });
};

export const confirmRegisteredUser = userInfo => dispatch => {
    dispatch({
        type: USER_CONFIRM_REQUEST
    });

    console.log("confirm user");
    const userData = {
        Username: userInfo.email.trim(),
        Pool: UserPool,
        UserPool,
        Storage: new CookieStorage({ domain: Domain(), secure: false })
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser?.confirmRegistration(userInfo.confirmationCode, true, (err: any, data: any) => {
        if (err) {
            console.log(err);
            dispatch({
                type: USER_CONFIRM_FAIL,
                payload: err.message
            });
        }
        if (data === "SUCCESS") {
            dispatch({
                type: USER_CONFIRM_SUCCESS,
                payload: "Account confirmed"
            });
        }
        console.log("data: ", data);
        console.log("userInfo: ", userInfo);
    });
};

export const changeUserPassword = userInfo => dispatch => {
    const cognitoUser = UserPool.getCurrentUser();

    dispatch({
        type: USER_CHANGE_PASSWORD_REQUEST
    });

    if (cognitoUser) {
        cognitoUser.getSession(function (err, session) {
            if (err) {
                console.log(err);
            }
            if (session) {
                cognitoUser.changePassword(userInfo.oldPassword, userInfo.newPassword, function (err, result) {
                    if (err) {
                        console.log(err);
                        console.log(err.message);
                        dispatch({
                            type: USER_CHANGE_PASSWORD_FAIL,
                            payload: err.message
                        });
                    } else {
                        console.log("change password result: ", result);
                        dispatch({
                            type: USER_CHANGE_PASSWORD_SUCCESS,
                            payload: result
                        });
                    }
                });
            }
        });
    }
};

export const resetPassword = info => dispatch => {
    console.log(info);
    const user = new CognitoUser({
        Username: info.resetEmail,
        Pool: UserPool
    });

    dispatch({
        type: USER_PASSWORD_RESET_REQUEST
    });

    dispatch({
        type: USER_PASSWORD_RESET_PROFILE_REQUEST
    });

    user.confirmPassword(info.passwordResetCode, info.newPassword, {
        onSuccess: () => {
            console.log("");
            if (info.page === "modal")
                dispatch({
                    type: USER_PASSWORD_RESET_SUCCESS,
                    payload: "success"
                });

            if (info.page === "profile")
                dispatch({
                    type: USER_PASSWORD_RESET_PROFILE_SUCCESS,
                    payload: "success"
                });
        },
        onFailure: err => {
            console.log(err);
            if (info.page === "modal")
                dispatch({
                    type: USER_PASSWORD_RESET_FAIL,
                    payload: err.message || JSON.stringify(err)
                });

            if (info.page === "profile")
                dispatch({
                    type: USER_PASSWORD_RESET_PROFILE_FAIL,
                    payload: err.message || JSON.stringify(err)
                });
        }
    });
};

export const forgotPasswordRequest = email => dispatch => {
    console.log(email);
    const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
        Storage: new CookieStorage({ domain: Domain(), secure: false })
    });

    dispatch({
        type: USER_PASSWORD_RESET_CODE_REQUEST
    });

    if (email === "") {
        console.log("1");
        throw new Error("email is required");
    } else {
        user.forgotPassword({
            onSuccess: data => {
                console.log("onSuccess:", data);

                dispatch({
                    type: USER_PASSWORD_RESET_CODE_SUCCESS,
                    payload: data
                });
            },

            onFailure: err => {
                console.log("onFailure:", err);

                dispatch({
                    type: USER_PASSWORD_RESET_CODE_FAIL,
                    payload: err.message || JSON.stringify(err)
                });
            },

            inputVerificationCode: data => {
                console.log("input code: ", data);
                let verificationCode;
                let newPassword;

                // user.confirmPassword(verificationCode, newPassword, {
                //   onSuccess: () => {
                //     console.log('success')
                //   },
                //   onFailure: (err) => {
                //     console.log(err)
                //   }
                // })

                dispatch({
                    type: USER_PASSWORD_RESET_CODE_SUCCESS,
                    payload: data
                });
            }
        });
    }
};

export const updateUser = userInfo => dispatch => {
    try {
        // @ts-ignore: Unreachable code error
        client_
            .mutate({
                mutation: CreateUser,
                variables: {
                    id: userInfo.id,
                    firstname: userInfo.first_name.trim(),
                    lastname: userInfo.last_name.trim(),
                    email: userInfo.email.trim(),
                    phone: { mobile: userInfo.phone.trim() },
                    status: userInfo.status
                }
            })
            .then((res: any) => console.log(res));
    } catch (err) {
        console.log("user not updated,: ", err);
    }
};

export const deleteUser = userInfo => dispatch => {
    try {
        // @ts-ignore: Unreachable code error
        client_.mutate({
            mutation: CreateUser,
            variables: {
                id: userInfo.id
            }
        });
    } catch (err) {
        console.log("user not deleted,: ", err);
    }
};

export const openLoginModalOnClick = state => dispatch => {
    dispatch({
        type: USER_OPEN_LOGIN_MODAL,
        payload: state
    });
};

export const openForgotPasswordModalOnClick = state => dispatch => {
    dispatch({
        type: USER_OPEN_FORGOT_PASSWORD_MODAL,
        payload: state
    });
};

export const loginUser = userInfo => async dispatch => {
    console.log(userInfo.email);
    dispatch({
        type: USER_LOGIN_REQUEST
    });

    const user = new CognitoUser({
        Username: userInfo.email.trim(),
        Pool: UserPool,
        Storage: new CookieStorage({ domain: Domain(), secure: false })
    });

    const authDetails = new AuthenticationDetails({
        Username: userInfo.email.trim().toLowerCase(),
        Password: userInfo.password
    });

    user.authenticateUser(authDetails, {
        onSuccess: (data: any) => {
            dispatch({
                type: USER_LOGIN_SUCCESS
            });
            //dispatch(getUserProfile(userInfo.email));
            let accessToken = data.getAccessToken().getJwtToken();
            AWS.config.region = "us-west-2";

            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: "us-west-2:40708dc0-1d52-4dc5-9f6a-bade84597a44", // your identity pool id here
                Logins: {
                    // Change the key below according to the specific region your user pool is in.
                    "cognito-idp.us-west-2.amazonaws.com/us-west-2_MksRVgimN": data.getIdToken().getJwtToken()
                }
            });

            // (< AWS.CognitoIdentityCredentials > AWS.config.credentials).refresh((error) => {

            //   if (error) {
            //     console.error("refresh error: ", error);
            //   } else {
            //     console.log('Successfully logged!');
            //   }
            // });

            console.log("login data: ", data);
            dispatch(fetchMessages(data.accessToken.jwtToken as any, data.accessToken.payload.username as any));
            dispatch(getUserProfile(data.accessToken.payload.username as any));
            setTimeout(() => {
                dispatch({
                    type: USER_LOGIN_RESET
                });
            }, 3000);
        },

        onFailure: err => {
            console.error("onFailure:", err);
            if (err.code === "UserNotConfirmedException") {
                dispatch({
                    type: USER_LOGIN_FAIL,
                    payload: "UserNotConfirmedException"
                });
            } else {
                dispatch({
                    type: USER_LOGIN_FAIL,
                    payload: err.message
                });
            }
        },

        newPasswordRequired: data => {
            console.log("newPasswordRequired:", data);
        }
    });
};

let count = 0;

export const getUserProfile = userId => dispatch => {
    console.log(userId);
    dispatch({
        type: USER_PROFILE_REQUEST
    });

    try {
        client_
            .query({
                query: GetUser(),
                variables: { id: userId },
                errorPolicy: "all"
            })
            .then((user_returned: any) => {
                if (user_returned && user_returned.data && user_returned.data.getUser) {
                    if (!user_returned.data.getUser.properties && count < 6) {
                        dispatch(getUserProfile(userId));
                        count = count + 1;
                        console.log(count, "counting");
                    } else {
                        console.log(user_returned);
                        dispatch({
                            type: USER_PROFILE_SUCCESS,
                            payload: user_returned.data.getUser
                        });
                    }
                } else {
                    dispatch({
                        type: USER_PROFILE_FAIL,
                        payload: "user not found"
                    });
                    console.log(user_returned);
                }
            });
    } catch (err) {
        dispatch({
            type: USER_PROFILE_FAIL,
            payload: "user not found"
        });
        console.log(err);
    }
};

export const updateUserProfile = userInfo => dispatch => {
    console.log(userInfo);
    let userInfoUpdate = { ...userInfo };
    delete userInfoUpdate["id"];
    delete userInfoUpdate["phone"];

    console.log(userInfoUpdate);

    dispatch({
        type: USER_PROFILE_UPDATE_REQUEST
    });

    try {
        // @ts-ignore: Unreachable code error
        client_
            .mutate({
                mutation: UpdateUser,
                variables: userInfoUpdate,
                errorPolicy: "all"
            })
            .then(res => {
                console.log("update user: ", res);
                let info1 = res.data;
                let info2 = res?.data?.updateUser;
                if (info1 !== null && info2 !== null) {
                    dispatch({
                        type: USER_PROFILE_UPDATE_SUCCESS,
                        payload: info2[0]
                    });
                } else {
                    console.log("test1");
                    // @ts-ignore: Unreachable code error
                    client_
                        .mutate({
                            mutation: CreateUser,
                            variables: userInfo
                        })
                        .then((res: any) => console.log(res));

                    dispatch({
                        type: USER_PROFILE_UPDATE_FAIL,
                        payload: "user profile update failed"
                    });
                }
            });
    } catch (err) {
        dispatch({
            type: USER_PROFILE_UPDATE_FAIL,
            payload: "user profile update failed"
        });
    }
};

export const getCurrentHostname = () => dispatch => {
    let hostname = window.location.hostname;
    if (hostname === "app.aqivah.com" || hostname === "www.app.aqivah.com") {
        dispatch({
            type: USER_DOMAIN,
            payload: "app"
        });
    }

    if (hostname === "aqivah.com" || hostname === "www.aqivah.com") {
        dispatch({
            type: USER_DOMAIN,
            payload: "search"
        });
    }

    if (hostname === "about.aqivah.com" || hostname === "www.about.aqivah.com") {
        dispatch({
            type: USER_DOMAIN,
            payload: "about"
        });
    }
};

export const setCurrentHostname = value => dispatch => {
    dispatch({
        type: USER_DOMAIN,
        payload: value
    });
};

export const sendMessage = (info, token) => async (dispatch, getState) => {
    console.log("jwt: ", token);

    const {
        userMessages: { userList }
    } = getState();
    let users = [...userList];
    try {
        dispatch({
            type: USER_SEND_MESSAGE_REQUEST
        });

        console.log("sending message with details: ", info);

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            params: {
                email: info.from
            }
        };

        if (info.to && info.from && info.message !== "") {
            const { data } = await axios.post(`${api_url}/api/messages/send`, info, config);

            console.log(data);

            if (!users.includes(info.to)) {
                users.unshift(info.to);
            } else {
                users.splice(users.indexOf(info.to), 1);
                users.unshift(info.to);
            }

            dispatch({
                type: USER_SEND_MESSAGE_SUCCESS,
                payload: { message: data, userList: users }
            });
        }
    } catch (error) {
        dispatch({
            type: USER_SEND_MESSAGE_FAIL,
            payload: { message: { ...info, error: true, createdAt: Date.now(), _id: Date.now() }, userList: users }
        });
    }
};

export const sendMessageFail = info => (dispatch, getState) => {
    const {
        userMessages: { userList }
    } = getState();
    let users = [...userList];

    if (info.to && info.from && info.message !== "") {
        if (!users.includes(info.to)) {
            users.unshift(info.to);
        } else {
            users.splice(users.indexOf(info.to), 1);
            users.unshift(info.to);
        }

        dispatch({
            type: USER_SEND_MESSAGE_ERROR,
            payload: { message: info, userList: users }
        });
    }
};

export const getNewMessage = message => (dispatch, getState) => {
    const {
        userMessages: { userList }
    } = getState();

    let users = [...userList];
    // console.log('new message from SOCKETIO: ',message)
    if (!users.includes(message.from)) {
        users.unshift(message.from);
    } else {
        users.splice(users.indexOf(message.from), 1);
        users.unshift(message.from);
    }

    dispatch({
        type: USER_GET_NEW_MESSAGE_SUCCESS,
        payload: { message: message, userList: users }
    });
};

export const fetchMessages = (token, username) => async dispatch => {
    try {
        dispatch({
            type: USER_FETCH_MESSAGES_REQUEST
        });

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                email: username
            }
        };

        const { data } = await axios.get(`${api_url}/api/messages`, config);
        console.log(data);
        const users: any = [];

        let messages = data.messages.sort((a, b) => {
            // @ts-ignore: Unreachable code error
            return ((new Date(a.createdAt) as any) - new Date(b.createdAt)) as any;
        });

        for (let i = 0; i < messages.length; i++) {
            if (messages[i].from === username) {
                if (!users.includes(messages[i].to)) {
                    users.unshift(messages[i].to);
                } else {
                    users.splice(users.indexOf(messages[i].to), 1);
                    users.unshift(messages[i].to);
                }
            }

            if (messages[i].to === username) {
                if (!users.includes(messages[i].from)) {
                    users.unshift(messages[i].from);
                } else {
                    users.splice(users.indexOf(messages[i].from), 1);
                    users.unshift(messages[i].from);
                }
            }
        }

        let contacts = [...(new Set(users) as any)];

        console.log("Messages should be here:", data);
        console.log("userlist: ", contacts);

        dispatch({
            type: USER_FETCH_MESSAGES_SUCCESS,
            payload: { messages: messages, userList: contacts }
        });
    } catch (error) {
        console.log(error);
        dispatch({
            type: USER_FETCH_MESSAGES_FAIL,
            payload: error
        });
    }
};

export const authExpiryTime = () => dispatch => {
    dispatch({
        type: USER_AUTH_EXPIRY_TIME,
        payload: new Date()
    });
};

export const authTokenRefresh = () => dispatch => {
    dispatch({
        type: USER_AUTH_TOKEN_REFRESH,
        payload: "refresh token"
    });
};

export const getPropertyOwner = ownerId => dispatch => {
    dispatch({
        type: USER_OWNER_PROFILE_REQUEST
    });
    console.log(ownerId);
    try {
        client_
            .query({
                query: GetUser(),
                variables: { id: ownerId },
                errorPolicy: "all"
            })
            .then((user_returned: any) => {
                console.log(user_returned);
                if (user_returned && user_returned.data && user_returned.data.getUser) {
                    console.log(user_returned);
                    dispatch({
                        type: USER_OWNER_PROFILE_SUCCESS,
                        payload: user_returned.data.getUser
                    });
                } else {
                    dispatch({
                        type: USER_OWNER_PROFILE_FAIL,
                        payload: "owner not found"
                    });
                    console.log(user_returned);
                }
            });
    } catch (err) {
        dispatch({
            type: USER_OWNER_PROFILE_FAIL,
            payload: "owner not found"
        });
        console.log(err);
    }
};
