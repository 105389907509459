import { StyleSheet } from '../config/my-aphrodite';

const styles = StyleSheet.create({
    body: {
      margin: "0",
      padding: "0",
      alignItems: "center",
      overflowY: "auto",
      fontFamily: "sans-serif",
      color: "black",
      backgroundColor: "white",
      overflowX: "hidden",
      //background: "url(" + Town + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },

    headerSection: {
      marginBottom: "1em",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100vw",
      color: "#ab32e5",
      background: "white",
      border: "1px",
      borderStyle: "solid",
      borderColor: "rgba(0,0,0,.1 )",
      boxShadow: "0 5px 10px rgba(0,0,0,.2)",
      overflowX: "hidden",
    },

    box: {
      // position: 'relative',
      top: "90px",
      left: "50%",
      // transform: 'translate(-50%, -50%)',
      marginTop: "40px",
      width: "99vw",
      background: "white", //'hsl(0,0%,100%,0.6);',
      padding: "2px",
      boxSizing: "border-box",
      //overflow: 'auto',
      height: "auto", //new
      border: "1px solid rgba(0,0,0,.1 )",
      boxShadow: "0 3px 7px rgba(0,0,0,.2)",
      marginBottom: "2em",
      borderRadius: "9px 9px 9px 9px",
    },

    box_h2: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      margin: "0 0 40px",
      color: "#ab32e5",
    },

    box_input: {
      padding: "10px 0",
      marginBottom: "30px",
      width: "100%",
      fontSize: "12px",
      boxSizing: "border-box",
      boxShadow: "none",
      outline: "none",
      border: "none",
      borderBottom: "2px solid #999",
      ":focus": { borderBottom: "2px solid #ab32e5" },
      ":valid": { borderBottom: "2px solid #ab32e5" },
    },

    box_input_submit: {
      borderBottom: "none",
      cursor: "pointer",
      background: "#ab32e5",
      color: "#fff",
      marginBottom: "0",
      textTransform: "uppercase",
      borderRadius: "10px 10px 10px 10px",
      padding: "10px 0",
      width: "90%",
      fontSize: "12px",
      boxSizing: "border-box",
      boxShadow: "none",
      outline: "none",
      border: "none",
    },

    box_form_container: {
      width: "100%",
    },

    box_form_sub_first: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      width: "100%",
    },

    box_form_sub_last: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
      width: "100%",
    },

    box_form_div: {
      position: "relative",
    },

    box_form_div_password: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
      minWidth: "100%",
      marginTop: "",
    },

    box_form_label: {
      position: "absolute",
      top: "10px",
      left: "0",
      pointerEvents: "none",
      color: "#999",
      transition: ".5s",
    },

    form: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "auto", //new
      //overflowY: 'auto',
    },

    box_over_600: {
      "@media (min-width: 700px)": {
        // position: 'absolute',
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
        marginTop: "50px",
        width: "60vw",
        height: "auto",
        background: "white", //'hsl(0,0%,100%,0.9);',
        padding: "2px",
        boxSizing: "border-box",
        border: "1px solid rgba(0,0,0,.1 )",
        boxShadow: "0 3px 7px rgba(0,0,0,.2)",
        //overflowY: 'auto',
      },
    },

    body_over_600: {
      "@media (min-width: 700px)": {
        display: "flex",
        flexDirection: "colomn",
        justifyContent: "center",
        margin: "0",
        padding: "0",
        alignItems: "center",
        fontFamily: "sans-serif",
        color: "black",
        backgroundColor: "white",
        overflowX: "hidden",
        overflow: "auto",
      },
    },

    container: {
      "@media (min-width: 700px)": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
      },
    },

    box_form_container_600: {
      "@media (min-width: 700px)": {
        width: "90%",
        display: "flex",
        justifyContent: "center",
        overflowY: "auto",
        paddingTop: "13px",
      },
    },

    box_form_sub_mobile_first: {
      "@media (min-width: 700px)": {
        display: "flex",
        justifyContent: "flex-start",
        position: "relative",
        width: "100%",
      },
    },

    box_form_sub_mobile_last: {
      "@media (min-width: 700px)": {
        display: "flex",
        justifyContent: "flex-end",
        position: "relative",
        width: "100%",
      },
    },
  });

  export default styles