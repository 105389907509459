import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "antd/es/divider";
import numeral from "numeral";
import Modal from "antd/es/modal";
import { UpdatePropertyPage } from "../update-property/update-property";
import "./requests.scss";

interface IRequestCard {
    property: any;
}

export default function RequestCard({ property }: IRequestCard) {
    const [open, setOpen] = useState(false);

    const close = () => {
        setOpen(false);
    };

    const acquisition = {
        buy: "Purchase",
        rent: "Renting"
    };

    const currency = {
        USD: "$ ",
        GHC: (
            <>
                GH<i className="fa-solid fa-cent-sign me-1"></i>
            </>
        )
    };

    return (
        <Card id="property-card-m0" className="search-property-card-m0" sx={{ maxWidth: 355 }}>
            <Modal open={open} footer={null} onCancel={close} width={820} title={"Update Request"}>
                <UpdatePropertyPage id={property._id} />
            </Modal>
            <div className="acquisition-tag d-flex justify-content-center align-items-center">{acquisition[property.acquisition]}</div>
            <CardContent className="pt-5">
                <div className="d-flex mb-3">
                    <h6 className="fw-bold location-text">
                        {property.location_details?.town ? property.location_details.town : property.location_details}
                    </h6>
                </div>
                <div className="d-flex flex-column mb-3">
                    <h6>Price Range</h6>
                    <p className="fw-bold">
                        GHC{numeral(property.min_price || "").format("0,0.00")} - GHC{""}
                        {numeral(property.max_price || "").format("0,0.00")}
                    </p>
                </div>

                <div>
                    <p>{property.additional_info}</p>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-2">
                    {property.bedrooms && (
                        <span className="d-flex align-items-center" style={{ fontSize: "11px" }}>
                            <i style={{ color: "#cecece", fontSize: "15px" }} className="fa-solid fa-bed me-1"></i>
                            {property.bedrooms} Beds
                        </span>
                    )}
                    <Divider type="vertical" />
                    {property.bathrooms && (
                        <span className="d-flex align-items-center" style={{ fontSize: "11px" }}>
                            <i style={{ color: "#cecece", fontSize: "15px" }} className="fa-solid fa-shower me-1"></i>
                            {property.bathrooms} Baths
                        </span>
                    )}
                    <Divider type="vertical" />
                </div>

                <div className="d-flex fw-light listing-line">Listing by {property.requester.name} (Accra, Ghana)</div>
            </CardContent>
        </Card>
    );
}
