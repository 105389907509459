import React, { useEffect, useState } from 'react';
import {UserPool} from "../config/UserPool"
import { useDispatch , useSelector} from 'react-redux';
import { getUserProfile, fetchMessages, authExpiryTime, authTokenRefresh, getNewMessage } from '../Actions/userActions';
import {io} from 'socket.io-client'
import {socket} from "../config/socketioConfig"

function useReconnectOnInternetReconnection () {
    const dispatch = useDispatch()

    // @ts-ignore: Unreachable code error
    const FetchedMessages = useSelector(state => state.userMessages)
    const {messages: fetchedMessages} = FetchedMessages

    const [internetConnectionStatus, setInternetConnectionStatus] = useState<any>()

    // @@ Re-connect socket after an internet disconnection.
    useEffect(() => {
    window.addEventListener("online", updateInternetConnectionStatus)

    const cognitoUser = UserPool.getCurrentUser()

    if(internetConnectionStatus === true && cognitoUser ){
    // @ts-ignore: Unreachable code error
    cognitoUser.getSession(function (err: any, session: any) {
      if (err) {
        console.log(err.message);
      } else {
        console.log(session)
        setTimeout(()=> {
          //dispatch(getUser(session.accessToken.payload.sub))
          //dispatch(getUserProfile(session.accessToken.payload.sub))  
          fetchedMessages.lenghth > 0 && dispatch(fetchMessages(session.accessToken.jwtToken, session.accessToken.payload.username))
        }, 5000)
          // if(socket.connected){    
          // socket.emit("join", socket.id/*session.accessToken.payload.username*/)
          // socket.on('new-message', message => {
          //   dispatch(getNewMessage(message))
          // })
  
          // console.log('socket', socket)  
          // console.log('connection reestablished')
          // }
        }    
      }
    )
    }

  }, [internetConnectionStatus])

  const updateInternetConnectionStatus = () => {
    setInternetConnectionStatus(window.navigator.onLine)
  }

}

export default useReconnectOnInternetReconnection