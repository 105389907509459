import React, { useContext, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./update-property.scss";
import Backdrop from "@mui/material/Backdrop";
import { PropertyContext } from "../../../../context/property-context/property-context";
import { ResidentialForm } from "./forms/residential-form";
import { CommercialForm } from "./forms/commercial-form";
import { FarmlandForm } from "./forms/farmland-form";
import axios from "axios";
import "./forms/forms.scss";

export const UpdatePropertyPage = ({ id }) => {
    const { property, events, handleCategoryChange, PROPERTY_UPLOAD } = useContext(PropertyContext);

    const [fetchedProperty, setFetchedProperty] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    function getProperty() {
        setLoading(true);
        axios
            .get(`/properties/search/${id}`)
            .then((res: any) => {
                console.log(res.data);
                setFetchedProperty(res.data.property);
            })
            .then(() => setLoading(false));
    }

    useEffect(() => getProperty(), []);

    return (
        <div id="update-property-page">
            <Backdrop open={loading} style={{ zIndex: "5" }}>
                <span className="spinner-border text-white" role="status"></span>
            </Backdrop>

            {fetchedProperty && (
                <div className="container-xxm list-property-card">
                    <div className="d-flex flex-column justify-content-start align-items-center">
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12} lg={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} lg={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={property.category === "Residential"} />}
                                        name="Residential"
                                        onChange={handleCategoryChange}
                                        label="Residential"
                                    />
                                </Grid>

                                <Grid item xs={12} lg={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={property.category === "Commercial"} />}
                                        name="Commercial"
                                        onChange={handleCategoryChange}
                                        label="Commercial"
                                    />
                                </Grid>

                                <Grid item xs={12} lg={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={property.category === "Farmland"} />}
                                        name="Farmland"
                                        onChange={handleCategoryChange}
                                        label="Farmlands"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={property.category === "Events"} />}
                                        name="Events"
                                        onChange={handleCategoryChange}
                                        label="Party & Events"
                                    />
                                </Grid>
                            </Grid>
                        </Grid> */}

                            {fetchedProperty.category === "Residential" && (
                                <Grid className="d-flex justify-content-around align-items-center mb-5" item xs={12} lg={12}>
                                    <ResidentialForm property={fetchedProperty} />
                                </Grid>
                            )}
                            {fetchedProperty.category === "Commercial" && (
                                <Grid className="d-flex justify-content-around align-items-center mb-5" item xs={12} lg={12}>
                                    <CommercialForm property={fetchedProperty} />
                                </Grid>
                            )}
                            {fetchedProperty.category === "Farmland" && (
                                <Grid className="d-flex justify-content-around align-items-center mb-5" item xs={12} lg={12}>
                                    <FarmlandForm property={fetchedProperty} />
                                </Grid>
                            )}

                            <Grid item xs={12} lg={6}></Grid>
                            <Grid item xs={12} lg={6}></Grid>
                        </Grid>
                    </div>
                </div>
            )}
        </div>
    );
};
