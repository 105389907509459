import React, { useEffect } from "react";
import Typography from "antd/es/typography";
import Tree from "antd/es/tree";
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header";
import "../../CSS_Styling/Policy-Doc.css";

const { Title, Paragraph } = Typography;

const title = {
    width: "100%",
    fontSize: "27px",
    //fontFamily: "'Google Sans',sans-serif",
    color: "#000000",
    fontWeight: 700,
    paddingLeft: "0",
    textDecoration: "underline",
    marginBottom: "2vh"
};

const functUnCap = sentence => {
    let arr = sentence.split(" ");
    console.log(arr);
    arr.map(word => {});
};

const TermsOfService = () => {
    const params = useParams();

    const scrollToSection = (id: string) => {
        let scrollSection = document.getElementById(id);
        if (scrollSection !== null) {
            scrollSection.scrollIntoView({
                behavior: "smooth"
            });
        }
    };

    useEffect(() => {
        let section = params.id;
        console.log(params);
        let scrollSection = document.getElementById(`${section}`);

        setTimeout(() => {
            if (scrollSection !== null) {
                scrollSection.scrollIntoView({
                    behavior: "smooth"
                    // block: 'center',
                    // inline: 'nearest',
                });
            }
        }, 300);
    }, [params.id]);

    function unCapThat(text) {
        let newArr: any = text.split("");
        let uncappedArr: any = [];
        for (let letter = 0; letter < newArr.length; letter++) {
            if (letter == 0) {
                uncappedArr.push(newArr[0]);
            } else {
                uncappedArr.push(newArr[letter].toLowerCase());
            }
        }
        console.log(uncappedArr);
        console.log(uncappedArr.join(""));
        let new_str = uncappedArr.join("");
        let capAqivah = new_str.split(" ");
        const index = capAqivah.findIndex(value => value === "aqivah");
        capAqivah[index] = "Aqivah";
        let fin_str = capAqivah.join(" ");
        return fin_str;
    }

    const tableData = [
        {
            title: `1. ${unCapThat("SERVICE PROVIDER")}`,
            key: "service-provider"
        },
        {
            title: `2. ${unCapThat("CHILDREN UNDER EIGHTEEN")}`,
            key: "children"
        },
        {
            title: `3. ${unCapThat("OUR RELATIONSHIP WITH YOU")}`,
            key: "relationship"
        },
        {
            title: `4. ${unCapThat("WHAT WE EXPECT FROM YOU")}`,
            key: "expectations"
        },
        {
            title: `5. ${unCapThat("INTELLECTUAL PROPERTY")}`,
            key: "intellectual-property"
        },
        {
            title: `6. ${unCapThat("PERSONAL INFORMATION")}`,
            key: "personal-information",
            children: [
                {
                    title: "6.1. Registration, Passwords and Signatures ",
                    key: "registration"
                },
                {
                    title: "6.2. Your Account",
                    key: "your-account"
                }
            ]
        },
        {
            title: `7. ${unCapThat("GEOGRAPHIC LIMITATION")}`,
            key: "geographic-limitation"
        },
        {
            title: `8. ${unCapThat("FREE TRIALS")}`,
            key: "free-trials"
        },
        {
            title: `9. ${unCapThat("PAYMENT METHOD")}`,
            key: "payment-method",
            children: [
                {
                    title: "9.1 Financial Validation",
                    key: "financial-validation"
                }
            ]
        },
        {
            title: `10. ${unCapThat("THIRD-PARTY LINKS")}`,
            key: "third-party-links"
        },
        {
            title: `11. ${unCapThat("ERRORS, INACCURACIES AND OMISSIONS")}`,
            key: "errors-inaccuracies-omissions"
        },
        {
            title: `12. ${unCapThat("DISCLAIMER OF WARRANTIES AND LIABILITY")}`,
            key: "warranties-liability"
        },
        {
            title: `13. ${unCapThat("SEVERABILITY")}`,
            key: "severability"
        },
        {
            title: `14. ${unCapThat("TERMINATION OF ACCOUNT, SUSPENSION OR DISCONTINUATION OF THE SERVICE")}`,
            key: "termination"
        },
        {
            title: `15. ${unCapThat("ENTIRE AGREEMENT")}`,
            key: "entire-agreement"
        },
        {
            title: `16. ${unCapThat("GOVERNING LAW")}`,
            key: "governing-law"
        },
        {
            title: `17. ${unCapThat("FORCE MAJEURE")}`,
            key: "force-majeure"
        },
        {
            title: `18. ${unCapThat("ARBITRATION")}`,
            key: "arbitration"
        },
        {
            title: `19. ${unCapThat("LIMITED TIME TO BRING YOUR CLAIM")}`,
            key: "claim"
        },
        {
            title: `20. ${unCapThat("CHANGES TO TERMS")}`,
            key: "changes-to-terms"
        },
        {
            title: `20. ${unCapThat("CONTACT INFORMATION")}`,
            key: "contact-information"
        }
    ];

    const onSelect = value => {
        scrollToSection(value[0]);
    };

    return (
        <div>
            <div className="policy-body">
                <Header />

                <Title level={2} style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    Terms of Service{" "}
                </Title>

                <Title level={5}> Content </Title>
                <Tree defaultSelectedKeys={["0"]} onSelect={onSelect} treeData={tableData} style={{ marginBottom: "3em" }} />

                <div className="policy-section" id="service-provider">
                    <Title style={title} level={4}>
                        1. SERVICE PROVIDER{" "}
                    </Title>

                    <Paragraph>
                        <p>Aqivah services are provided by, and you’re contracting with:</p>
                        <strong>AQIVAH LTD</strong>
                        <p>organized under the laws of GHANA, and operating under the laws of the GHANA </p>
                        <p>8 Namoale Street </p>
                        <p>North Kaneshie, Accra</p>
                        <p>GHANA </p>
                        <p>GPS ...</p>
                    </Paragraph>
                </div>

                <div className="policy-section" id="children">
                    <Title style={title} level={4}>
                        2. CHILDREN UNDER EIGHTEEN
                    </Title>
                    <Paragraph>
                        The Company does not knowingly collect either online or offline, personal information from persons under the
                        age of 18. If you are under 18, please do not give us any Personal Information. If you’re a parent or guardian,
                        and you allow your child to use the service, then these terms apply to you, and you are responsible for your
                        child’s activity on the services. If you have reason to believe that a child under the age of 18 has provided
                        Personal Information to us, please contact us, and we will endeavour to delete that information from our
                        databases.
                    </Paragraph>
                </div>

                <div className="policy-section" id="relationship">
                    <Title style={title} level={4}>
                        3. OUR RELATIONSHIP WITH YOU{" "}
                    </Title>
                    <Paragraph>
                        Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms,
                        conditions, policies, and notices stated herein. By visiting, accessing, and using the Service, you accept and
                        agree to be bound, without modification, limitation, or qualification, by the Terms. These Terms apply to all
                        users, visitor and others who access or use the Service including without limitation to browsers, owners,
                        seekers, customers, merchants, and/ or contributors of content. Please read these Terms of Service carefully
                        before accessing or using our Services. By accessing or using any part of the site, you agree to be bound by
                        these Terms. If you do not agree to all the terms and conditions of this agreement, then you may not access the
                        website or use any services. If these Terms constitute an offer, acceptance is expressly limited to these
                        Terms. Any new Service which are added to the site shall also be subject to the Terms of Service. You can
                        review the most current version of the Terms of Service at any time on this page. We reserve the right to
                        update, change or replace any part of these Terms of Service by posting updates and/or changes to our website.
                        It is your responsibility to check this page periodically for changes. Your continued use of or access to the
                        website following the posting of any changes constitutes acceptance of those changes.
                    </Paragraph>
                </div>

                <div className="policy-section" id="expectations">
                    <Title style={title} level={4}>
                        4. WHAT WE EXPECT FROM YOU.
                    </Title>

                    <Paragraph>
                        You are granted a non-exclusive, non-transferable, revocable license to access and use our Services strictly in
                        accordance with these terms of service. As a condition of your use of the Service, you warrant to Aqivah that
                        you will not use the Service for any purpose that is unlawful or prohibited by these Terms. You may not use the
                        Service in any manner which could damage, disable, overburden, or impair the Service or interfere with any
                        other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or
                        information through any means not intentionally made available or provided for through the Site. You may not
                        access or use the service for any purposes other than that for which we make the service available. The site
                        may not be used in connection with any commercial endeavours except those that are specifically endorsed or
                        approved by use. As a user of the service, you agree not to;
                    </Paragraph>

                    <Paragraph>
                        <div>
                            1. Systematically retrieve data or other content from the site to create or compile, directly or
                            indirectly, a collection, compilation, database, or directory without written permission from us.
                        </div>
                        <div>
                            2. Make any unauthorized use of the site, including collecting usernames and/or email of users by
                            electronic or other means for the purpose of sending unsolicited email, or creating user accounts by
                            automated means or under false pretences.
                        </div>
                        <div>
                            3. Circumvent, disable, or otherwise interfere with security-related features of the site, including
                            features that prevent or restrict the use of copying of any content or enforce limitations on the use of
                            the site and/or the content contained therein.
                        </div>
                        <div>4. Engage in unauthorized framing of or linking to the site.</div>
                        <div>
                            5. Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account
                            information such as user passwords.
                        </div>
                        <div>6. Make improper use of our support services or submit false reports of abuse or misconduct.</div>
                        <div>
                            7. Engage in any automated use of the system, such as using scripts to send comments or messages, or using
                            any data mining, robots, or similar data gathering and extraction tools.
                        </div>
                        <div>
                            8. Interfere with, disrupt, or create an undue burden on the site or the networks or services connected to
                            the site.
                        </div>
                        <div>9. Attempt to impersonate another user or person or use the username of another user.</div>
                        <div>10. Use any information obtained from the site to harass, abuse, or harm another person.</div>
                        <div>
                            11. Use the site as part of any effort to compete with us or otherwise with the site and/or the content for
                            any revenue-generating endeavour or commercial enterprise.
                        </div>
                        <div>
                            12. Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way
                            making up a part of the site.
                        </div>
                        <div>
                            13. Attempt to bypass any measures of the site designed to prevent or restrict access to the site, or any
                            portion of the site.
                        </div>
                        <div>
                            14. Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion
                            of the site to you.
                        </div>
                        <div>15. Delete the copyright or other proprietary rights notice from any content.</div>
                        <div>
                            16. Copy or adapt the site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other
                            code.
                        </div>
                        <div>
                            17. Upload or transmit (or attempt to transmit) viruses, Trojan horses, or other material, including
                            excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with
                            any party’s uninterrupted use and enjoyment of the site or modifies, impairs, disrupts, alters, or
                            interferes with the use, features, functions, operation, or maintenance of the site.
                        </div>
                        <div>
                            18. Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active
                            information collection or transmission mechanism, including without limitation, clear graphics interchange
                            formats (“gifs”), 1x1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as
                            “spyware” or “passive collection mechanisms” or “pcms”).
                        </div>
                        <div>19. Disparage, tarnish, or otherwise harm, in our opinion, us and/or the site.</div>
                        <div>
                            20. Except as may be the result of standard search engine or internet browser usage, use, launch, develop,
                            or distribute any automated system, including without limitation, any spider, robot, cheat utility,
                            scraper, or offline reader that accesses the site, or using or launching any unauthorized script or other
                            software.
                        </div>
                    </Paragraph>

                    <Paragraph>
                        We reserve the right to terminate your use of the Service or any related website for violating any of the
                        prohibited uses.
                    </Paragraph>
                </div>

                <div className="policy-section" id="intellectual-property">
                    <Title style={title} level={4}>
                        {" "}
                        5. INTELLECTUAL PROPERTY
                    </Title>
                    <Paragraph>
                        All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation
                        thereof, and any software used on the Site, is the property of AQIVAH or its suppliers and protected by
                        copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and
                        abide by all copyright and other proprietary notices, legends or other restrictions contained in any such
                        content and will not make any changes thereto.
                    </Paragraph>
                    <Paragraph>
                        You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create
                        derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. AQIVAH
                        content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any
                        protected content, and in particular you will not delete or alter any proprietary rights or attribution notices
                        in any content. You will use protected content solely for your personal use and will make no other use of the
                        content without the express written permission of AQIVAH and the copyright owner. You agree that you do not
                        acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to
                        the intellectual property of AQIVAH or our licensors except as expressly authorized by these Terms.
                    </Paragraph>
                </div>

                <Title style={title} level={4}>
                    USING OUR SERVICES
                </Title>

                <div className="policy-section" id="personal-information">
                    <Title style={title} level={4}>
                        6. PERSONAL INFORMATION
                    </Title>
                    <Paragraph>
                        Your submission of personal information through the site is governed by our Privacy Policy, please read the
                        Privacy Policy as available on the Site. The Privacy Policy is incorporated by reference and made part of these
                        Terms. Thus, by agreeing to these Terms, you agree that your presence on the Site and use of the Services are
                        governed by the Company's Privacy Policy in effect at the time of your use. We may use our registered
                        customer's email address and other personally identifiable information to send event updates and marketing
                        communications. To opt-out from such messages, please notify us the same at ............................ We may
                        continue to use your email address to notify you of any critical administrative or operational updates.
                    </Paragraph>

                    <Title id="registration" level={5}>
                        Registration, Passwords and Signatures
                    </Title>
                    <Paragraph>
                        {" "}
                        Some of our services require that you create an account and in consideration of your use of the Services, you
                        agree to: (a) provide true, accurate, current and complete information about yourself as prompted by any
                        registration form that you may fill out on any Web Site (such information being the ("PERSONAL INFORMATION ")
                        and (b) maintain and promptly update the PERSONAL INFORMATION to keep it true, accurate, current and complete.
                        If you provide any PERSONAL INFORMATION that is untrue, inaccurate, not current or incomplete, or the Company
                        has grounds to suspect that such PERSONAL INFORMATION is untrue, inaccurate, not current or incomplete, the
                        Company has the right to suspend or terminate your account and refuse any and all current or future use of the
                        Services (or any portion thereof).
                    </Paragraph>

                    <Title level={5} id="your-account">
                        Your Account{" "}
                    </Title>
                    <Paragraph>
                        If you use our services, you are responsible for maintaining the confidentiality of your account and password
                        and for restricting access to your computer, and you agree to accept responsibility for all activities that
                        occur under your account or password. You may not assign under account or password. You may not assign or
                        otherwise transfer your account to any other person or entity. You acknowledge that the Company is not
                        responsible for third party access to your account that results from theft or misappropriations of your
                        account. You agree to notify the Company immediately of any unauthorized use of your account or any other
                        breach of security. The Company will not be liable for any loss that you may incur as a result of someone else
                        using your password or account, however, you could be held liable for losses incurred by the Company or another
                        party due to someone else using your account or password. If messages sent to an email address provided by you
                        and associated with your account are returned as undeliverable or wrong address; Company reserves the right to
                        terminate your account immediately with or without notice to you and without any liability to you or any third
                        party.
                    </Paragraph>
                    <Paragraph>
                        If the Company reasonably believes that an account and password is being used / misused in any manner, the
                        Company shall reserve the right to cancel access rights immediately without notice, and block access to all
                        users from that IP address.
                    </Paragraph>
                    <Paragraph>
                        Company reserves the right to reject any username selected by you and/or revoke your right to any previously
                        selected username and give such username to any other person or entity in Company's sole discretion and without
                        any liability to you.
                    </Paragraph>
                </div>

                <div className="policy-section" id="geographic-limitation">
                    <Title style={title} level={4}>
                        7. GEOGRAPHIC LIMITATION
                    </Title>

                    <Paragraph>
                        The Site and/or the Services are controlled and offered by Company from its facilities in the territory of
                        Ghana. The Company makes no representations that the Site or Services are appropriate or available for use in
                        other locations. If you are accessing or using the Site or Services from other jurisdictions, you do so at your
                        own risk and you are responsible for compliance with local law.
                    </Paragraph>
                </div>

                <div className="policy-section" id="free-trials">
                    <Title style={title} level={4}>
                        8. FREE TRIALS
                    </Title>
                    <Paragraph>
                        You may be offered a free trial period at the start of your membership of the Services, solely at the
                        discretion of the Company. The duration and the terms of the free trial period shall be at the discretion of
                        the Company and shall be specified during sign up. Free trials may not be combined with any other offer(s).
                        Free trials will not be given in cases of renewal of membership or activation of another account from a
                        physical address and/or email address and/or device which is linked to an already existing account, etc.
                        Billing may automatically commence at the end the free trial period, without prior notice, as per the
                        subscription plan chosen by you, unless you cancel your membership prior to the expiry of the free trial
                        period. It is hence recommended that you keep track of your account, including the date when the free trial
                        period ends.
                    </Paragraph>
                    <Paragraph>
                        Once billing commences, we will continue to bill your Payment Method for your membership fee until you cancel.
                        You may cancel your membership at any time; however, there are no refunds or credits for partially used
                        periods.
                    </Paragraph>
                </div>

                <div className="policy-section" id="payment-method">
                    <Title style={title} level={4}>
                        9. PAYMENT METHOD
                    </Title>
                    <Paragraph>
                        {" "}
                        We may provide paid products and/or services within the Service. In that case, we use third-party services for
                        payment processing (e.g. payment processors).
                    </Paragraph>
                    <Paragraph>
                        We will not store or collect your payment card details. That information is provided directly to our
                        third-party payment processors whose use of your personal information is governed by their Privacy Policy.
                        These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards
                        Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. PCI-DSS
                        requirements help ensure the secure handling of payment information.
                    </Paragraph>
                    <Paragraph>The payment processors we work with are:</Paragraph>

                    <Title level={5} id="financial-validation">
                        Financial Validation{" "}
                    </Title>
                    <Paragraph>
                        Aqivah uses Plaid Inc. (“Plaid”) to gather End User’s data from financial institutions. By using our service,
                        you grant Darwin Homes and Plaid the right, power, and authority to act on your behalf to access and transmit
                        your personal and financial information from the relevant financial institution. You agree to your personal and
                        financial information being transferred, stored, and processed by Plaid in accordance with the Plaid Privacy
                        Policy
                    </Paragraph>
                </div>

                <div className="policy-section" id="third-party-links">
                    <Title style={title} level={4}>
                        10. THIRD-PARTY LINKS
                    </Title>
                    <Paragraph>
                        {" "}
                        Certain content, products and services available via our Service may include materials from third parties.
                        Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are
                        not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any
                        liability or responsibility for any third-party materials or websites, or for any other materials, products, or
                        services of third parties.
                    </Paragraph>
                    <Paragraph>
                        We are not liable for any harm or damages related in connection with any third-party websites. Please review
                        carefully the third-party's policies and practices and make sure you understand them before you engage in any
                        transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to
                        the third-party.
                    </Paragraph>
                </div>

                <div className="policy-section" id="errors-inaccuracies-omissions">
                    <Title style={title} level={4}>
                        11. ERRORS, INACCURACIES AND OMISSIONS
                    </Title>
                    <Paragraph>Aqivah does not collect information from Children. </Paragraph>
                    <Paragraph>
                        Occasionally there may be information on our site or in the Service that contains typographical errors,
                        inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product
                        shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies, or
                        omissions, and to change or update information or cancel orders if any information in the Service or on any
                        related website is inaccurate at any time without prior notice (including after you have submitted your order).
                    </Paragraph>
                    <Paragraph>
                        We undertake no obligation to update, amend or clarify information in the Service or on any related website,
                        including without limitation, pricing information, except as required by law. No specified update or refresh
                        date applied in the Service or on any related website, should be taken to indicate that all information in the
                        Service or on any related website has been modified or updated.
                    </Paragraph>
                </div>

                <div className="policy-section" id="warranties-liability">
                    <Title style={title} level={4}>
                        12. DISCLAIMER OF WARRANTIES AND LIABILITY
                    </Title>
                    <Paragraph>
                        You understand and agree that the Company provides the Services on 'as-is' 'with all faults' and 'as available'
                        basis. You agree that use of the Site or the Services is at your risk. All warranties including without
                        limitation, the implied warranties of merchantability, fitness for a particular purpose, for the title and
                        non-infringement are disclaimed and excluded. No representations, warranties or guarantees whatsoever are made
                        by the Company whether express or implied and expressly disclaim any and all representations and warranties as
                        to the (a) accuracy, adequacy, reliability, completeness, suitability or applicability of the information, the
                        content, data, products and/or services, merchantability or any warranty for fitness for a particular purpose;
                        (b) that the service will be uninterrupted, timely, secure, or error-free or that all deficiencies, errors,
                        defects or non-conformities will be corrected; (c) the quality of any services, content, information, data, or
                        other material on the website will meet your expectations or requirements; (d) any errors in the site will be
                        corrected; (e) warranties against infringement of any third party intellectual property or proprietary rights;
                        or (f) other warranties relating to performance, non-performance, or other acts or omissions of the Company,
                        its officers, directors, employees, affiliates, agents, licensors, or suppliers etc. The Company does not
                        warrant that any of the software used and or licensed in connection with the Services will be compatible with
                        other third-party software or devices nor does it warrant that operation of the Services and the associated
                        software will not damage or disrupt other software or hardware. The Company, its affiliates, successors, and
                        assigns, and each of their respective investors, directors, officers, employees, agents, and suppliers
                        (including distributors and content licensors) shall not be liable, at any time for any, direct, indirect,
                        punitive, incidental, special, consequential, damages arising out of or in any way connected with the use of
                        Site or the Services, whether based in contract, tort, strict liability, or other theory, even if the Company
                        have been advised of the possibility of damages. In the event any exclusion contained herein be held to be
                        invalid for any reason and the Company or any of its affiliate entities, officers, directors or employees
                        become liable for loss or damage, then, any such liability of the Company or any of its affiliate entities,
                        officers, directors or employees shall be limited to not exceeding subscription charges paid by you in the
                        month preceding the date of your claim for the particular subscription in question chosen by you.
                    </Paragraph>
                    <Paragraph>
                        Under no circumstances will AQIVAH be held responsible or liable, in any way, for any content which is
                        derogatory, threatening, defamatory, obscene or offensive or offends public sensibilities or morals and shall
                        also not assume liability for any errors or omissions in any content, or for any loss or damage of any kind
                        incurred as a result of the use of any content posted or uploaded on the Site, or any infringement of another's
                        rights, including intellectual property rights. You specifically agree that AQIVAH is not responsible for any
                        content sent using and/or included in AQIVAH’s site/service by any third party.
                    </Paragraph>
                </div>

                <div className="policy-section" id="severability">
                    <Title style={title} level={4}>
                        13. SEVERABILITY
                    </Title>
                    <Paragraph>
                        If any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision
                        shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable
                        portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the
                        validity and enforceability of any other remaining provisions.
                    </Paragraph>
                </div>

                <div className="policy-section" id="termination">
                    <Title style={title} level={4}>
                        14. TERMINATION OF ACCOUNT, SUSPENSION OR DISCONTINUATION OF THE SERVICE
                    </Title>
                    <Paragraph>
                        The Company reserves the right to change, suspend, or discontinue temporarily or permanently, some or all of
                        the Services (including the Content and the devices through which the Services are accessed), with respect to
                        any or all users, at any time without notice. You acknowledge that the Company may do so in its sole
                        discretion. You also agree that the Company will not be liable to you for any modification, suspension, or
                        discontinuance of the Services, although if you are a paid subscriber and the Company suspends or discontinues
                        the Services, the Company may, in its sole discretion, provide you with a credit, refund, discount or other
                        form of consideration (for example, the Company may credit additional days of service to your account).
                        However, if the Company terminates your account or suspends or discontinues your access to the Services due to
                        your violation of these Terms, then you will not be eligible for any such credit, refund, discount or other
                        consideration.
                    </Paragraph>
                    <Paragraph>
                        Company may retain any information and associated records that are required to be retained or preserved under
                        applicable laws including post termination of your account and irrespective of whether such information or
                        content has been removed or access to it has been disabled or termination; and/or accordingly may deny you
                        access to our Services (or any part thereof).
                    </Paragraph>
                    <Paragraph>
                        The obligations and liabilities of the parties incurred prior to the termination date shall survive the
                        termination of this agreement for all purposes.
                    </Paragraph>
                    <Paragraph>
                        These Terms of Service are effective unless and until terminated by either you or us. You may terminate these
                        Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease
                        using our site.
                    </Paragraph>
                </div>

                <div className="policy-section" id="entire-agreement">
                    <Title style={title} level={4}>
                        15. ENTIRE AGREEMENT
                    </Title>
                    <Paragraph>
                        The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute
                        a waiver of such right or provision.
                    </Paragraph>
                    <Paragraph>
                        These Terms of Service and any policies or operating rules posted by us on this site or in respect to The
                        Service constitutes the entire agreement and understanding between you and us and govern your use of the
                        Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or
                        written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
                    </Paragraph>
                    <Paragraph>
                        Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting
                        party.
                    </Paragraph>
                </div>

                <div className="policy-section" id="governing-law">
                    <Title style={title} level={4}>
                        16. GOVERNING LAW
                    </Title>
                    <Paragraph>
                        These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and
                        construed in accordance with the laws of Ghana.
                    </Paragraph>
                </div>

                <div className="policy-section" id="force-majeure">
                    <Title style={title} level={4}>
                        17. FORCE MAJEURE
                    </Title>
                    <Paragraph>
                        Neither Party shall have any liability for any interruption or delay, to access the Site due to Force Majeure
                        Event. For the purposes of this clause, 'Force Majeure Event' means any event or circumstance or combination of
                        events and circumstances which is reasonably beyond the control of the party affected thereby and which causes
                        or results in default or delay in performance by such affected party of any of its obligations under this
                        agreement and includes an act of God, war, epidemics, lockdowns, hostilities, civil commotion, strikes,
                        lockouts and other industrial disputes.
                    </Paragraph>
                </div>

                <div className="policy-section" id="arbitration">
                    <Title style={title} level={4}>
                        18. ARBITRATION
                    </Title>
                    <Paragraph>
                        In the event the parties are not able to resolve any dispute between them arising out of or concerning these
                        Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for
                        damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant
                        to the Alternative Dispute Resolution Act of Ghana, conducted by a single neutral arbitrator and administered
                        by the American Arbitration Association, or a similar arbitration service selected by the parties, in a
                        location mutually agreed upon by the parties. The arbitrator's award shall be final, and judgment may be
                        entered upon it in any court having jurisdiction. In the event that any legal or equitable action, proceeding
                        or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to
                        recover its costs and reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in
                        regard to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether
                        directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The parties
                        agree that the Alternative Dispute Resolution Act of Ghana governs the interpretation and enforcement of this
                        provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be
                        determined by the Arbitrator. This arbitration provision shall survive the termination of these Terms and
                        Conditions.
                    </Paragraph>
                </div>

                <div className="policy-section" id="claim">
                    <Title style={title} level={4}>
                        19. LIMITED TIME TO BRING YOUR CLAIM
                    </Title>
                    <Paragraph>
                        You and the Company agree that any cause of action arising out of or related to use of the Site or the Services
                        must commence within one (1) year after the cause of action accrues otherwise, such cause of action will be
                        permanently barred.
                    </Paragraph>
                </div>

                <div className="policy-section" id="changes-to-terms">
                    <Title style={title} level={4}>
                        20. CHANGES TO TERMS
                    </Title>
                    <Paragraph>
                        You can review the most current version of the Terms of Service at any time at this page. AQIVAH reserves the
                        right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting
                        updates and changes to our website. It is your responsibility to check our website periodically for changes.
                        Your continued use of or access to our website or the Service following the posting of any changes to these
                        Terms of Service constitutes acceptance of those changes. The most current version of the Terms will supersede
                        all previous versions.
                    </Paragraph>
                </div>

                <div className="policy-section" id="contact-information">
                    <Title style={title} level={4}>
                        21. CONTACT INFORMATION
                    </Title>
                    <Paragraph>Questions about the Terms of Service should be sent to us at ......................</Paragraph>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default TermsOfService;
