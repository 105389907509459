import React, { useState, useEffect, useContext } from "react";
import { createStyles, makeStyles, Theme } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import { useDispatch } from "react-redux"; //delete
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { PropertyContext } from "../../context/property-context/property-context";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            // margin: theme.spacing(1),
            minWidth: "100%",
            maxWidth: "100%"
        },
        selectEmpty: {
            marginTop: theme.spacing(2)
        },
        buttons: {
            display: "flex",
            justifyContent: "flex-end"
        },
        button: {
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(1)
        }
    })
);

export default function LocationDetails() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<CountryType[]>([
        { name: "Lapaz" },
        { name: "Tema" },
        { name: "East Legon" },
        { name: "Adenta" },
        { name: "Cantonments" },
        { name: "Dansoman" }
    ]);
    const { status, locationDetails, handleLocationDetails, nextStep, previousStep } = useContext(PropertyContext);

    const loading = open && options.length === 0;

    const [hidden, setHidden] = useState<boolean>(false);
    const [region, setRegion] = useState<any>("");
    const [location, setLocation] = useState<any>("");
    const [city, setCity] = useState<any>("");

    useEffect(() => {
        locationDetails.region && setRegion(locationDetails.region);
        locationDetails.city && setCity(locationDetails.city);
        locationDetails.location && setLocation(locationDetails.location);
    }, [locationDetails]);

    function clearFields() {
        setRegion("");
        setLocation("");
        setCity("");
        setHidden(false);
    }

    useEffect(() => {
        if (status.success) {
            clearFields();
        }
    }, [status]);

    const handleRegion = e => {
        setRegion(e.target.value);
    };

    const handleCity = e => {
        setCity(e.target.value);
    };

    const handleNext = () => {
        nextStep();
    };

    const handleBack = () => {
        previousStep();
    };

    const handleHidden = () => {
        setHidden(!hidden);
    };

    const handleSubmit = e => {
        e.preventDefault();
        handleLocationDetails({
            region: region,
            location: location,
            city: city
        });
        handleNext();
    };

    const handleLocationFieldChange = (e, values) => {
        //setOptions([{ name: e.target.value }, ...options]);
        setLocation(values.name);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Typography variant="h6" gutterBottom>
                Location details
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        id="asynchronous-demo"
                        style={{ width: "100%" }}
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        getOptionSelected={(option, value) => option.name === value.name}
                        getOptionLabel={option => option.name}
                        options={options}
                        loading={loading}
                        onChange={handleLocationFieldChange}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Location"
                                fullWidth
                                // variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="city"
                        name="city"
                        label="City"
                        fullWidth
                        autoComplete="shipping address-level2"
                        value={city}
                        onChange={handleCity}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Region</InputLabel>
                        <Select value={region} onChange={handleRegion} fullWidth label="Property type">
                            <MenuItem value={"g_accra"}>Greater Accra</MenuItem>
                            <MenuItem value={"ashanti"}>Ashanti</MenuItem>
                            <MenuItem value={"central"}>Central</MenuItem>
                            <MenuItem value={"western"}>Western</MenuItem>
                            <MenuItem value={"eastern"}>Eastern</MenuItem>
                            <MenuItem value={"volta"}>Volta</MenuItem>
                            <MenuItem value={"brong_ahafo"}>Brong-Ahafo</MenuItem>
                            <MenuItem value={"northern"}>Northern</MenuItem>
                            <MenuItem value={"upper_east"}>Upper East</MenuItem>
                            <MenuItem value={"upper_west"}>Upper West</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="zip"
                        name="zip"
                        label="Ghana Post Code"
                        fullWidth
                        //autoComplete="shipping postal-code"
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox color="primary" onChange={handleHidden} checked={hidden} name="hideProperty" value="yes" />}
                        label="Hide property"
                    />
                </Grid>
            </Grid>
            <div className={classes.buttons}>
                <Button onClick={handleBack} className={classes.button}>
                    Back
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    // onClick={handleNext}
                    className={classes.button}
                    type={"submit"}>
                    {/* {activeStep === steps.length - 1 ? 'Place order' : 'Next'} */}
                    Next
                </Button>
            </div>
        </form>
    );
}

interface CountryType {
    name: string;
}
