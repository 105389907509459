import React from "react";
import aqivahLogo from "../../../../assets/images/AqIcPurpleNoText.png";
import "./header.scss";

export const OrganizationHeader = ({ img = aqivahLogo, name = "" }) => {
    return (
        <div id="organization-id">
            <img src={img} style={{ width: "30px", height: "auto" }} alt="logo" />
            <span>{name}</span>
        </div>
    );
};
