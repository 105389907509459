import React, { useContext, useState, useEffect } from "react";
import Table from "antd/es/table";
import Button from "antd/es/button";
import axios from "axios";
import { useParams } from "react-router-dom";
import { PopupProviderContext } from "../../../../common/popup-alerts-provider/popup-context";
import { logError } from "../../../../utils/logError";

export const Users = () => {
    const [open, setOpen] = useState(false);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const { flashError } = useContext(PopupProviderContext);

    function fetchOrganizationUsers() {
        setLoading(true);
        axios
            .get(`/organization/fetch-users/${id}`)
            .then(res => setUsers(res.data.users))
            .catch((err: any) => {
                flashError("Could not load users", logError(err));
            })
            .then(() => setLoading(false));
    }

    useEffect(() => fetchOrganizationUsers, []);

    return (
        <div id="org-users">
            <div className="container-sm">
                <header className="d-flex">
                    <h3 className="mb-4">Users</h3>

                    <div className="flex-1-1"></div>

                    <Button disabled={loading} className="me-2" onClick={fetchOrganizationUsers}>
                        {loading ? (
                            <div className="spinner-border spinner-border-sm" role="status"></div>
                        ) : (
                            <i className="fa-solid fa-arrows-rotate me-2"></i>
                        )}{" "}
                        Refresh
                    </Button>
                    <Button type="primary" onClick={() => setOpen(true)}>
                        <i className="fa-solid fa-plus me-2 text-white"></i>
                        Add User
                    </Button>
                </header>

                <Table />
            </div>
        </div>
    );
};
