import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { SearchResults, CurrentUser, currentCategory } from "./Reducer/reducer";
import {
    registerUserReducer,
    confirmUserReducer,
    userLoginReducer,
    userProfileReducer,
    userProfileUpdateReducer,
    openLoginReducer,
    userCurrentHostnameReducer,
    userMessagesReducer,
    userAuthenticationExpiryTimeReducer,
    userAuthTokenRefreshReducer,
    userOwnerProfileReducer,
    newMessagesForScrollBar,
    userNewMessageNotificationReducer,
    userResetPasswordReducer,
    userResetPasswordForProfileReducer,
    userForgotPasswordReducer,
    openForgotPasswordReducer,
    userChangePasswordReducer
} from "./Reducer/userReducers";
import {
    propertyCreateReducer,
    propertyFetchOwnerReducer,
    propertyCreationStepsReducer,
    propertyCreateRequestReducer,
    getPropertyReducer
} from "./Reducer/propertyReducers";
import { sortResultsReducer } from "./Reducer/sortReducers";

const reducer = combineReducers({
    SearchResults: SearchResults,
    CurrentUser: CurrentUser,
    currentCategory: currentCategory,
    registerUser: registerUserReducer,
    confirmUser: confirmUserReducer,
    userLogin: userLoginReducer,
    userProfile: userProfileReducer,
    userProfileUpdate: userProfileUpdateReducer,
    openLogin: openLoginReducer,
    propertyCreate: propertyCreateReducer,
    propertyFetch: propertyFetchOwnerReducer,
    propertyCreationSteps: propertyCreationStepsReducer,
    propertyCreateRequest: propertyCreateRequestReducer,
    propertyGet: getPropertyReducer,
    sortResults: sortResultsReducer,
    userCurrentHostname: userCurrentHostnameReducer,
    userMessages: userMessagesReducer,
    newMessages: newMessagesForScrollBar,
    userNewMessageNotification: userNewMessageNotificationReducer,
    userAuthenticationExpiryTime: userAuthenticationExpiryTimeReducer,
    userAuthTokenRefresh: userAuthTokenRefreshReducer,
    userOwnerProfile: userOwnerProfileReducer,
    userForgotPassword: userForgotPasswordReducer,
    userResetPassword: userResetPasswordReducer,
    userResetPasswordForProfile: userResetPasswordForProfileReducer,
    userChangePassword: userChangePasswordReducer,
    openForgotPassword: openForgotPasswordReducer
});

const middleware: any = [thunkMiddleware];
const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
