import React, { useContext } from "react";
import Radio from "antd/es/radio";
import Checkbox from "antd/es/checkbox";
import { SearchContext } from "../../../context/search-context/search-context";

export const PropertyTypes = () => {
    const { propertyFilters, handleFilterChange, setPropertyFilters } = useContext(SearchContext);

    const propertyType = (e: any) => {
        if (e.target.checked) {
            setPropertyFilters({ ...propertyFilters, type: e.target.name });
        } else {
            setPropertyFilters({ ...propertyFilters, type: "" });
        }
    };

    const categoryOptions = [
        { label: "Residential", value: "residential" },
        { label: "Commercial", value: "commercial" },
        { label: "Farms", value: "farmland" },
        { label: "Party & Events", value: "events" }
    ];

    const residentialTypes = [
        { name: "House", value: "house" },
        { name: "Townhouse", value: "townhouse" },
        { name: "Condo", value: "condo" },
        { name: "Apartment", value: "apartment" }
    ];

    const commercialTypes = [
        { name: "Office", value: "office" },
        { name: "Retail Shop", value: "retail_shop" }
    ];

    const farmlandTypes = [
        { name: "Crop farm", value: "crop_farm" },
        { name: "Fish farm", value: "fish_farm" },
        { name: "Livestock farm", value: "livestock_farm" }
    ];

    const eventVenueTypes = [
        { name: "Theatre", value: "theatre" },
        { name: "Cinema", value: "cinema" },
        { name: "Church", value: "Church" }
    ];

    return (
        <div className="d-flex flex-column justify-content-start align-items-center">
            <span className="filter-title mb-3">Building Type</span>
            <div className="d-flex flex-column justify-content-start align-items-start">
                <Radio.Group
                    style={{ fontSize: "8px", marginBottom: "15px" }}
                    options={categoryOptions}
                    name="category"
                    onChange={handleFilterChange}
                    value={propertyFilters?.category}
                    // optionType="button"
                />

                {propertyFilters?.category === "residential" &&
                    residentialTypes.map((property_type: any) => (
                        <Checkbox
                            name={property_type.value}
                            checked={propertyFilters?.type === property_type.value}
                            onChange={propertyType}>
                            {property_type.name}
                        </Checkbox>
                    ))}

                {propertyFilters?.category === "commercial" &&
                    commercialTypes.map((property_type: any) => (
                        <Checkbox
                            name={property_type.value}
                            checked={propertyFilters.type === property_type.value}
                            onChange={propertyType}>
                            {property_type.name}
                        </Checkbox>
                    ))}

                {propertyFilters?.category === "farmland" &&
                    farmlandTypes.map((property_type: any) => (
                        <Checkbox
                            name={property_type.value}
                            checked={propertyFilters?.type === property_type.value}
                            onChange={propertyType}>
                            {property_type.name}
                        </Checkbox>
                    ))}

                {propertyFilters?.category === "events" &&
                    eventVenueTypes.map((property_type: any) => (
                        <Checkbox
                            name={property_type.value}
                            checked={propertyFilters.type === property_type.value}
                            onChange={propertyType}>
                            {property_type.name}
                        </Checkbox>
                    ))}
            </div>
        </div>
    );
};
