import React, { useState, useContext } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
//import Link from '@mui/material/Link';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { makeStyles } from "@mui/styles";
import Link from "@mui/material/Link";
import aqivahLogo from "../../assets/images/AqIcPurpleText.png";
import Header from "../../components/Header";
import InputAdornment from "@mui/material/InputAdornment";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Spin from "antd/es/spin";
import axios from "axios";
import { logError } from "../../utils/logError";
import { useQueryParams } from "../../utils/use-query-params";
import { PopupProviderContext } from "../../common/popup-alerts-provider/popup-context";

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh"
    },
    image: {
        //backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundImage:
            "url(https://images.unsplash.com/photo-1430285561322-7808604715df?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80)",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center"
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        margin: theme.spacing(1),
        //backgroundColor: theme.palette.secondary.main
        backgroundColor: "tranparent"
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export default function AcceptOrganizationInvitation(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [showPassword2, setShowPassword2] = useState<boolean>(false);
    const [showPassword3, setShowPassword3] = useState<boolean>(false);
    const [showCode, setShowCode] = useState<boolean>(false);
    const [confirmDetails, setConfirmDetails] = useState({ confirmMessage: "", confirmError: "", confirmLoading: false });

    const qp = useQueryParams();
    const organization_name = qp.org;

    const { flashSuccess, flashError } = useContext(PopupProviderContext);
    const navigate = useNavigate();

    const validationSchemaSignUp = Yup.object({
        password: Yup.string(),
        confirmPassword: Yup.string()
    });

    const formikAcceptInvitation = useFormik({
        initialValues: {
            password: "",
            confirmPassword: ""
        },
        validationSchema: validationSchemaSignUp,
        onSubmit: values => {
            console.log(values);
            setLoading(true);
            const key = qp.key;

            axios
                .post("/organizations/accept-invitation", { key })
                .then((res: any) => {
                    console.log(res);
                    flashSuccess("Registration successful", "Check your email for a confirmation link");
                    window.location.href = "/owner-dashboard";
                })
                .catch(err => {
                    console.log(err);
                    setError(logError(err));
                    flashError("Something went wrong!", logError(err));
                    setLoading(false);
                })
                .then(() => {
                    setLoading(false);
                });
        }
    });

    return (
        <Grid container component="main" className={classes.root}>
            {/* <Header/> */}
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        {/* <LockOutlinedIcon /> */}
                        <img src={aqivahLogo} alt="" />
                    </Avatar>
                    <h4 className="mb-0">Accept Organization Invitation</h4>
                    <small className="mb-4">
                        By accepting this invite, you will be able to list properties on behalf of {organization_name}
                    </small>
                    <form onSubmit={formikAcceptInvitation.handleSubmit}>
                        <div>
                            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                                {loading ? <Spin spinning={true} size="small"></Spin> : "Accept Invitation"}
                            </Button>
                            <Grid spacing={3} container justify="flex-end">
                                <Grid item xs={6}></Grid>
                                <Grid item xs={6}>
                                    <Link onClick={() => navigate("/")} variant="body2">
                                        Home
                                    </Link>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}
