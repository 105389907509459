import React, { useState, useContext } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
//import Link from '@mui/material/Link';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { makeStyles } from "@mui/styles";
import Link from "@mui/material/Link";
import aqivahLogo from "../../assets/images/AqIcPurpleText.png";
import Header from "../../components/Header";
import InputAdornment from "@mui/material/InputAdornment";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Spin from "antd/es/spin";
import axios from "axios";
import { logError } from "../../utils/logError";
import { PopupProviderContext } from "../../common/popup-alerts-provider/popup-context";

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh"
    },
    image: {
        //backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundImage:
            "url(https://images.unsplash.com/photo-1430285561322-7808604715df?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80)",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center"
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        margin: theme.spacing(1),
        //backgroundColor: theme.palette.secondary.main
        backgroundColor: "tranparent"
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export default function RegisterSeeker(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [showPassword2, setShowPassword2] = useState<boolean>(false);
    const [showPassword3, setShowPassword3] = useState<boolean>(false);
    const [showCode, setShowCode] = useState<boolean>(false);
    const [confirmDetails, setConfirmDetails] = useState({ confirmMessage: "", confirmError: "", confirmLoading: false });

    const { flashSuccess, flashError } = useContext(PopupProviderContext);
    const navigate = useNavigate();

    let phone_regex = /^([026|26]).\d{7,8}$/;

    const validationSchemaSignUp = Yup.object({
        firstName: Yup.string()
            .matches(/^[A-Za-z ]*$/, "Please enter valid first name")
            .max(40)
            .required(),
        lastName: Yup.string()
            .matches(/^[A-Za-z ]*$/, "Please enter valid last name")
            .max(40)
            .required(),
        email: Yup.string().email("Invalid email format").required("Email is required"),
        phoneNumber: Yup.string().required("Phone number is required").matches(phone_regex, "Phone number format is not valid"),
        password: Yup.string()
            .required("Password is required")
            .matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Password must contain at least 8 characters, one uppercase, one number and one special case"
            ),
        confirmPassword: Yup.string()
            .required("Confirm password is required")
            .when("password", {
                is: password => (password && password.length > 0 ? true : false),
                then: Yup.string().oneOf([Yup.ref("password")], "Passwords do not match")
            }),
        acknowledge: Yup.bool().oneOf([true], "Field must be checked")
    });

    const formikSignUp = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            password: "",
            confirmPassword: "",
            acknowledge: false
        },
        validationSchema: validationSchemaSignUp,
        onSubmit: values => {
            console.log(values);
            values["mobile"] = values.phoneNumber;
            setLoading(true);
            axios
                .post("/auth/signup", { ...values, user_type: "seeker" })
                .then((res: any) => {
                    console.log(res);
                    flashSuccess("Registration successful", "Check your email for a confirmation link");
                    navigate("/login");
                })
                .catch(err => {
                    console.log(err);
                    setError(logError(err));
                    flashError("Something went wrong!", logError(err));
                    setLoading(false);
                })
                .then(() => {
                    setLoading(false);
                });
        }
    });

    return (
        <Grid container component="main" className={classes.root}>
            {/* <Header/> */}
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        {/* <LockOutlinedIcon /> */}
                        <img src={aqivahLogo} alt="" />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Register
                    </Typography>
                    <form onSubmit={formikSignUp.handleSubmit}>
                        <div>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="fname"
                                        name="firstName"
                                        variant="outlined"
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        value={formikSignUp.values.firstName}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.firstName && formikSignUp.errors.firstName ? true : false}
                                        helperText={formikSignUp.touched.firstName && formikSignUp.errors.firstName}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="lname"
                                        value={formikSignUp.values.lastName}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.lastName && formikSignUp.errors.lastName ? true : false}
                                        helperText={formikSignUp.touched.lastName && formikSignUp.errors.lastName}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} style={{ marginTop: "20px" }}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="phone"
                                        label="Phone Number"
                                        name="phoneNumber"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">+233</InputAdornment>
                                        }}
                                        value={formikSignUp.values.phoneNumber}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.phoneNumber && formikSignUp.errors.phoneNumber ? true : false}
                                        helperText={formikSignUp.touched.phoneNumber && formikSignUp.errors.phoneNumber}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    {/* <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        name="email"
                                        value={formikSignUp.values.email}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.email && formikSignUp.errors.email ? true : false}
                                        helperText={formikSignUp.touched.email && formikSignUp.errors.email}
                                        size="small"
                                    /> */}
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} style={{ marginTop: "20px" }}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        name="email"
                                        value={formikSignUp.values.email}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.email && formikSignUp.errors.email ? true : false}
                                        helperText={formikSignUp.touched.email && formikSignUp.errors.email}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>

                            <div className="" style={{ margin: "30px 0 0 0" }}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="first_password"
                                        label="Password"
                                        name="password"
                                        type={showPassword2 ? "text" : "password"}
                                        value={formikSignUp.values.password}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.password && formikSignUp.errors.password ? true : false}
                                        InputProps={{
                                            endAdornment:
                                                formikSignUp.values.password !== "" ? (
                                                    !showPassword2 ? (
                                                        <i onClick={() => setShowPassword2(!showPassword2)} className="fas fa-eye"></i>
                                                    ) : (
                                                        <i
                                                            onClick={() => setShowPassword2(!showPassword2)}
                                                            className="fas fa-eye-slash"></i>
                                                    )
                                                ) : null
                                        }}
                                        size="small"
                                    />
                                    <FormHelperText
                                        error={formikSignUp.touched.password && formikSignUp.errors.password ? true : false}>
                                        {" "}
                                        Password must have an uppercase characters, a lowercase character, a number, a special
                                        character, and must be 8 characters long
                                    </FormHelperText>
                                </Grid>
                            </div>

                            <div className="" style={{ margin: "20px 0 7px 0" }}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="confirm_password"
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        type={showPassword3 ? "text" : "password"}
                                        value={formikSignUp.values.confirmPassword}
                                        onChange={formikSignUp.handleChange}
                                        error={
                                            formikSignUp.touched.confirmPassword && formikSignUp.errors.confirmPassword ? true : false
                                        }
                                        helperText={formikSignUp.touched.confirmPassword && formikSignUp.errors.confirmPassword}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                formikSignUp.values.confirmPassword !== "" ? (
                                                    !showPassword3 ? (
                                                        <i onClick={() => setShowPassword3(!showPassword3)} className="fas fa-eye"></i>
                                                    ) : (
                                                        <i
                                                            onClick={() => setShowPassword3(!showPassword3)}
                                                            className="fas fa-eye-slash"></i>
                                                    )
                                                ) : null
                                        }}
                                    />
                                </Grid>
                            </div>

                            <Grid container justify="center" style={{ margin: "20px 0 7px 0" }}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                name="acknowledge"
                                                checked={formikSignUp.values.acknowledge}
                                                onChange={formikSignUp.handleChange}
                                            />
                                        }
                                        label={
                                            <span
                                                style={{
                                                    color:
                                                        formikSignUp.touched.acknowledge && formikSignUp.errors.acknowledge
                                                            ? "red"
                                                            : "black"
                                                }}>
                                                <small>
                                                    By signing up, I accept the{" "}
                                                    <RouterLink
                                                        style={{ color: "#3f51b5" }}
                                                        to="/about"
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        {" "}
                                                        Aqivah Terms of Service{" "}
                                                    </RouterLink>
                                                    and acknowledge the{" "}
                                                    <RouterLink
                                                        to="/privacy-policy"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ textDecoration: "underline", color: "#3f51b5" }}>
                                                        Privacy Policy.
                                                    </RouterLink>
                                                </small>
                                            </span>
                                        }
                                    />
                                </Grid>
                            </Grid>

                            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                                {loading ? <Spin spinning={true} size="small"></Spin> : "Register"}
                            </Button>
                            <Grid spacing={3} container justify="flex-end">
                                <Grid item xs={6}>
                                    <Link onClick={() => navigate("/info-list-property")} variant="body2">
                                        Want to register as an owner? Click here
                                    </Link>
                                </Grid>
                                <Grid item xs={6}>
                                    <Link onClick={() => navigate("/login")} variant="body2">
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}
