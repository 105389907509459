import React, { useState } from 'react';
import './DashboardSeeker.css';
import Board from './views/Board';
import Documents from './views/Documents';
import Interests from './views/Interests';
import Preferences from './views/Preferences';
import Responses from './views/Responses';
import Profile from './views/Profile';
import { Route, NavLink, HashRouter } from 'react-router-dom';
import { Layout, Menu, Avatar, Button, Dropdown } from 'antd';
import MenuDropdown from './components/MenuDropdown';
//import useNavigation from '../../components/NavigationMenu'

import {
	DashboardOutlined,
	AppstoreAddOutlined,
	AppstoreOutlined,
	SyncOutlined,
	FolderOutlined,
	ProfileOutlined
} from '@ant-design/icons';

const { Header, Content, Sider } = Layout;

const menu = (
	<Menu>
		<Menu.Item>
			<NavLink to="/profile">Profile</NavLink>
		</Menu.Item>
		<Menu.Item>
			<a target="_blank" rel="noopener noreferrer" href="http://www.taobao.com/">
				Logout
			</a>
		</Menu.Item>
	</Menu>
);

const DashBoard = (props:any) => {

	const [collapsed, setCollapsed] = useState<boolean>(false)

	const onCollapse = (collapsed) => {
		console.log(collapsed);
		setCollapsed(collapsed)
	};

	// const {navMenu} = useNavigation(props, 'home')

	return (
		<HashRouter>
			<Layout style={{ minHeight: '100vh' }}>
				<Sider
					collapsible
					collapsed={collapsed}
					onCollapse={onCollapse}
					theme="light"
					className="sidebar-menu"
				>
					<div className="logo">
						<a href="/">
							<img src={require('./assets/Aqlogo.png')} alt="logo" width="50px" height="50px" />
						</a>
					</div>

					<Menu theme="light" mode="inline">
						<Menu.Item
							key="1"
							style={{ fontSize: 15 }}
							icon={<DashboardOutlined style={{ fontSize: 18 }} />}
						>
							<NavLink to="/">Dashboard</NavLink>
						</Menu.Item>

						<Menu.Item
							key="2"
							style={{ fontSize: 15 }}
							icon={<AppstoreAddOutlined style={{ fontSize: 18 }} />}
						>
							<NavLink to="/preferences">Preferences</NavLink>
						</Menu.Item>

						<Menu.Item
							style={{ fontSize: 15 }}
							key="3"
							icon={<AppstoreOutlined style={{ fontSize: 18 }} />}
						>
							<NavLink to="/interests">Interests</NavLink>
						</Menu.Item>
						<Menu.Item
							key="4"
							style={{ fontSize: 15 }}
							icon={<SyncOutlined style={{ fontSize: 18 }} />}
						>
							<NavLink to="/response">Response</NavLink>
						</Menu.Item>
						<Menu.Item
							style={{ fontSize: 15 }}
							key="5"
							icon={<FolderOutlined style={{ fontSize: 18 }} />}
						>
							<NavLink to="/documents">Documents</NavLink>
						</Menu.Item>
					</Menu>
				</Sider>

				<Layout className="site-layout">
					<Header className="site-layout-background top-header" style={{ padding: 0 }}>
						<Menu theme="light" mode="horizontal" defaultSelectedKeys={['1']}>
							<Menu.Item key="1" className="mobile-menu-item" style={{ margin: 'auto' }}>
								<NavLink to="/" style={{ paddingLeft: '15px' }}>
									<DashboardOutlined style={{ fontSize: 18 }} />
									<p
										style={{
											padding: 0,
											marginBlock: '0px',
											marginBlockStart: '0px',
											marginBlockEnd: '0px',
											lineHeight: 'initial',
											fontSize: '8pt'
										}}
									>
										Dashboard
										</p>
								</NavLink>
							</Menu.Item>
							<Menu.Item key="2" className="mobile-menu-item" style={{ margin: 'auto' }}>
								<NavLink to="/preferences" style={{ paddingLeft: '15px' }}>
									<AppstoreAddOutlined style={{ fontSize: 18 }} />
									<p
										style={{
											padding: 0,
											marginBlock: '0px',
											marginBlockStart: '0px',
											marginBlockEnd: '0px',
											lineHeight: 'initial',
											fontSize: '8pt'
										}}
									>
										Preferences
										</p>
								</NavLink>
							</Menu.Item>
							<Menu.Item key="3" className="mobile-menu-item" style={{ margin: 'auto' }}>
								<NavLink to="/interests" style={{ paddingLeft: '12px' }}>
									<AppstoreOutlined style={{ fontSize: 18 }} />
									<p
										style={{
											padding: 0,
											marginBlock: '0px',
											marginBlockStart: '0px',
											marginBlockEnd: '0px',
											lineHeight: 'initial',
											fontSize: '8pt'
										}}
									>
										Interests
										</p>
								</NavLink>
							</Menu.Item>
							<Menu.Item key="4" className="mobile-menu-item" style={{ margin: 'auto' }}>
								<NavLink to="/response" style={{ paddingLeft: '15px' }}>
									<SyncOutlined style={{ fontSize: 18 }} />
									<p
										style={{
											padding: 0,
											marginBlock: '0px',
											marginBlockStart: '0px',
											marginBlockEnd: '0px',
											lineHeight: 'initial',
											fontSize: '8pt'
										}}
									>
										Responses
										</p>
								</NavLink>
							</Menu.Item>
							<Menu.Item key="5" className="mobile-menu-item" style={{ margin: 'auto' }}>
								<NavLink to="/documents" style={{ paddingLeft: '15px' }}>
									<FolderOutlined style={{ fontSize: 18 }} />
									<p
										style={{
											padding: 0,
											marginBlock: '0px',
											marginBlockStart: '0px',
											marginBlockEnd: '0px',
											lineHeight: 'initial',
											fontSize: '8pt'
										}}
									>
										Documents
										</p>
								</NavLink>
							</Menu.Item>

							<div className="header-components">
								<MenuDropdown />
								<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
							</div>
						</Menu>
					</Header>
					{/* {navMenu} */}
					<Content style={{}}>
						<div
							className="main-content site-layout-background round-edge"
							style={{ padding: 24, minHeight: 460, margin: '40px 40px' }}>

							<Route exact path="/" component={Board} />
							<Route exact path="/documents" component={Documents} />
							<Route exact path="/interests" component={Interests} />
							<Route exact path="/preferences" component={Preferences} />
							<Route exact path="/response" component={Responses} />
							<Route exact path="/profile" component={Profile} />

						</div>
						<div className="floating-action">
							<Dropdown overlay={menu} placement="topCenter">
								<Button
									shape="circle"
									size="large"
									icon={<ProfileOutlined />}
									style={{
										border: 'none',
										backgroundColor: '#3492eb',
										color: '#ffffff'
									}}
								/>
							</Dropdown>
						</div>
					</Content>
					{/* <Footer style={{ textAlign: 'center' }}>Search Aqivah | About Aqivah</Footer> */}
				</Layout>
			</Layout>
		</HashRouter>
	);
}

//export default DashBoard

function AppLayout(props) {
	return (
		<div>
			<DashBoard />
		</div>
	);
}

export default AppLayout;
