import {
    CREATE_PROPERTY_REQUEST,
    CREATE_PROPERTY_SUCCESS,
    CREATE_PROPERTY_FAIL,
    CREATE_PROPERTY_RESET,
    FETCH_OWNER_PROPERTY_REQUEST,
    FETCH_OWNER_PROPERTY_SUCCESS,
    FETCH_OWNER_PROPERTY_FAIL,
    FETCH_OWNER_PROPERTY_RESET,
    CREATE_PROPERTY_NEXT_STEP,
    CREATE_PROPERTY_PREVIOUS_STEP,
    CREATE_PROPERTY_RESET_STEP,
    CREATE_PROPERTY_REQUEST_REQUEST,
    CREATE_PROPERTY_REQUEST_SUCCESS,
    CREATE_PROPERTY_REQUEST_FAIL,
    CREATE_PROPERTY_REQUEST_RESET,
    GET_PROPERTY_REQUEST,
    GET_PROPERTY_SUCCESS,
    GET_PROPERTY_FAIL
}
from '../constants/propertyConstants'


export const propertyCreationStepsReducer = (state={activeStep: 0}, action) => {
    switch (action.type){
        case CREATE_PROPERTY_NEXT_STEP:
            return {activeStep: state.activeStep + 1}
        case CREATE_PROPERTY_PREVIOUS_STEP:
            return {activeStep: state.activeStep - 1}
        case CREATE_PROPERTY_RESET_STEP:
            return {activeStep: 0}
        default:
            return state 
    }
}

export const propertyCreateReducer = (state = { loading: false, success: false, property: [] }, action) => {
    switch (action.type) {
        case CREATE_PROPERTY_REQUEST:
            return { loading: true }
        case CREATE_PROPERTY_SUCCESS:
            return { loading: false, success: true, property: action.payload }
        case CREATE_PROPERTY_FAIL:
            return { loading: false, success: false, error: action.payload }
        case CREATE_PROPERTY_RESET:
            return { loading: false, success: false, property: [] }
        default:
            return state
    }
}


const staticData = {
    id: '1',
    category: 'COMMERCIAL',
    saleType: 'RENT',
    description: 'A general test ',
    short_description: '5 bedroom house for rent',
    furnishing: 'FULLY_FURNISHED',
    price: {figure: 1000, currency: 'GHS', duration: 'MONTHLY'},
    parking_capacity: 200,
    location:{region:'g_accra', city:'Accra', town:'Trassaco'}
}

export const propertyFetchOwnerReducer = (state = { properties: [staticData], loading: false }, action) => {
    switch (action.type) {
        case FETCH_OWNER_PROPERTY_REQUEST:
            return { properties: [...state.properties], loading: true }
        case FETCH_OWNER_PROPERTY_SUCCESS:
            return { properties: [...state.properties, ...action.payload], loading: false }
        case FETCH_OWNER_PROPERTY_FAIL:
            return { properties: [...state.properties], loading: false, error: action.payload }
        case FETCH_OWNER_PROPERTY_RESET:
            return { properties: [], loading: false }
        default:
            return state

    }
}


export const propertyCreateRequestReducer = (state={loading:false, success:false, request:[]}, action) => {
    switch(action.type){
        case CREATE_PROPERTY_REQUEST_REQUEST:
            return {loading: true}
        case CREATE_PROPERTY_REQUEST_SUCCESS:
            return {loading:false, success:true, request:action.payload}
        case CREATE_PROPERTY_REQUEST_FAIL:
            return {loading:false, success:false, error: action.payload}
        case CREATE_PROPERTY_REQUEST_RESET:
            return {loading: false, success:false, request:[]}
        default:
            return state
    }
}

export const getPropertyReducer = (state ={loading:false, success:false, property:[]}, action) => {
    switch(action.type){
        case GET_PROPERTY_REQUEST:
            return {loading:true, success:false, property:[]}
        case GET_PROPERTY_SUCCESS:
            return {loading:false, success:true, property:[action.payload]}
        case GET_PROPERTY_FAIL:
            return {loading: false, success:true, property:[], error: action.payload}
        default:
            return state
    }
}