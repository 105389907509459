import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./screens/expanded-property-page/css/chatsection.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { ApolloProvider } from "@apollo/client";
import { Rehydrated } from "aws-appsync-react";
//import Rehydrated from "./config/Rehydrated"
import { client } from "./Graphql/client";
import { UserProvider } from "./context/user-context/user-provider";
import { PropertyProvider } from "./context/property-context/property-provider";
import { PopupProvider } from "./common/popup-alerts-provider/popup-provider";
import { SearchProvider } from "./context/search-context/search-provider";
import { AuthenticationProvider } from "./context/auth-context/auth-provider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { unregister, register } from "./serviceWorker";
// import ThemeProvider from "./theme/index";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material/styles";

const { client_ } = client();

const theme = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#3f51b5"
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            light: "#0066ff",
            main: "#0044ff",
            // dark: will be calculated from palette.secondary.main,
            contrastText: "#ffcc00"
        },
        success: {
            main: "#388e3c",
            light: "#66bb6a",
            dark: ""
        },
        // Provide every color token (light, main, dark, and contrastText) when using
        // custom colors for props in Material UI's components.
        // Then you will be able to use it like this: `<Button color="custom">`
        // (For TypeScript, you need to add module augmentation for the `custom` value)

        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2
    }
});
const WIthProvider = () => {
    return (
        <ApolloProvider client={client_ as any}>
            {/* <Rehydrated> */}
            <ThemeProvider theme={theme}>
                <AuthenticationProvider>
                    <PopupProvider>
                        <UserProvider>
                            <SearchProvider>
                                <PropertyProvider>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <App />
                                    </LocalizationProvider>
                                </PropertyProvider>
                            </SearchProvider>
                        </UserProvider>
                    </PopupProvider>
                </AuthenticationProvider>
            </ThemeProvider>
            {/* </Rehydrated> */}
        </ApolloProvider>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <WIthProvider />
            {/* <App /> */}
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
register();
