import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import BathtubIcon from "@mui/icons-material/Bathtub";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import TextField from "@mui/material/TextField";
import Upload from "antd/es/upload";
import Alert from "@mui/material/Alert";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { PropertyContext } from "../../context/property-context/property-context";
import { useQuery, useMutation } from "@apollo/client";
import { CreateResidential_, createRes } from "../../Graphql/mutations";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1, 0)
    },
    total: {
        fontWeight: 700
    },
    title: {
        marginTop: theme.spacing(2)
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: "100%",
        maxWidth: "100%"
    },
    formControlPrice: {
        minWidth: "30%",
        maxWidth: "30%",
        marginRight: "3%"
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    details: {
        color: theme.palette.text.secondary
    },
    "& > * + *": {
        marginTop: theme.spacing(2)
    }
}));

export default function Review() {
    const [id, setId] = useState<any>("");
    const [state, setState] = useState<any>("add");
    const [existing_images, setExistingImages] = useState<any>([]);
    const [images, addImages] = useState<any>([]);
    const [new_images, newImages] = useState<any>([]);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>("");
    const [previewTitle, setPreviewTitle] = useState<string>("");
    const [actualImageFile, setActualImage] = useState<any>([]);
    const [category, setCategory] = useState<any>("residential");
    const [saleType, setSaleType] = useState<any>("rent");
    const [propertyType, setPropertyType] = useState<any>("");
    const [paymentPlan, setPaymentPlan] = useState<any>("2_year");
    const [furnishing, setFurnishing] = useState<any>("fully_furnished");
    const [waterSource, setWaterSource] = useState<any>("gwcl");
    const [bedroom, setBedroom] = useState<number>(5);
    const [bathroom, setBathroom] = useState<number>(3);
    const [hidden, setHidden] = useState<boolean>(true);

    const [price, setPrice] = useState<number>(0);
    const [formattedNumber, setFormattedPrice] = useState<any>("");
    const [currency, setCurrency] = useState<any>("");

    const [amenities, setAmenities] = useState<any>();
    const [parking_capacity, setParkingCapacity] = useState<any>(20);
    const [seating_capacity, setSeatingCapacity] = useState<any>(4000);

    const [unit, setUnit] = useState<any>();
    const [size, setSize] = useState<number>();

    const [region, setRegion] = useState<any>("g_accra");
    const [city, setCity] = useState<any>("Accra");
    const [ghanapost, setGhanaPost] = useState<any>("G87231D2");
    const [short_description, setShortDescription] = useState<any>("5 bedroom house for rent");
    const [description, setDescription] = useState<any>("");
    const [location, setLocation] = useState<any>("Tema East");

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<CountryType[]>([]);
    const autoCompleteLoading = open && options.length === 0;

    const classes = useStyles();
    const dispatch = useDispatch();

    const { propertyDetails, locationDetails, handleStatus, nextStep, previousStep } = useContext(PropertyContext);
    const [mutateFunction, { loading: createResidentialLoading, error, data }] = useMutation(createRes);
    const [loading, setLoading] = useState(false);

    const propertyInfo = { ...propertyDetails, ...locationDetails };

    useEffect(() => {
        console.log(propertyInfo);
        let property_price = propertyInfo.price ? formatNumber(propertyInfo.price) : 0;

        propertyInfo.id && setId(propertyInfo.id);
        propertyInfo.state && setState(propertyInfo.state);
        propertyInfo.existing_images && setExistingImages(propertyInfo.existing_images);
        propertyInfo.new_images && newImages(propertyInfo.new_images);
        propertyInfo.category && setCategory(propertyInfo.category);
        propertyInfo.saleType && setSaleType(propertyInfo.saleType);
        propertyInfo.propertyType && setPropertyType(propertyInfo.propertyType);
        propertyInfo.description && setDescription(propertyInfo.description);
        propertyInfo.short_description && setShortDescription(propertyInfo.short_description);
        propertyInfo.paymentPlan && setPaymentPlan(propertyInfo.paymentPlan);
        propertyInfo.furnishing && setFurnishing(propertyInfo.furnishing);
        propertyInfo.waterSource && setWaterSource(propertyInfo.waterSource);
        propertyInfo.bedrooms && setBedroom(propertyInfo.bedrooms);
        propertyInfo.bathrooms && setBathroom(propertyInfo.bathrooms);
        propertyInfo.price && setPrice(propertyInfo.price);
        propertyInfo.currency && setCurrency(propertyInfo.currency);
        propertyInfo.price && setFormattedPrice(property_price);
        propertyInfo.images && addImages(propertyInfo.images);
        propertyInfo.region && setRegion(propertyInfo.region);
        propertyInfo.location && setLocation(propertyInfo.location);
        propertyInfo.city && setCity(propertyInfo.city);
        propertyInfo.size && setSize(propertyInfo.size);
        propertyInfo.unit && setUnit(propertyInfo.unit);
        propertyInfo.parking_capacity && setParkingCapacity(propertyInfo.parking_capacity);
        propertyInfo.seating_capacity && setSeatingCapacity(propertyInfo.seating_capacity);
    }, [propertyDetails, locationDetails]);

    const formatNumber = (num: any) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    };

    function createResidentialProperty() {
        setLoading(true);
        handleStatus({ loading: true, success: false, error: false });
        axios
            .post("/properties/create-residential", {
                type: propertyType,
                acquisition: saleType,
                price: Number(price),
                currency: currency,
                description: description,
                location: { city: city },
                images: images,
                bathrooms: bathroom,
                bedrooms: bedroom,
                amenities: ["gym", "schools"],
                furnishing: furnishing,
                parking_capacity: parking_capacity,
                size: size,
                unit: unit,
                seating_capacity: seating_capacity,
                city: city,
                region: region
            })
            .then((res: any) => {
                handleStatus({ loading: false, success: true, error: false });
                console.log(res);
            })
            .catch(err => {
                console.log(err);
                handleStatus({ loading: false, success: false, error: true });
            })
            .then(() => {
                console.log("");
                setLoading(false);
            });
    }

    function createCommercialProperty() {
        setLoading(true);
        handleStatus({ loading: true, success: false, error: false });
        axios
            .post("/properties/create-commercial", {
                category: category,
                type: propertyType,
                acquisition: saleType,
                price: Number(price),
                currency: currency,
                description: description,
                location: { city: city },
                images: images,
                commercial: {
                    commercialType: propertyType,
                    state: furnishing,
                    parkingCapacity: parking_capacity,
                    amenities: ["gym", "schools"]
                }
            })
            .then((res: any) => {
                handleStatus({ loading: false, success: true, error: false });
                console.log(res);
            })
            .catch(err => {
                console.log(err);
                handleStatus({ loading: false, success: false, error: true });
            })
            .then(() => {
                console.log("esed");
                setLoading(false);
            });
    }

    function createFarmlandProperty() {
        setLoading(true);
        handleStatus({ loading: true, success: false, error: false });
        axios
            .post("/properties/create-farmland", {
                category: category === "AGRICULTURAL_FARMLAND" ? "FARMLAND" : "",
                type: propertyType,
                acquisition: saleType,
                price: Number(price),
                currency: currency,
                description: description,
                location: { city: city },
                images: images,
                farmland: {
                    size: {
                        measurement: size,
                        unit: unit
                    }
                }
            })
            .then((res: any) => {
                handleStatus({ loading: false, success: true, error: false });
                console.log(res);
            })
            .catch(err => {
                handleStatus({ loading: false, success: false, error: true });
                console.log(err);
            })
            .then(() => {
                console.log("");
                setLoading(false);
            });
    }

    function createEventProperty() {
        setLoading(true);
        handleStatus({ loading: true, success: false, error: false });
        axios
            .post("/properties/create-events", {
                category: category === "PARTIES_EVENT" ? "PARTIES" : "",
                type: propertyType,
                acquisition: saleType,
                price: Number(price),
                currency: currency,
                description: description,
                location: { city: city },
                images: images,
                parties: {
                    parkingCapacity: parking_capacity,
                    seating: seating_capacity,
                    amenities: ["Gym", "Bar"]
                }
            })
            .then((res: any) => {
                handleStatus({ loading: false, success: true, error: false });
                console.log(res);
            })
            .catch(err => {
                handleStatus({ loading: false, success: false, error: true });
                console.log(err);
            })
            .then(() => {
                console.log("");
                setLoading(false);
            });
    }

    const handleSubmit = e => {
        e.preventDefault();
        console.log("propertyType:", propertyType);
        switch (category) {
            case "RESIDENTIAL":
                createResidentialProperty();
                break;
            case "COMMERCIAL":
                createCommercialProperty();
                break;
            case "AGRICULTURAL_FARMLAND":
                createFarmlandProperty();
                break;
            case "PARTIES_EVENT":
                createEventProperty();
                break;
            default:
                console.log("error");
        }
    };

    const handleBack = () => {
        previousStep();
    };

    function getBase64(file: any) {
        console.log("images", file);
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const handlePreview = async (file: any) => {
        console.log(file);
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
        setActualImage([...actualImageFile]);
        //console.log(actualImageFile)
    };

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <Typography variant="h6" gutterBottom>
                    Review property
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Category</InputLabel>
                            <Select value={category} fullWidth disabled>
                                <MenuItem value={"RESIDENTIAL"}>Residential</MenuItem>
                                <MenuItem value={"COMMERCIAL"}>Commercial</MenuItem>
                                <MenuItem value={"AGRICULTURAL_FARMLAND"}>Agricultural</MenuItem>
                                <MenuItem value={"PARTIES_EVENT"}>Party and Events</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Property type</InputLabel>
                            <Select value={propertyType} fullWidth label="Property type" disabled>
                                {category === "RESIDENTIAL"
                                    ? [
                                          <MenuItem value={"HOUSE"}>House</MenuItem>,
                                          <MenuItem value={"BUNGALOW"}>Bungalow</MenuItem>,
                                          <MenuItem value={"TOWNHOUSE"}>Townhouse</MenuItem>,
                                          <MenuItem value={"APARTMENT"}>Apartment</MenuItem>
                                      ]
                                    : category === "COMMERCIAL"
                                    ? [
                                          <MenuItem value={"OFFICE"}>Office</MenuItem>,
                                          <MenuItem value={"SHOP"}>Shop</MenuItem>,
                                          <MenuItem value={"SHIPPING_CONTAINER"}>Shipping Container</MenuItem>
                                      ]
                                    : category === "AGRICULTURAL_FARMLAND"
                                    ? [
                                          <MenuItem value={"FISH_FARM"}>Fish farm</MenuItem>,
                                          <MenuItem value={"CROP_FARM"}>Crop farm</MenuItem>,
                                          <MenuItem value={"LIVESTOCK"}>Livestock</MenuItem>
                                      ]
                                    : category === "PARTIES_EVENT"
                                    ? [<MenuItem value={"CHURCH"}>Church</MenuItem>, <MenuItem value={"THEATRE"}>Theatre</MenuItem>]
                                    : [
                                          <MenuItem value={"HOUSE"}>House</MenuItem>,
                                          <MenuItem value={"BUNGALOW"}>Bungalow</MenuItem>,
                                          <MenuItem value={"TOWNHOUSE"}>Townhouse</MenuItem>,
                                          <MenuItem value={"APARTMENT"}>Apartment</MenuItem>
                                      ]}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            required
                            id="short_description"
                            name="short_description"
                            label="Short description"
                            fullWidth
                            autoComplete="shipping address-line1"
                            disabled
                            value={short_description}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="description"
                            name="description"
                            label="Description"
                            fullWidth
                            multiline
                            rows={6}
                            rowsMax={60}
                            autoComplete="shipping address-line2"
                            disabled
                            value={description}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Sale type</InputLabel>
                            <Select value={saleType} fullWidth label="Sale type" disabled>
                                <MenuItem value={"RENT"}>For rent</MenuItem>
                                <MenuItem value={"BUY"}>For sale</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item style={{ display: "flex" }} xs={12} sm={6}>
                        <FormControl className={classes.formControlPrice}>
                            <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                            <Select value={currency ?? " "} fullWidth={false} label="Currency" disabled>
                                <MenuItem value={"GHS"}>GHS</MenuItem>
                                <MenuItem value={"USD"}>USD</MenuItem>
                            </Select>
                        </FormControl>

                        <div>
                            <TextField
                                required
                                id="price"
                                name="price"
                                label="Price"
                                type={"text"}
                                value={formattedNumber}
                                fullWidth
                                disabled
                            />
                        </div>
                    </Grid>

                    {saleType === "rent" && (
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Payment plan</InputLabel>
                                <Select value={paymentPlan} fullWidth label="Payment plan" disabled>
                                    <MenuItem value={"DAILY"}>Daily payment</MenuItem>
                                    <MenuItem value={"WEEKLY"}>Weekly payment</MenuItem>
                                    <MenuItem value={"MONTHLY"}>Monthly payment</MenuItem>
                                    <MenuItem value={"YEARLY"}>Yearly payment</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {(category === "RESIDENTIAL" || category === "COMMERCIAL") && (
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Furnishing</InputLabel>
                                <Select value={furnishing} fullWidth label="Furnishing" disabled>
                                    <MenuItem value={"NOT_FURNISHED"}>Unfurnished</MenuItem>
                                    <MenuItem value={"PARTLY_FURNISHED"}>Partly Furnished</MenuItem>
                                    <MenuItem value={"FURNISHED"}>Fully Furnished</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {(category === "PARTIES_EVENT" || category === "COMMERCIAL") && (
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="parking_capacity"
                                name="parking_capacity"
                                label={"Parking Capacity"}
                                type={"number"}
                                fullWidth
                                value={parking_capacity}
                                disabled
                                //autoComplete="given-name"
                            />
                        </Grid>
                    )}

                    {category === "PARTIES_EVENT" && (
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="seating_capacity"
                                name="seating_capacity"
                                label={"Seating Capacity"}
                                type={"number"}
                                fullWidth
                                value={seating_capacity}
                                disabled
                                //autoComplete="given-name"
                            />
                        </Grid>
                    )}

                    {category === "residential" && (
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Water source</InputLabel>
                                <Select value={waterSource} fullWidth label="Furnishing" disabled>
                                    <MenuItem value={"gwcl"}>GWCL</MenuItem>
                                    <MenuItem value={"well"}>Well</MenuItem>
                                    <MenuItem value={"bore_hole"}>Bore hole</MenuItem>
                                    <MenuItem value={"other"}>Other</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {category === "AGRICULTURAL_FARMLAND" && (
                        <Grid item style={{ display: "flex" }} xs={12} sm={6}>
                            <FormControl className={classes.formControlPrice}>
                                <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                <Select defaultValue={" "} value={unit ?? " "} fullWidth={false} label="Currency" disabled>
                                    <MenuItem value={"PLOT"}>Plot</MenuItem>
                                    <MenuItem value={"ACRE"}>Acre</MenuItem>
                                    <MenuItem value={"HECTARE"}>Hectare</MenuItem>
                                    <MenuItem value={"SQUAREMETER"}>Sqare Metre</MenuItem>
                                </Select>
                            </FormControl>

                            <div>
                                <TextField id="size" name="size" label={"Size"} type={"number"} fullWidth value={size} disabled />
                            </div>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
                            label="Use this address for payment details"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Upload
                            //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            fileList={images}
                            accept="image/*,video/*"
                            onPreview={handlePreview}
                            multiple={true}
                            style={{ justifySelf: "center", alignSelf: "center" }}
                            customRequest={() => {}}
                            disabled></Upload>
                    </Grid>
                </Grid>

                {category === "residential" && (
                    <>
                        {/* <Typography variant="h6" gutterBottom style={{ margin: '15px 15px' }}>
                         Second section
                        </Typography> */}

                        <Grid container spacing={3}>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{
                                    padding: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                <Typography variant="subtitle1">
                                    <LocalHotelIcon color="primary" style={{ fontSize: "30px" }} />
                                </Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}>
                                    <input disabled={true} value={bedroom} className="bath-input" />
                                </div>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{
                                    padding: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                <Typography variant="subtitle1">
                                    <BathtubIcon color="primary" style={{ fontSize: "30px" }} />
                                </Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}>
                                    <input disabled={true} value={bathroom} className="bath-input" />
                                </div>
                            </Grid>
                        </Grid>
                    </>
                )}

                <Typography variant="h6" gutterBottom>
                    Location details
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            id="asynchronous-demo"
                            style={{ width: "100%" }}
                            open={open}
                            disabled
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            getOptionSelected={(option, value) => option.name === value.name}
                            getOptionLabel={option => option.name}
                            options={options}
                            loading={autoCompleteLoading}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Location"
                                    fullWidth
                                    value={location}
                                    // variant="outlined"
                                    disabled
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {autoCompleteLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="city"
                            name="city"
                            label="City"
                            fullWidth
                            autoComplete="shipping address-level2"
                            disabled
                            value={city}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Region</InputLabel>
                            <Select value={region} fullWidth label="Property type" disabled>
                                <MenuItem value={"g_accra"}>Greater Accra</MenuItem>
                                <MenuItem value={"ashanti"}>Ashanti</MenuItem>
                                <MenuItem value={"central"}>Central</MenuItem>
                                <MenuItem value={"western"}>Western</MenuItem>
                                <MenuItem value={"eastern"}>Eastern</MenuItem>
                                <MenuItem value={"volta"}>Volta</MenuItem>
                                <MenuItem value={"brong_ahafo"}>Brong-Ahafo</MenuItem>
                                <MenuItem value={"northern"}>Northern</MenuItem>
                                <MenuItem value={"upper_east"}>Upper East</MenuItem>
                                <MenuItem value={"upper_west"}>Upper West</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="zip"
                            name="zip"
                            label="Ghana Post Code"
                            fullWidth
                            disabled
                            value={ghanapost}
                            //autoComplete="shipping postal-code"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={hidden} name="hideProperty" value="yes" />}
                            label="Hide property"
                            disabled
                        />
                    </Grid>
                </Grid>

                <div className={classes.buttons}>
                    <Button onClick={handleBack} className={classes.button}>
                        Back
                    </Button>

                    <Button variant="contained" color="primary" className={classes.button} type={"submit"}>
                        {/* {activeStep === steps.length - 1 ? 'Place order' : 'Next'} */}
                        Submit
                    </Button>
                </div>
            </form>
            {createResidentialLoading && <Alert severity="info">Property created!</Alert>}
            {error && <Alert severity="info">{JSON.stringify(error)}</Alert>}
        </React.Fragment>
    );
}

interface CountryType {
    name: string;
}
