import React, { useState, useContext } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
//import Link from '@mui/material/Link';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { makeStyles } from "@mui/styles";
import Link from "@mui/material/Link";
import aqivahLogo from "../../assets/images/AqIcPurpleText.png";
import Header from "../../components/Header";
import InputAdornment from "@mui/material/InputAdornment";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Spin from "antd/es/spin";
import axios from "axios";
import { logError } from "../../utils/logError";
import { useQueryParams } from "../../utils/use-query-params";
import { PopupProviderContext } from "../../common/popup-alerts-provider/popup-context";

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh"
    },
    image: {
        //backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundImage:
            "url(https://images.unsplash.com/photo-1430285561322-7808604715df?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80)",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center"
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        margin: theme.spacing(1),
        //backgroundColor: theme.palette.secondary.main
        backgroundColor: "tranparent"
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export default function CompleteOrganizationRegistration(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [showPassword2, setShowPassword2] = useState<boolean>(false);
    const [showPassword3, setShowPassword3] = useState<boolean>(false);
    const [showCode, setShowCode] = useState<boolean>(false);
    const [confirmDetails, setConfirmDetails] = useState({ confirmMessage: "", confirmError: "", confirmLoading: false });

    const qp = useQueryParams();

    const { flashSuccess, flashError } = useContext(PopupProviderContext);
    const navigate = useNavigate();

    let phone_regex = /^([026|26]).\d{7,8}$/;

    const validationSchemaSignUp = Yup.object({
        password: Yup.string()
            .required("Password is required")
            .matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Password must contain at least 8 characters, one uppercase, one number and one special case"
            ),
        confirmPassword: Yup.string()
            .required("Confirm password is required")
            .when("password", {
                is: password => (password && password.length > 0 ? true : false),
                then: Yup.string().oneOf([Yup.ref("password")], "Passwords do not match")
            })
    });

    const formikSignUp = useFormik({
        initialValues: {
            password: "",
            confirmPassword: ""
        },
        validationSchema: validationSchemaSignUp,
        onSubmit: values => {
            console.log(values);
            setLoading(true);
            const key = qp.key;
            axios
                .post("/organizations/complete-organization-registration", { ...values, key })
                .then((res: any) => {
                    console.log(res);
                    flashSuccess("Registration successful", "Check your email for a confirmation link");
                    navigate("/login");
                })
                .catch(err => {
                    console.log(err);
                    setError(logError(err));
                    flashError("Something went wrong!", logError(err));
                    setLoading(false);
                })
                .then(() => {
                    setLoading(false);
                });
        }
    });

    return (
        <Grid container component="main" className={classes.root}>
            {/* <Header/> */}
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        {/* <LockOutlinedIcon /> */}
                        <img src={aqivahLogo} alt="" />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Complete Organization Registration
                    </Typography>
                    <form onSubmit={formikSignUp.handleSubmit}>
                        <div>
                            <div className="" style={{ margin: "30px 0 0 0" }}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="first_password"
                                        label="Set password"
                                        name="password"
                                        type={showPassword2 ? "text" : "password"}
                                        value={formikSignUp.values.password}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.password && formikSignUp.errors.password ? true : false}
                                        InputProps={{
                                            endAdornment:
                                                formikSignUp.values.password !== "" ? (
                                                    !showPassword2 ? (
                                                        <i onClick={() => setShowPassword2(!showPassword2)} className="fas fa-eye"></i>
                                                    ) : (
                                                        <i
                                                            onClick={() => setShowPassword2(!showPassword2)}
                                                            className="fas fa-eye-slash"></i>
                                                    )
                                                ) : null
                                        }}
                                        size="small"
                                    />
                                    <FormHelperText
                                        error={formikSignUp.touched.password && formikSignUp.errors.password ? true : false}>
                                        {" "}
                                        Password must have an uppercase characters, a lowercase character, a number, a special
                                        character, and must be at least 8 characters long
                                    </FormHelperText>
                                </Grid>
                            </div>

                            <div className="" style={{ margin: "20px 0 7px 0" }}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="confirm_password"
                                        label="Confirm password"
                                        name="confirmPassword"
                                        type={showPassword3 ? "text" : "password"}
                                        value={formikSignUp.values.confirmPassword}
                                        onChange={formikSignUp.handleChange}
                                        error={
                                            formikSignUp.touched.confirmPassword && formikSignUp.errors.confirmPassword ? true : false
                                        }
                                        helperText={formikSignUp.touched.confirmPassword && formikSignUp.errors.confirmPassword}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                formikSignUp.values.confirmPassword !== "" ? (
                                                    !showPassword3 ? (
                                                        <i onClick={() => setShowPassword3(!showPassword3)} className="fas fa-eye"></i>
                                                    ) : (
                                                        <i
                                                            onClick={() => setShowPassword3(!showPassword3)}
                                                            className="fas fa-eye-slash"></i>
                                                    )
                                                ) : null
                                        }}
                                    />
                                </Grid>
                            </div>

                            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                                {loading ? <Spin spinning={true} size="small"></Spin> : "Register"}
                            </Button>
                            <Grid spacing={3} container justify="flex-end">
                                <Grid item xs={6}></Grid>
                                <Grid item xs={6}>
                                    <Link onClick={() => navigate("/")} variant="body2">
                                        Go Back
                                    </Link>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}
