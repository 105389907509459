import React, { useEffect, useState } from 'react';
import {UserPool} from "../config/UserPool"
import { useDispatch , useSelector} from 'react-redux';
import {authExpiryTime, getNewMessage} from "../Actions/userActions"
import {io} from 'socket.io-client'
import {socket} from "../config/socketioConfig"

function useRefreshToken () {

    const dispatch = useDispatch()

    const interval =  86400000 //3600000 used to be 1hr now 24hrs

  // @@ Refresh auth tokens after 1 hr expiration.
  useEffect(() => {
    console.log("refreshing...")
    const refreshTokenInterval = setInterval(() => {
      const cognitoUser = UserPool.getCurrentUser()
      if (cognitoUser !== null) {
        cognitoUser.getSession(function (err: any, session: any) {
          if (err) {
            console.log(err);
            return
          }
          dispatch(authExpiryTime())
          console.log('session validity: ' + session.isValid())
          console.log(session.accessToken.jwtToken)
          // AWS.config.region = 'us-west-2'
          // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          //   IdentityPoolId: 'us-west-2:40708dc0-1d52-4dc5-9f6a-bade84597a44',
          //   Logins: {
          //     'cognito-idp.us-west-2.amazonaws.com/us-west-2_MksRVgimN': session.getIdToken().getJwtToken()
          //   }
          // });
  
          // (AWS.config.credentials as AWS.Credentials).get((err) => {
          //   if(!err){
          //     let accessId = AWS.config.credentials?.accessKeyId
          //     console.log(accessId)
          //     return AWS.config.credentials
          //   }else{
          //     }
          // })

          //   if(!socket.connected){
          //     socket.on("connection_error", (err) => {
          //         console.log(`connectin error due to ${err.massage}`, err)
          //     })
          //     socket.emit("join", session.accessToken.payload.username)
          //     socket.on('new-message', message => {
          //         dispatch(getNewMessage(message))  
          //     })
          // }
          console.log('socket', socket)
        })
      }
    }, interval )

    return () => clearInterval(refreshTokenInterval)
  }, [])
}

export default useRefreshToken