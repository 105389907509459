import React, { useContext, useState, useEffect } from "react";
import Table from "antd/es/table";
import Button from "@mui/material/Button";
import AntButton from "antd/es/button";
import axios from "axios";
import { PopupProviderContext } from "../../../../common/popup-alerts-provider/popup-context";
import { logError } from "../../../../utils/logError";
import Modal from "antd/es/modal";
import TextField from "@mui/material/TextField";
import { OrganizationProviderContext } from "../../../../common/organization-provider/organization-provider-context";
import { debounce } from "../../../../utils/debounce";
import Tag from "antd/es/tag";
import moment from "moment";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Spin from "antd/es/spin";

import "./users.scss";
import { min } from "lodash";
import { Space } from "antd";

interface User {
    _id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
}
export const Users = () => {
    const GET_USER = "get-user";
    const INVITE_USER = "invite-user";

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const { flashError, flashSuccess } = useContext(PopupProviderContext);
    const [email, setEmail] = useState("");
    const [user, setUser] = useState<User | null>(null);
    const [events, setEvents] = useState<any>([]);
    const [permissions, setPermissions] = useState<any>([]);
    const { organization: org } = useContext(OrganizationProviderContext);

    const cardStyling: any = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "stretch",
        boxShadow: "2px 2px 20px #d3d3d3",
        padding: "15px 15px",
        borderRadius: "9px",
        backgroundColor: "#fff",
        width: "100%"
    };

    function fetchOrganizationUsers() {
        setLoading(true);
        axios
            .get(`/organizations/fetch-members/${org?.url_name}`)
            .then(res => setUsers(res.data.users))
            .catch((err: any) => {
                console.log(err);
            })
            .then(() => setLoading(false));
    }

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
    };

    function getUserInfo() {
        setEvents([...events, GET_USER]);
        axios
            .get("/organizations/get-user-info", { params: { email } })
            .then((res: any) => {
                setUser(res.data.result.user);
            })
            .catch((err: any) => setUser(null))
            .then(() => setEvents(events.filter(event => event !== GET_USER)));
    }

    function inviteUser() {
        setEvents([...events, INVITE_USER]);
        axios
            .post(`/organizations/invite-member/${user?._id}`)
            .then((req: any) => {
                flashSuccess("Invite sent!");
            })
            .catch((err: any) => console.log(err))
            .then(() => setEvents(events.filter((event: any) => event !== INVITE_USER)));
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (name: any, user: any) => user.first_name + " " + user.last_name
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "Phone Number",
            dataIndex: "phone",
            key: "phone"
        },
        {
            title: "Organization Name",
            dataIndex: "first_name",
            key: "first_name",
            render: (name: any, user: any) => user.organization?.name
        },
        {
            title: "Date Added",
            dataIndex: "first_name",
            key: "first_name",
            render: (name: any, user: any) => moment(user.organization?.date_added).format("Do MMM YYYY, hh:mm a")
        },
        {
            title: "Action",
            dataIndex: "first_name",
            key: "first_name",
            render: (name: any, user: any) => (
                <Space>
                    <i style={{ color: "red", cursor: "pointer" }} className="fa-solid fa-trash-can me-2"></i>
                    <i style={{ cursor: "pointer" }} className="fa-solid fa-pen-to-square"></i>
                </Space>
            )
        }
    ];

    const handlePermissions = (e: any) => {
        console.log(e.target.name, e.target.value, e.target.checked);
        if (e.target.checked) {
            setPermissions([...permissions, e.target.name]);
        } else {
            setPermissions(permissions.filter((permission: any) => permission != e.target.name));
        }
    };

    useEffect(() => fetchOrganizationUsers(), []);

    useEffect(() => debounce("fetch_users", getUserInfo, 500), [email]);

    return (
        <div id="org-dash-users">
            <Modal title="Invite User" open={open} footer={null} onCancel={() => setOpen(false)} width={450}>
                <div className="d-flex flex-column justify-content-start align-items-center">
                    <TextField fullWidth size="small" name="email" label="Email" autoFocus onChange={handleEmailChange} />
                    {events.includes(GET_USER) && <Spin className="mt-3" size="default" />}
                    {user && (
                        <div style={cardStyling} className="branch-banner mt-5">
                            <div className="d-flex justify-content-start mb-0">
                                <h6 className="fw-bold">User</h6>
                            </div>
                            <hr />
                            <div className="d-flex mb-3">
                                <span className="fw-bold me-3">
                                    <i className="fa-solid fa-code-branch me-2"></i>Name:
                                </span>
                                <div className="flex-1-1"></div>
                                {<Tag color={"blue"}>{user.first_name + " " + user.last_name}</Tag>}
                            </div>
                            <div className="d-flex mb-3">
                                <span className="fw-bold me-3">
                                    <i className="fa-solid fa-street-view me-2"></i>Email:
                                </span>
                                <div className="flex-1-1"></div>
                                {<Tag color={"blue"}>{user.email}</Tag>}
                            </div>
                            <div className="d-flex mb-3">
                                <span className="fw-bold me-3">
                                    <i className="fa-solid fa-street-view me-2"></i>Permissions:
                                </span>
                                <div className="flex-1-1 ms-3"></div>
                                <FormGroup onChange={handlePermissions}>
                                    <FormControlLabel
                                        name="List Properties"
                                        control={
                                            <Checkbox checked={permissions.includes("List Properties")} size="small" defaultChecked />
                                        }
                                        label="List Properties"
                                    />
                                    <FormControlLabel
                                        name="Delete Properties"
                                        control={<Checkbox checked={permissions.includes("Delete Properties")} size="small" />}
                                        label="Delete Properties"
                                    />
                                    <FormControlLabel
                                        name="Manage Users"
                                        control={<Checkbox checked={permissions.includes("Manage Users")} size="small" />}
                                        label="Manage Users"
                                    />
                                </FormGroup>
                            </div>
                            <div></div>
                            <div className="d-flex justify-content-center mb-3">
                                <span className="fw-bold"></span>
                                <div className="flex-1-1"></div>
                                <Button fullWidth size="small" onClick={inviteUser} variant="contained" color="primary">
                                    Invite User
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>

            <div className="container-sm">
                <header className="d-flex justify-content-between">
                    <h3 className="mb-4 me-4">Users</h3>

                    <div>
                        <Button disabled={loading} variant="outlined" size="small" className="me-2" onClick={fetchOrganizationUsers}>
                            {loading ? (
                                <div className="spinner-border spinner-border-sm" role="status"></div>
                            ) : (
                                <i className="fa-solid fa-arrows-rotate me-2"></i>
                            )}
                            Refresh
                        </Button>
                        <AntButton type="primary" onClick={() => setOpen(true)}>
                            <i className="fa-solid fa-plus me-2 text-white"></i>
                            Add User
                        </AntButton>
                    </div>
                </header>

                <Table loading={loading} dataSource={users} columns={columns} />
            </div>
        </div>
    );
};
