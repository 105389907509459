

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET'

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST"
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS"
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL"

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST"
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS"
export const USER_DELETE_FAIL = "USER_DELETE_FAIL"

export const USER_CONFIRM_REQUEST = 'USER_CONFIRM_REQUEST'
export const USER_CONFIRM_SUCCESS = 'USER_CONFIRM_SUCCESS'
export const USER_CONFIRM_FAIL = 'USER_CONFIRM_FAIL'
export const USER_CONFIRM_RESET = 'USER_CONFIRM_RESET'

export const CONFIRM_RESEND_SUCCESS = "CONFIRM_RESEND_SUCCESS"
export const CONFIRM_RESEND_REQUEST = "CONFIRM_RESEND_REQUEST"
export const CONFIRM_RESEND_FAIL = "CONFIRM_RESEND_FAIL"

export const USER_OPEN_LOGIN_MODAL = 'USER_OPEN_LOGIN_MODAL'
export const USER_OPEN_FORGOT_PASSWORD_MODAL = 'USER_OPEN_FORGOT_PASSWORD_MODAL'

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET'

export const USER_PASSWORD_RESET_CODE_REQUEST = "USER_PASSWORD_RESET_CODE_REQUEST"
export const USER_PASSWORD_RESET_CODE_SUCCESS = "USER_PASSWORD_RESET_CODE_SUCCESS"
export const USER_PASSWORD_RESET_CODE_FAIL = "USER_PASSWORD_RESET_CODE_FAIL"
export const USER_PASSWORD_RESET_CODE = "USER_PASSWORD_RESET_CODE"

export const USER_PASSWORD_RESET_REQUEST = "USER_PASSWORD_RESET_REQUEST"
export const USER_PASSWORD_RESET_SUCCESS = "USER_PASSWORD_RESET_SUCCESS"
export const USER_PASSWORD_RESET_FAIL = "USER_PASSWORD_RESET_FAIL"
export const USER_PASSWORD_RESET_RESET = "USER_PASSWORD_RESET_RESET"

export const USER_PASSWORD_RESET_PROFILE_REQUEST = "USER_PASSWORD_RESET_PROFILE_REQUEST"
export const USER_PASSWORD_RESET_PROFILE_SUCCESS = "USER_PASSWORD_RESET_PROFILE_SUCCESS"
export const USER_PASSWORD_RESET_PROFILE_FAIL = "USER_PASSWORD_RESET_PROFILE_FAIL"
export const USER_PASSWORD_RESET_PROFILE_RESET = "USER_PASSWORD_RESET_PROFILE_RESET"

export const USER_CHANGE_PASSWORD_REQUEST = "USER_CHANGE_PASSWORD_REQUEST"
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS"
export const USER_CHANGE_PASSWORD_FAIL = "USER_CHANGE_PASSWORD_FAIL"
export const USER_CHANGE_PASSWORD_RESET = "USER_CHANGE_PASSWORD_RESET"


export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST'
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS'
export const USER_PROFILE_FAIL = 'USER_PROFILE_FAIL'
export const USER_PROFILE_RESET = 'USER_PROFILE_RESET'

export const USER_PROFILE_UPDATE_REQUEST = 'USER_PROFILE_UPDATE_REQUEST'
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS'
export const USER_PROFILE_UPDATE_FAIL = 'USER_PROFILE_UPDATE_FAIL'
export const USER_PROFILE_UPDATE_RESET = 'USER_PROFILE_UPDATE_RESET'

export const USER_SEND_MESSAGE_REQUEST = "USER_SEND_MESSAGE_REQUEST" 
export const USER_SEND_MESSAGE_SUCCESS = "USER_SEND_MESSAGE_SUCCESS" 
export const USER_SEND_MESSAGE_FAIL = "USER_SEND_MESSAGE_FAIL" 

export const USER_SEND_MESSAGE_ERROR = "USER_SEND_MESSAGE_ERROR"

export const USER_FETCH_MESSAGES_REQUEST = "USER_FETCH_MESSAGES_REQUEST"
export const USER_FETCH_MESSAGES_SUCCESS = "USER_FETCH_MESSAGES_SUCCESS"
export const USER_FETCH_MESSAGES_FAIL = "USER_FETCH_MESSAGES_FAIL"

export const USER_GET_NEW_MESSAGE_REQUEST = "USER_GET_NEW_MESSAGE_REQUEST"
export const USER_GET_NEW_MESSAGE_SUCCESS = "USER_GET_NEW_MESSAGE_SUCCESS"
export const USER_GET_NEW_MESSAGE_FAIL = "USER_GET_NEW_MESSAGE_FAIL"

export const USER_AUTH_EXPIRY_TIME = "USER_AUTH_EXPIRY_TIME"
export const USER_AUTH_TOKEN_REFRESH = "USER_AUTH_TOKEN_REFRESH"

export const USER_OWNER_PROFILE_REQUEST = "USER_OWNER_PROFILE_REQUEST"
export const USER_OWNER_PROFILE_SUCCESS = "USER_OWNER_PROFILE_SUCCESS"
export const USER_OWNER_PROFILE_FAIL = "USER_OWNER_PROFILE_FAIL"

export const USER_MESSAGE_NOTIFICATION = "USER_MESSAGE_NOTIFICATION"

export const USER_DOMAIN = 'USER_DOMAIN'
export const GET_USER = 'GET_USER' 
export const CURRENT_CATEGORY = 'CURRENT_CATEGORY'

export const NEW_TOKEN = "NEW_TOKEN"
export const NEW_TOKEN_FAIL = "NEW_TOKEN_FAIL"