import React, { useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import { PropertyContext } from "../../../../../context/property-context/property-context";
import { commercialPropertyTypes } from "../../../../property-listing/property-types";
import Radio from "antd/es/radio";
import "./forms.scss";
import { FormLabel } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import numeral from "numeral";
import Compressor from "compressorjs";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import Image from "antd/es/image";
import { PopupProviderContext } from "../../../../../common/popup-alerts-provider/popup-context";
import { logError } from "../../../../../utils/logError";

const useStyles = makeStyles(theme => ({
    textField: {
        fontSize: "13px"
    }
}));

export const CommercialForm = ({ property }) => {
    const classes = useStyles();

    const [uploading, setUploading] = useState<any>(false);
    const [deleteId, setDeleteId] = useState("");
    const { flashSuccess, flashError } = useContext(PopupProviderContext);

    const { PROPERTY_UPLOAD, reset, events, setEvents } = useContext(PropertyContext);

    const currencyOptions = [
        { label: <i className="fa-solid fa-cent-sign"></i>, value: "GHC" },
        { label: <i className="fa-solid fa-dollar-sign"></i>, value: "USD" }
    ];

    const currentYear: number = new Date().getFullYear();

    const validationSchemaCommercial = Yup.object({
        acquisition: Yup.string().oneOf(["buy", "rent"], "Incorrect value selected").required("Acquisition type is required"),
        payment_plan: Yup.string(),
        type: Yup.string().required("Property type is required"),
        description: Yup.string().required("Please provide a detailed description of this property"),
        price: Yup.string().required("Property price is required"),
        currency: Yup.string().required("Please select the currency"),
        location: Yup.string().required("Choose a location"),
        buildYear: Yup.number(),
        tags: Yup.array(),
        amenities: Yup.array(),
        images: Yup.array(),
        parking_capacity: Yup.number()
    });

    const formikCommercial = useFormik({
        initialValues: {
            category: property.category || "",
            acquisition: property.acquisition || "",
            payment_plan: property.payment_plan || "",
            type: property.type || "",
            description: property.description || "",
            price: property.price || "",
            currency: property.currency || "GHC",
            location: property.location || "",
            buildYear: property.buildYear || "",
            tags: property.tags || "",
            amenities: property.amenities || "",
            images: property.images || ([] as any),
            parking_capacity: property.parking_capacity || ""
        },
        validationSchema: validationSchemaCommercial,
        onSubmit: values => {
            setEvents([...events, PROPERTY_UPLOAD]);
            axios
                .post("/properties/create-commercial", {
                    ...values,
                    location: { town: "West Legon", city: "Accra", region: "Greater Accra", latitude: "5.6536", longitude: "-0.2081" }
                })
                .then((res: any) => {
                    console.log(res);
                    flashSuccess("Property uploaded successfully");
                    setTimeout(() => {
                        reset();
                    }, 1200);
                })
                .catch(err => {
                    console.log(err);
                    flashError("Something went wrong", logError(err));
                })
                .then(() => setEvents(events.filter((event: any) => event !== PROPERTY_UPLOAD)));
        }
    });

    const handleImageUpload = async (values: any) => {
        const fileList = new FormData();

        Array.from(values.target.files).forEach((file: any) => {
            fileList.append("files", file);
        });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };

        axios
            .post("properties/image-upload", fileList, config)
            .then((res: any) => {
                console.log(res);
                console.log(res.data);
                formikCommercial.setFieldValue("images", [...formikCommercial.values.images, ...res.data.filelocation]);
                setUploading(false);
            })
            .catch((err: any) => {
                setUploading(false);
                flashError("Something went wrong", "Could not complete process");
            });
        setUploading(true);
    };

    const handleFileDelete = (file: any) => {
        setDeleteId(file);
        axios
            .post("auth/delete-file", { filename: file })
            .then((res: any) => {
                console.log(res);
                const newLocation = formikCommercial.values.images.filter(files => files.name !== file);

                formikCommercial.setFieldValue("images", newLocation);
            })
            .catch(err => {
                console.log(err);
                flashError("Something went wrong", "Could not complete process");
            })
            .then(() => setDeleteId(""));
    };

    const handleUploadClick = () => {
        (document as any).getElementById("upload-btn").click();
    };

    const onPriceChange = e => {
        const value = e.target.value.replaceAll(",", "");
        const re = /^[0-9\b]+$/;
        if (value === "" || re.test(value)) {
            const value_to_number = Number(value);
            formikCommercial.setFieldValue("price", value_to_number);
        }
    };

    const handleAcquisitionChange = (e, value) => {
        if (e.target.checked) {
            if (value === "buy") formikCommercial.setFieldValue("payment_plan", "");
            formikCommercial.setFieldValue("acquisition", value);
        }
    };

    const handleCurrencyChange = e => {
        formikCommercial.setFieldValue("currency", e.target.value);
    };

    return (
        <form onSubmit={formikCommercial.handleSubmit}>
            <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                        <FormLabel component="legend">Property Type</FormLabel>
                        <Select
                            className={classes.textField}
                            name="type"
                            size="medium"
                            value={formikCommercial.values.type}
                            label="Property Type"
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={() => (
                                <div style={{ display: "flex", flexWrap: "wrap", fontWeight: "bold" }}>
                                    {formikCommercial.values.type && (
                                        <Chip color="info" key={formikCommercial.values.type} label={formikCommercial.values.type} />
                                    )}
                                </div>
                            )}
                            onChange={formikCommercial.handleChange}
                            error={formikCommercial.touched.type && formikCommercial.errors.type ? true : false}>
                            {commercialPropertyTypes.map((type: any, k: any) => (
                                <MenuItem key={k} value={type.value}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {formikCommercial.touched.type && formikCommercial.errors.type && (
                            <FormHelperText error>{formikCommercial.errors.type}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormControl sx={{}} component="fieldset" variant="outlined">
                        <FormLabel component="legend">Acquisition</FormLabel>
                        <FormGroup row sx={{ display: "flex" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formikCommercial.values.acquisition === "buy"}
                                        value="buy"
                                        onChange={e => handleAcquisitionChange(e, "buy")}
                                        name="acquisition"
                                    />
                                }
                                label="For Sale"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formikCommercial.values.acquisition === "rent"}
                                        value="rent"
                                        onChange={e => handleAcquisitionChange(e, "rent")}
                                        name="acquisition"
                                    />
                                }
                                label="For Rent"
                            />
                        </FormGroup>
                        <FormHelperText error>{formikCommercial.errors.acquisition}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} lg={6}>
                    {formikCommercial.values.acquisition === "rent" && (
                        <FormControl fullWidth>
                            <FormLabel component="legend">Payment Plan</FormLabel>
                            <Select
                                className={classes.textField}
                                name="payment_plan"
                                size="medium"
                                value={formikCommercial.values.payment_plan}
                                label="Property Type"
                                onChange={formikCommercial.handleChange}
                                error={formikCommercial.touched.payment_plan && formikCommercial.errors.payment_plan ? true : false}>
                                <MenuItem value={"DAILY"}>Daily payment</MenuItem>
                                <MenuItem value={"WEEKLY"}>Weekly payment</MenuItem>
                                <MenuItem value={"MONTHLY"}>Monthly payment</MenuItem>
                                <MenuItem value={"YEARLY"}>Yearly payment</MenuItem>
                            </Select>
                            {formikCommercial.touched.payment_plan && formikCommercial.errors.payment_plan && (
                                <FormHelperText error>{formikCommercial.errors.payment_plan}</FormHelperText>
                            )}
                        </FormControl>
                    )}
                </Grid>
                <Grid xs={12} lg={6}></Grid>

                <hr className="mb-5 mt-5" style={{ width: "100%" }} />
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormControl>
                                <FormLabel className="mb-3">Currency</FormLabel>
                                <Radio.Group
                                    size="large"
                                    style={{ fontSize: "8px", marginBottom: "0" }}
                                    options={currencyOptions}
                                    name="currency"
                                    onChange={handleCurrencyChange}
                                    value={formikCommercial.values.currency}
                                    optionType="button"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={8} className="d-flex align-items-end">
                            <FormControl>
                                <FormLabel className="mb-3">Price</FormLabel>
                                <TextField
                                    fullWidth
                                    className=""
                                    size="small"
                                    name="price"
                                    style={{ borderRadius: 0 }}
                                    value={numeral(formikCommercial.values.price).format("0,0")}
                                    onChange={e => onPriceChange(e)}
                                    InputProps={{
                                        startAdornment:
                                            formikCommercial.values.currency === "USD" ? (
                                                <i className="fa-solid fa-dollar-sign"></i>
                                            ) : (
                                                <i className="fa-solid fa-cent-sign"></i>
                                            )
                                    }}
                                    error={formikCommercial.touched.price && formikCommercial.errors.price ? true : false}
                                    variant="outlined"
                                />
                                {formikCommercial.touched.price && formikCommercial.errors.price && (
                                    <FormHelperText error>{formikCommercial.errors.price}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                        <FormLabel component="legend" className="mb-3">
                            Build Year
                        </FormLabel>
                        <TextField
                            className={classes.textField}
                            name="buildYear"
                            placeholder="Build year"
                            size="small"
                            type="number"
                            value={formikCommercial.values.buildYear}
                            onChange={formikCommercial.handleChange}
                            error={formikCommercial.touched.buildYear && formikCommercial.errors.buildYear ? true : false}
                        />
                        {formikCommercial.touched.buildYear && formikCommercial.errors.buildYear && (
                            <FormHelperText error>{formikCommercial.errors.buildYear}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid item xs={12} lg={12}>
                    <FormControl fullWidth>
                        <FormLabel component="legend">Property Description</FormLabel>
                        <TextField
                            className={classes.textField}
                            name="description"
                            multiline
                            maxRows={16}
                            minRows={4}
                            value={formikCommercial.values.description}
                            onChange={formikCommercial.handleChange}
                            error={formikCommercial.touched.description && formikCommercial.errors.description ? true : false}
                        />
                        {formikCommercial.touched.description && formikCommercial.errors.description && (
                            <FormHelperText error>{formikCommercial.errors.description}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid xs={6} item>
                    <Button type="button" variant="text" color="primary" onClick={handleUploadClick}>
                        <i className="fa-solid fa-plus me-2" style={{ fontSize: "18px" }}></i>
                        Upload Images
                    </Button>
                    {uploading && <small className="ms-2">uploading files...</small>}
                    <input hidden multiple type="file" onChange={handleImageUpload} id="upload-btn" />

                    <Grid container spacing={2}>
                        {formikCommercial.values.images.map((image: any, k: any) => (
                            <Grid item xs={3} sm={4} key={k}>
                                <div className="d-flex flex-column justify-content-start align-items-center">
                                    <Image style={{ height: "90px", width: "auto" }} src={image.location} alt="" />
                                    {image.name === deleteId ? (
                                        <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="visually-hidden">deleting...</span>
                                        </div>
                                    ) : (
                                        <i
                                            role="button"
                                            style={{ color: "red" }}
                                            onClick={() => handleFileDelete(image.name)}
                                            className="fa-solid fa-trash-can"></i>
                                    )}
                                </div>
                            </Grid>
                        ))}
                        {formikCommercial.errors.images && <FormHelperText error>{formikCommercial.errors.images}</FormHelperText>}
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginTop: "40px" }}>
                    <Button
                        // disabled={formikCommercial.touched.description && location.length == 0}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        // onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};
