import React from 'react';
import { IoIosArrowBack } from 'react-icons/io'
import '../../CSS_Styling/LoginPageCSS.css';

function ForgotPassword(props: any) {
    return (
        <div >
            <body>

                <div className="header-section">
                    <IoIosArrowBack fontSize="50px" onClick={() => props.history.goBack()} />
                    {/*<img className="Logo" src={Aqivah_Icon} alt="" />*/}
                    <p></p>
                </div>

                <div className="body">
                    <div className="box">
                        <h2>forgot password</h2>

                        <form action="">
                            <div className="input-holder">
                                <input type="text" name="" required={true} />
                                <label className="label" htmlFor="">* Email or Phone number</label>
                            </div>

                            <div className="input-holder">
                                <input type="submit" value="Submit" name="" />
                            </div>

                        </form>

                        <div className="forgot-password">
                            <p onClick={() => props.history.push('/login')} >Login</p>
                        </div>
                    </div>

                </div>

            </body>
        </div>
    );
}

export default ForgotPassword;
