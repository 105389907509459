import React, { useState } from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const UserIdentification = () => {

	const [fileList, setFileList] = useState<any>([
		{
			uid: '-1',
			name: 'zzz.png',
			status: 'done',
			url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
			thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
		}
	])

	const handleChange = (info) => {
		let fileList_ = [ ...info.fileList ];
		setFileList([...info.fileList])

		// 1. Limit the number of uploaded files
		// Only to show two recent uploaded files, and old ones will be replaced by the new
		fileList_ = fileList_.slice(-2);

		// 2. Read from response and show file link
		fileList_ = fileList_.map((file) => {
			if (file.response) {
				// Component will show file.url as link
				file.url = file.response.url;
			}
			return file;
		});

		//this.setState({ fileList });
		setFileList(fileList_)
	};
		const props = {
			action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
			onChange: handleChange,
			multiple: true
		};
		return (
			<div>
				<Upload
					{...props}
					action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
					listType="picture"
					defaultFileList={[ ...fileList ]}
				>
					<Button icon={<UploadOutlined />}>Upload ID</Button>
				</Upload>
				<br />
			</div>
		);
}

export default UserIdentification;
