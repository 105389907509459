import React, { useEffect, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import AttachImg from "../../../../assets/file-images/attached-file.png";
import PdfImg from "../../../../assets/file-images/pdf.png";
import ExcelImg from "../../../../assets/file-images/excel.png";
import WordImg from "../../../../assets/file-images/word.png";
import Img from "../../../../assets/file-images/image.png";
import ZipImg from "../../../../assets/file-images/zip.png";
import axios from "axios";
import { PopupProviderContext } from "../../../../common/popup-alerts-provider/popup-context";
import { logError } from "../../../../utils/logError";
import { Button } from "@mui/material";

export const Documents = () => {
    const FETCH_USER_DETAILS = "fetch-user-details";
    const DELETE_SUCCESS = "delete-success";

    const [events, setEvents] = useState<Array<string>>([]);
    const [userDetails, setUserDetails] = useState<any>(null);
    const [deleteId, setDeleteId] = useState("");

    const [uploading, setUploading] = useState<any>(false);
    const [name, setName] = useState<any>(0);
    const [location, setLocation] = useState<any>([]);
    const [files, setFiles] = useState<any>([]);

    const { flashError, flashSuccess } = useContext(PopupProviderContext);

    const extentionsList: any = {
        pdf: PdfImg,
        png: Img,
        jpg: Img,
        jpeg: Img,
        doc: WordImg,
        docx: WordImg,
        xlsx: ExcelImg,
        csv: ExcelImg,
        rar: ZipImg
    };

    const handleUpload = async (values: any) => {
        const fileList = new FormData();

        Array.from(values.target.files).forEach((file: any) => {
            fileList.append("files", file);
        });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };

        axios
            .post("auth/personal-documents/upload", fileList, config)
            .then((res: any) => {
                console.log(res);
                console.log(res.data);
                flashSuccess("Upload Successful!");
                fetchUserDetails();
                setFiles([...files, ...res.data.filename]);
                setName(res.data.filename);
                setLocation([...location, ...res.data.filelocation]);
                setUploading(false);
            })
            .catch((err: any) => {
                setUploading(false);
            });
        setUploading(true);
    };

    const handleUploadClick = () => {
        (document as any).getElementById("upload-btn").click();
    };

    function fetchUserDetails() {
        setEvents([...events, FETCH_USER_DETAILS]);
        axios
            .get(`/auth/user`)
            .then((res: any) => {
                setUserDetails(res.data.user);
            })
            .catch((err: any) => flashError("Something went wrong!", logError(err)))
            .then(() => setEvents(events.filter((event: string) => event !== FETCH_USER_DETAILS)));
    }

    const handleFileDelete = (file: any) => {
        setDeleteId(file);
        axios
            .post("auth/delete-file", { filename: file })
            .then((res: any) => {
                fetchUserDetails();
                setEvents([...events, DELETE_SUCCESS]);
            })
            .catch(err => {
                console.log(err);
                flashError("Something went wrong", "Could not complete process");
            })
            .then(() => setDeleteId(""));
    };

    useEffect(() => {
        fetchUserDetails();
    }, []);

    return (
        <div>
            <header className="flex mb-5">
                <h4 className="mb-4">Documents</h4>
                <div className="flex-1-1"></div>
                <button type="button" className="btn btn-primary" onClick={handleUploadClick}>
                    <i className="fa-solid fa-plus me-2"></i>
                    Upload File
                </button>
                {uploading && <small className="ms-2">uploading files...</small>}
                <input hidden multiple type="file" onChange={handleUpload} id="upload-btn" />
            </header>

            <Grid container spacing={3}>
                {userDetails?.owner_documents &&
                    userDetails?.owner_documents.map((item: any, i: any) => (
                        <Grid className="mb-3 d-flex flex-column justify-content-start align-items-center" item xs={6} md={4} lg={3}>
                            <img
                                alt=""
                                src={extentionsList[item.name.split(".")[1]] || AttachImg}
                                style={{ width: "55px", height: "auto" }}
                            />
                            <small>{item.name.split(".")[0]}</small>
                            {item.name === deleteId ? (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">deleting...</span>
                                </div>
                            ) : (
                                <i
                                    role="button"
                                    style={{ color: "red" }}
                                    onClick={() => handleFileDelete(item.name)}
                                    className="fa-solid fa-trash-can"></i>
                            )}
                        </Grid>
                    ))}
                {/* <Grid className="mb-3 d-flex flex-column justify-content-start align-items-center" item xs={6} md={4} lg={3}>
                    <img alt="" src={extentionsList["pdf"] || AttachImg} style={{ width: "55px", height: "auto" }} />
                    <small>Certificate of Business</small>
                </Grid>
                <Grid className="mb-3 d-flex flex-column justify-content-start align-items-center" item xs={6} md={4} lg={3}>
                    <img alt="" src={extentionsList["pdf"] || AttachImg} style={{ width: "55px", height: "auto" }} />
                    <small>Land Certificate</small>
                </Grid>
                <Grid className="mb-3 d-flex flex-column justify-content-start align-items-center" item xs={6} md={4} lg={3}>
                    <img alt="" src={extentionsList["doc"] || AttachImg} style={{ width: "55px", height: "auto" }} />
                    <small>Land Agreement</small>
                </Grid>
                <Grid className="mb-3 d-flex flex-column justify-content-start align-items-center" item xs={6} md={4} lg={3}>
                    <img alt="" src={extentionsList["jpeg"] || AttachImg} style={{ width: "55px", height: "auto" }} />
                    <small>Owner ID</small>
                </Grid>
                <Grid className="mb-3 d-flex flex-column justify-content-start align-items-center" item xs={6} md={4} lg={3}>
                    <img alt="" src={extentionsList["rar"] || AttachImg} style={{ width: "55px", height: "auto" }} />
                    <small>Supporting Documents</small>
                </Grid>
                <Grid className="mb-3 d-flex flex-column justify-content-start align-items-center" item xs={6} md={4} lg={3}>
                    <img alt="" src={extentionsList["pdf"] || AttachImg} style={{ width: "55px", height: "auto" }} />
                    <small>Property Bill Payments</small>
                </Grid> */}
            </Grid>
        </div>
    );
};
