import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Alert from "antd/es/alert";
import { makeStyles } from "@mui/styles";
import Header from "../../components/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AdminProviderContext } from "../../common/admin-provider/admin-provider-context";
import * as Yup from "yup";
import { useFormik } from "formik";
import Spin from "antd/es/spin";
import AttachImg from "../../../../assets/file-images/attached-file.png";
import PdfImg from "../../../../assets/file-images/pdf.png";
import ExcelImg from "../../../../assets/file-images/excel.png";
import WordImg from "../../../../assets/file-images/word.png";
import Img from "../../../../assets/file-images/image.png";
import ZipImg from "../../../../assets/file-images/zip.png";

import "./register.scss";
import { values } from "lodash";

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh"
    },
    image: {
        //backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundImage:
            "url(https://images.unsplash.com/photo-1593604340846-4fbe9763a8f3?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80)",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center"
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    textField: {
        fontSize: "11px"
    }
}));

interface IMessage {
    message: string;
    type: undefined | "info" | "success" | "error";
}

export default function Register() {
    const classes = useStyles();
    const [message, setMessage] = useState<IMessage>({ message: "", type: undefined });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState<any>(false);
    const [name, setName] = useState<any>(0);
    const [location, setLocation] = useState<any>([]);
    const [files, setFiles] = useState<any>([]);
    const [deleteId, setDeleteId] = useState("");
    const [accountType, setAccountType] = useState("");

    const { refreshAdmin } = useContext(AdminProviderContext);

    const handleUploadPersonal = async (values: any) => {
        const fileList = new FormData();

        Array.from(values.target.files).forEach((file: any) => {
            fileList.append("files", file);
        });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };

        axios
            .post("auth/personal-documents/upload", fileList, config)
            .then((res: any) => {
                console.log(res);
                console.log(res.data);
                setFiles([...files, ...res.data.filename]);
                setName(res.data.filename);
                setLocation([...location, ...res.data.filelocation]);
                setUploading(false);
            })
            .catch((err: any) => {
                setUploading(false);
            });
        setUploading(true);
    };

    const handleUploadOrganization = async (values: any) => {
        const fileList = new FormData();

        Array.from(values.target.files).forEach((file: any) => {
            fileList.append("files", file);
        });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };

        axios
            .post("auth/upload-document", fileList, config)
            .then((res: any) => {
                console.log(res);
                console.log(res.data);
                setFiles([...files, ...res.data.filename]);
                setName(res.data.filename);
                setLocation([...location, ...res.data.filelocation]);
                setUploading(false);
            })
            .catch((err: any) => {
                setUploading(false);
            });
        setUploading(true);
    };

    const handleFileDelete = (file: any) => {
        setDeleteId(file);
        axios
            .post("auth/delete-file", { filename: file })
            .then((res: any) => {
                console.log(res);
                const newList = files.filter(filename => filename !== file);
                const newLocation = location.filter(files => files.name !== file);
                setFiles(newList);
                setLocation(newLocation);
            })
            .catch(err => {
                console.log(err);
                setMessage({ message: "Could not complete process", type: "error" });
            })
            .then(() => setDeleteId(""));
    };

    const handleUploadClick = () => {
        (document as any).getElementById("upload-btn").click();
    };

    const handleAccountType = e => {
        if (e.target.checked) {
            setAccountType(e.target.name);
        } else {
            setAccountType("");
        }
    };

    const validationSchemaIndividual = Yup.object({
        email: Yup.string(),
        phone: Yup.string()
    });

    const formikIndividual = useFormik({
        initialValues: { email: "", phone: "" },
        validationSchema: validationSchemaIndividual,
        onSubmit: values => {
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            setMessage({ message: "", type: undefined });
            setLoading(true);
            axios
                .post("/auth/owner-registration", values, config)
                .then((res: any) => {
                    console.log(res);
                    refreshAdmin();
                    setMessage({ message: "Registration Successful", type: "success" });
                    setLoading(false);
                    setTimeout(() => {
                        navigate("/owner-dashboard");
                    }, 1500);
                })
                .catch(() => setMessage({ message: "Could not complete process", type: "error" }))
                .then(() => setLoading(false));
        }
    });

    const validationSchemaOrganization = Yup.object({
        name: Yup.string().required("Email is required"),
        email: Yup.string().email("Invalid email address").required("Password is required"),
        phone: Yup.number().required("Phone number is required"),
        address: Yup.string().required("Please enter the address of the organization")
    });

    const formikOrganization = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            address: ""
        },
        validationSchema: validationSchemaOrganization,
        onSubmit: values => {
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            setMessage({ message: "", type: undefined });
            setLoading(true);
            axios
                .post("/auth/register-organization", { ...values, documents: location })
                .then((res: any) => {
                    refreshAdmin();
                    setMessage({ message: "Registration Successful", type: "success" });
                    setLoading(false);
                    setTimeout(() => {
                        navigate("/owner-dashboard");
                    }, 1500);
                })
                .catch(() => setMessage({ message: "Could not complete process", type: "error" }))
                .then(() => setLoading(false));
        }
    });

    function check() {
        console.log(formikIndividual.values);
    }

    return (
        <Grid container id="register-page" component="main" className={classes.root}>
            <Header />

            <div className="register-card">
                <h3 className="mx-auto fw-bold">Become a Registered Owner on Aqivah</h3>
                <p className="">List properties on Aqivah</p>

                {message.message ? (
                    <div className="text-center">
                        <Alert message={message.message} type={message.type}>
                            {message.message}
                        </Alert>
                    </div>
                ) : (
                    ""
                )}
                {loading && (
                    <div className="text-center">
                        <Spin />
                    </div>
                )}
                <div className="pb-5"></div>

                <form
                    onSubmit={
                        accountType === "individual"
                            ? formikIndividual.handleSubmit
                            : accountType === "organization"
                            ? formikOrganization.handleSubmit
                            : () => ""
                    }>
                    {/* <Grid container>
                        <Grid item xs={5}> */}
                    <Grid container spacing={3}>
                        <Grid xs={6} item>
                            <FormControlLabel
                                control={<Checkbox checked={accountType === "individual"} />}
                                name="individual"
                                onChange={handleAccountType}
                                label="Individual"
                            />

                            <FormControlLabel
                                control={<Checkbox checked={accountType === "organization"} />}
                                name="organization"
                                onChange={handleAccountType}
                                label="Organization"
                            />
                        </Grid>
                        <Grid xs={6}></Grid>
                        {accountType === "organization" && (
                            <Grid xs={6} item>
                                <TextField
                                    className={classes.textField}
                                    name="name"
                                    fullWidth
                                    placeholder="Organization Name"
                                    value={formikOrganization.values.name}
                                    onChange={formikOrganization.handleChange}
                                    error={formikOrganization.touched.name && formikOrganization.errors.name ? true : false}
                                    helperText={formikOrganization.touched.name && formikOrganization.errors.name}
                                />
                            </Grid>
                        )}
                        {accountType === "organization" && (
                            <Grid xs={6} item>
                                <TextField
                                    className={classes.textField}
                                    name="email"
                                    fullWidth
                                    placeholder="Organization Email"
                                    value={formikOrganization.values.email}
                                    onChange={formikOrganization.handleChange}
                                    error={formikOrganization.touched.email && formikOrganization.errors.email ? true : false}
                                    helperText={formikOrganization.touched.email && formikOrganization.errors.email}
                                />
                            </Grid>
                        )}
                        {accountType === "organization" && (
                            <Grid xs={6} item>
                                <TextField
                                    className={classes.textField}
                                    name="phone"
                                    fullWidth
                                    placeholder="Organization Phone Number"
                                    value={formikOrganization.values.phone}
                                    onChange={formikOrganization.handleChange}
                                    error={formikOrganization.touched.phone && formikOrganization.errors.phone ? true : false}
                                    helperText={formikOrganization.touched.phone && formikOrganization.errors.phone}
                                />
                            </Grid>
                        )}
                        {accountType === "organization" && (
                            <Grid xs={6} item>
                                <TextField
                                    className={classes.textField}
                                    name="address"
                                    fullWidth
                                    placeholder="Organization Address"
                                    value={formikOrganization.values.address}
                                    onChange={formikOrganization.handleChange}
                                    error={formikOrganization.touched.address && formikOrganization.errors.address ? true : false}
                                    helperText={formikOrganization.touched.address && formikOrganization.errors.address}
                                />
                            </Grid>
                        )}
                        {accountType === "organization" && (
                            <Grid xs={6} item>
                                <h6>Upload Documents</h6>
                                <Button type="button" variant="text" color="primary" onClick={handleUploadClick}>
                                    <i className="fa-solid fa-plus me-2" style={{ fontSize: "18px" }}></i>
                                    Upload File(s)
                                </Button>
                                {uploading && <small className="ms-2">uploading files...</small>}
                                {files.map((file: any, key: any) => (
                                    <div key={key}>
                                        <small style={{ marginRight: "5px" }}>{file}</small>
                                        {file == deleteId ? (
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="visually-hidden">deleting...</span>
                                            </div>
                                        ) : (
                                            <i
                                                role="button"
                                                style={{ color: "red" }}
                                                onClick={() => handleFileDelete(file)}
                                                className="fa-solid fa-trash-can"></i>
                                        )}
                                    </div>
                                ))}
                                <input hidden multiple type="file" onChange={handleUploadOrganization} id="upload-btn" />
                            </Grid>
                        )}
                        {accountType === "individual" && (
                            <Grid xs={6} item>
                                <h6>Upload Documents</h6>
                                <Button type="button" variant="text" color="primary" onClick={handleUploadClick}>
                                    <i className="fa-solid fa-plus me-2" style={{ fontSize: "18px" }}></i>
                                    Upload File(s)
                                </Button>
                                {uploading && <small className="ms-2">uploading files...</small>}
                                {files.map((file: any, key: any) => (
                                    <div key={key}>
                                        <small style={{ marginRight: "5px" }}>{file}</small>
                                        {file == deleteId ? (
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="visually-hidden">deleting...</span>
                                            </div>
                                        ) : (
                                            <i
                                                role="button"
                                                style={{ color: "red" }}
                                                onClick={() => handleFileDelete(file)}
                                                className="fa-solid fa-trash-can"></i>
                                        )}
                                    </div>
                                ))}
                                <input hidden multiple type="file" onChange={handleUploadPersonal} id="upload-btn" />
                            </Grid>
                        )}
                        <Grid xs={12} item>
                            <button className="register-button" onClick={check} type="submit">
                                <i className="fa-solid fa-arrow-right text-white"></i>
                            </button>
                        </Grid>
                    </Grid>
                    {/* </Grid>
                        <Grid item xs={7}></Grid>
                    </Grid> */}
                </form>
            </div>
        </Grid>
    );
}
