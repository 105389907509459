import React, { useState, useContext } from "react";
import Typography from "antd/es/typography";
import Alert from "antd/es/alert";
// import TextField from "@mui/material/TextField";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import axios, { AxiosResponse } from "axios";
import { AxiosError } from "axios";
import { AdminProviderContext } from "../../common/admin-provider/admin-provider-context";
import { AdminProvider } from "../../common/admin-provider/admin-provider";
import { AUTH_TOKEN_KEY, USER_KEY, ORGANIZATION_KEY } from "../../common";
import Checkbox from "@mui/material/Checkbox";
import { css } from "../../config/my-aphrodite";
import styles from "../../Styles/AuthenticationFormsStyle";
import * as Yup from "yup";
import { useFormik } from "formik";
import Spin from "antd/es/spin";
// import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { logError } from "../../utils/logError";
import "./auth-styling.scss";

type UserType = "user" | "organization";

export const Login = ({ openSignUp, openForgotPassword, closeLogin, owner = false }) => {
    const { Title } = Typography;

    const { user, refreshAdmin } = useContext(AdminProviderContext) || {};

    const [userType, setUserType] = useState<UserType>(owner ? "organization" : "user");

    const useStyles = makeStyles(theme => ({
        root: {
            height: "100vh"
        },
        image: {
            backgroundImage: "url(https://source.unsplash.com/random)",
            backgroundRepeat: "no-repeat",
            backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center"
        },
        paper: {
            margin: theme.spacing(8, 4),
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(1)
        },
        submit: {
            margin: theme.spacing(3, 0, 2)
        }
    }));

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const validationSchemaLogin = Yup.object({
        email: Yup.string().email("Invalid email format").required("Email is required"),
        password: Yup.string().required("Password is required")
    });

    const formikLogin = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: validationSchemaLogin,
        onSubmit: values => {
            console.log("login init");
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            setLoading(true);
            setSuccess(false);
            setError("");
            if (userType === "user") {
                axios
                    .post("/auth/login", values, config)
                    .then((res: any) => {
                        console.log(res);
                        localStorage.removeItem(ORGANIZATION_KEY);
                        localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(res.data.token));
                        localStorage.setItem(USER_KEY, JSON.stringify(res.data.user));
                        setError("");
                        setLoading(false);
                        setSuccess(true);
                        refreshAdmin();
                        setTimeout(() => {
                            closeLogin();
                        }, 2000);
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(logError(err));
                        setError(logError(err));
                        console.log(err.response.data.error);
                        setLoading(false);
                        setSuccess(false);
                    });
            }
            if (userType === "organization") {
                axios
                    .post("/organizations/login", values, config)
                    .then((res: any) => {
                        console.log(res);
                        localStorage.removeItem(USER_KEY);
                        localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(res.data.token));
                        localStorage.setItem(ORGANIZATION_KEY, JSON.stringify(res.data.organization));
                        setError("");
                        setLoading(false);
                        setSuccess(true);
                        refreshAdmin();
                        setTimeout(() => {
                            window.location.href = "/organization-dashboard";
                        }, 2000);
                    })
                    .catch(err => {
                        console.log(err);
                        setError(logError(err));
                        console.log(err.response.data.error);
                        setLoading(false);
                        setSuccess(false);
                    });
            }
        }
    });

    return (
        <div id="login">
            <div className="login-text">
                <div className="text-section">
                    {" "}
                    <Title level={3}>Login</Title>
                </div>
            </div>

            <Grid container spacing={2} className="mb-4">
                <Grid item xs={12}>
                    <Grid
                        className={`user-type-select ${userType === "user" ? "selected" : ""}`}
                        onClick={() => setUserType("user")}
                        container
                        spacing={0}>
                        <Grid className="d-flex align-items-start pt-1" item xs={1}>
                            {/* <Checkbox checked={userType === "user" ? true : false} />{" "} */}
                            <input type="checkbox" checked={userType === "user" ? true : false} />
                        </Grid>
                        <Grid item xs={11}>
                            <h6 className="fw-bold subject">User</h6>
                            <p className="p-description">Account owner that performs tasks requiring unrestricted access</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        className={`user-type-select ${userType === "organization" ? "selected" : ""}`}
                        onClick={() => setUserType("organization")}
                        container
                        spacing={0}>
                        <Grid item className="d-flex align-items-start pt-1" style={{ height: "100%" }} xs={1}>
                            {/* <Checkbox size="small" style={{ margin: 0 }} checked={userType === "organization" ? true : false} />{" "} */}
                            <input type="checkbox" checked={userType === "organization" ? true : false} />
                        </Grid>
                        <Grid item xs={11}>
                            <h6 className="fw-bold subject">Organization</h6>
                            <p className="p-description">User within an account that performs daily tasks</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {error ? (
                <div style={{ marginBottom: "20px" }} className="auth-spinner mb-3">
                    {" "}
                    <Alert message={error} type="error" showIcon />{" "}
                </div>
            ) : (
                ""
            )}
            {success ? (
                <div style={{ marginBottom: "20px" }} className="auth-spinner mb-3">
                    {" "}
                    <Alert message={"Signed in"} type="success" showIcon />{" "}
                </div>
            ) : (
                ""
            )}

            <form onSubmit={formikLogin.handleSubmit}>
                <div /*className="input-holder"*/ style={{ width: "100%" }}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="login_email_l"
                            label="Email"
                            name="email"
                            autoFocus
                            value={formikLogin.values.email}
                            onChange={formikLogin.handleChange}
                            error={formikLogin.touched.email && formikLogin.errors.email ? true : false}
                            helperText={formikLogin.touched.email && formikLogin.errors.email}
                            size="small"
                        />
                    </Grid>
                </div>

                <div
                    /*className={css(styles.box_form_div_password)}*/ style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        width: "100%"
                    }}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="login_password_"
                            label="Password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            value={formikLogin.values.password}
                            onChange={formikLogin.handleChange}
                            error={formikLogin.touched.password && formikLogin.errors.password ? true : false}
                            helperText={formikLogin.touched.password && formikLogin.errors.password}
                            InputProps={{
                                endAdornment:
                                    formikLogin.values.password !== "" ? (
                                        !showPassword ? (
                                            <i onClick={() => setShowPassword(!showPassword)} className="fas fa-eye"></i>
                                        ) : (
                                            <i onClick={() => setShowPassword(!showPassword)} className="fas fa-eye-slash"></i>
                                        )
                                    ) : null
                            }}
                            size="small"
                        />
                    </Grid>
                </div>

                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                    {loading ? <Spin spinning={true} size="small"></Spin> : "Login"}
                </Button>
            </form>

            <Grid container style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
                <Link style={{ textDecoration: "none", textTransform: "none" }} onClick={openSignUp} variant="body2">
                    <small>Register</small>
                </Link>

                <Link style={{ textDecoration: "none", textTransform: "none" }} onClick={openForgotPassword} variant="body2">
                    <small>Forgot Password or Username?</small>
                </Link>
            </Grid>
        </div>
    );
};
