import React from 'react';

import { Card } from 'antd';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { documentsFile } from '../data/documents';

const { Meta } = Card;

const Documents = () => {
		return (
			<div className="documents-array">
				{documentsFile.map((document) => {
					return (
						<div className="document-box">
							<Card
								cover={<img alt="example" src={document.image} />}
								style={{ height: '100%', marginTop: '10px', marginRight: '20px' }}
								actions={[
									<SettingOutlined key="setting" />,
									<EditOutlined key="edit" />,
									<EllipsisOutlined key="ellipsis" />
								]}
							>
								<Meta title={document.title} description={document.description} />
							</Card>
						</div>
					);
				})}
			</div>
		);
}

export default Documents;
