import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Divider from "antd/es/divider";
import numeral from "numeral";
import Modal from "antd/es/modal";
import { UpdatePropertyPage } from "../update-property/update-property";
import "./properties.scss";

interface IPropertyCard {
    property: any;
}

export default function PropertyCard({ property }: IPropertyCard) {
    const [open, setOpen] = useState(false);

    const close = () => {
        setOpen(false);
    };

    const acquisition = {
        buy: "For Sale",
        rent: "For Rent"
    };

    const currency = {
        USD: "$ ",
        GHC: (
            <>
                GH<i className="fa-solid fa-cent-sign me-1"></i>
            </>
        )
    };

    return (
        <Card id="property-card-m0" className="search-property-card-m0" sx={{ maxWidth: 355 }}>
            <Modal open={open} footer={null} onCancel={close} width={820} title={"Update Property"}>
                <UpdatePropertyPage id={property._id} />
            </Modal>
            <div className="acquisition-tag d-flex justify-content-center align-items-center">{acquisition[property.acquisition]}</div>
            <CardMedia
                component="img"
                height="194"
                image={property.images[0]?.location ? property.images[0]?.location : property.images[0]}
            />
            <CardContent>
                <div className="d-flex justify-content-between align-items-center mb-1">
                    <h6 className="fw-bold tag-text">{property.type}</h6>
                    <i style={{ color: "#3089e8", fontSize: "20px" }} className="fa-regular fa-heart"></i>
                </div>
                <div className="d-flex mb-1">
                    <h6 className="fw-bold location-text">{property.location.town ? property.location.town : property.location}</h6>
                </div>
                <div className="d-flex mb-5">
                    <h5 className="fw-bold">
                        {currency[property.currency]}
                        {numeral(property.price || "").format("0,0")}
                    </h5>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-2">
                    <span className="d-flex align-items-center" style={{ fontSize: "11px" }}>
                        <i style={{ color: "#cecece", fontSize: "15px" }} className="fa-solid fa-bed me-1"></i>
                        {property.bedrooms} Beds
                    </span>
                    <Divider type="vertical" />
                    <span className="d-flex align-items-center" style={{ fontSize: "11px" }}>
                        <i style={{ color: "#cecece", fontSize: "15px" }} className="fa-solid fa-shower me-1"></i>
                        {property.bathrooms} Baths
                    </span>
                    <Divider type="vertical" />
                    <span className="d-flex align-items-center" style={{ fontSize: "11px" }}>
                        <i style={{ color: "#cecece", fontSize: "15px" }} className="fa-solid fa-ruler-combined me-1"></i> 1720 ft
                        <sup>2</sup>
                    </span>
                </div>

                <div className="d-flex mb-2">
                    <i
                        style={{ fontSize: "18px", cursor: "pointer" }}
                        onClick={() => setOpen(true)}
                        className="fa-solid fa-pen-to-square"></i>
                </div>
                <div className="d-flex fw-light listing-line">Listing by Murdock Square Group (Accra, Ghana)</div>
            </CardContent>
        </Card>
    );
}
