import React, { useEffect, useState } from "react";
import DatePicker from "antd/es/date-picker";
import axios from "axios";

export const BookViewing = ({ property }) => {
    const [date, setDate] = useState("");

    const handleDatePick = (date: any, dateString: any) => {
        console.log(dateString);
        setDate(dateString);
    };

    const bookViewing = () => {
        axios
            .post("/properties/book-viewing", { owner_id: property.ownerId, property_id: property._id, date: date })
            .then(() => console.log("booked!"))
            .catch((err: any) => console.log(err));
    };

    useEffect(() => {
        console.log(property);
    }, []);

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center">
                <DatePicker showTime disabledDate={(date: any) => date < new Date()} onChange={handleDatePick} />
            </div>

            <div className="mt-4">
                <button onClick={bookViewing}>Book Viewing</button>
            </div>
        </div>
    );
};
