import React from 'react';
import { Menu, Dropdown } from 'antd';
import { NavLink } from 'react-router-dom';

const menu = (
	<Menu>
		<Menu.Item>
			<NavLink to="/profile">Profile</NavLink>
		</Menu.Item>
	</Menu>
);
function MenuDropdown () {
		return (
			<Dropdown overlay={menu} placement="bottomRight">
				<a
					href="/#"
					style={{
						marginRight: '20px'
					}}
					className="ant-dropdown-link"
					onClick={(e) => e.preventDefault()}
				>
					My Aqivah
				</a>
			</Dropdown>
		);
}

export default MenuDropdown;
