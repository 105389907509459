import { 
    SEARCH_RESULTS_SUCCESS, 
    SEARCH_RESULTS_FAIL, 
    SEARCH_RESULTS_REQUEST, 
    SEARCH_RESULTS_RESET } 
from "../constants/propertyConstants"

export const SearchResults = (state = {properties:[], loading:false}, action: any) => {
    switch (action.type) {
        case SEARCH_RESULTS_REQUEST: 
            return {loading: true, properties:[]}
        case SEARCH_RESULTS_SUCCESS:
            return { loading:false, properties:action.payload}
        case SEARCH_RESULTS_FAIL: 
            return { loading: false, error: action.payload }
        case SEARCH_RESULTS_RESET: 
            return { properties: [], loading:false}
        default:
            return state
    }
}

export const CurrentUser = (state = null, action: any) => {
    switch (action.type) {
        case 'GET_USER':
            return action.user

        default:
            return state

    }
}

export const currentCategory = (state = 'residential', action: any) => {
    switch (action.type) {
        case 'CURRENT_CATEGORY':
            return action.category

        default:
            return state
    }
}
