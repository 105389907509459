var documentsFile = [
	{
		image: 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
		title: 'Apartment ownership',
		description: 'First document'
	},
	{
		image: 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
		title: 'House ownership',
		description: 'This is the description'
	},
	{
		image: 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
		title: 'Apartment ownership',
		description: 'This is the description'
	},
	{
		image: 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
		title: 'Forth document',
		description: 'This is the description'
	},
	{
		image: 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
		title: 'Fifth document',
		description: 'This is the description'
	},
	{
		image: 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
		title: 'Sixth document',
		description: 'This is the description'
	},
	{
		image: 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
		title: 'Seventh document',
		description: 'This is the description'
	},
	{
		image: 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
		title: 'Eighth document',
		description: 'This is the description'
	},
	{
		image: 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
		title: 'Nineth document',
		description: 'This is the description'
	},
	{
		image: 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
		title: 'Tenth document',
		description: 'This is the description'
	}
];
module.exports = {
	documentsFile
};
