var saved = [
	{
		image: 'https://cdn.pixabay.com/photo/2016/06/24/10/47/architecture-1477041_960_720.jpg',
		type: 'House',
		description: 'House Details'
	},
	{
		image: 'https://cdn.pixabay.com/photo/2017/03/19/01/43/living-room-2155376_960_720.jpg',
		type: 'Apartment',
		description: 'Apartment Details'
	},
	{
		image: 'https://cdn.pixabay.com/photo/2016/11/18/17/46/architecture-1836070__340.jpg',
		type: 'House',
		description: 'House Details'
	},
	{
		image: 'https://cdn.pixabay.com/photo/2014/08/11/21/40/wall-416062_960_720.jpg',
		type: 'Apartment',
		description: 'Apartment Details'
	},
	{
		image: 'https://cdn.pixabay.com/photo/2016/06/24/10/47/architecture-1477041_960_720.jpg',
		type: 'House',
		description: 'House Details'
	},
	{
		image: 'https://cdn.pixabay.com/photo/2017/03/19/01/43/living-room-2155376_960_720.jpg',
		type: 'Apartment',
		description: 'Apartment Details'
	},
	{
		image: 'https://cdn.pixabay.com/photo/2016/11/18/17/46/architecture-1836070__340.jpg',
		type: 'House',
		description: 'House Details'
	},
	{
		image: 'https://cdn.pixabay.com/photo/2014/08/11/21/40/wall-416062_960_720.jpg',
		type: 'Apartment',
		description: 'Apartment Details'
	}
];

var recommended = [
	{
		image: 'https://cdn.pixabay.com/photo/2013/10/09/02/27/boat-house-192990__340.jpg',
		type: 'House',
		description: 'House Details'
	},
	{
		image:
			'https://media.istockphoto.com/photos/bohemian-living-room-interior-3d-render-picture-id1182454657?b=1&k=6&m=1182454657&s=170667a&w=0&h=KnmxqIVEZ98YHhcXzIauU1mBwF5XxdP8dUmrwQEAmbs=',
		type: 'Apartment',
		description: 'Apartment Details'
	},
	{
		image: 'https://cdn.pixabay.com/photo/2014/07/10/17/18/large-home-389271__340.jpg',
		type: 'House',
		description: 'House Details'
	},
	{
		image:
			'https://media.istockphoto.com/photos/modern-bright-and-airy-scandinavian-design-living-room-picture-id1188732010?b=1&k=6&m=1188732010&s=170667a&w=0&h=lxQW4ApH3My-KekR3cXSevWE4yOWaKkLFFxu7TB2Ub4=',
		type: 'Apartment',
		description: 'Apartment Details'
	},
	{
		image: 'https://cdn.pixabay.com/photo/2016/11/18/17/46/architecture-1836070__340.jpg',
		type: 'House',
		description: 'House Details'
	},
	{
		image:
			'https://media.istockphoto.com/photos/bohemian-living-room-interior-3d-render-picture-id1182454657?b=1&k=6&m=1182454657&s=170667a&w=0&h=KnmxqIVEZ98YHhcXzIauU1mBwF5XxdP8dUmrwQEAmbs=',
		type: 'Apartment',
		description: 'Apartment Details'
	},
	{
		image: 'https://cdn.pixabay.com/photo/2014/07/10/17/18/large-home-389271__340.jpg',
		type: 'House',
		description: 'House Details'
	},
	{
		image:
			'https://media.istockphoto.com/photos/modern-bright-and-airy-scandinavian-design-living-room-picture-id1188732010?b=1&k=6&m=1188732010&s=170667a&w=0&h=lxQW4ApH3My-KekR3cXSevWE4yOWaKkLFFxu7TB2Ub4=',
		type: 'Apartment',
		description: 'Apartment Details'
	}
];

module.exports = {
	saved,
	recommended
};
