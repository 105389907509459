const data01 = [
	{
		name: 'Group A',
		value: 400
	},
	{
		name: 'Group B',
		value: 300
	},
	{
		name: 'Group C',
		value: 300
	},
	{
		name: 'Group D',
		value: 200
	},
	{
		name: 'Group E',
		value: 278
	},
	{
		name: 'Group F',
		value: 189
	}
];
const data02 = [
	{
		name: 'Group A',
		value: 2400
	},
	{
		name: 'Group B',
		value: 4567
	},
	{
		name: 'Group C',
		value: 1398
	},
	{
		name: 'Group D',
		value: 9800
	},
	{
		name: 'Group E',
		value: 3908
	},
	{
		name: 'Group F',
		value: 4800
	}
];

const data03 = [
	{
		name: 'Page A',
		uv: 4000,
		pv: 2400,
		amt: 2400
	},
	{
		name: 'Page B',
		uv: 3000,
		pv: 1398,
		amt: 2210
	},
	{
		name: 'Page C',
		uv: 2000,
		pv: 9800,
		amt: 2290
	},
	{
		name: 'Page D',
		uv: 2780,
		pv: 3908,
		amt: 2000
	},
	{
		name: 'Page E',
		uv: 1890,
		pv: 4800,
		amt: 2181
	},
	{
		name: 'Page F',
		uv: 2390,
		pv: 3800,
		amt: 2500
	},
	{
		name: 'Page G',
		uv: 3490,
		pv: 4300,
		amt: 2100
	}
];
const data04 = [
	{
		name: 'Page A',
		uv: 4000,
		pv: 2400
	},
	{
		name: 'Page B',
		uv: 3000,
		pv: 1398
	},
	{
		name: 'Page C',
		uv: 2000,
		pv: 9800
	},
	{
		name: 'Page D',
		uv: 2780,
		pv: 3908
	},
	{
		name: 'Page E',
		uv: 1890,
		pv: 4800
	},
	{
		name: 'Page F',
		uv: 2390,
		pv: 3800
	},
	{
		name: 'Page G',
		uv: 3490,
		pv: 4300
	}
];
const data05 = [
	{
		name: 'Page A',
		uv: 4000,
		pv: 2400,
		amt: 2400
	},
	{
		name: 'Page B',
		uv: 3000,
		pv: 1398,
		amt: 2210
	},
	{
		name: 'Page C',
		uv: 2000,
		pv: 9800,
		amt: 2290
	},
	{
		name: 'Page D',
		uv: 2780,
		pv: 3908,
		amt: 2000
	},
	{
		name: 'Page E',
		uv: 1890,
		pv: 4800,
		amt: 2181
	},
	{
		name: 'Page F',
		uv: 2390,
		pv: 3800,
		amt: 2500
	},
	{
		name: 'Page G',
		uv: 3490,
		pv: 4300,
		amt: 2100
	}
];

module.exports = {
	data01,
	data02,
	data03,
	data04,
	data05
};
