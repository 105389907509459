import React, { useEffect, useState } from "react";
// import { UserPool } from "../config/UserPool";
import { useDispatch, useSelector } from "react-redux";
// import { getUserProfile, fetchMessages, authExpiryTime, authTokenRefresh, getNewMessage } from "../Actions/userActions";
// import { getUser } from "../Actions/ActionCreator";
// import { getCurrentHostname } from "../Actions/userActions";
// import { io } from "socket.io-client";
// import { socket } from "../config/socketioConfig";
import ReactGA from "react-ga";
// import AWS from "aws-sdk";

//@@ Run on app load, configures AWS sdk, connect to socketIO
function useAppInitialize() {
    const dispatch = useDispatch();
    useEffect(() => {
        // console.log(process.env.REACT_APP_TEST_VARIABLE)
        // AWS.config.update({
        //   region: 'us-west-2',
        //   credentials: new AWS.Credentials({
        //     accessKeyId: process.env.REACT_APP_AccessKey as string,
        //     secretAccessKey: process.env.REACT_APP_SecretAccessKey as string
        //   })
        // })

        // dispatch(getCurrentHostname())

        // const cognitoUser = UserPool.getCurrentUser()

        // if (cognitoUser !== null) {
        //   cognitoUser.getSession(function (err: any, session: any) {
        //     if (err) {
        //       console.log(err);
        //       return
        //     }
        //     dispatch(authExpiryTime())
        //     // console.log('session validity: ' + session.isValid())
        //     // AWS.config.region = 'us-west-2'
        //     // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        //     //   IdentityPoolId: 'us-west-2:40708dc0-1d52-4dc5-9f6a-bade84597a44',
        //     //   Logins: {
        //     //     'cognito-idp.us-west-2.amazonaws.com/us-west-2_MksRVgimN': session.getIdToken().getJwtToken()
        //     //   }
        //     // });

        //     // (AWS.config.credentials as AWS.Credentials).get((err) => {
        //     //   if(!err){
        //     //     let accessId = AWS.config.credentials?.accessKeyId
        //     //     console.log(accessId)
        //     //     return AWS.config.credentials
        //     //   }else{
        //     //     }
        //     //   })

        //     })

        //   cognitoUser.getSession(function (err: any, session: any) {
        //     if (err) {
        //       console.log(err);
        //     } else {
        //       console.log(session)
        //       setTimeout(() => {
        //         dispatch(getUser(session.accessToken.payload.sub))
        //         dispatch(getUserProfile(session.accessToken.payload.sub))
        //         dispatch(fetchMessages(session.accessToken.jwtToken, session.accessToken.payload.username))
        //       }, 3000)

        //     if(!socket.connected){
        //         socket.on("connection_error", (err) => {
        //             console.log(`connectin error due to ${err.massage}`, err)
        //         })
        //         socket.emit("join", session.accessToken.payload.username)
        //         socket.on('new-message', message => {
        //             console.log(1)
        //             dispatch(getNewMessage(message))
        //         })
        //     }

        //       console.log('socket', socket)
        //     }

        //   })
        // }

        //ReactGA.initialize('UA-185071628-1')  ** Previous Aqivah **
        if (window.location.hostname === "aqivah.com" || window.location.hostname === "www.aqivah.com") {
            ReactGA.initialize("UA-185796581-1");
            ReactGA.pageview(window.location.pathname + window.location.search);
        }

        if (window.location.hostname === "app.aqivah.com" || window.location.hostname === "www.app.aqivah.com") {
            ReactGA.initialize("UA-185864678-1");
            ReactGA.pageview(window.location.pathname + window.location.search);
        }

        if (window.location.hostname === "about.aqivah.com" || window.location.hostname === "www.about.aqivah.com") {
            ReactGA.initialize("UA-185939879-1");
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, []);
}

export default useAppInitialize;
