import React from 'react';
import { Card } from 'antd';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { XAxis, YAxis, CartesianGrid, Tooltip, Area, AreaChart } from 'recharts';
import { Bar, BarChart, Legend, Line, LineChart } from 'recharts';
import { data01, data02, data03, data04, data05 } from '../data/ChartData';

const Board = () =>  {
		return (
			<div className="site-card-wrapper">
				<div className="component-card">
					<Card
						title="Preferences"
						style={{ backgroundColor: '#f3f4f7' }}
						bordered={false}
						className="round-edge"
					>
						<ResponsiveContainer width="100%" height={250}>
							<PieChart>
								<Pie
									data={data01}
									dataKey="value"
									nameKey="name"
									cx="50%"
									cy="50%"
									outerRadius={50}
									fill="#8884d8"
								/>
								<Pie
									data={data02}
									dataKey="value"
									nameKey="name"
									cx="50%"
									cy="50%"
									innerRadius={60}
									outerRadius={80}
									fill="#82ca9d"
									label
								/>
							</PieChart>
						</ResponsiveContainer>
					</Card>
				</div>

				<div className="component-card">
					<Card title="Interests" bordered={false}>
						<ResponsiveContainer width="100%" height={250}>
							<AreaChart data={data03} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
								<defs>
									<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
										<stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
										<stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
									</linearGradient>
									<linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
										<stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
										<stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
									</linearGradient>
								</defs>
								<XAxis dataKey="name" />
								<YAxis />
								<CartesianGrid strokeDasharray="3 3" />
								<Tooltip />
								<Area
									type="monotone"
									dataKey="uv"
									stroke="#8884d8"
									fillOpacity={1}
									fill="url(#colorUv)"
								/>
								<Area
									type="monotone"
									dataKey="pv"
									stroke="#82ca9d"
									fillOpacity={1}
									fill="url(#colorPv)"
								/>
							</AreaChart>
						</ResponsiveContainer>
					</Card>
				</div>

				<div className="component-card">
					<Card title="Response" bordered={false}>
						<ResponsiveContainer width="100%" height={250}>
							<BarChart data={data04}>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="name" />
								<YAxis />
								<Tooltip />
								<Legend />
								<Bar dataKey="pv" fill="#8884d8" />
								<Bar dataKey="uv" fill="#82ca9d" />
							</BarChart>
						</ResponsiveContainer>
					</Card>
				</div>

				<div className="component-card">
					<Card
						title="Documents"
						bordered={false}
						style={{ backgroundColor: '#f3f4f7' }}
						className="round-edge"
					>
						<ResponsiveContainer width="100%" height={250}>
							<LineChart data={data05} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="name" />
								<YAxis />
								<Tooltip />
								<Legend />
								<Line type="monotone" dataKey="pv" stroke="#8884d8" />
								<Line type="monotone" dataKey="uv" stroke="#82ca9d" />
							</LineChart>
						</ResponsiveContainer>
					</Card>
				</div>
			</div>
		);
}

export default Board;
