import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { SearchContext } from "../../../context/search-context/search-context";
import Radio from "antd/es/radio";
import Button from "@mui/material/Button";
import numeral from "numeral";

export const PriceRange = ({ hideReset = false }) => {
    const { propertyFilters, handleFilterChange, setPropertyFilters } = useContext(SearchContext);

    const currencyOptions = [
        {
            label: (
                <>
                    <i className="fa-solid fa-cent-sign"></i> Cedi
                </>
            ),
            value: "GHC"
        },
        {
            label: (
                <>
                    <i className="fa-solid fa-dollar-sign"></i> Dollars
                </>
            ),
            value: "USD"
        }
    ];

    const onPriceChange = (e, price_field) => {
        const value = e.target.value.replaceAll(",", "");
        const re = /^[0-9\b]+$/;
        if (value === "" || re.test(value)) {
            const value_to_number = Number(value);
            if (price_field === "min")
                setPropertyFilters({
                    ...propertyFilters,
                    price: {
                        min: value_to_number,
                        max: value_to_number > propertyFilters.price.max ? value_to_number : propertyFilters.price.max
                    }
                });
            if (price_field === "max")
                setPropertyFilters({ ...propertyFilters, price: { ...propertyFilters.price, max: value_to_number } });
        }
    };

    const resetPriceField = () => {
        setPropertyFilters({ ...propertyFilters, price: { min: 0, max: 0 } });
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} className="d-flex flex-column align-items-center justify-content-center">
                <Radio.Group
                    style={{ fontSize: "8px", marginBottom: "15px" }}
                    options={currencyOptions}
                    name="currency"
                    onChange={handleFilterChange}
                    value={propertyFilters.currency}
                    // optionType="button"
                />
            </Grid>
            <Grid item xs={6} className="d-flex flex-column align-items-start justify-content-start">
                <span className="filter-title">Minimun</span>
                <TextField
                    fullWidth
                    className="mb-5"
                    name="min_price"
                    style={{ borderRadius: 0 }}
                    value={numeral(propertyFilters.price.min).format("0,0")}
                    onChange={e => onPriceChange(e, "min")}
                    placeholder="Min"
                    size="small"
                    InputProps={{
                        startAdornment:
                            propertyFilters.currency === "USD" ? (
                                <i className="fa-solid fa-dollar-sign"></i>
                            ) : (
                                <i className="fa-solid fa-cent-sign"></i>
                            )
                    }}
                    id="outlined-basic"
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={6} className="d-flex flex-column align-items-start justify-content-start">
                <span className="filter-title">Maximum</span>
                <TextField
                    fullWidth
                    className="mb-5"
                    name="max_price"
                    style={{ borderRadius: 0 }}
                    value={numeral(propertyFilters.price.max).format("0,0")}
                    onChange={e => onPriceChange(e, "max")}
                    placeholder="Max"
                    size="small"
                    InputProps={{
                        startAdornment:
                            propertyFilters.currency === "USD" ? (
                                <i className="fa-solid fa-dollar-sign"></i>
                            ) : (
                                <i className="fa-solid fa-cent-sign"></i>
                            )
                    }}
                    id="outlined-basic"
                    variant="outlined"
                />
            </Grid>
            {!hideReset && (
                <>
                    <Grid item xs={3}>
                        <Button onClick={resetPriceField} variant="text" color="secondary" size="small">
                            Reset
                        </Button>
                    </Grid>
                    <Grid item xs={9}>
                        <Button variant="contained" fullWidth color="primary" size="small">
                            Done
                        </Button>
                    </Grid>
                </>
            )}
        </Grid>
    );
};
