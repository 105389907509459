import React, { useContext } from "react";
import Radio from "antd/es/radio";
import { SearchContext } from "../../../context/search-context/search-context";
import Grid from "@mui/material/Grid";

export const AcquisitionFilter = () => {
    const { propertyFilters, setPropertyFilters } = useContext(SearchContext);

    const container: any = {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
    };

    const handleAcquisitionSelect = e => {
        const name = e.target.name;
        setPropertyFilters(prevState => ({ ...prevState, acquisition: name }));
    };

    return (
        <div className="d-flex w-100 flex-column justify-content-start align-items-center">
            <span className="filter-title mb-3">Rules</span>
            <div className="d-flex w-100 flex-column justify-content-start align-items-start">
                <Grid container spacing={2}>
                    <Grid style={container} item xs={12} sm={12} lg={12} md={12}>
                        <div className="d-flex justify-content-between align-items-center user-select-none">
                            <Radio
                                name="buy"
                                checked={propertyFilters?.acquisition === "buy"}
                                onClick={handleAcquisitionSelect}
                                value={"buy"}>
                                Buy
                            </Radio>
                            <Radio
                                name="rent"
                                checked={propertyFilters?.acquisition === "rent"}
                                onClick={handleAcquisitionSelect}
                                value={"rent"}>
                                Rent
                            </Radio>
                            <Radio
                                name="short_stay"
                                checked={propertyFilters?.acquisition === "short_stay"}
                                onClick={handleAcquisitionSelect}
                                value={"short_stay"}>
                                Short Stay
                            </Radio>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
