import React, { useContext } from "react";
import Radio from "antd/es/radio";
import Checkbox from "antd/es/checkbox";
import { SearchContext } from "../../../context/search-context/search-context";
import Grid from "@mui/material/Grid";

export const RulesFilter = () => {
    const { propertyFilters, handleFilterChange, setPropertyFilters } = useContext(SearchContext);

    const container: any = {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
    };

    const handleRulesSelect = e => {
        const name = e.target.name;
        if (propertyFilters?.rules[name] === false || !propertyFilters?.rules[name] === true) {
            console.log(propertyFilters?.rules[name]);
            setPropertyFilters(prevState => ({ ...prevState, rules: { ...prevState.rules, [name]: true } }));
        } else {
            console.log("unchecked here");
            setPropertyFilters(prevState => ({ ...prevState, rules: { ...prevState.rules, [name]: false } }));
        }
    };

    return (
        <div className="d-flex w-100 flex-column justify-content-start align-items-center">
            <span className="filter-title mb-3">Rules</span>
            <div className="d-flex w-100 flex-column justify-content-start align-items-start">
                <Grid container spacing={2}>
                    <Grid style={container} item xs={12} sm={12} lg={12} md={12}>
                        <div className="d-flex flex-column justify-content-start align-items-start user-select-none">
                            <Radio
                                name="smoking"
                                checked={propertyFilters?.rules?.smoking}
                                onClick={handleRulesSelect}
                                value={"smoking"}>
                                Is smoking allowed?
                            </Radio>
                            <Radio name="pets" checked={propertyFilters?.rules?.pets} onClick={handleRulesSelect} value={"pets"}>
                                Are pets allowed?
                            </Radio>
                            <Radio
                                name="parties"
                                checked={propertyFilters?.rules?.parties}
                                onClick={handleRulesSelect}
                                value={"parties"}>
                                Are parties allowed?
                            </Radio>
                            <Radio name="noise" checked={propertyFilters?.rules?.noise} onClick={handleRulesSelect} value={"noise"}>
                                Is noise making tolerated?
                            </Radio>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
