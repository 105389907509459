import React, { useState, useEffect, useContext } from "react";
import Modal from "antd/es/modal";
import Spin from "antd/es/spin";
import ScrollBar from "./ScrollBar";
import attachment from "./assets/attachment.svg";
import flysend from "./assets/fly-send.svg";
import smilling from "./assets/smiling.svg";
import upload from "./assets/upload.svg";
import ErrorIcon from "@mui/icons-material/Error";
import axios from "axios";
import "./css/chatsection.css";
import { Button } from "antd";
import { AdminProviderContext } from "../../common/admin-provider/admin-provider-context";

const Messaging = ({ user, opened, handleCloseMessage }) => {
    const FETCH_MESSAGES = "FETCH_MESSAGES";
    const SEND_MESSAGE = "SEND_MESSAGE";

    const { user: loggedInUser } = useContext(AdminProviderContext);

    const [events, setEvents] = useState<any>([]);

    let scrollEnd;

    // const [email, setEmail] = useState("");

    const [text, setText] = useState("");

    const [messages, setMessages] = useState<any>([]);

    const handleTextChange = e => {
        setText(e.target.value);
    };

    function fetchMessages() {
        setEvents([FETCH_MESSAGES, ...events]);
        if (loggedInUser) {
            axios
                .get("/messages/fetch-messages")
                .then((res: any) => {
                    setMessages(res.data.result.messages);
                })
                .catch((err: any) => {
                    console.log(err);
                })
                .then(() => {
                    setEvents(events.filter((event: any) => event !== FETCH_MESSAGES));
                });
        } else {
            console.log("No user logged in", loggedInUser);
        }
    }

    const handleSend = () => {
        setEvents([SEND_MESSAGE, ...events]);
        axios
            .post("/messages/send-message", { to: user._id, message: text })
            .catch((err: any) => console.log(err))
            .then(() => {
                setMessages([...messages, { message: text, from: loggedInUser?._id, to: user._id }]);
                setEvents(events.filter((event: any) => event !== SEND_MESSAGE));
                setText("");
            });
    };

    const handleDateTime = date => {
        const days = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

        const method = new Date(date);

        return `${days[method.getDay()]}, ${method.getDate() < 10 ? "0" + method.getDate() : method.getDate()} ${
            months[method.getMonth()]
        } ${method.getFullYear()}, ${method.getHours() < 10 ? "0" + method.getHours() : method.getHours()}:${
            method.getMinutes() < 10 ? "0" + method.getMinutes() : method.getMinutes()
        }`;
    };

    const handleClose = () => {
        handleCloseMessage();
    };

    useEffect(fetchMessages, []);

    return (
        <Modal open={opened} closable={true} onCancel={handleClose} footer={null} width={700}>
            <div className="inbox-ev">
                <div className="chat-box-ev">
                    <div className="chat-header-ev">
                        <div className="header-icon-ev">
                            <img
                                alt={`${
                                    user ? user.firstname + " " + user.lastname + "'s user profile image" : "User's profile image"
                                }`}
                                src={require("./assets/logo.png").default}
                            />
                            {/* <i
                    style={{ color: "green" }}
                    className="fas fa-circle green"
                  /> */}
                        </div>
                        <div className="header-details-ev">
                            <div className="user-icon-ev">{user ? user.first_name + " " + user.last_name : ""}</div>
                        </div>
                    </div>

                    <div style={{ overflow: "hidden" }} className="chatter-ev">
                        <ScrollBar user={user ? user.email : null} className={"chatter"}>
                            {messages.map((message: any, i: any) => {
                                events.includes(FETCH_MESSAGES) && <Spin />;
                                if (user && (i > 0 ? message._id !== messages[i - 1]._id : message._id !== messages[i + 1])) {
                                    return user?.email?.length >= 1 &&
                                        message.from === user._id &&
                                        message.to === loggedInUser?._id /*Email value here*/ ? (
                                        <div
                                            key={message._id}
                                            id={message._id}
                                            style={{ marginBottom: "20px" }}
                                            className="chat-wrapper-ev left-ev">
                                            {/* <div className="chatter-title-ev">{message.from}</div> */}
                                            <div className="chatter-message-ev">
                                                <p>{message?.message}</p>
                                            </div>
                                            <div className="time-plate-ev">{handleDateTime(message.created_at)}</div>
                                            {/* {message.error && (
                                                <div className="error-text">
                                                    Not delivered{" "}
                                                    <ErrorIcon style={{ color: "red", fontSize: "15px", marginLeft: "4px" }} />{" "}
                                                </div>
                                            )} */}
                                        </div>
                                    ) : user?.email?.length >= 1 && message.from === loggedInUser?._id && message.to === user._id ? (
                                        <div
                                            key={message._id}
                                            id={message._id}
                                            style={{ marginBottom: "20px" }}
                                            className="chat-wrapper-ev right-ev">
                                            {/* <div className="chatter-title-ev">{message.from}</div> */}
                                            <div className="chatter-message-ev">
                                                <p>{message.message}</p>
                                            </div>
                                            <div className="time-plate-ev">{handleDateTime(message.created_at)}</div>
                                            {/* {message.error && (
                                                <div className="error-text">
                                                    Not delivered{" "}
                                                    <ErrorIcon style={{ color: "red", fontSize: "15px", marginLeft: "4px" }} />{" "}
                                                </div>
                                            )} */}
                                        </div>
                                    ) : null;
                                }
                                // {
                                //     error && <div>Can't load messages</div>;
                                // }
                            })}
                        </ScrollBar>
                    </div>
                    <div className="chat-footer-ev">
                        <div className="chat-input-ev">
                            <textarea onChange={handleTextChange} value={text} rows={2} placeholder="Type your message here..." />
                            <ul className="chat-action-ev">
                                <li>
                                    <a href="javascript:;">
                                        <img src={attachment} />
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:;">
                                        <img alt="upload button" src={upload} />
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:;">
                                        <img src={smilling} />
                                    </a>
                                </li>
                            </ul>
                            <Button loading={events.includes(SEND_MESSAGE)} onClick={handleSend}>
                                Send
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default Messaging;
