import React, { useState, useContext } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { PopupProviderContext } from "../../common/popup-alerts-provider/popup-context";
import { AdminProviderContext } from "../../common/admin-provider/admin-provider-context";
import { useFormik } from "formik";
import * as Yup from "yup";
import ownerImgage from "../../assets/owners_icons/business_owner.png";
import "./register.scss";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link as RouterLink } from "react-router-dom";
import { logError } from "../../utils/logError";
import Spin from "antd/es/spin";
import Footer from "../../components/Footer/Footer";

export const OwnerRegistration = () => {
    const [loading, setLoading] = useState(false);
    const [showPassword2, setShowPassword2] = useState<boolean>(false);
    const [showPassword3, setShowPassword3] = useState<boolean>(false);

    const { flashError, flashSuccess } = useContext(PopupProviderContext);
    const { refreshAdmin, user } = useContext(AdminProviderContext);

    let phone_regex = /^([026|26]).\d{7,8}$/;

    const validationSchemaSignUp = Yup.object({
        firstName: Yup.string()
            .matches(/^[A-Za-z ]*$/, "Please enter valid first name")
            .max(40)
            .required(),
        lastName: Yup.string()
            .matches(/^[A-Za-z ]*$/, "Please enter valid last name")
            .max(40)
            .required(),
        email: Yup.string().email("Invalid email format").required("Email is required"),
        phoneNumber: Yup.string().required("Phone number is required").matches(phone_regex, "Phone number format is not valid"),
        password: Yup.string()
            .required("Password is required")
            .matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Password must contain at least 8 characters, one uppercase, one number and one special case"
            ),
        confirmPassword: Yup.string()
            .required("Confirm password is required")
            .when("password", {
                is: password => (password && password.length > 0 ? true : false),
                then: Yup.string().oneOf([Yup.ref("password")], "Passwords do not match")
            }),
        acknowledge: Yup.bool().oneOf([true], "Field must be checked")
    });

    const formikSignUp = useFormik({
        initialValues: {
            firstName: user?.first_name || "",
            lastName: user?.last_name || "",
            email: user?.email || "",
            phoneNumber: user?.phone || "",
            password: "",
            confirmPassword: "",
            acknowledge: false
        },
        validationSchema: validationSchemaSignUp,
        onSubmit: values => {
            console.log(values);
            values["mobile"] = values.phoneNumber;
            setLoading(true);
            axios
                .post("/auth/signup", { ...values, user_type: "seeker" })
                .then((res: any) => {
                    console.log(res);
                    flashSuccess("Registration successful");
                    // setSuccess(true);
                    // openLogin();
                })
                .catch(err => {
                    console.log(err);
                    // setError(logError(err));
                    flashError("Something went wrong", logError(err));
                    setLoading(false);
                })
                .then(() => {
                    setLoading(false);
                });
        }
    });

    return (
        <div id="owner-registration" className="py-5">
            <Header />

            <div className="hero">
                <div className="container-sm">
                    <h3 className="fw-light text-center mb-4">Explore Your Selling Options</h3>
                    <p className="p-info-lg text-center">
                        Wherever you are in the selling journey, our hassle-free tools and guides will help you make the right move.
                    </p>
                </div>
            </div>

            <div className="image-background"></div>

            <div className="registration-section">
                <div className="container-xxm py-5">
                    <h3 className="text-center fw-light mb-5">Register As An Owner</h3>

                    <div>
                        <form onSubmit={formikSignUp.handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="fname"
                                        name="firstName"
                                        variant="outlined"
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        value={formikSignUp.values.firstName}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.firstName && formikSignUp.errors.firstName ? true : false}
                                        helperText={formikSignUp.touched.firstName && formikSignUp.errors.firstName}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="lname"
                                        value={formikSignUp.values.lastName}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.lastName && formikSignUp.errors.lastName ? true : false}
                                        helperText={formikSignUp.touched.lastName && formikSignUp.errors.lastName}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} style={{ marginTop: "20px" }}>
                                <Grid item xs={12} lg={5}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="phone"
                                        label="Phone Number"
                                        name="phoneNumber"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">+233</InputAdornment>
                                        }}
                                        value={formikSignUp.values.phoneNumber}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.phoneNumber && formikSignUp.errors.phoneNumber ? true : false}
                                        helperText={formikSignUp.touched.phoneNumber && formikSignUp.errors.phoneNumber}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} lg={7}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        name="email"
                                        value={formikSignUp.values.email}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.email && formikSignUp.errors.email ? true : false}
                                        helperText={formikSignUp.touched.email && formikSignUp.errors.email}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={6} style={{ marginTop: "20px" }}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="first_password"
                                        label="Password"
                                        name="password"
                                        type={showPassword2 ? "text" : "password"}
                                        value={formikSignUp.values.password}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.password && formikSignUp.errors.password ? true : false}
                                        InputProps={{
                                            endAdornment:
                                                formikSignUp.values.password !== "" ? (
                                                    !showPassword2 ? (
                                                        <i onClick={() => setShowPassword2(!showPassword2)} className="fas fa-eye"></i>
                                                    ) : (
                                                        <i
                                                            onClick={() => setShowPassword2(!showPassword2)}
                                                            className="fas fa-eye-slash"></i>
                                                    )
                                                ) : null
                                        }}
                                        size="small"
                                    />
                                    <FormHelperText
                                        error={formikSignUp.touched.password && formikSignUp.errors.password ? true : false}>
                                        {" "}
                                        Password must have an uppercase characters, a lowercase character, a number, a special
                                        character, and must be 8 characters long
                                    </FormHelperText>
                                </Grid>

                                <Grid item xs={12} lg={6} style={{ marginTop: "20px" }}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="confirm_password"
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        type={showPassword3 ? "text" : "password"}
                                        value={formikSignUp.values.confirmPassword}
                                        onChange={formikSignUp.handleChange}
                                        error={
                                            formikSignUp.touched.confirmPassword && formikSignUp.errors.confirmPassword ? true : false
                                        }
                                        helperText={formikSignUp.touched.confirmPassword && formikSignUp.errors.confirmPassword}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                formikSignUp.values.confirmPassword !== "" ? (
                                                    !showPassword3 ? (
                                                        <i onClick={() => setShowPassword3(!showPassword3)} className="fas fa-eye"></i>
                                                    ) : (
                                                        <i
                                                            onClick={() => setShowPassword3(!showPassword3)}
                                                            className="fas fa-eye-slash"></i>
                                                    )
                                                ) : null
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container justify="center" style={{ margin: "20px 0 7px 0" }}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                name="acknowledge"
                                                checked={formikSignUp.values.acknowledge}
                                                onChange={formikSignUp.handleChange}
                                            />
                                        }
                                        label={
                                            <span
                                                style={{
                                                    color:
                                                        formikSignUp.touched.acknowledge && formikSignUp.errors.acknowledge
                                                            ? "red"
                                                            : "black"
                                                }}>
                                                <small>
                                                    By signing up, I accept the{" "}
                                                    <RouterLink
                                                        style={{ color: "#3f51b5" }}
                                                        to="/about"
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        {" "}
                                                        Aqivah Terms of Service{" "}
                                                    </RouterLink>
                                                    and acknowledge the{" "}
                                                    <RouterLink
                                                        to="/privacy-policy"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ textDecoration: "underline", color: "#3f51b5" }}>
                                                        Privacy Policy.
                                                    </RouterLink>
                                                </small>
                                            </span>
                                        }
                                    />
                                </Grid>
                            </Grid>

                            <Button type="submit" fullWidth variant="contained" color="primary">
                                {loading ? <Spin spinning={true} size="small"></Spin> : "Register"}
                            </Button>
                            <Grid container justify="flex-end" className="mt-3">
                                <Grid item>
                                    <Link /*onClick={() => openLogin()}*/ variant="body2">Already have an account? Sign in</Link>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
