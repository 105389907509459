import React from 'react';
import Layout from '../../components/Seeker_DashBoard/AppLayout';
//import useNavigationTab from '../../components/NavigationMenu'
import Meta from '../../components/Helmet'

function SeekerDashboard(props) {
	// const {footer} = useNavigationTab(props, 'home')

	return (
		<div>
			<Meta title="Aqivah - Dashboard"/>
			<Layout />
			{/* {footer} */}
		</div>
	);
}

export default SeekerDashboard;
