import React, { useEffect } from "react";
import { Typography } from "antd";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
// import "../../CSS_Styling/Policy-Doc.css";
import "./privacy-policy.scss";
import Footer from "../../components/Footer/Footer";
import { Tree } from "antd";

const { Title, Text, Paragraph } = Typography;

const title = {
    width: "100%",
    fontSize: "27px",
    //fontFamily: "'Google Sans',sans-serif",
    color: "#000000",
    fontWeight: 700,
    paddingLeft: "0",
    textDecoration: "underline",
    marginBottom: "2vh"
};

const functUnCap = sentence => {
    let arr = sentence.split(" ");
    console.log(arr);
    arr.map(word => {});
};

const PolicyDoc = () => {
    const params = useParams();

    const scrollToSection = (id: string) => {
        let scrollSection = document.getElementById(id);
        if (scrollSection !== null) {
            scrollSection.scrollIntoView({
                behavior: "smooth"
            });
        }
    };

    useEffect(() => {
        let section = params.id;
        console.log(params);
        let scrollSection = document.getElementById(`${section}`);

        setTimeout(() => {
            if (scrollSection !== null) {
                scrollSection.scrollIntoView({
                    behavior: "smooth"
                    // block: 'center',
                    // inline: 'nearest',
                });
            }
        }, 300);
    }, [params.id]);

    function unCapThat(text) {
        let newArr: any = text.split("");
        let uncappedArr: any = [];
        for (let letter = 0; letter < newArr.length; letter++) {
            if (letter == 0) {
                uncappedArr.push(newArr[0]);
            } else {
                uncappedArr.push(newArr[letter].toLowerCase());
            }
        }
        console.log(uncappedArr);
        console.log(uncappedArr.join(""));
        let new_str = uncappedArr.join("");
        let capAqivah = new_str.split(" ");
        const index = capAqivah.findIndex(value => value === "aqivah");
        capAqivah[index] = "Aqivah";
        let fin_str = capAqivah.join(" ");
        return fin_str;
    }

    const tableData = [
        {
            title: "1. Basics",
            key: "basics"
        },
        {
            title: "2. What is the scope of this privacy policy?",
            key: "scope"
        },
        {
            title: "3. What is Aqivah' business model?",
            key: "business-model"
        },
        {
            title: `4. ${unCapThat("WHAT INFORMATION DOES AQIVAH COLLECT ABOUT ME")}?`,
            key: "info-collection"
        },
        {
            title: `5. ${unCapThat("HOW IS PERSONAL INFORMATION COLLECTED")}?`,
            key: "mode-of-collection"
        },
        {
            title: `6. ${unCapThat("INFORMATION YOU PROVIDE US WHEN YOU CREATE AN ACCOUNT")}`,
            key: "info-provided",
            children: [
                {
                    title: "5.1. Voluntary Information",
                    key: "vol-info"
                },
                {
                    title: "5.2. Automatically collected Information",
                    key: "auto-col-info"
                },
                {
                    title: "5.3. Log data",
                    key: "log-data"
                },
                {
                    title: "5.4. Usage information",
                    key: "use-info"
                }
            ]
        },
        {
            title: `7. ${unCapThat("HOW DOES AQIVAH USE MY INFORMATION")}?`,
            key: "how-info-used"
        },
        {
            title: `8. ${unCapThat("DOES AQIVAH SHARE MY INFORMATION")}?`,
            key: "info-sharing"
        },
        {
            title: `8a. Does Aqivah Ever Make Any Of My Personal Data?`,
            key: "personal-data-use"
        },
        {
            title: `9. ${unCapThat("WHERE IS MY INFORMATION STORED")}?`,
            key: "info-storage"
        },
        {
            title: `10. ${unCapThat("WILL AQIVAH SEND ME EMAILS")}?`,
            key: "send-emails"
        },
        {
            title: `11. ${unCapThat("DOES AQIVAH COLLECT INFORMATION FROM CHILDREN")}?`,
            key: "info-from-children"
        },
        {
            title: `12. ${unCapThat("HOW SECURE IS MY INFORMATION")}?`,
            key: "info-security"
        },
        {
            title: `13. ${unCapThat("HOW CAN I DELETE MY PERSONAL INFORMATION FROM AQIVAH")}?`,
            key: "info-deletion"
        },
        {
            title: `14. ${unCapThat("HOW LONG IS PERSONAL INFORMATION RETAINED")}?`,
            key: "info-retention"
        },
        {
            title: `15. ${unCapThat("WHAT HAPPENS IF AQIVAH CLOSES MY ACCOUNT")}?`,
            key: "account-closure"
        },
        {
            title: `16. ${unCapThat("CHANGES TO THIS POLICY")}`,
            key: "policy-changes"
        }
    ];

    const onSelect = value => {
        scrollToSection(value[0]);
    };

    return (
        <div id="privacy-policy">
            <div className="policy-body">
                <Header />

                <Title level={2} style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    Aqivah Privacy Policy{" "}
                </Title>

                <Title level={5}> Content </Title>
                <Tree defaultSelectedKeys={["0"]} onSelect={onSelect} treeData={tableData} style={{ marginBottom: "3em" }} />

                <div className="policy-section" id="basics">
                    <Title style={title} level={4}>
                        1. BASICS{" "}
                    </Title>

                    <Paragraph>
                        Data protection is very important to Aqivah that is why we are committed to using worldwide standards to
                        safeguard the information you choose to provide or share with us, both personal and non- personal. Aqivah’s
                        website can be used without providing any personal information. Processing of personal information becomes
                        necessary if services are used via our website. This policy statement is to explain what information we collect
                        and why. We hope that you will take time and read.
                    </Paragraph>
                    <Paragraph>As a start your information collected will not be shared, rented or sold to any third party.</Paragraph>
                    <Paragraph>
                        We also grant you the power to control the information shared with us and the power to opt out at anytime you
                        wish to do so.
                    </Paragraph>
                </div>

                <div className="policy-section" id="scope">
                    <Title style={title} level={4}>
                        2. WHAT IS THE SCOPE OF THIS PRIVACY POLICY?
                    </Title>
                    <Paragraph>
                        This Privacy Policy is incorporated into Aqivah’s Terms of Service located at{" "}
                        <a href="https://about.aqivah.com/legal" target="_blank" rel="noopener noreferrer">
                            https://about.aqivah.com/legal
                        </a>
                        , and applies to personal information that identifies you including name, phone number, email address, and
                        government id (passport, voter card, drivers’ license, national id card) (“personal information”) obtained by
                        us through your use of Aqivah’s Site, Services and Mobile App (“Services”) as described in this Policy.
                    </Paragraph>
                </div>

                <div className="policy-section" id="business-model">
                    <Title style={title} level={4}>
                        3. WHAT IS AQIVAH’S BUSINESS MODEL?{" "}
                    </Title>
                    <Paragraph>Generally Aqivah offers a free service to its two main clientele. These clients are: </Paragraph>

                    <Paragraph>
                        <div>1. Owners who upload property; or</div>
                        <div>2. Agent acting on behalf of a property owner to upload property ; and</div>
                        <div>
                            3. Seekers i.e. individuals searching, saving property and registering and uploading their search creteria
                            .
                        </div>
                    </Paragraph>

                    <Paragraph>Aqivah may include links on its website to the websites of other organisations.</Paragraph>
                </div>

                <div className="policy-section" id="info-collection">
                    <Title style={title} level={4}>
                        4. WHAT INFORMATION DOES AQIVAH COLLECT ABOUT ME?
                    </Title>
                    <Paragraph>
                        Aqivah collects personal information that is reasonably necessary to provide its services and to manage its
                        relationships with clients, other parties and to pursue the legitimate interests of its business.{" "}
                    </Paragraph>
                    <Paragraph>
                        When personal information is requested, you may choose to provide it or not. As stated earlier Aqivah’s website
                        can be used without providing any personal information. Processing of personal information becomes necessary if
                        services are used via our website.
                    </Paragraph>
                    <Paragraph>
                        The type of personal information collected will depend on the services being provided and the circumstances of
                        collection.
                    </Paragraph>

                    <Paragraph>
                        <div>
                            1. For property transactions, we may collect personal information from the parties involved including
                            owners, vendors, purchasers, landlords, seekers, tenants etc.
                        </div>
                        <div> 2. Information collected includes</div>
                        <div>(a) name,</div>
                        <div>(b) contact information (i.e. e‐mail address, home and office address, telephone) </div>
                        <div>(c) date of birth,</div>
                        <div>(d) gender, </div>
                        <div>(e) identification card number and expiry date.</div>
                    </Paragraph>

                    <Paragraph>
                        If it is necessary to collect sensitive information from an individual, your consent will be obtained. However
                        if you do not provide personal information when requested by Aqivah, Aqivah may be unable to provide you with
                        some of our services.
                    </Paragraph>
                </div>

                <div className="policy-section" id="mode-of-collection">
                    <Title style={title} level={4}>
                        {" "}
                        5. HOW IS PERSONAL INFORMATION COLLECTED?
                    </Title>
                    <Paragraph>
                        Aqivah will collect personal information directly from the individual to whom it relates whenever practicable.
                        This may be done
                    </Paragraph>
                    <Paragraph>
                        <div>(a) verbally (e.g. in person or over the telephone),</div>
                        <div>
                            (b) in writing (e.g. by letter, email, application or other form) or (c) through contact via our websites.
                        </div>
                    </Paragraph>
                    <Paragraph>
                        In circumstances where it is not practicable to collect the information directly from the individual, Aqivah
                        may collect the information via third parties. This information may be sourced from, but not limited to,
                    </Paragraph>
                    <Paragraph>
                        <div>
                            (a) Aqivah’s own records about you, from which Aqivah may internally generate its own scores, assessments
                            or deductions, particularly in relation to credit worthiness;
                        </div>
                        <div>(b) Otheragentsandvaluerswhoworkwithus;</div>
                        <div>(c) Property owners for whom we market properties or provide other services;</div>
                        <div>
                            (d) Market research companies contracted by us to obtain information in relation to our services or from
                            which we purchase mailing lists; or
                        </div>
                        <div>(e) Publicly available information and databases;</div>
                    </Paragraph>
                    Aqivah may include links on its website to the websites of other organisations. Aqivah is not responsible for the
                    content or privacy practices of such websites or organisations
                </div>

                <div className="policy-section" id="info-provided">
                    <Title style={title} level={4}>
                        6. INFORMATION YOU PROVIDE US WHEN YOU CREATE AN ACCOUNT
                    </Title>
                    <Title id="vol-info" level={5}>
                        Voluntary Information
                    </Title>
                    <Paragraph>
                        {" "}
                        When you sign up for or use our “Services”, you voluntarily give us certain “personal information”, including
                        name, phone number, email address, and government id (passport, voter card, drivers’ license or national id
                        card).
                    </Paragraph>

                    <Title level={5} id="auto-col-info">
                        Automatically collected Information{" "}
                    </Title>
                    <Paragraph>
                        Certain data about the devices you use to connect with Aqivah and your use of the Site, Mobile App, and/or
                        Services are automatically logged in our systems, including:
                    </Paragraph>

                    <Title id="log-data" level={5}>
                        Log data
                    </Title>
                    <Paragraph>
                        As with most websites and technology services delivered over the internet, our servers automatically collect
                        data when you access or use our Site, Software, and/or Services and record it in log files. This log data may
                        include IP address, browser type and settings, date and time of use, information about browser configuration,
                        language preferences.
                    </Paragraph>

                    <Title id="use-info" level={5}>
                        Usage information
                    </Title>
                    <Paragraph>
                        This is information about the Aqivah Site, App, and/or Services you use and how you use them. We may also
                        obtain data from our third-party partners and service providers to analyze how users use our Site, Software,
                        and/or Services. For example, we will know how many users access a specific page on the Site and which links
                        they clicked on. We use this aggregated information to better understand and optimize the Site.
                    </Paragraph>
                </div>

                <div className="policy-section" id="how-info-used">
                    <Title style={title} level={4}>
                        7. HOW DOES AQIVAH USE MY INFORMATION?
                    </Title>

                    <Paragraph>
                        We use, process, and store your Information as reasonably necessary to perform our contract with you and for
                        our legitimate business interests. In effect the information you provide :
                    </Paragraph>
                    <Paragraph>
                        <div>
                            (a) Helps us provide and administer our Site, Software, and/or Services, authenticate users for security
                            purposes, provide personalized user features and access, process transactions, conduct research, develop
                            new features, and improve the features, algorithms, and usability of our Site, Software, and/or Services.
                        </div>
                        <div>
                            (b) Is used to communicate with you about your use of our Site, Software, and/or Services, product
                            announcements, and software updates, as well as respond to your requests for assistance, including
                            providing account verification support if you’re having difficulty accessing your account.
                        </div>
                        <div>
                            (c) Is used in direct marketing emails and special offers about Aqivah, from which you can unsubscribe at
                            any time. For more information, please see section 10 titled, “Will Aqivah send me emails?”
                        </div>
                        <div>
                            (d) Is used to calculate aggregate statistics on the number of unique devices using our Site, Software,
                            and/or Services, and to detect and prevent fraud.
                        </div>
                    </Paragraph>
                </div>

                <div className="policy-section" id="info-sharing">
                    <Title style={title} level={4}>
                        8. DOES AQIVAH SHARE MY INFORMATION?
                    </Title>
                    <Paragraph>
                        Generally Aqivah does not share, sell your personal information to third parties. In the event that we have to
                        share personal information, we will seek your explicit consent to share your Personal Information. We may only
                        disclose Personal Information to third parties when:
                    </Paragraph>
                    <Paragraph>
                        (a) We believe it is necessary to investigate potential violations of the Terms of Service, to enforce those
                        Terms of Service, or to investigate, prevent, or take action regarding illegal activities, suspected fraud, or
                        potential threats against persons, property, or the systems on which we operate our Site, Software, and/or
                        Services
                    </Paragraph>
                    <Paragraph>
                        (b) We determine that the access, preservation, or disclosure of your Personal Information is required by law
                        to protect the rights, property, or personal safety of Aqivah and users of our Site, Software, and/or Services,
                        or to respond to lawful requests by public authorities, including national security or law enforcement
                        requests.{" "}
                    </Paragraph>
                    <Paragraph>
                        (c) We use service providers who assist us in meeting business operations needs, including hosting, delivering,
                        and improving our Services.
                    </Paragraph>
                    <Paragraph>
                        (d) We use service providers for specific services and functions, including email communication, customer
                        support services, and analytics. These service providers may only access, process, or store Personal
                        Information pursuant to our instructions and to perform their duties to us.{" "}
                    </Paragraph>
                    <Paragraph>
                        (e) We need to do so in connection with a merger, acquisition, bankruptcy, reorganization, sale of some or all
                        of our assets or stock, public offering of securities, or steps in consideration of such activities (e.g., due
                        diligence). In these cases some or all of your Personal Information may be shared with or transferred to
                        another entity, subject to this Privacy Policy.
                    </Paragraph>
                    <Paragraph>
                        (f) We may disclose Non-Personal Information publicly and to third parties, partners under agreement with us,
                        or as part of progress reports we may provide to users.
                    </Paragraph>
                    Aqivah does not share your Personal Information with third parties for the purpose of enabling them to deliver
                    their advertisements to you.
                    <Title level={4} id="personal-data-use">
                        8a. Does Aqivah Ever Make Any Of My Personal Data?
                    </Title>
                    Aqivah will neither use or share your Personal Information or User Content except in the limited circumstances
                    described in the “Does Aqivah share my Information?” section of the Policy
                </div>

                <div className="policy-section" id="info-storage">
                    <Title style={title} level={4}>
                        9. WHERE IS MY INFORMATION STORED?
                    </Title>
                    <Paragraph>
                        {" "}
                        Information submitted to Aqivah will be transferred to, processed to , and stored on{" "}
                        <strong>Amazon Web Services (AWS)</strong>. When you use the Software on your computing device, User Content
                        you save will be stored locally on that device and synced with our servers. If you post or transfer any
                        Information to or through our Site, Software, and/or Services, you are agreeing to such Information, including
                        Personal Information and User Content, being hosted and accessed in the United States.
                    </Paragraph>
                </div>

                <div className="policy-section" id="send-emails">
                    <Title style={title} level={4}>
                        10. WILL AQIVAH SEND ME EMAILS?
                    </Title>
                    <Paragraph>
                        {" "}
                        From time to time, we may want to contact you with information about product announcements, software updates,
                        and special offers. We also may want to contact you with information about products and services from our
                        business partners. You may opt out of such communications at any time by clicking the “unsubscribe” link found
                        within Aqivah emails and changing your contact preferences. All Aqivah account holders will continue to receive
                        transactional messages related to our Services, even if you unsubscribe from promotional emails.
                    </Paragraph>
                </div>

                <div className="policy-section" id="info-from-children">
                    <Title style={title} level={4}>
                        11. DOES AQIVAH COLLECT INFORMATION FROM CHILDREN?
                    </Title>
                    <Paragraph>Aqivah does not collect information from Children. </Paragraph>
                </div>

                <div className="policy-section" id="info-security">
                    <Title style={title} level={4}>
                        12. HOW SECURE IS MY INFORMATION?
                    </Title>
                    <Paragraph>
                        Aqivah is committed to protecting the security of your Information and takes reasonable precautions to protect
                        it. However, internet data transmissions, whether wired or wireless, cannot be guaranteed to be 100% secure,
                        and as a result, we cannot ensure the security of Information you transmit to us, including Personal
                        Information and User Content; accordingly, you acknowledge that you do so at your own risk.
                    </Paragraph>
                    <Paragraph>
                        We use industry-standard encryption to protect your data in transit. This is commonly referred to as transport
                        layer security (“TLS”) or secure socket layer (“SSL”) technology.
                    </Paragraph>
                    <Paragraph>
                        Once we receive your data and transmit it for storage with our third party hosting servers ,{" "}
                        <strong>AWS</strong>, they will protect it, per their contract, on their servers using a combination of
                        technical, physical, and logical security safeguards. The security of the data stored locally in any of our
                        Software installed on your computing device requires that you make use of the security features of your device.
                        We recommend that you take the appropriate steps to secure all computing devices that you use in connection
                        with our Site, Software, and Services.
                    </Paragraph>
                    <Paragraph>
                        If Aqivah learns of a security system breach, we may attempt to notify you and provide information on
                        protective steps, if available, through the email address that you have provided to us or by posting a notice
                        on the Site. Depending on where you live, you may have a legal right to receive such notices in writing.
                    </Paragraph>
                </div>

                <div className="policy-section" id="info-deletion">
                    <Title style={title} level={4}>
                        13. HOW CAN I DELETE MY PERSONAL INFORMATION FROM AQIVAH?
                    </Title>
                    <Paragraph>
                        You can remove your Personal Information from Aqivah at any time by logging into your account, accessing the
                        Settings page, and then deleting your account. More details can be found under “Settings” on the home page.
                        Please note that, for security reasons, subscribers of our paid services will first be instructed to cancel
                        their subscriptions before they can delete their Aqivah account.
                    </Paragraph>
                </div>

                <div className="policy-section" id="info-retention">
                    <Title style={title} level={4}>
                        14. HOW LONG IS PERSONAL INFORMATION RETAINED?
                    </Title>
                    <Paragraph>
                        You can remove your Personal Information from Aqivah at any time by deleting your account as described above.
                        However, we may keep some of your Personal Information for as long as reasonably necessary for our legitimate
                        business interests, including fraud detection and prevention and to comply with our legal obligations including
                        tax, legal reporting, and auditing obligations.
                    </Paragraph>
                </div>

                <div className="policy-section" id="account-closure">
                    <Title style={title} level={4}>
                        15. WHAT HAPPENS IF AQIVAH CLOSES MY ACCOUNT?
                    </Title>
                    <Paragraph>
                        If Aqivah closes your account due to your violation of the Terms of Service, then you may contact Aqivah to
                        request deletion of your data. Aqivah will evaluate such requests on a case by case basis, pursuant to our
                        legal obligations.
                    </Paragraph>
                </div>

                <div className="policy-section" id="policy-changes">
                    <Title style={title} level={4}>
                        16. CHANGES TO THIS POLICY
                    </Title>
                    <Paragraph>
                        As Aqivah evolves, we may need to update this Policy to keep pace with changes in our Site, Software, and
                        Services, our business, and laws applicable to you and us. We will, however, always maintain our commitment to
                        respect your privacy. We will notify you of any material changes that impact your rights under this Policy by
                        email (to your most recently provided email address) or post any other revisions to this Policy, along with
                        their effective date, in an easy-to-find area of the Site, so we recommend that you periodically check back
                        here to stay informed of any changes. Please note that your continued use of Aqivah after any change means that
                        you agree with and consent to be bound by the new Policy. If you disagree with any subsequent changes in this
                        Policy and do not wish your information to be subject to it, you will need to delete your Aqivah account.
                    </Paragraph>
                </div>

                <div className="policy-section" id="policy-enforcement">
                    <Title style={title} level={4}>
                        17. ENFORCEMENT OF POLICY
                    </Title>
                    <Paragraph>
                        If for some reason you believe Aqivah has not adhered to these standards, please alert us and we will spare no
                        effort to swiftly make the necessary amendments.
                    </Paragraph>
                </div>

                <div className="policy-section" id="contact">
                    <Title style={title} level={4}>
                        18. CONTACT US
                    </Title>
                    <Paragraph>
                        You may contact us with any questions or comments relating to this Privacy Policy by submitting a help desk
                        request here or by contacting us at <a href="mailto: privacy@aqivah.com">privacy@aqivah.com</a> .
                    </Paragraph>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PolicyDoc;
