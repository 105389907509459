export const Domain = () => {
    const productionDomain = ".aqivah.com";
    const testDomain = "aqivah-test.netlify.app";
    const localDomain = ".localhost";

    console.log("Domain seeking line:", process.env.REACT_APP_LOCAL, process.env.REACT_APP_TEST_VARIABLE)
    if (process.env.REACT_APP_ENVIRONMENT === "production" && !process.env.REACT_APP_LOCAL) {
        return productionDomain
    }

    if (process.env.REACT_APP_ENVIRONMENT === "development" && !process.env.REACT_APP_LOCAL) {
        return testDomain;
    }

    if (process.env.REACT_APP_LOCAL === "localhost"){
        return localDomain
    }

    return productionDomain
};