import { CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js'
import {currentUserPool} from './AuthInfo'
import {Domain} from "./Domain"

 const poolData = {
    UserPoolId: currentUserPool.UserPoolId,
    ClientId: currentUserPool.ClientId,
    Storage: new CookieStorage({domain: Domain(), secure: false })
}

export const UserPool = new CognitoUserPool(poolData);