import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "./user-context";
import { GetUser } from "../../Graphql/queries";
import { CreateUser } from "../../Graphql/mutations";
import { client } from "../../Graphql/client";

export const UserProvider = ({ children }: any) => {
    const { client_ } = client();

    const [user, setUser] = useState({});

    function fetchUser() {
        // client_
        //     .query({
        //         query: GetUser(),
        //         variables: { id: "id_value" }
        //     })
        //     .then((res: any) => {
        //         console.log("Fetch new user from new backend response -> ", res);
        //         setUser(res?.data?.getUser);
        //     })
        //     .catch(err => {
        //         console.log(err, "something went wrong");
        //     });
        return;
    }

    useEffect(fetchUser, []);

    const createUser = variables => {
        client_
            .mutate({
                mutation: CreateUser,
                variables: variables
            })
            .then((res: any) => {
                console.log("CreateUser Mutation:", res);
            })
            .catch(err => {
                console.log(err);
            });
    };

    return <UserContext.Provider value={{ user, createUser }}>{children}</UserContext.Provider>;
};
