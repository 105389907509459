interface IPropertyValues {
    name: string;
    value: string;
}
type PropertyType = Array<IPropertyValues>;

export const residentialPropertyTypes: PropertyType = [
    { name: "House", value: "house" },
    { name: "Apartment", value: "apartment" },
    { name: "Townhouse", value: "townhouse" },
    { name: "Bungalow", value: "bungalow" }
];

export const commercialPropertyTypes: PropertyType = [
    { name: "Office", value: "office" },
    { name: "Retail Shop", value: "retail_shop" }
];

export const farmlandPropertyTypes: PropertyType = [
    { name: "Crop farm", value: "crop_farm" },
    { name: "Fish farm", value: "fish_farm" },
    { name: "Livestock farm", value: "livestock_farm" }
];

export const eventsPropertyTypes: PropertyType = [
    { name: "Theatre", value: "theatre" },
    { name: "Church", value: "church" }
];

export const amenities = ["Gym", "Laund"];
