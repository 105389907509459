import { createContext } from "react";

export interface User {
    _id: string;
    name: string;
    first_name: string;
    last_name: string;
    email: string;
    documents: Array<any>;
    phone: string;
    user_type: "owner" | "seeker" | "investor";
}

export interface Organization {
    _id: string;
    name: string;
    email: string;
    phone_number: string;
    password?: string;
    phone: string;
    address: string;
    verified: boolean;
    members: Array<any>;
    documents?: Array<File>;
    createdBy?: any;
    url_name?: string;
    subscription: string;
    image?: string;
}

interface IOrganizationProviderContext {
    organization: Organization | null;
    refreshOrganization: () => void;
}

export const OrganizationProviderContext = createContext<IOrganizationProviderContext>({
    organization: null,
    refreshOrganization: () => console.log("uninitiated")
});
