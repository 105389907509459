import React, { useContext } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer/Footer";
import Grid from "@mui/material/Grid";
import "./list-property";
import organization_OwnerImg from "../../assets/owners_icons/organization_owner.png";
import ownerImg from "../../assets/owners_icons/home_owner.png";
import { useNavigate } from "react-router-dom";
import { AuthenticationContext } from "../../context/auth-context/auth-context";
import { AdminProviderContext } from "../../common/admin-provider/admin-provider-context";

export const ListingInfo = () => {
    const navigate = useNavigate();
    const { openLoginModal } = useContext(AuthenticationContext);
    const { user } = useContext(AdminProviderContext);

    const registerOwner = () => {
        if (user) {
            navigate("/seeker-owner-registration");
        } else {
            navigate("/owner-registration");
        }
    };

    const registerOrganization = () => {
        navigate("/organization-registration");
    };

    return (
        <div id="listing-info">
            <Header />
            <div className="hero">
                <div className="container-sm">
                    <h3 className="fw-light text-center mb-4">Explore Your Selling Options</h3>
                    <p className="p-info-lg text-center">
                        Wherever you are in the selling journey, our hassle-free tools and guides will help you make the right move.
                    </p>
                </div>
            </div>

            <div className="image-background"></div>

            <div className="listing-info-section">
                <div className="container-sm">
                    <h3 className="fw-light text-center mb-5">Complete Your Registration to Unlock Your Owner Dashboard</h3>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <img src={ownerImg} className="mx-auto icon-img" alt="" />
                                </Grid>
                                <Grid item xs={10}>
                                    <h4 className="text-left fw-light">Register as Owner</h4>
                                    <p className="p-regular text-left">
                                        Register as an owner on our platform to list properties and view requests from customers
                                    </p>
                                    <div className="text-center"></div>
                                    <div className="button-container-dt mt-5">
                                        <button onClick={registerOwner} className="ml-auto mr-auto action-button">
                                            Get Started
                                        </button>
                                    </div>
                                </Grid>
                            </Grid>
                            <div className="button-container-mb mt-5">
                                <button onClick={registerOwner} className="mx-auto action-button">
                                    Get Started
                                </button>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <img src={organization_OwnerImg} alt="" className="mx-auto icon-img" />
                                </Grid>
                                <Grid item xs={10}>
                                    <h4 className="text-left fw-light">Register as an Organization</h4>
                                    <p className="p-regular text-left">
                                        Register your company to list property on the Aqivah platform. Organizations have the option of
                                        enlisting members to act as agents on behalf of said organization
                                    </p>
                                    <div className="text-left"></div>
                                    <div className="button-container-dt mt-5">
                                        <button onClick={registerOrganization} className="mx-auto action-button">
                                            Get Started
                                        </button>
                                    </div>
                                </Grid>
                            </Grid>
                            <div className="button-container-mb mt-5">
                                <button onClick={registerOrganization} className="mx-auto action-button">
                                    Get Started
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className="login-section">
                <div className="container-sm">
                    <h3 className="fw-light text-center mb-4">Already Have An Account?</h3>
                    <div className="text-center mt-5">
                        <button onClick={openLoginModal} className="mx-auto action-button">
                            Login Here
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
};
