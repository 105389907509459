import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import "./css/scrollbar.css";

export default function ScrollBar({ user, children, className, ...restProps }) {
    const [hovering, setHovering] = useState(false);
    const [scrollBoxHeight, setScrollBoxHeight] = useState(20);
    const [scrollBoxTop, setScrollBoxTop] = useState(0);
    const [lastScrollThumbPosition, setScrollThumbPosition] = useState(0);
    const [isDragging, setDragging] = useState(false);

    const handleMouseOver = useCallback(() => {
        !hovering && setHovering(true);
    }, [hovering]);

    const handleMouseOut = useCallback(() => {
        !!hovering && setHovering(false);
    }, [hovering]);

    const handleDocumentMouseUp = useCallback(
        e => {
            if (isDragging) {
                e.preventDefault();
                setDragging(false);
            }
        },
        [isDragging]
    );

    const handleDocumentMouseMove = useCallback(
        e => {
            if (isDragging) {
                e.preventDefault();
                e.stopPropagation();
                const scrollHostElement = scrollHostRef.current;
                // @ts-ignore: Unreachable code error
                const { scrollHeight, offsetHeight } = scrollHostElement;

                let deltaY = e.clientY - lastScrollThumbPosition;
                let percentage = deltaY * (scrollHeight / offsetHeight);

                setScrollThumbPosition(e.clientY);
                setScrollBoxTop(Math.min(Math.max(0, scrollBoxTop + deltaY), offsetHeight - scrollBoxHeight));
                // @ts-ignore: Unreachable code error
                scrollHostElement.scrollTop = Math.min(
                    // @ts-ignore: Unreachable code error
                    scrollHostElement.scrollTop + percentage,
                    scrollHeight - offsetHeight
                );
            }
        },
        [isDragging, lastScrollThumbPosition, scrollBoxHeight, scrollBoxTop]
    );
    const handleClick = useCallback(
        e => {
            const scrollHostElement = scrollHostRef.current;
            // @ts-ignore: Unreachable code error
            const { scrollHeight, offsetHeight } = scrollHostElement;

            let deltaY = e.clientY - lastScrollThumbPosition;
            let percentage = deltaY * (scrollHeight / offsetHeight);

            setScrollThumbPosition(e.clientY);
            setScrollBoxTop(Math.min(Math.max(0, scrollBoxTop + deltaY), offsetHeight - scrollBoxHeight));
            // @ts-ignore: Unreachable code error
            scrollHostElement.scrollTop = Math.min(
                // @ts-ignore: Unreachable code error
                scrollHostElement.scrollTop + percentage,
                scrollHeight - offsetHeight
            );
        },
        [lastScrollThumbPosition, scrollBoxHeight, scrollBoxTop]
    );

    const handleScrollThumbMouseDown = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
        setScrollThumbPosition(e.clientY);
        setDragging(true);
        console.log("handleScrollThumbMouseDown");
        console.log(e);
    }, []);

    const handleScroll = useCallback(() => {
        if (!scrollHostRef) {
            return;
        }
        const scrollHostElement = scrollHostRef.current;
        // @ts-ignore: Unreachable code error
        const { scrollTop, scrollHeight, offsetHeight } = scrollHostElement;

        let newTop = (parseInt(scrollTop, 10) / parseInt(scrollHeight, 10)) * offsetHeight;
        // newTop = newTop + parseInt(scrollTop, 10);
        newTop = Math.min(newTop, offsetHeight - scrollBoxHeight);
        setScrollBoxTop(newTop);
    }, [scrollBoxHeight]);

    // ***@ code to scroll to latest message. Still under development
    // // @ts-ignore: Unreachable code error
    // const Messages = useSelector(state => state.newMessages)
    // const {message} = Messages

    // useEffect(() => {
    //   if(!scrollHostRef){
    //     return
    //   }

    //   console.log(user, message.from, message.to)

    //   if(message.to === user || message.from === user){
    //   const scrollHostElement = scrollHostRef.current;
    //   // @ts-ignore: Unreachable code error
    //   const { scrollTop, scrollHeight, offsetHeight } = scrollHostElement;

    //   let x = offsetHeight - scrollBoxHeight
    //   let y = scrollHeight - x
    //   let z = y - scrollBoxHeight

    //   let newTop =
    //     (parseInt(scrollTop, 10) / parseInt(scrollHeight, 10)) * offsetHeight;
    //   // newTop = newTop + parseInt(scrollTop, 10);
    //   newTop = Math.min(newTop, offsetHeight - scrollBoxHeight);
    //   setScrollBoxTop(z);
    //   console.log(offsetHeight, scrollBoxHeight, scrollTop, scrollHeight)
    //   }
    // }, [message])

    const scrollHostRef = useRef();

    useEffect(() => {
        const scrollHostElement = scrollHostRef.current;
        // @ts-ignore: Unreachable code error
        const { clientHeight, scrollHeight } = scrollHostElement;
        const scrollThumbPercentage = clientHeight / scrollHeight;
        const scrollThumbHeight = Math.max(scrollThumbPercentage * clientHeight, 20);
        setScrollBoxHeight(scrollThumbHeight);
        // @ts-ignore: Unreachable code error
        scrollHostElement.addEventListener("scroll", handleScroll, true);
        return function cleanup() {
            // @ts-ignore: Unreachable code error
            scrollHostElement.removeEventListener("scroll", handleScroll, true);
        };
    }, [handleScroll]);

    useEffect(() => {
        //this is to handle the dragging on scroll-thumb
        document.addEventListener("mousemove", handleDocumentMouseMove);
        document.addEventListener("mouseup", handleDocumentMouseUp);
        document.addEventListener("mouseleave", handleDocumentMouseUp);
        document.addEventListener("mouseclick", handleClick);
        return function cleanup() {
            document.removeEventListener("mousemove", handleDocumentMouseMove);
            document.removeEventListener("mouseup", handleDocumentMouseUp);
            document.removeEventListener("mouseleave", handleDocumentMouseUp);
            document.removeEventListener("mouseclick", handleClick);
        };
    }, [handleDocumentMouseMove, handleDocumentMouseUp, handleClick]);

    return (
        <div className={"scrollhost-container"} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <div
                // @ts-ignore: Unreachable code error
                ref={scrollHostRef}
                className={`scrollhost ${className}`}
                {...restProps}>
                {children}
            </div>
            <div className={"scroll-bar"} style={{ opacity: hovering ? 1 : 0 }} onClick={handleClick}>
                <div
                    className={"scroll-thumb"}
                    style={{ height: scrollBoxHeight, top: scrollBoxTop }}
                    onMouseDown={handleScrollThumbMouseDown}
                />
            </div>
        </div>
    );
}
