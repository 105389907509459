import React, { useState, useContext } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { PopupProviderContext } from "../../common/popup-alerts-provider/popup-context";
import { AdminProviderContext } from "../../common/admin-provider/admin-provider-context";
import { useFormik } from "formik";
import * as Yup from "yup";
import ownerImgage from "../../assets/owners_icons/business_owner.png";
import "./register.scss";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link as RouterLink } from "react-router-dom";
import { logError } from "../../utils/logError";
import Spin from "antd/es/spin";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";

export const ExistingUserOwnerRegistration = () => {
    const [loading, setLoading] = useState(false);
    const [showPassword2, setShowPassword2] = useState<boolean>(false);
    const [showPassword3, setShowPassword3] = useState<boolean>(false);

    const navigate = useNavigate();

    const { flashError, flashSuccess } = useContext(PopupProviderContext);
    const { user, refreshAdmin } = useContext(AdminProviderContext);

    // let phone_regex = /^([026|26]).\d{7,8}$/;

    const validationSchemaSignUp = Yup.object({});

    const formikSignUp = useFormik({
        initialValues: {
            // firstName: user?.first_name || "",
            // lastName: user?.last_name || "",
            // email: user?.email || "",
            // phoneNumber: user?.phone || "",
            // password: "",
            // confirmPassword: "",
            // acknowledge: false
        },
        validationSchema: validationSchemaSignUp,
        onSubmit: values => {
            setLoading(true);
            console.log("stored user", user);
            axios
                .post("/auth/existing-user-owner-registration", { email: user?.email, user_type: "seeker" })
                .then((res: any) => {
                    console.log(res);
                    flashSuccess("Registration successful, you'll be redirected to the dashboard shortly.");
                    refreshAdmin();
                    navigate("/owner-dashboard");
                    // setSuccess(true);
                    // openLogin();
                })
                .catch(err => {
                    console.log(err);
                    // setError(logError(err));
                    flashError("Something went wrong", logError(err));
                    setLoading(false);
                })
                .then(() => {
                    setLoading(false);
                });
        }
    });

    return (
        <div id="owner-registration" className="py-5">
            <Header />

            <div className="hero">
                <div className="container-sm">
                    <h3 className="fw-light text-center mb-4">Explore Your Selling Options</h3>
                    <p className="p-info-lg text-center">
                        Wherever you are in the selling journey, our hassle-free tools and guides will help you make the right move.
                    </p>
                </div>
            </div>

            <div className="image-background"></div>

            <div className="registration-section">
                <div className="container-xxm py-5">
                    <h3 className="text-center fw-light mb-5">Register As An Owner</h3>

                    <div>
                        <form onSubmit={formikSignUp.handleSubmit}>
                            <Button type="submit" fullWidth variant="contained" color="primary">
                                {loading ? <Spin spinning={true} size="small"></Spin> : "Register As An Owner"}
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
