
export const SEARCH_RESULTS_REQUEST = 'SEARCH_RESULTS_REQUEST'
export const SEARCH_RESULTS_SUCCESS = 'SEARCH_RESULTS_SUCCESS'
export const SEARCH_RESULTS_FAIL = 'SEARCH_RESULTS_FAIL'
export const SEARCH_RESULTS_RESET = 'SEARCH_RESULTS_RESET'

export const CREATE_PROPERTY_NEXT_STEP = 'CREATE_PROPERTY_NEXT_STEP'
export const CREATE_PROPERTY_PREVIOUS_STEP = 'CREATE_PROPERTY_PREVIOUS_STEP'
export const CREATE_PROPERTY_RESET_STEP = 'CREATE_PROPERTY_RESET_STEP'


export const CREATE_PROPERTY_REQUEST = 'CREATE_PROPERTY_REQUEST'
export const CREATE_PROPERTY_SUCCESS = 'CREATE_PROPERTY_SUCCESS'
export const CREATE_PROPERTY_FAIL = 'CREATE_PROPERTY_FAIL'
export const CREATE_PROPERTY_RESET = 'CREATE_PROPERTY_RESET'

export const UPDATE_PROPERTY_REQUEST = 'UPDATE_PROPERTY_REQUEST'
export const UPDATE_PROPERTY_SUCCESS = 'UPDATE_PROPERTY_SUCCESS'
export const UPDATE_PROPERTY_FAIL = 'UPDATE_PROPERTY_FAIL'
export const UPDATE_PROPERTY_RESET = 'UPDATE_PROPERTY_RESET'

export const FETCH_OWNER_PROPERTY_REQUEST = 'FETCH_OWNER_PROPERTY_REQUEST'
export const FETCH_OWNER_PROPERTY_SUCCESS = 'FETCH_OWNER_PROPERTY_SUCCESS'
export const FETCH_OWNER_PROPERTY_FAIL = 'FETCH_OWNER_PROPERTY_FAIL'
export const FETCH_OWNER_PROPERTY_RESET = 'FETCH_OWNER_PROPERTY_RESET'

export const CREATE_PROPERTY_REQUEST_REQUEST = 'CREATE_PROPERTY_REQUEST_REQUEST'
export const CREATE_PROPERTY_REQUEST_SUCCESS = 'CREATE_PROPERTY_REQUEST_SUCCESS'
export const CREATE_PROPERTY_REQUEST_FAIL = 'CREATE_PROPERTY_REQUEST_FAIL'
export const CREATE_PROPERTY_REQUEST_RESET = 'CREATE_PROPERTY_REQUEST_RESET'

export const GET_PROPERTY_REQUEST = 'GET_PROPERTY_REQUEST'
export const GET_PROPERTY_SUCCESS = 'GET_PROPERTY_SUCCESS'
export const GET_PROPERTY_FAIL = 'GET_PROPERTY_FAIL'



