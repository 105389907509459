import gql from "graphql-tag";

// New Queries

export const ListUsers = () => gql`
    query ($id: ID!, $status: UserStatus!) {
        listUsers(id: $id, status: $status) {
            items {
                id
                firstname
                lastname
            }
        }
    }
`;

export const GetUser_ = () => gql`
    query ($id: ID) {
        getUser(id: $id) {
            id
            firstname
            lastname
            properties
            ownerType
            status
        }
    }
`;

export const ListProperties = () => gql`
   query ($category: PropertyCategory) {
    getProperties (filter: {category: $category, acquisition: "RENT"}) {
      items {
        id
        acquisition
        price 
        description
        images
        ... on Agricultural_Farmland {
          size {
            measurement
            unit
          }
        }
        ... on Commercial {
          amenities
          parkingCapacity
        }
        ... on Parties_Event {
          seating
        }
        ... on Residential {
          amenities
          bedrooms
          bathrooms
        }
      },
      # nextToken
    }
`;

export const ListAdvertisements = () => gql`
    query {
        listAdvertisements {
            items
        }
    }
`;

export const ListPropertyRequests = () => gql`
    query {
        listPropertyRequests {
            items
        }
    }
`;

export const GetUser = () => gql`
    query ($id: ID!) {
        getUser(id: $id) {
            id
            firstName
            lastName
            mobile
            email
            createdAt
        }
    }
`;

export const GetProperty = () => gql`
    query ($id: ID!) {
        getProperty(property: { id: $id }) {
            ... on Agricultural_Farmland {
                id
            }
            ... on Commercial {
                id
            }
            ... on Parties_Event {
                id
            }
            ... on Residential {
                id
                type
                price {
                    currency
                    figure
                    duration
                }
                acquisition
                description
                location {
                    town
                }
                images
                bedrooms
                bathrooms
                state
                owner
            }
        }
    }
`;

export const GetPropertyRequest = () => gql`
   query(){
    getPropertyRequest(){
      property{
        item{
          ... on Agricultural_Farmland{
            id type acquisition 
          }
          ... on Commercial {
            id type acquisition
          }
          ... on Parties_Event {
            id type acquisition
          }
          ... on Residential {
            id type price{currency, figure, duration} acquisition description location{town} images bedrooms bathrooms state
          }
        }
      }
    }
   }
`;
