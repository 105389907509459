import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./list-property.scss";
import Backdrop from "@mui/material/Backdrop";
import { PropertyContext } from "../../../../context/property-context/property-context";
import { ResidentialForm } from "../../../property-listing/residential-form";
import { CommercialForm } from "../../../property-listing/commercial-form";
import { FarmlandForm } from "../../../property-listing/farmland-form";

export const ListPropertyPage = () => {
    const { property, events, handleCategoryChange, PROPERTY_UPLOAD } = useContext(PropertyContext);

    return (
        <>
            <Backdrop open={events.includes(PROPERTY_UPLOAD)} style={{ zIndex: "5" }}>
                <span className="spinner-border text-white" role="status"></span>
            </Backdrop>

            <div className="container-xxm list-property-card">
                <div className="d-flex flex-column justify-content-start align-items-center">
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} lg={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={property.category === "Residential"} />}
                                        name="Residential"
                                        onChange={handleCategoryChange}
                                        label="Residential"
                                    />
                                </Grid>

                                <Grid item xs={12} lg={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={property.category === "Commercial"} />}
                                        name="Commercial"
                                        onChange={handleCategoryChange}
                                        label="Commercial"
                                    />
                                </Grid>

                                <Grid item xs={12} lg={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={property.category === "Farmland"} />}
                                        name="Farmland"
                                        onChange={handleCategoryChange}
                                        label="Farmlands"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={property.category === "Events"} />}
                                        name="Events"
                                        onChange={handleCategoryChange}
                                        label="Party & Events"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {property.category === "Residential" && (
                            <Grid className="d-flex justify-content-around align-items-center mb-5" item xs={12} lg={12}>
                                <ResidentialForm />
                            </Grid>
                        )}
                        {property.category === "Commercial" && (
                            <Grid className="d-flex justify-content-around align-items-center mb-5" item xs={12} lg={12}>
                                <CommercialForm />
                            </Grid>
                        )}
                        {property.category === "Farmland" && (
                            <Grid className="d-flex justify-content-around align-items-center mb-5" item xs={12} lg={12}>
                                <FarmlandForm />
                            </Grid>
                        )}

                        <Grid item xs={12} lg={6}></Grid>
                        <Grid item xs={12} lg={6}></Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
};
