import { createContext } from "react";

export interface User {
    _id: string;
    name: string;
    first_name: string;
    last_name: string;
    email: string;
    documents: Array<any>;
    phone: string;
    organization?: any;
    property_documents?: any;
    owner_documents?: any;
    user_type: "owner" | "seeker" | "investor" | "organization_member";
}

export interface Organization {
    _id: string;
    name: string;
    email: string;
    phone_number: string;
    password?: string;
    phone: string;
    address: string;
    verified: boolean;
    members: Array<any>;
    documents?: Array<File>;
    createdBy?: any;
    url_name?: string;
    subscription: string;
    image?: string;
}

interface IUserProviderContext {
    organization: Organization | null;
    user: User | null;
    refreshAdmin: () => void;
    getUser: () => void;
}

export const AdminProviderContext = createContext<IUserProviderContext>({
    organization: null,
    user: null,
    refreshAdmin: () => console.log("uninitiated"),
    getUser: () => console.log("")
});
