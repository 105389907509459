import React, { useState, useEffect, useContext } from "react";
import { createStyles, makeStyles, Theme } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Upload from "antd/es/upload";
import Modal from "antd/es/modal";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import BathtubIcon from "@mui/icons-material/Bathtub";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import FormHelperText from "@mui/material/FormHelperText";
import * as Yup from "yup";
import { PropertyContext } from "../../context/property-context/property-context";
import { useFormik } from "formik";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            // margin: theme.spacing(1),
            minWidth: "100%",
            maxWidth: "100%"
        },
        formControlPrice: {
            minWidth: "30%",
            maxWidth: "30%",
            marginRight: "3%"
        },
        selectEmpty: {
            // marginTop: theme.spacing(2),
        },
        buttons: {
            display: "flex",
            justifyContent: "flex-end"
        },
        button: {
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(1)
        }
    })
);

export default function PropertyDetails() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [bathrooms, setBathrooms] = useState(0);
    const [bedrooms, setBedrooms] = useState(0);

    const { nextStep, handlePropertyDetails, propertyDetails, status } = useContext(PropertyContext);

    const validationSchema = Yup.object({
        category: Yup.string().required("Property category is required"),
        short_description: Yup.string().required("Property description is required"),
        description: Yup.string().required("Property description is required"),
        propertyType: Yup.string().required("Property type is required"),
        saleType: Yup.string().required("Sale type is required"),
        price: Yup.string().required("Price is required"),
        currency: Yup.string().required("Currency is required"),
        paymentPlan: Yup.string().when("saleType", {
            is: "RENT",
            then: Yup.string().required("Payment plan is required")
        }),
        furnishing: Yup.string().when("saleType", {
            is: "RENT",
            then: Yup.string().required("Furnishing state is required")
        }),
        waterSource: Yup.string().when("category", {
            is: "RESIDENTIAL",
            then: Yup.string().required("Water source is required")
        }),
        // bedrooms: Yup.number().when("category", {
        //     is: "RESIDENTIAL",
        //     then: Yup.number().min(1).required("Number of bedrooms required")
        // }),
        // bathrooms: Yup.number().when("category", {
        //     is: "RESIDENTIAL",
        //     then: Yup.number().min(1).required("Number of bathrooms required")
        // }),
        parking_capacity: Yup.number().when("category", {
            is: "COMMERCIAL",
            then: Yup.number().required("Parking capacity required")
        }),
        unit: Yup.string().when("category", {
            is: "AGRICULTURAL",
            then: Yup.string().required("Size unit is required")
        }),
        size: Yup.number().when("category", {
            is: "AGRICULTURAL",
            then: Yup.number().required("Size is required")
        }),
        seating_capacity: Yup.number().when("category", {
            is: "EVENT",
            then: Yup.number().required("Seating is required")
        })
        // images: Yup.array(),
        // new_images: Yup.array()
    });

    const formik = useFormik({
        initialValues: {
            category: "",
            description: "",
            short_description: "",
            propertyType: "",
            saleType: "",
            price: "",
            currency: "",
            paymentPlan: "",
            furnishing: "",
            waterSource: "",
            parking_capacity: 0,
            seating_capacity: 0,
            unit: "",
            size: 0
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            console.log(values);
            handlePropertyDetails({ ...values, bedrooms: bedrooms, bathrooms: bathrooms });
            nextStep();
        }
    });

    function clearFields() {
        setBedrooms(0);
        setBathrooms(0);
        formik.resetForm({
            values: {
                category: "",
                description: "",
                short_description: "",
                propertyType: "",
                saleType: "",
                price: "",
                currency: "",
                paymentPlan: "",
                furnishing: "",
                waterSource: "",
                parking_capacity: 0,
                seating_capacity: 0,
                unit: "",
                size: 0
            }
        });
    }

    useEffect(() => {
        if (status.success) {
            clearFields();
        }
    }, [status]);

    const [existing_images, setExistingImages] = useState<any>([]);
    const [images, addImages] = useState<any>([]);
    const [new_images, newImages] = useState<any>([]);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>("");
    const [previewTitle, setPreviewTitle] = useState<string>("");
    const [actualImageFile, setActualImage] = useState<any>([]);
    const [price, setPrice] = useState<any>(0);
    const [formattedNumber, setFormattedPrice] = useState<any>("");

    useEffect(() => {
        let description_input = document.getElementById("description");

        console.log(propertyDetails);

        let property_price = propertyDetails.price ? formatNumber(propertyDetails.price) : 0;

        //propertyType.length !== 0 && setField(propertyDetails);
        propertyDetails.images && addImages(propertyDetails.images);
        propertyDetails.existing_images && setExistingImages(propertyDetails.existing_images);
        propertyDetails.description && appendDescription(propertyDetails.description, description_input);

        let image_url: any = [];
        if (propertyDetails.existing_images) {
            propertyDetails.existing_images.map(image_name => {
                image_url = [
                    ...image_url,
                    {
                        state: "existing",
                        uid: image_name,
                        url: `https://aqivah-backend-test-property-upload.s3.us-west-2.amazonaws.com/${image_name}.jpg`,
                        name: image_name,
                        status: "done"
                    }
                ];
            });

            addImages([...image_url]);
        }

        // formik.resetForm({
        //     values: {
        //         category: propertyDetails.category || "",
        //         description: propertyDetails.description || "",
        //         short_description: propertyDetails.short_description || "",
        //         propertyType: propertyDetails.propertyType || "",
        //         saleType: propertyDetails.saleType || "",
        //         price: propertyDetails.price || "",
        //         currency: propertyDetails.currency || "",
        //         paymentPlan: propertyDetails.paymentPlan || "",
        //         furnishing: propertyDetails.furnishing || "",
        //         waterSource: propertyDetails.waterSource || "",
        //         bedrooms: propertyDetails.bedrooms || 0,
        //         bathrooms: propertyDetails.bathrooms || 0,
        //         parking_capacity: propertyDetails.parking_capacity || 0,
        //         seating_capacity: propertyDetails.seating_capacity || 0,
        //         unit: propertyDetails.unit || "",
        //         size: propertyDetails.size || 0
        //         // images: propertyDetails.images || [],
        //         // newImages: propertyDetails.newImages || []
        //     }
        // });
    }, []);

    const handleBathroomChange = (value: any) => {
        if (value === "increase") {
            bathrooms < 10 && setBathrooms(bathrooms + 1);
            return;
        }
        if (value === "decrease") {
            bathrooms > 0 && setBathrooms(bathrooms - 1);
            return;
        }
    };

    const handleBedroomChange = (value: any) => {
        if (value === "increase") {
            bedrooms < 10 && setBedrooms(bedrooms + 1);
            return;
        }
        if (value === "decrease") {
            bedrooms > 0 && setBedrooms(bedrooms - 1);
            return;
        }
    };

    function getBase64(file: any) {
        //console.log('images', file)
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const handlePreview = async (file: any) => {
        //console.log(file)
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
        setActualImage([...actualImageFile]);
    };

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleCancel = () => {
        setPreviewVisible(false);
    };

    const handleChange = ({ fileList }: any) => {
        //console.log(fileList)
        addImages(fileList);
        formik.values.images = fileList;
        for (let image = 0; image < fileList.length; image++) {
            if (!fileList[image].state) {
                newImages([...new_images, fileList[image]]);
                formik.values.newImages = [...new_images, fileList[image]];
            }
        }
    };

    const handleNext = () => {
        nextStep();
    };

    const appendDescription = async (value, input_field) => {
        //  @ts-ignore: Unreachable code error
        input_field.textContent = value ? value : "";
    };

    const handlePrice = e => {
        let price_ = e.target.value.replaceAll(",", "");
        if (Number(price_)) {
            setPrice(Number(price_));
            setFormattedPrice(formatNumber(Number(price_)));
        }
        if (!Number(price_)) {
            console.log(Number(price_));
            let number = formattedNumber.replaceAll(",", "");
            if (Number(number) === Infinity) {
                setFormattedPrice("");
                setPrice(0);
            } else {
                setPrice(Number(price_));
                Number(price_) === 0 ? setFormattedPrice("") : setFormattedPrice(formatNumber(Number(price_)));
            }
        }
    };

    const formatNumber = (num: any) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    };

    const addCommas = (num: any) => {
        console.log(num);
        if (num) {
            let b = Number(num).toLocaleString("en-US");
            console.log("value:", b);
            return num.toLocaleString("en-US");
        } else {
            return "";
        }
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography variant="h6" gutterBottom>
                Property details
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel
                            error={formik.touched.category && (formik.errors.category ? true : false)}
                            id="demo-simple-select-label">
                            Category
                        </InputLabel>
                        <Select
                            id="category"
                            name="category"
                            value={formik.values.category}
                            onChange={formik.handleChange}
                            fullWidth
                            label="Category">
                            <MenuItem value={"RESIDENTIAL"}>Residential</MenuItem>
                            <MenuItem value={"COMMERCIAL"}>Commercial</MenuItem>
                            <MenuItem value={"AGRICULTURAL_FARMLAND"}>Agricultural</MenuItem>
                            <MenuItem value={"PARTIES_EVENT"}>Party and Events</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id="short_description"
                        name="short_description"
                        label="Short description"
                        fullWidth
                        value={formik.values.short_description}
                        onChange={formik.handleChange}
                        error={formik.touched.short_description && (formik.errors.short_description ? true : false)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id="description"
                        name="description"
                        label="Description"
                        fullWidth
                        /*value={description}*/ onChange={formik.handleChange}
                        value={formik.values.description}
                        multiline
                        rows={6}
                        rowsMax={60}
                        error={formik.touched.description && (formik.errors.description ? true : false)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Property type</InputLabel>
                        <Select
                            id="type"
                            name="propertyType"
                            value={formik.values.propertyType}
                            onChange={formik.handleChange}
                            fullWidth
                            label="Property type"
                            error={formik.touched.propertyType && (formik.errors.propertyType ? true : false)}>
                            {formik.values.category === "RESIDENTIAL"
                                ? [
                                      <MenuItem key={"house"} value={"HOUSE"}>
                                          House
                                      </MenuItem>,
                                      <MenuItem key={"bungalow"} value={"BUNGALOW"}>
                                          Bungalow
                                      </MenuItem>,
                                      <MenuItem key={"townhouse"} value={"TOWNHOUSE"}>
                                          Townhouse
                                      </MenuItem>,
                                      <MenuItem key={"apartment"} value={"APARTMENT"}>
                                          Apartment
                                      </MenuItem>
                                  ]
                                : formik.values.category === "COMMERCIAL"
                                ? [
                                      <MenuItem key={"office"} value={"OFFICE"}>
                                          Office
                                      </MenuItem>,
                                      <MenuItem key={"shop"} value={"SHOP"}>
                                          Shop
                                      </MenuItem>,
                                      <MenuItem key={"shipping_container"} value={"SHIPPING_CONTAINER"}>
                                          Shipping Container
                                      </MenuItem>
                                  ]
                                : formik.values.category === "AGRICULTURAL_FARMLAND"
                                ? [
                                      <MenuItem key={"fish_farm"} value={"FISH_FARM"}>
                                          Fish farm
                                      </MenuItem>,
                                      <MenuItem key={"crop_farm"} value={"CROP_FARM"}>
                                          Crop farm
                                      </MenuItem>,
                                      <MenuItem key={"livestock"} value={"LIVESTOCK"}>
                                          Livestock
                                      </MenuItem>
                                  ]
                                : formik.values.category === "PARTIES_EVENT"
                                ? [
                                      <MenuItem key={"church"} value={"CHURCH"}>
                                          Church
                                      </MenuItem>,
                                      <MenuItem key={"theatre"} value={"THEATRE"}>
                                          Theatre
                                      </MenuItem>
                                  ]
                                : [
                                      <MenuItem key={"house"} value={"HOUSE"}>
                                          House
                                      </MenuItem>,
                                      <MenuItem key={"bungalow"} value={"BUNGALOW"}>
                                          Bungalow
                                      </MenuItem>,
                                      <MenuItem key={"townhouse"} value={"TOWNHOUSE"}>
                                          Townhouse
                                      </MenuItem>,
                                      <MenuItem key={"apartment"} value={"APARTMENT"}>
                                          Apartment
                                      </MenuItem>
                                  ]}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel /*error={submit && error.saleType}*/ id="demo-simple-select-label">Sale type</InputLabel>
                        <Select
                            value={formik.values.saleType}
                            onChange={formik.handleChange}
                            name="saleType"
                            fullWidth
                            label="Sale type"
                            error={formik.touched.saleType && (formik.errors.saleType ? true : false)}>
                            <MenuItem value={"RENT"}>For rent</MenuItem>
                            <MenuItem value={"BUY"}>For sale</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item style={{ display: "flex" }} xs={12} sm={6}>
                    <FormControl className={classes.formControlPrice}>
                        <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                        <Select
                            value={formik.values.currency}
                            name="currency"
                            onChange={formik.handleChange}
                            fullWidth={false}
                            label="Currency">
                            <MenuItem value={"GHS"}>GHS</MenuItem>
                            <MenuItem value={"USD"}>USD</MenuItem>
                        </Select>
                    </FormControl>

                    <div>
                        <TextField
                            id="price"
                            name="price"
                            label={"Price"}
                            type={"text"}
                            fullWidth
                            //value={fields.formattedNumber}
                            value={formik.values.price.toLocaleString()}
                            onChange={formik.handleChange}
                            error={formik.touched.price && (formik.errors.price ? true : false)}
                        />
                    </div>
                </Grid>

                {formik.values.saleType === "RENT" && (
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                error={formik.touched.paymentPlan && (formik.errors.paymentPlan ? true : false)}
                                id="demo-simple-select-label">
                                Payment plan
                            </InputLabel>
                            <Select
                                value={formik.values.paymentPlan}
                                name="paymentPlan"
                                onChange={formik.handleChange}
                                fullWidth
                                label="Payment plan"
                                error={formik.touched.paymentPlan && (formik.errors.paymentPlan ? true : false)}>
                                <MenuItem value={"DAILY"}>Daily payment</MenuItem>
                                <MenuItem value={"WEEKLY"}>Weekly payment</MenuItem>
                                <MenuItem value={"MONTHLY"}>Monthly payment</MenuItem>
                                <MenuItem value={"YEARLY"}>Yearly payment</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}

                {(formik.values.category === "RESIDENTIAL" || formik.values.category === "COMMERCIAL") && (
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                error={formik.touched.furnishing && (formik.errors.furnishing ? true : false)}
                                id="demo-simple-select-label">
                                Furnishing
                            </InputLabel>
                            <Select
                                value={formik.values.furnishing}
                                name="furnishing"
                                onChange={formik.handleChange}
                                fullWidth
                                label="Furnishing"
                                error={formik.touched.furnishing && (formik.errors.furnishing ? true : false)}>
                                <MenuItem value={"NOT_FURNISHED"}>Unfurnished</MenuItem>
                                <MenuItem value={"PARTLY_FURNISHED"}>Partly Furnished</MenuItem>
                                <MenuItem value={"FURNISHED"}>Fully Furnished</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}

                {(formik.values.category === "PARTIES_EVENT" || formik.values.category === "COMMERCIAL") && (
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="parking_capacity"
                            name="parking_capacity"
                            label={"Parking Capacity"}
                            type={"number"}
                            fullWidth
                            value={formik.values.parking_capacity}
                            onChange={formik.handleChange}
                            error={formik.touched.parking_capacity && (formik.errors.parking_capacity ? true : false)}
                        />
                    </Grid>
                )}

                {formik.values.category === "PARTIES_EVENT" && (
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="seating_capacity"
                            name="parking_capacity"
                            label={"Seating Capacity"}
                            type={"number"}
                            fullWidth
                            error={formik.touched.seating_capacity && (formik.errors.seating_capacity ? true : false)}
                            value={formik.values.seating_capacity}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                )}

                {formik.values.category === "RESIDENTIAL" && (
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Water source</InputLabel>
                            <Select
                                value={formik.values.waterSource}
                                name="waterSource"
                                onChange={formik.handleChange}
                                fullWidth
                                error={formik.touched.waterSource && (formik.errors.waterSource ? true : false)}
                                label="Water Source">
                                <MenuItem value={"gwcl"}>GWCL</MenuItem>
                                <MenuItem value={"well"}>Well</MenuItem>
                                <MenuItem value={"bore_hole"}>Bore hole</MenuItem>
                                <MenuItem value={"other"}>Other</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}

                {formik.values.category === "AGRICULTURAL_FARMLAND" && (
                    <Grid item style={{ display: "flex" }} xs={12} sm={6}>
                        <FormControl className={classes.formControlPrice}>
                            <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                            <Select
                                defaultValue={" "}
                                name="unit"
                                value={formik.values.unit}
                                onChange={formik.handleChange}
                                fullWidth={false}
                                error={formik.touched.unit && (formik.errors.unit ? true : false)}
                                label="Unit">
                                <MenuItem value={"PLOT"}>Plot</MenuItem>
                                <MenuItem value={"ACRE"}>Acre</MenuItem>
                                <MenuItem value={"HECTARE"}>Hectare</MenuItem>
                                <MenuItem value={"SQUAREMETER"}>Sqare Metre</MenuItem>
                            </Select>
                        </FormControl>

                        <div>
                            <TextField
                                id="size"
                                name="size"
                                label={"Size"}
                                type={"number"}
                                error={formik.touched.size && (formik.errors.size ? true : false)}
                                fullWidth
                                value={formik.values.size}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Upload
                        //customRequest={() => {return}}
                        //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        fileList={[...images]}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        name="image_upload"
                        type="input"
                        multiple={true}
                        style={{ justifySelf: "center", alignSelf: "center" }}>
                        {images.length >= 10 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                        <img alt="example" style={{ width: "100%" }} src={previewImage} />
                    </Modal>
                    {/* <FormHelperText error={submit && error.images ? true : false}>Add a minimum of 3 images</FormHelperText> */}
                </Grid>
            </Grid>

            {formik.values.category === "RESIDENTIAL" && (
                <>
                    <Grid container spacing={3}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                                padding: "30px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center"
                            }}>
                            <Typography variant="subtitle1">
                                <LocalHotelIcon color="primary" style={{ fontSize: "30px" }} />
                            </Typography>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <button
                                    type="button"
                                    name="bedroom_plus"
                                    className="bath_buttons"
                                    onClick={() => handleBedroomChange("increase")}>
                                    <PlusOutlined />
                                </button>

                                <input name="bedrooms" disabled={true} value={bedrooms} className="bath-input" />

                                <button
                                    type="button"
                                    name="bedroom_minus"
                                    className="bath_buttons"
                                    onClick={() => handleBedroomChange("decrease")}>
                                    <MinusOutlined />
                                </button>
                            </div>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                                padding: "30px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center"
                            }}>
                            <Typography variant="subtitle1">
                                <BathtubIcon color="primary" style={{ fontSize: "30px" }} />
                            </Typography>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <button
                                    type="button"
                                    name="bathroom_plus"
                                    className="bath_buttons"
                                    onClick={() => handleBathroomChange("increase")}>
                                    <PlusOutlined />
                                </button>

                                <input name="bathrooms" disabled={true} value={bathrooms} className="bath-input" />

                                <button
                                    type="button"
                                    name="bathroom_minus"
                                    className="bath_buttons"
                                    onClick={() => handleBathroomChange("decrease")}>
                                    <MinusOutlined />
                                </button>
                            </div>
                        </Grid>

                        <input
                            style={{ visibility: "hidden", width: "0px" }}
                            name="bathrooms"
                            disabled={true}
                            value={formik.values.bathrooms}
                            className="bath-input"
                            type="text"
                        />
                    </Grid>
                </>
            )}

            {/* <Typography variant="h6" gutterBottom>
        Amenities
      </Typography> */}

            <div className={classes.buttons}>
                <Button
                    variant="contained"
                    color="primary"
                    // onClick={handleNext}
                    className={classes.button}
                    type={"submit"}>
                    Next
                </Button>
            </div>
        </form>
    );
}

interface Categories {
    title: string;
}

const categoryRange = [{ title: "Residential" }, { title: "Commercial" }, { title: "Agricultural" }, { title: "Party and Events" }];
