import React, { useEffect, useContext } from "react";
import { User, Organization, AdminProviderContext } from "./admin-provider-context";
import { PopupProviderContext } from "../popup-alerts-provider/popup-context";
import { useState } from "react";
import axios, { AxiosResponse } from "axios";
import { AUTH_TOKEN_KEY, USER_KEY, ORGANIZATION_KEY, SERVICE_PROVIDER_KEY } from "../keys/keys";
import { useNavigate } from "react-router-dom";
import { useQueryParams } from "../../utils/use-query-params";
import Spin from "antd/es/spin";
import { logError } from "../../utils/logError";

interface IAdminProvider {
    restricted?: boolean;
    owner_restricted?: boolean;
    children: any;
    no_auth?: boolean;
}

export const AdminProvider = ({ children, restricted, owner_restricted, no_auth }: IAdminProvider): JSX.Element => {
    const [user, setUser] = useState<User | null>(null);
    const [owner, setOwner] = useState<User | null>(null);
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [fetching, setFetching] = useState(false);
    const { flashError } = useContext(PopupProviderContext);
    const navigate = useNavigate();

    let qp = useQueryParams();
    const org = qp.org;
    const key = qp.key;

    const refreshAdmin = (): void => {
        const number = localStorage.getItem("Number") ? JSON.parse(localStorage.getItem("number") as any) : 0;
        console.log("Hi admin", number + 1);
        localStorage.setItem("number", number + 1);
        const token: any = localStorage.getItem(AUTH_TOKEN_KEY) ? JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY) as any) : null;
        const user_store = localStorage.getItem(USER_KEY) ? JSON.parse(localStorage.getItem(USER_KEY) as any) : null;
        setUser(user_store);
        console.log("tracker 1");
        if (restricted && !token) {
            console.log("tracker 2");
            if (window.location.pathname === "/list-property") {
                navigate("/info-list-property");
                return;
            }
            let redirectPath = `/login`;

            if (window.location.pathname) {
                // redirectPath += `?next=${window.location.pathname + window.location.search}`;
                redirectPath += `?next=${window.location.pathname}${org ? "&org=" + org : ""}${key ? "&key=" + key : ""}`;
            }

            window.location.href = redirectPath;
            return;
        }
        if (restricted && token) {
            console.log("tracker 3");
            setFetching(true);
            axios
                .get("/auth/user")
                .then(res => {
                    localStorage.setItem(USER_KEY, JSON.stringify(res.data.user));
                    setUser(res.data.user);
                    if (res.data.user.user_type === "owner" || res.data.user.user_type === "organization_member") {
                        setOwner(res.data.user);
                    }
                    setFetching(false);
                })
                .catch(err => {
                    // localStorage.removeItem(USER_KEY);
                    // flashError("You need to log in");
                    navigate("/");
                    setFetching(false);
                })
                .then(() => setFetching(false));
            return;
        }
        if (no_auth) {
            setFetching(true);
            axios
                .get("/auth/user", { params: { auth: "no-auth" } })
                .then(res => {
                    localStorage.setItem(USER_KEY, JSON.stringify(res.data.user));
                    setUser(res.data.user);
                    if (res.data.user.user_type === "owner" || res.data.user.user_type === "organization_member") {
                        setOwner(res.data.user);
                    }
                    setFetching(false);
                })
                .catch(err => {
                    // localStorage.removeItem(USER_KEY);
                    // flashError("You need to log in");
                    navigate("/");
                    setFetching(false);
                })
                .then(() => setFetching(false));
            return;
        }
        setFetching(true);
        console.log("tracker 4", restricted);
        // axios
        //     .get("/auth/user")
        //     .then(res => {
        //         localStorage.setItem(USER_KEY, JSON.stringify(res.data.user));
        //         setUser(res.data.user);
        //         if (res.data.user.user_type === "owner" || res.data.user.user_type === "organization_member") {
        //             setOwner(res.data.user);
        //         }
        //         setFetching(false);
        //     })
        //     .then(() => setFetching(false));
        return;
    };

    function getUser() {
        setFetching(true);
        console.log("testing fethcing user");
        axios
            .get("/auth/user")
            .then(res => {
                localStorage.setItem(USER_KEY, JSON.stringify(res.data.user));
                setUser(res.data.user);
                if (res.data.user.user_type === "owner" || res.data.user.user_type === "organization_member") {
                    setOwner(res.data.user);
                }
                setFetching(false);
            })
            .catch(err => {
                // localStorage.removeItem(USER_KEY);
                // flashError("You need to log in");
                navigate("/");
                setFetching(false);
            })
            .then(() => setFetching(false));
        return;
    }

    const fetchUser = async () => {
        setFetching(true);
        axios
            .get("/auth/user")
            .then(res => {
                localStorage.setItem(USER_KEY, JSON.stringify(res.data.user));
                setUser(res.data.user);
                console.log(res.data.user);
                if (res.data.user.user_type === "owner" || res.data.user.user_type === "organization_member") {
                    setOwner(res.data.user);
                }
                setFetching(false);
            })
            .then(() => setFetching(false));
    };

    const checkAdmin = async () => {
        // let current_admin: any = null;
        if (!fetching) {
            const user_store = localStorage.getItem(USER_KEY) ? JSON.parse(localStorage.getItem(USER_KEY) as any) : null;
            setUser(user_store);

            console.log("Sequence", user);
            if (restricted && !owner_restricted && user_store) {
                console.log("1a");
                return;
            }
            if (restricted && !user_store) {
                let redirectPath = `/login`;

                if (window.location.pathname) {
                    redirectPath += `?next=${window.location.pathname}${org ? "&org=" + org : ""}${key ? "&key=" + key : ""}`;
                }

                window.location.href = redirectPath;
                console.log("1b");
                return;
            }
            if (restricted && user?.user_type === "seeker") {
                console.log("1c");
                navigate("/info-list-property");
                return;
            }
            return;
        }
    };

    useEffect(refreshAdmin, [restricted]);
    useEffect(() => {
        checkAdmin();
    }, [window.location.pathname, fetching]);

    return (
        <AdminProviderContext.Provider value={{ user, refreshAdmin, getUser, organization }}>
            {restricted && fetching && (
                <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin />
                </div>
            )}
            {((restricted && user) || !restricted) && children}
        </AdminProviderContext.Provider>
    );
};
