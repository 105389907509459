import React from 'react';

const Responses = () => {
		return (
			<div>
				<img
					src={require('../assets/response.png')}
					alt="response under construction"
					width="100%"
					height="100%"
				/>
			</div>
		);
}

export default Responses;
