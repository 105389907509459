import React, { useState, useEffect } from "react";
import Typography from "antd/es/typography";
import Alert from "antd/es/alert";
// import TextField from "@mui/material/TextField";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import { css } from "../../config/my-aphrodite";
import styles from "../../Styles/AuthenticationFormsStyle";
import * as Yup from "yup";
import { useFormik } from "formik";
import Spin from "antd/es/spin";
import { makeStyles } from "@mui/styles";
import { logError } from "../../utils/logError";

export const SignUp = ({ closeSignUp, openLogin }) => {
    const { Title } = Typography;

    const useStyles = makeStyles(theme => ({
        root: {
            height: "100vh"
        },
        image: {
            backgroundImage: "url(https://source.unsplash.com/random)",
            backgroundRepeat: "no-repeat",
            backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center"
        },
        paper: {
            margin: theme.spacing(8, 4),
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(1)
        },
        submit: {
            margin: theme.spacing(3, 0, 2)
        }
    }));

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [showPassword2, setShowPassword2] = useState<boolean>(false);
    const [showPassword3, setShowPassword3] = useState<boolean>(false);
    const [showCode, setShowCode] = useState<boolean>(false);
    const [confirmDetails, setConfirmDetails] = useState({ confirmMessage: "", confirmError: "", confirmLoading: false });

    let phone_regex = /^([026|26]).\d{7,8}$/;

    const validationSchemaSignUp = Yup.object({
        firstName: Yup.string()
            .matches(/^[A-Za-z ]*$/, "Please enter valid first name")
            .max(40)
            .required(),
        lastName: Yup.string()
            .matches(/^[A-Za-z ]*$/, "Please enter valid last name")
            .max(40)
            .required(),
        email: Yup.string().email("Invalid email format").required("Email is required"),
        phoneNumber: Yup.string().required("Phone number is required").matches(phone_regex, "Phone number format is not valid"),
        password: Yup.string()
            .required("Password is required")
            .matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Password must contain at least 8 characters, one uppercase, one number and one special case"
            ),
        confirmPassword: Yup.string()
            .required("Confirm password is required")
            .when("password", {
                is: password => (password && password.length > 0 ? true : false),
                then: Yup.string().oneOf([Yup.ref("password")], "Passwords do not match")
            }),
        acknowledge: Yup.bool().oneOf([true], "Field must be checked")
    });

    const formikSignUp = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            password: "",
            confirmPassword: "",
            acknowledge: false
        },
        validationSchema: validationSchemaSignUp,
        onSubmit: values => {
            console.log(values);
            values["mobile"] = values.phoneNumber;
            setLoading(true);
            axios
                .post("/auth/signup", { ...values, user_type: "seeker" })
                .then((res: any) => {
                    console.log(res);
                    setSuccess(true);
                    openLogin();
                })
                .catch(err => {
                    console.log(err);
                    setError(logError(err));
                    setLoading(false);
                })
                .then(() => {
                    setLoading(false);
                });
        }
    });

    const validationSchemaConfirmRegistration = Yup.object({
        email: Yup.string().email("Invalid email format").required("Email is required"),
        confirmationCode: Yup.string().required("Confirmation code required")
    });

    const formikConfirmRegistration = useFormik({
        initialValues: {
            confirmEmail: "",
            confirmationCode: ""
        },
        validationSchema: validationSchemaConfirmRegistration,
        onSubmit: values => {
            console.log(values);
            // dispatch(
            //     confirmRegisteredUser({
            //         confirmationCode: values.confirmationCode,
            //         email: values.confirmEmail.trim().toLowerCase()
            //     })
            // );
        }
    });

    useEffect(() => console.log(formikSignUp), [formikSignUp]);

    return (
        <div>
            {success === false ? (
                <div>
                    <div className="login-text">
                        <div className="text-section">
                            {" "}
                            <Title level={2}>Register</Title>
                        </div>
                    </div>
                    <div className="auth-spinner"> {error ? <Alert message={error} type="error" showIcon /> : ""}</div>

                    <form onSubmit={formikSignUp.handleSubmit}>
                        <div className={css(styles.form)}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="fname"
                                        name="firstName"
                                        variant="outlined"
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        value={formikSignUp.values.firstName}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.firstName && formikSignUp.errors.firstName ? true : false}
                                        helperText={formikSignUp.touched.firstName && formikSignUp.errors.firstName}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="lname"
                                        value={formikSignUp.values.lastName}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.lastName && formikSignUp.errors.lastName ? true : false}
                                        helperText={formikSignUp.touched.lastName && formikSignUp.errors.lastName}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} style={{ marginTop: "20px" }}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="phone"
                                        label="Phone Number"
                                        name="phoneNumber"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">+233</InputAdornment>
                                        }}
                                        value={formikSignUp.values.phoneNumber}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.phoneNumber && formikSignUp.errors.phoneNumber ? true : false}
                                        helperText={formikSignUp.touched.phoneNumber && formikSignUp.errors.phoneNumber}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    {/* <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        name="email"
                                        value={formikSignUp.values.email}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.email && formikSignUp.errors.email ? true : false}
                                        helperText={formikSignUp.touched.email && formikSignUp.errors.email}
                                        size="small"
                                    /> */}
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} style={{ marginTop: "20px" }}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        name="email"
                                        value={formikSignUp.values.email}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.email && formikSignUp.errors.email ? true : false}
                                        helperText={formikSignUp.touched.email && formikSignUp.errors.email}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>

                            <div className={css(styles.box_form_div_password)} style={{ margin: "30px 0 0 0" }}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="first_password"
                                        label="Password"
                                        name="password"
                                        type={showPassword2 ? "text" : "password"}
                                        value={formikSignUp.values.password}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.password && formikSignUp.errors.password ? true : false}
                                        InputProps={{
                                            endAdornment:
                                                formikSignUp.values.password !== "" ? (
                                                    !showPassword2 ? (
                                                        <i onClick={() => setShowPassword2(!showPassword2)} className="fas fa-eye"></i>
                                                    ) : (
                                                        <i
                                                            onClick={() => setShowPassword2(!showPassword2)}
                                                            className="fas fa-eye-slash"></i>
                                                    )
                                                ) : null
                                        }}
                                        size="small"
                                    />
                                    <FormHelperText
                                        error={formikSignUp.touched.password && formikSignUp.errors.password ? true : false}>
                                        {" "}
                                        Password must have an uppercase characters, a lowercase character, a number, a special
                                        character, and must be 8 characters long
                                    </FormHelperText>
                                </Grid>
                            </div>

                            <div
                                /*className={css(styles.box_form_div_password)}*/
                                style={{ margin: "20px 0 7px 0", width: "100%" }}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="confirm_password"
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        type={showPassword3 ? "text" : "password"}
                                        value={formikSignUp.values.confirmPassword}
                                        onChange={formikSignUp.handleChange}
                                        error={
                                            formikSignUp.touched.confirmPassword && formikSignUp.errors.confirmPassword ? true : false
                                        }
                                        helperText={formikSignUp.touched.confirmPassword && formikSignUp.errors.confirmPassword}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                formikSignUp.values.confirmPassword !== "" ? (
                                                    !showPassword3 ? (
                                                        <i onClick={() => setShowPassword3(!showPassword3)} className="fas fa-eye"></i>
                                                    ) : (
                                                        <i
                                                            onClick={() => setShowPassword3(!showPassword3)}
                                                            className="fas fa-eye-slash"></i>
                                                    )
                                                ) : null
                                        }}
                                    />
                                </Grid>
                            </div>

                            <Grid container style={{ margin: "20px 0 15px 0" }}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                name="acknowledge"
                                                checked={formikSignUp.values.acknowledge}
                                                onChange={formikSignUp.handleChange}
                                            />
                                        }
                                        label={
                                            <span
                                                style={{
                                                    color:
                                                        formikSignUp.touched.acknowledge && formikSignUp.errors.acknowledge
                                                            ? "red"
                                                            : "black"
                                                }}>
                                                <small>
                                                    By signing up, I accept the{" "}
                                                    <RouterLink
                                                        style={{ color: "#3f51b5" }}
                                                        to="/about"
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        {" "}
                                                        Aqivah Terms of Service{" "}
                                                    </RouterLink>
                                                    and acknowledge the{" "}
                                                    <RouterLink
                                                        to="/privacy-policy"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ textDecoration: "underline", color: "#3f51b5" }}>
                                                        Privacy Policy.
                                                    </RouterLink>
                                                </small>
                                            </span>
                                        }
                                    />
                                </Grid>
                            </Grid>

                            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                                {loading ? <Spin spinning={true} size="small"></Spin> : "Register"}
                            </Button>
                            <Grid container spacing={2} className="mt-2">
                                <Grid item sm={12} md={6} lg={6}>
                                    <RouterLink onClick={closeSignUp} to="/info-list-property">
                                        <small>Register as Owner?</small>
                                    </RouterLink>
                                </Grid>
                                <Grid item>
                                    <Link
                                        style={{ textTransform: "none", textDecoration: "none" }}
                                        onClick={() => openLogin()}
                                        variant="body2">
                                        <small>Already have an account? Sign in</small>
                                    </Link>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </div>
            ) : (
                <div>
                    <div className="login-text">
                        <div className="text-section">
                            {" "}
                            <Title level={2}>Confirmation Code</Title>
                        </div>
                    </div>
                    {confirmDetails.confirmError ? (
                        <div className="auth-spinner">
                            {" "}
                            <Alert message={confirmDetails.confirmError} type="error" showIcon />{" "}
                        </div>
                    ) : (
                        ""
                    )}
                    {confirmDetails.confirmMessage ? (
                        <div className="auth-spinner">
                            {" "}
                            <Alert message={confirmDetails.confirmMessage} type="success" showIcon />{" "}
                        </div>
                    ) : (
                        ""
                    )}

                    <form onSubmit={formikConfirmRegistration.handleSubmit}>
                        <div className="input-holder">
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="confirmEmail"
                                    value={formikConfirmRegistration.values.confirmEmail}
                                    onChange={formikConfirmRegistration.handleChange}
                                    error={
                                        formikConfirmRegistration.touched.confirmEmail &&
                                        Boolean(formikConfirmRegistration.errors.confirmEmail)
                                    }
                                    helperText={
                                        formikConfirmRegistration.touched.confirmEmail && formikConfirmRegistration.errors.confirmEmail
                                    }
                                    size="small"
                                />
                            </Grid>
                        </div>

                        <div
                            /*className={css(styles.box_form_div_password)}*/ style={{
                                marginTop: "20px",
                                width: "100%",
                                background: "red !important"
                            }}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="confirmation_code"
                                    label="Confirmation Code"
                                    name="confirmationCode"
                                    type={showCode ? "text" : "password"}
                                    value={formikConfirmRegistration.values.confirmationCode}
                                    onChange={formikConfirmRegistration.handleChange}
                                    error={
                                        formikConfirmRegistration.touched.confirmationCode &&
                                        Boolean(formikConfirmRegistration.errors.confirmationCode)
                                    }
                                    helperText={
                                        formikConfirmRegistration.touched.confirmationCode &&
                                        formikConfirmRegistration.errors.confirmationCode
                                    }
                                    size="small"
                                    InputProps={{
                                        endAdornment:
                                            formikConfirmRegistration.values.confirmationCode !== "" ? (
                                                !showCode ? (
                                                    <i onClick={() => setShowCode(!showCode)} className="fas fa-eye"></i>
                                                ) : (
                                                    <i onClick={() => setShowCode(!showCode)} className="fas fa-eye-slash"></i>
                                                )
                                            ) : null
                                    }}
                                />
                            </Grid>
                        </div>

                        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                            {confirmDetails.confirmLoading ? <Spin spinning={true} size="small"></Spin> : "Confirm Account"}
                        </Button>
                    </form>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} justify="flex-end">
                            <Link
                                onClick={() => {
                                    closeSignUp();
                                    openLogin();
                                }}
                                style={{ textDecoration: "none", textTransform: "none" }}
                                variant="body2">
                                <small>Login</small>
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    );
};
