import React, { useState, useContext } from "react";
import { PropertyContext } from "./property-context";
import axios from "axios";
import { PopupProviderContext } from "../../common/popup-alerts-provider/popup-context";

export const PropertyProvider = ({ children }) => {
    const PROPERTY_UPLOAD = "upload-property";
    const { flashError } = useContext(PopupProviderContext);

    const [property, setProperty] = useState({
        category: "",
        type: "",
        acquisition: "",
        bedrooms: 1,
        bathrooms: 1,
        buildYear: "",
        price: "",
        currency: "GHC",
        description: "",
        amenities: "",
        tags: "",
        location: "",
        unit: "",
        measurement: "",
        seating_capacity: "",
        parking_capacity: ""
    });

    const [uploading, setUploading] = useState<any>(false);
    const [name, setName] = useState<any>(0);
    const [location, setLocation] = useState<any>([]);
    const [files, setFiles] = useState<any>([]);
    const [deleteId, setDeleteId] = useState("");
    const [events, setEvents] = useState<Array<string>>([]);

    const [refresh, setRefresh] = useState("");

    const reset = () => {
        setLocation([]);
        setFiles([]);
        setDeleteId("");

        setProperty({
            category: "residential",
            type: "",
            acquisition: "",
            bedrooms: 1,
            bathrooms: 1,
            buildYear: "",
            price: "",
            currency: "GHC",
            description: "",
            amenities: "",
            tags: "",
            location: "",
            unit: "",
            measurement: "",
            seating_capacity: "",
            parking_capacity: ""
        });
    };

    const handleFieldChange = (e: any) => {
        setProperty({ ...property, [e.target.name]: e.target.value });
    };

    const handleUploadClick = () => {
        (document as any).getElementById("upload-btn").click();
    };

    const handleCategoryChange = e => {
        if (e.target.checked) {
            setProperty({ ...property, category: e.target.name });
        }
    };

    const handleFileDelete = (file: any) => {
        setDeleteId(file);
        axios
            .post("auth/delete-file", { filename: file })
            .then((res: any) => {
                console.log(res);
                const newList = files.filter(filename => filename !== file);
                const newLocation = location.filter(files => files.name !== file);
                setFiles(newList);
                setLocation(newLocation);
            })
            .catch(err => {
                console.log(err);
                flashError("Something went wrong", "Could not complete process");
                // setMessage({ message: "Could not complete process", type: "error" });
            })
            .then(() => setDeleteId(""));
    };

    const handleImageUpload = async (values: any) => {
        const fileList = new FormData();

        Array.from(values.target.files).forEach((file: any) => {
            fileList.append("files", file);
        });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };

        axios
            .post("properties/image-upload", fileList, config)
            .then((res: any) => {
                console.log(res);
                console.log(res.data);
                setFiles([...files, ...res.data.filename]);
                setLocation([...location, ...res.data.filelocation]);
                setUploading(false);
            })
            .catch((err: any) => {
                setUploading(false);
                flashError("Something went wrong", "Could not complete process");
            });
        setUploading(true);
    };

    return (
        <PropertyContext.Provider
            value={{
                property,
                setProperty,
                uploading,
                setUploading,
                name,
                setName,
                location,
                setLocation,
                files,
                setFiles,
                deleteId,
                setDeleteId,
                reset,
                handleFieldChange,
                handleUploadClick,
                handleCategoryChange,
                handleFileDelete,
                handleImageUpload,
                events,
                setEvents,
                PROPERTY_UPLOAD,
                refresh,
                setRefresh
            }}>
            {children}
        </PropertyContext.Provider>
    );
};
