import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
    USER_REGISTER_RESET,
    USER_REGISTER_FAIL,
    USER_CONFIRM_RESET,
    USER_REGISTER_SUCCESS,
    USER_CONFIRM_FAIL,
    USER_LOGIN_RESET,
    USER_PASSWORD_RESET_CODE
} from "../constants/userConstants";
import { useDispatch, useSelector } from "react-redux";
import {
    userRegistration,
    confirmRegisteredUser,
    loginUser,
    openLoginModalOnClick,
    openForgotPasswordModalOnClick,
    forgotPasswordRequest,
    resetPassword
} from "../Actions/userActions";
import { css } from "../config/my-aphrodite";
import "../CSS_Styling/LoginPageCSS.css";
import "../CSS_Styling/LandingPageCSS.css";
import "../CSS_Styling/LoginPageCSS.css";
import Spin from "antd/es/spin";
import Modal from "antd/es/modal";
import Alert from "antd/es/alert";
import Typography from "antd/es/typography";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import styles from "../Styles/AuthenticationFormsStyle";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import * as Yup from "yup";
import { useFormik } from "formik";
import { UserContext } from "../context/user-context/user-context";
import axios from "axios";

const { Title } = Typography;

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh"
    },
    image: {
        backgroundImage: "url(https://source.unsplash.com/random)",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center"
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export const useSignInModel = history => {
    const { createUser } = useContext(UserContext);
    const classes = useStyles();

    const [phone_number_formatted, setPhoneFormat] = useState<string>("");

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPassword2, setShowPassword2] = useState<boolean>(false);
    const [showPassword3, setShowPassword3] = useState<boolean>(false);
    const [showCode, setShowCode] = useState<boolean>(false);

    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState<boolean>(false);

    const [openSignUpModal, setOpenSignUpModal] = useState<boolean>(false);
    const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
    const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState<any>(false);

    const validationSchemaLogin = Yup.object({
        email: Yup.string().email("Invalid email format").required("Email is required"),
        password: Yup.string().required("Password is required")
    });

    function login(values: any) {
        axios
            .post("/login", values)
            .then((res: any) => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const formikLogin = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: validationSchemaLogin,
        onSubmit: values => {
            console.log("login init");
            login(values);
            // dispatch({
            //     type: USER_LOGIN_RESET
            // });
            // dispatch(
            //     loginUser({
            //         email: values.email.trim().toLowerCase(),
            //         password: values.password
            //     })
            // );
        }
    });

    let phone_regex = /^([026|26]).\d{7,8}$/;

    const validationSchemaSignUp = Yup.object({
        firstName: Yup.string()
            .matches(/^[A-Za-z ]*$/, "Please enter valid first name")
            .max(40)
            .required(),
        lastName: Yup.string()
            .matches(/^[A-Za-z ]*$/, "Please enter valid last name")
            .max(40)
            .required(),
        email: Yup.string().email("Invalid email format").required("Email is required"),
        phoneNumber: Yup.string().required("Phone number is required").matches(phone_regex, "Phone number format is not valid"),
        password: Yup.string()
            .required("Password is required")
            .matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Password must contain at least 8 characters, one uppercase, one number and one special case"
            ),
        confirmPassword: Yup.string()
            .required("Confirm password is required")
            .when("password", {
                is: password => (password && password.length > 0 ? true : false),
                then: Yup.string().oneOf([Yup.ref("password")], "Passwords do not match")
            }),
        acknowledge: Yup.bool().oneOf([true], "Field must be checked")
    });

    const formikSignUp = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            password: "",
            confirmPassword: "",
            acknowledge: false
        },
        validationSchema: validationSchemaSignUp,
        onSubmit: values => {
            console.log(values);
            createUser({
                email: values.email.trim().toLowerCase(),
                password: values.password,
                mobile: values.phoneNumber,
                firstName: values.firstName,
                lastName: values.lastName
            });
            // try {
            //     dispatch(
            //         userRegistration({
            //             email: values.email.trim().toLowerCase(),
            //             password: values.password,
            //             phone_number: values.phoneNumber,
            //             first_name: values.firstName,
            //             last_name: values.lastName
            //         })
            //     );
            // } catch (err) {
            //     dispatch({
            //         type: USER_REGISTER_FAIL,
            //         payload: err
            //     });
            // }
        }
    });

    const validationSchemaConfirmRegistration = Yup.object({
        email: Yup.string().email("Invalid email format").required("Email is required"),
        confirmationCode: Yup.string().required("Confirmation code required")
    });

    const formikConfirmRegistration = useFormik({
        initialValues: {
            confirmEmail: "",
            confirmationCode: ""
        },
        validationSchema: validationSchemaConfirmRegistration,
        onSubmit: values => {
            dispatch(
                confirmRegisteredUser({
                    confirmationCode: values.confirmationCode,
                    email: values.confirmEmail.trim().toLowerCase()
                })
            );
        }
    });

    const validationSchemaRequestPasswordReset = Yup.object({
        email: Yup.string().email("Invalid email format").required("Email is required")
    });

    const formikRequestPasswordReset = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: validationSchemaRequestPasswordReset,
        onSubmit: values => {
            dispatch(forgotPasswordRequest(values.email));
        }
    });

    const validationSchemaSetNewPassword = Yup.object({
        code: Yup.string().required("Required! Confirmation code sent to your email"),
        newPassword: Yup.string()
            .required("Password is required")
            .matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Password must contain at least 8 characters, one uppercase, one number and one special case"
            ),
        confirmNewPassword: Yup.string()
            .required("Passwords do not match")
            .when("newPassword", {
                is: password => (password && password.length > 0 ? true : false),
                then: Yup.string().oneOf([Yup.ref("newPassword")], "Password doesn't match")
            })
    });

    const formikSetNewPassword = useFormik({
        initialValues: {
            code: "",
            newPassword: "",
            confirmNewPassword: ""
        },
        validationSchema: validationSchemaSetNewPassword,
        onSubmit: values => {
            dispatch(
                resetPassword({
                    resetEmail: formikRequestPasswordReset.values.email,
                    passwordResetCode: values.code,
                    newPassword: values.newPassword,
                    page: "modal"
                })
            );
        }
    });

    // @ts-ignore: Unreachable code error
    //const open_login = useSelector((state) => state.openLogin);
    //const { open } = open_login;

    //@ts-ignore: Unreachable code error
    const open_forgotPassword = useSelector(state => state.openForgotPassword);
    const { open: open_forgotpassword } = open_forgotPassword;

    // @ts-ignore: Unreachable code error
    const userForgotPassword = useSelector(state => state.userForgotPassword);
    const { code, loading: forgotPasswordLoading, success: forgotPasswordSuccess, error: forgotPasswordError } = userForgotPassword;

    // @ts-ignore: Unreachable code error
    const userResetPassword = useSelector(state => state.userResetPassword);
    const { loading: resetPasswordLoading, success: resetPasswordSuccess, error: resetPasswordError } = userResetPassword;

    useEffect(() => {
        if (open_forgotpassword) {
            if (!openForgotPasswordModal) {
                setOpenForgotPasswordModal(true);
            }
        }

        if (!open_forgotpassword) {
            setOpenForgotPasswordModal(false);
        }
    }, [open_forgotPassword, open_forgotpassword, openForgotPasswordModal]);

    const dispatch = useDispatch();

    // @ts-ignore: Unreachable code error
    const registerUser = useSelector(state => state.registerUser);
    const { loading, success, error } = registerUser;

    // @ts-ignore: Unreachable code error
    const confirmUser = useSelector(state => state.confirmUser);
    const { message: confirmMessage, loading: confirmLoading, success: confirmSuccess, error: confrimError } = confirmUser;

    // @ts-ignore: Unreachable code error
    const userLogin = useSelector(state => state.userLogin);
    const { loading: loginLoading, success: loginSuccess, error: loginError } = userLogin;

    //Confrim User
    useEffect(() => {
        setTimeout(() => {
            if (confirmSuccess === true) {
                closeSignUp();
                openLogin();
            }
        }, 2000);

        if (confirmSuccess === true) {
            setTimeout(() => {
                dispatch({
                    type: USER_CONFIRM_RESET
                });
            }, 2500);
        }
    }, [confirmSuccess]);

    //@@ Password reset success
    useEffect(() => {
        setTimeout(() => {
            if (resetPasswordSuccess) {
                closeForgotPassword();
                openLogin();

                dispatch(openForgotPasswordModalOnClick(false));
                setOpenForgotPasswordModal(false);
                setOpenLoginModal(true);
            }
        }, 2000);
    }, [resetPasswordSuccess]);

    useEffect(() => {
        //@@ If user is not confirmed:
        if (loginError === "UserNotConfirmedException") {
            console.log("user not confirmed");
            dispatch({
                type: USER_LOGIN_RESET
            });
            setTimeout(() => {
                dispatch({
                    type: USER_CONFIRM_FAIL,
                    payload: "Please confirm your account. Enter your email and click resend code to get confirmation code code."
                });
            }, 200);
            console.log(history);
            //@@ redirect to the confirmation page to complete account confirmation:
            if (history) history("/confirm-registration");
        }

        if (loginSuccess) {
            setTimeout(() => {
                closeLogin();
                clearFields();
                document.body.style.removeProperty("overflow");
            }, 1500);
        }
    }, [loginError, loginSuccess]);

    const clearFields = () => {
        setShowPassword(false);
        setShowPassword2(false);
        setShowPassword3(false);
        setShowNewPassword(false);
        formikLogin.resetForm({
            values: {
                email: "",
                password: ""
            }
        });
        formikSignUp.resetForm({
            values: {
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                password: "",
                confirmPassword: "",
                acknowledge: false
            }
        });
        formikConfirmRegistration.resetForm({
            values: {
                confirmEmail: "",
                confirmationCode: ""
            }
        });
        formikRequestPasswordReset.resetForm({
            values: {
                email: ""
            }
        });
        formikSetNewPassword.resetForm({
            values: {
                code: "",
                newPassword: "",
                confirmNewPassword: ""
            }
        });
        dispatch({
            type: USER_LOGIN_RESET
        });
        dispatch({
            type: USER_PASSWORD_RESET_CODE
        });
        dispatch({
            type: USER_REGISTER_RESET
        });
        dispatch({
            type: USER_PASSWORD_RESET_CODE
        });
    };

    useEffect(() => {
        clearFields();
        //@@ success
    }, [confirmSuccess]);

    function checkNum(num) {
        console.log(num);
        let a = num.toString();
        if (a.length === 10) {
            if (a.substring(0, 1) === "0") {
                console.log("+233" + a.substring(1, a.length));
                setPhoneFormat("+233" + a.substring(1, a.length));
                console.log(phone_number_formatted);
                return true;
            } else {
                console.log("phone number format not valid: ERR0");
                return false;
            }
        } else if (a.length === 9) {
            if (a.substring(0) === "0") {
                console.log("number format incorrect: ERR1");
                return false;
            } else {
                console.log("+233" + a);
                setPhoneFormat("+233" + a);
                console.log(phone_number_formatted);
                return true;
            }
        } else {
            console.log("invalid phone number length: ERR2");
            return false;
        }
    }

    const emailIsValid = email => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const openLogin = () => {
        clearFields();
        closeSignUp();
        setOpenLoginModal(true);
    };

    function closeLogin() {
        clearFields();
        setOpenSignUpModal(false);
        setOpenLoginModal(false);
        formikLogin.resetForm({
            values: {
                email: "",
                password: ""
            }
        });
    }

    const handleCancel = () => {
        closeSignUp();
    };

    const handleLoginCancel = () => {
        // setOpenLoginModal(false)
        //dispatch(openLoginModalOnClick(false));
    };

    function openSignUp() {
        dispatch(openForgotPasswordModalOnClick(false));

        dispatch({
            type: USER_REGISTER_RESET
        });

        if (openSignUpModal) {
            setOpenSignUpModal(false);
        } else {
            setOpenSignUpModal(true);
        }

        clearFields();
    }

    const closeSignUp = () => {
        clearFields();
        setOpenSignUpModal(false);
    };

    function openForgotPassword() {
        clearFields();
        closeLogin();
        setOpenForgotPasswordModal(true);
        dispatch(openForgotPasswordModalOnClick(true));
    }

    const closeForgotPassword = () => {
        setOpenForgotPasswordModal(false);
        dispatch(openForgotPasswordModalOnClick(false));

        dispatch({
            type: USER_PASSWORD_RESET_CODE
        });
    };

    const loginForm = (
        <Modal visible={openLoginModal} onCancel={/*handleLoginCancel*/ closeLogin} footer={null} width={700}>
            <div>
                <div className="login-text">
                    <div className="text-section">
                        {" "}
                        <Title level={2}>Login</Title>
                    </div>
                </div>
                {loginError ? (
                    <div className="auth-spinner">
                        {" "}
                        <Alert message={loginError} type="error" showIcon />{" "}
                    </div>
                ) : (
                    ""
                )}
                {loginSuccess ? (
                    <div className="auth-spinner">
                        {" "}
                        <Alert message={"Signed in"} type="success" showIcon />{" "}
                    </div>
                ) : (
                    ""
                )}

                <form onSubmit={formikLogin.handleSubmit}>
                    <div className="input-holder">
                        <Grid item xs={12} sm={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="login_email_l"
                                label="Email"
                                name="email"
                                autoFocus
                                value={formikLogin.values.email}
                                onChange={formikLogin.handleChange}
                                error={formikLogin.touched.email && Boolean(formikLogin.errors.email)}
                                helperText={formikLogin.touched.email && formikLogin.errors.email}
                                size="small"
                            />
                        </Grid>
                    </div>

                    <div className={css(styles.box_form_div_password)} style={{ marginTop: "20px" }}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="login_password_"
                                label="Password"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                value={formikLogin.values.password}
                                onChange={formikLogin.handleChange}
                                error={formikLogin.touched.password && formikLogin.errors.password == "true" ? true : false}
                                helperText={formikLogin.touched.password && formikLogin.errors.password}
                                InputProps={{
                                    endAdornment:
                                        formikLogin.values.password !== "" ? (
                                            !showPassword ? (
                                                <i onClick={() => setShowPassword(!showPassword)} className="fas fa-eye"></i>
                                            ) : (
                                                <i onClick={() => setShowPassword(!showPassword)} className="fas fa-eye-slash"></i>
                                            )
                                        ) : null
                                }}
                                size="small"
                            />
                        </Grid>
                    </div>

                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                        {loginLoading ? <Spin spinning={true} size="small"></Spin> : "Login"}
                    </Button>
                </form>

                <Grid container style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Link
                        onClick={() => {
                            closeLogin();
                            openSignUp();
                        }}
                        variant="body2">
                        Register
                    </Link>

                    <Link
                        onClick={() => {
                            openForgotPassword();
                        }}
                        variant="body2">
                        Forgot Password or Username?
                    </Link>
                </Grid>
            </div>
        </Modal>
    );

    const signUpForm = (
        <Modal visible={openSignUpModal} onCancel={closeSignUp} footer={null} width={800}>
            {success === false ? (
                <div>
                    <div className="login-text">
                        <div className="text-section">
                            {" "}
                            <Title level={2}>Register</Title>
                        </div>
                    </div>
                    <div className="auth-spinner"> {error ? <Alert message={error} type="error" showIcon /> : ""}</div>

                    <form onSubmit={formikSignUp.handleSubmit}>
                        <div className={css(styles.form)}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="fname"
                                        name="firstName"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        value={formikSignUp.values.firstName}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.firstName && Boolean(formikSignUp.errors.firstName)}
                                        helperText={formikSignUp.touched.firstName && formikSignUp.errors.firstName}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="lname"
                                        value={formikSignUp.values.lastName}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.lastName && Boolean(formikSignUp.errors.lastName)}
                                        helperText={formikSignUp.touched.lastName && formikSignUp.errors.lastName}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} style={{ marginTop: "20px" }}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="phone"
                                        label="Phone Number"
                                        name="phoneNumber"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">+233</InputAdornment>
                                        }}
                                        value={formikSignUp.values.phoneNumber}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.phoneNumber && Boolean(formikSignUp.errors.phoneNumber)}
                                        helperText={formikSignUp.touched.phoneNumber && formikSignUp.errors.phoneNumber}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        name="email"
                                        value={formikSignUp.values.email}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.email && Boolean(formikSignUp.errors.email)}
                                        helperText={formikSignUp.touched.email && formikSignUp.errors.email}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>

                            <div className={css(styles.box_form_div_password)} style={{ margin: "20px 0 7px 0" }}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="first_password"
                                        label="Password"
                                        name="password"
                                        type={showPassword2 ? "text" : "password"}
                                        value={formikSignUp.values.password}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.password && formikSignUp.errors.password ? true : false}
                                        InputProps={{
                                            endAdornment:
                                                formikSignUp.values.password !== "" ? (
                                                    !showPassword2 ? (
                                                        <i onClick={() => setShowPassword2(!showPassword2)} className="fas fa-eye"></i>
                                                    ) : (
                                                        <i
                                                            onClick={() => setShowPassword2(!showPassword2)}
                                                            className="fas fa-eye-slash"></i>
                                                    )
                                                ) : null
                                        }}
                                        size="small"
                                    />
                                    <FormHelperText
                                        error={formikSignUp.touched.password && formikSignUp.errors.password ? true : false}>
                                        {" "}
                                        Password must have an uppercase characters, a lowercase character, a number, a special
                                        character, and must be 8 characters long
                                    </FormHelperText>
                                </Grid>
                            </div>

                            <div className={css(styles.box_form_div_password)} style={{ margin: "20px 0 7px 0" }}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="confirm_password"
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        type={showPassword3 ? "text" : "password"}
                                        value={formikSignUp.values.confirmPassword}
                                        onChange={formikSignUp.handleChange}
                                        error={formikSignUp.touched.confirmPassword && Boolean(formikSignUp.errors.confirmPassword)}
                                        helperText={formikSignUp.touched.confirmPassword && formikSignUp.errors.confirmPassword}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                formikSignUp.values.confirmPassword !== "" ? (
                                                    !showPassword3 ? (
                                                        <i onClick={() => setShowPassword3(!showPassword3)} className="fas fa-eye"></i>
                                                    ) : (
                                                        <i
                                                            onClick={() => setShowPassword3(!showPassword3)}
                                                            className="fas fa-eye-slash"></i>
                                                    )
                                                ) : null
                                        }}
                                    />
                                </Grid>
                            </div>

                            <Grid container justify="center">
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                name="acknowledge"
                                                checked={formikSignUp.values.acknowledge}
                                                onChange={formikSignUp.handleChange}
                                            />
                                        }
                                        label={
                                            <span
                                                style={{
                                                    color:
                                                        formikSignUp.touched.acknowledge && formikSignUp.errors.acknowledge
                                                            ? "red"
                                                            : "black"
                                                }}>
                                                By signing up, I accept the{" "}
                                                <RouterLink
                                                    style={{ color: "#3f51b5" }}
                                                    to="/about"
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    {" "}
                                                    Aqivah Terms of Service{" "}
                                                </RouterLink>
                                                and acknowledge the{" "}
                                                <RouterLink
                                                    to="/privacy-policy"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ textDecoration: "underline", color: "#3f51b5" }}>
                                                    Privacy Policy.
                                                </RouterLink>
                                            </span>
                                        }
                                    />
                                    {/* By signing up, I accept the  <RouterLink style={{color:'#3f51b5'}} to="/about" target="_blank" rel="noopener noreferrer"> Aqivah Terms of Service  </RouterLink>
                    and acknowledge the <RouterLink to="/privacy-policy" target="_blank" rel="noopener noreferrer" style={{textDecoration:"underline", color:'#3f51b5'}}>Privacy Policy.</RouterLink> */}
                                </Grid>
                            </Grid>

                            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                                {loading ? <Spin spinning={true} size="small"></Spin> : "Sign Up"}
                            </Button>
                            <Grid container justify="flex-end">
                                <Grid item>
                                    <Link onClick={() => openLogin()} variant="body2">
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </div>
            ) : (
                <div>
                    <div className="login-text">
                        <div className="text-section">
                            {" "}
                            <Title level={2}>Confirmation Code</Title>
                        </div>
                    </div>
                    {confrimError ? (
                        <div className="auth-spinner">
                            {" "}
                            <Alert message={confrimError} type="error" showIcon />{" "}
                        </div>
                    ) : (
                        ""
                    )}
                    {confirmMessage ? (
                        <div className="auth-spinner">
                            {" "}
                            <Alert message={confirmMessage} type="success" showIcon />{" "}
                        </div>
                    ) : (
                        ""
                    )}

                    <form onSubmit={formikConfirmRegistration.handleSubmit}>
                        <div className="input-holder">
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="confirmEmail"
                                    value={formikConfirmRegistration.values.confirmEmail}
                                    onChange={formikConfirmRegistration.handleChange}
                                    error={
                                        formikConfirmRegistration.touched.confirmEmail &&
                                        Boolean(formikConfirmRegistration.errors.confirmEmail)
                                    }
                                    helperText={
                                        formikConfirmRegistration.touched.confirmEmail && formikConfirmRegistration.errors.confirmEmail
                                    }
                                    size="small"
                                />
                            </Grid>
                        </div>

                        <div className={css(styles.box_form_div_password)} style={{ marginTop: "20px" }}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="confirmation_code"
                                    label="Confirmation Code"
                                    name="confirmationCode"
                                    type={showCode ? "text" : "password"}
                                    value={formikConfirmRegistration.values.confirmationCode}
                                    onChange={formikConfirmRegistration.handleChange}
                                    error={
                                        formikConfirmRegistration.touched.confirmationCode &&
                                        Boolean(formikConfirmRegistration.errors.confirmationCode)
                                    }
                                    helperText={
                                        formikConfirmRegistration.touched.confirmationCode &&
                                        formikConfirmRegistration.errors.confirmationCode
                                    }
                                    size="small"
                                    InputProps={{
                                        endAdornment:
                                            formikConfirmRegistration.values.confirmationCode !== "" ? (
                                                !showCode ? (
                                                    <i onClick={() => setShowCode(!showCode)} className="fas fa-eye"></i>
                                                ) : (
                                                    <i onClick={() => setShowCode(!showCode)} className="fas fa-eye-slash"></i>
                                                )
                                            ) : null
                                    }}
                                />
                            </Grid>
                        </div>

                        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                            {confirmLoading ? <Spin spinning={true} size="small"></Spin> : "Confirm Account"}
                        </Button>
                    </form>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} justify="flex-end">
                            <Link
                                onClick={() => {
                                    closeSignUp();
                                    openLogin();
                                }}
                                variant="body2">
                                Login
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            )}
        </Modal>
    );

    const forgotPasswordForm = (
        <Modal visible={openForgotPasswordModal} onCancel={closeForgotPassword} footer={null} width={700}>
            {!forgotPasswordSuccess && (
                <div>
                    <div className="login-text">
                        <div className="text-section">
                            {" "}
                            <Title level={2}>Reset Password</Title>
                        </div>
                    </div>
                    {resetPasswordError && (
                        <div className="auth-spinner">
                            {" "}
                            <Alert message={resetPasswordError} type="error" showIcon />{" "}
                        </div>
                    )}
                    {resetPasswordSuccess && (
                        <div className="auth-spinner">
                            {" "}
                            <Alert message={"Password reset successful"} type="success" showIcon />{" "}
                        </div>
                    )}

                    <form onSubmit={formikRequestPasswordReset.handleSubmit}>
                        <div className="input-holder">
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="login_email_f1"
                                    label="Email"
                                    name="email"
                                    autoFocus
                                    value={formikRequestPasswordReset.values.email}
                                    onChange={formikRequestPasswordReset.handleChange}
                                    error={
                                        formikRequestPasswordReset.touched.email && Boolean(formikRequestPasswordReset.errors.email)
                                    }
                                    helperText={formikRequestPasswordReset.touched.email && formikRequestPasswordReset.errors.email}
                                    size="small"
                                />
                            </Grid>
                        </div>

                        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                            {forgotPasswordLoading ? <Spin spinning={true} size="small"></Spin> : "Request Code"}
                        </Button>
                    </form>

                    <Grid container style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Link
                            onClick={() => {
                                closeForgotPassword();
                                openLogin();
                            }}
                            variant="body2">
                            Login
                        </Link>

                        <Link
                            onClick={() => {
                                closeForgotPassword();
                                openSignUp();
                            }}
                            variant="body2">
                            Register
                        </Link>
                    </Grid>
                </div>
            )}

            {forgotPasswordSuccess && (
                <div>
                    <div className="login-text">
                        <div className="text-section">
                            {" "}
                            <Title level={2}>Set new password</Title>
                        </div>
                    </div>
                    {forgotPasswordError ? (
                        <div className="auth-spinner">
                            {" "}
                            <Alert message={forgotPasswordError} type="error" showIcon />{" "}
                        </div>
                    ) : (
                        ""
                    )}
                    {forgotPasswordSuccess ? (
                        <div className="auth-spinner">
                            {" "}
                            <Alert message={`Code delivered to ${code.CodeDeliveryDetails.Destination}`} type="info" showIcon />{" "}
                        </div>
                    ) : (
                        ""
                    )}

                    <form onSubmit={formikSetNewPassword.handleSubmit}>
                        <div className="input-holder">
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="login_email_f2"
                                    label="Confirmation Code"
                                    name="code"
                                    autoFocus
                                    autoComplete="code"
                                    value={formikSetNewPassword.values.code}
                                    onChange={formikSetNewPassword.handleChange}
                                    error={formikSetNewPassword.touched.code && Boolean(formikSetNewPassword.errors.code)}
                                    helperText={formikSetNewPassword.touched.code && formikSetNewPassword.errors.code}
                                    size="small"
                                />
                            </Grid>
                        </div>

                        <div className={css(styles.box_form_div_password)} style={{ marginTop: "20px" }}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="login_password_2"
                                    label="New Password"
                                    name="newPassword"
                                    type={showNewPassword ? "text" : "password"}
                                    value={formikSetNewPassword.values.newPassword}
                                    onChange={formikSetNewPassword.handleChange}
                                    error={
                                        formikSetNewPassword.touched.newPassword && Boolean(formikSetNewPassword.errors.newPassword)
                                    }
                                    helperText={formikSetNewPassword.touched.newPassword && formikSetNewPassword.errors.newPassword}
                                    size="small"
                                    InputProps={{
                                        endAdornment:
                                            formikSetNewPassword.values.newPassword !== "" &&
                                            (!showNewPassword ? (
                                                <i onClick={() => setShowNewPassword(!showNewPassword)} className="fas fa-eye"></i>
                                            ) : (
                                                <i
                                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                                    className="fas fa-eye-slash"></i>
                                            ))
                                    }}
                                />
                            </Grid>
                        </div>

                        <div className={css(styles.box_form_div_password)} style={{ marginTop: "20px" }}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="login_password_3"
                                    label="Confirm New Password"
                                    name="confirmNewPassword"
                                    type={showConfirmNewPassword ? "text" : "password"}
                                    value={formikSetNewPassword.values.confirmNewPassword}
                                    onChange={formikSetNewPassword.handleChange}
                                    error={
                                        formikSetNewPassword.touched.confirmNewPassword &&
                                        Boolean(formikSetNewPassword.errors.confirmNewPassword)
                                    }
                                    helperText={
                                        formikSetNewPassword.touched.confirmNewPassword &&
                                        formikSetNewPassword.errors.confirmNewPassword
                                    }
                                    size="small"
                                    InputProps={{
                                        endAdornment:
                                            formikSetNewPassword.values.confirmNewPassword !== "" &&
                                            (!showConfirmNewPassword ? (
                                                <i
                                                    onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                                                    className="fas fa-eye"></i>
                                            ) : (
                                                <i
                                                    onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                                                    className="fas fa-eye-slash"></i>
                                            ))
                                    }}
                                />
                            </Grid>
                        </div>

                        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                            {loginLoading ? <Spin spinning={true} size="small"></Spin> : "Reset Password"}
                        </Button>
                    </form>

                    <Grid container style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Link
                            onClick={() => {
                                closeForgotPassword();
                                openLogin();
                            }}
                            variant="body2">
                            Login
                        </Link>

                        <Link
                            onClick={() => {
                                closeForgotPassword();
                                openSignUp();
                            }}
                            variant="body2">
                            Register
                        </Link>
                    </Grid>
                </div>
            )}
        </Modal>
    );

    return {
        loginForm,
        signUpForm,
        forgotPasswordForm,
        openLogin,
        closeLogin,
        openSignUp,
        closeSignUp,
        openForgotPassword,
        closeForgotPassword
    };
};
