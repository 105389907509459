import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Divider from "antd/es/divider";
import numeral from "numeral";
import "./property-search-page.scss";

interface IProperty {
    id: string | number;
    source: string;
    url: string;
    name: string;
    scrapped_type: string;
    category: string;
    acquisition: string;
    price: number;
    currency: string;
    description: string;
    stay_date: any;
    images: string;
    bedrooms: number;
    bathrooms: number;
    location: any;
    garage: number;
    amenities: string;
    payment_plan: string;
}

export default function PropertyCardScrapper({ property }: { property: IProperty }) {
    const [screenType, setScreenType] = React.useState("desktop");

    React.useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 760) {
                setScreenType("desktop");
            }

            if (window.innerWidth <= 760) {
                setScreenType("mobile");
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });

    const images = property.images ? property.images.split(",") : [];

    const handleImages = (images: string) => {
        const imgs = JSON.parse(images);
        return imgs;
    };
    return screenType == "mobile" ? (
        <Card
            id="property-card-m0"
            className={`search-property-card-m0`}
            /*sx={{ maxWidth: 355 }}*/ sx={{ maxWidth: "100%", display: "flex" }}>
            <CardMedia component="img" height="225" width="194" src={handleImages(property.images)?.[0]} />
            <CardContent>
                <div className="d-flex justify-content-between align-items-center mb-1">
                    <h6 className="fw-bold tag-text">{property?.category}</h6>
                    <i style={{ color: "#3089e8", fontSize: "20px" }} className="fa-regular fa-heart"></i>
                </div>
                <div className="d-flex mb-1">
                    <h6 className="fw-bold location-text">{property?.name}</h6>
                </div>
                <div className="d-flex mb-5">
                    <h5 className="fw-bold">
                        {property?.currency} {numeral(property?.price).format("0,0.00")}
                    </h5>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-2">
                    <span className="d-flex align-items-center" style={{ fontSize: "11px" }}>
                        <i style={{ color: "#cecece", fontSize: "15px" }} className="fa-solid fa-bed me-1"></i>
                        {property?.bedrooms} Beds
                    </span>
                    <Divider type="vertical" />
                    <span className="d-flex align-items-center" style={{ fontSize: "11px" }}>
                        <i style={{ color: "#cecece", fontSize: "15px" }} className="fa-solid fa-shower me-1"></i>
                        {property?.bathrooms} Baths
                    </span>
                </div>

                {/* <div className="d-flex fw-light listing-line">Listing by Murdock Square Group (Accra, Ghana)</div> */}
            </CardContent>
        </Card>
    ) : (
        <div id="property-card-m0-mobile" className="d-flex w-100">
            <img
                src={handleImages(property.images)?.[0]}
                style={{
                    minWidth: "350px",
                    maxWidth: "350px",
                    minHeight: "225px",
                    maxHeight: "225px",
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                }}
                alt=""
            />

            <div className="d-flex flex-column justify-content-start align-items-start p-3 w-100">
                <div className="d-flex justify-content-between align-items-center mb-1 w-100">
                    <h6 className="fw-bold tag-text">{property?.category}</h6>
                    <i style={{ color: "#3089e8", fontSize: "20px" }} className="fa-regular fa-heart"></i>
                </div>
                <div className="d-flex mb-1">
                    <h6 className="fw-bold location-text">{property?.name}</h6>
                </div>
                <div className="d-flex mb-5">
                    <h5 className="fw-bold">
                        {property?.currency} {numeral(property?.price).format("0,0.00")}
                    </h5>
                </div>

                <div className="flex-1-1"></div>

                <div className="d-flex justify-content-between align-items-center mb-2">
                    <span className="d-flex align-items-center" style={{ fontSize: "11px" }}>
                        <i style={{ color: "#cecece", fontSize: "15px" }} className="fa-solid fa-bed me-1"></i>
                        {property?.bedrooms} Beds
                    </span>
                    <Divider type="vertical" />
                    <span className="d-flex align-items-center" style={{ fontSize: "11px" }}>
                        <i style={{ color: "#cecece", fontSize: "15px" }} className="fa-solid fa-shower me-1"></i>
                        {property?.bathrooms} Baths
                    </span>
                </div>

                {/* <div className="d-flex fw-light listing-line">Listing by Murdock Square Group (Accra, Ghana)</div> */}
            </div>
        </div>
    );
}
