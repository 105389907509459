import { UserPool } from "../config/UserPool";
import AWS from "aws-sdk";
import { InMemoryCache, ApolloClient, HttpLink, createHttpLink, ApolloProvider, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { ApolloLink } from "apollo-link";

AWS.config.update({
    region: "us-west-2",
    credentials: new AWS.Credentials({
        accessKeyId: process.env.REACT_APP_AccessKey as string,
        secretAccessKey: process.env.REACT_APP_SecretAccessKey as string
    })
});

const cognitoUser = UserPool.getCurrentUser();

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        );

    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = createHttpLink({
    uri: "http://aqivah-bakend2.herokuapp.com/graphql"
});

export const client = () => {
    const client_ = new ApolloClient({
        link: from([errorLink, httpLink]),
        cache: new InMemoryCache()
    });

    return { client_ };
};

export const getUser = () => {
    // let isAuthValid = false;

    // if (cognitoUser) {
    //     cognitoUser.getSession((err, result) => {
    //         if (err) {
    //             isAuthValid = false;
    //         } else {
    //             isAuthValid = result.isValid();
    //         }
    //     });
    // }
    // return isAuthValid;
    return;
};

export const getSession = async () => {
    // await new Promise((resolve, reject) => {
    //     const user = UserPool.getCurrentUser();
    //     if (user) {
    //         user.getSession((err: any, session: any) => {
    //             if (err) {
    //                 reject();
    //             } else {
    //                 resolve(session);
    //                 console.log("session " + session);
    //             }
    //         });
    //     } else {
    //         reject();
    //     }
    // });
    return;
};
