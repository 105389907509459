import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import PropertyCard from "./card";
import axios from "axios";
import Pagination from "antd/es/pagination";
import Button from "antd/es/button";
import { PopupProviderContext } from "../../../../common/popup-alerts-provider/popup-context";
import { PropertyContext } from "../../../../context/property-context/property-context";
import "./properties.scss";
import { logError } from "../../../../utils/logError";
import Backdrop from "@mui/material/Backdrop";
import Modal from "antd/es/modal";
import { ListPropertyPage } from "../list-property/list-property";
import Select from "antd/es/select";

export const Properties = () => {
    const FETCH_OWNER_PROPERTIES = "fetch owner properties";

    const [properties, setProperties] = useState([]);
    const [filters, setFilters] = useState({ page: 1, pageSize: 15 });
    const [total, setTotal] = useState(0);
    const [events, setEvents] = useState<Array<string>>([]);
    const [open, setOpen] = useState(false);
    const [category, setCategory] = useState("");
    const { flashError } = useContext(PopupProviderContext);
    const { refresh } = useContext(PropertyContext);

    function fetchOwnerProperties() {
        setEvents([...events, FETCH_OWNER_PROPERTIES]);
        axios
            .get("/properties", { params: { ...filters, propertyCategory: category } })
            .then((res: any) => {
                setProperties(res.data.result.properties);
                setTotal(res.data.result.total);
            })
            .catch((err: any) => flashError("Something went wrong", logError(err)))
            .then(() => setEvents(events.filter((event: any) => event !== FETCH_OWNER_PROPERTIES)));
    }

    const handleCategoryChange = (value: string) => {
        setCategory(value);
    };

    const close = () => {
        setOpen(false);
    };

    const handlePageChange = (value: any) => {
        setFilters({ ...filters, page: value });
    };

    useEffect(() => {
        fetchOwnerProperties();
    }, [filters.page, category, refresh]);

    return (
        <div id="properties-db" className="mb-5">
            <Modal open={open} footer={null} onCancel={close} width={820} title={"List Property"}>
                <ListPropertyPage />
            </Modal>
            <Backdrop style={{ zIndex: "5" }} open={events.includes(FETCH_OWNER_PROPERTIES)}>
                <div className="spinner-border text-white" role="status"></div>
            </Backdrop>

            <div className="container-sm">
                <header className="properties-header" style={{ width: "100%", marginBottom: "50px" }}>
                    <h4 style={{}}>Listed Properties</h4>
                    <div className="d-flex">
                        <Select
                            defaultValue=""
                            className="me-1"
                            style={{ minWidth: 140 }}
                            onChange={handleCategoryChange}
                            options={[
                                {
                                    value: "",
                                    label: "All"
                                },
                                {
                                    value: "Residential",
                                    label: "Residential"
                                },
                                {
                                    value: "Commercial",
                                    label: "Commercial"
                                },
                                {
                                    value: "Farmland",
                                    label: "Agric & Farmlands"
                                },
                                {
                                    value: "Events",
                                    label: "Party & Events"
                                }
                            ]}
                        />
                        <Button disabled={events.includes(FETCH_OWNER_PROPERTIES)} className="me-2" onClick={fetchOwnerProperties}>
                            {events.includes(FETCH_OWNER_PROPERTIES) ? (
                                <div className="spinner-border spinner-border-sm" role="status"></div>
                            ) : (
                                <i className="fa-solid fa-arrows-rotate me-2"></i>
                            )}{" "}
                            Refresh
                        </Button>
                        <Button type="primary" onClick={() => setOpen(true)}>
                            <i className="fa-solid fa-plus me-2 text-white"></i>
                            Add Property
                        </Button>
                    </div>
                </header>
                <Grid container spacing={3}>
                    {properties.map((property: any, i: any) => (
                        <Grid key={i} item xs={12} md={6} lg={4}>
                            <div className="property-card">
                                <PropertyCard property={property} />
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <div className="d-flex align-items-center mt-4">
                    <Pagination total={total} current={filters.page} pageSize={filters.pageSize} onChange={handlePageChange} />
                    <Chip label={total} color="default" />
                </div>
            </div>
        </div>
    );
};
