import React, { useEffect, useState } from 'react';
import {UserPool} from "../config/UserPool"
import { useDispatch , useSelector} from 'react-redux';
import { getUserProfile, fetchMessages, authExpiryTime, authTokenRefresh, getNewMessage } from '../Actions/userActions';
import {io} from 'socket.io-client'
import {socket} from "../config/socketioConfig"

function useWebSocketReconnect () {

  // @@ Check socket connection every 30 seconds and reconnect, if disconnected
  const reconnectionInterval = 30000

  const dispatch = useDispatch()

  const handleDateTime = (date) => {
    const days = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    const method = new Date(date);

    return `${
      method.getDate() < 10 ? "0" + method.getDate() : method.getDate()
    } ${months[method.getMonth()]} ${method.getFullYear()}, ${
      method.getHours() < 10 ? "0" + method.getHours() : method.getHours()
    }:${
      method.getMinutes() < 10 ? "0" + method.getMinutes() : method.getMinutes()
    }`;
  };

  useEffect(()=> {

    const refreshConnectionInterval = setInterval(() => {
      //console.log("checking socketIO connection: ", handleDateTime(Date.now()))

      const cognitoUser = UserPool.getCurrentUser()
      if (cognitoUser !== null && !socket.connected) {
        //console.log("socket not connected. Reconnecting....: ", handleDateTime(Date.now()) )
        cognitoUser.getSession(function (err: any, session: any) {
          if (err) {
            console.log(err);
            return
          }
          dispatch(authExpiryTime())
          //console.log('session validity: ' + session.isValid(), handleDateTime(Date.now()) )
          //console.log(session.accessToken.jwtToken)

          // @ts-ignore: Unreachable code error
          socket.io.connect()

          socket.on("connection_error", (err) => {
            //console.log(`connectin error due to ${err.massage}`, err)
          })
          //console.log("username:", session.accessToken.payload.username)
          socket.emit("join", /*socket.id*/session.accessToken.payload.username)
          socket.on('new-message', message => {
            dispatch(getNewMessage(message))  
          })
          
          //console.log(`socket @ ${handleDateTime(Date.now())}`, socket)
        })
      }

      // @ts-ignore: Unreachable code error 
      // cognitoUser.getSession(function (err: any, session: any) {
      //   if (err) {
      //     console.log(err);
      //     return
      //   }

      //   socket.emit("join", /*socket.id*/session.accessToken.payload.username)
      //   socket.on('new-message', message => {
      //     dispatch(getNewMessage(message))  
      //   })
        
      //   console.log(`socket @ ${handleDateTime(Date.now())}`, socket)
      // })
      
      //console.log("conected!: ", socket, handleDateTime(Date.now())) 
    }, reconnectionInterval)
    return () => clearInterval(refreshConnectionInterval)
  }, [])

}

export default useWebSocketReconnect